import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "w3-css/w3.css";
import Tx from "../input";
import "./estiloform.css";

export default function BootstrapModa(props) {
  const [show, setShow] = useState(false);
  const [datos, setDatos] = useState({
    deviceID: "",
    tipo: "",
    grupo: "",
    organizacion: "",
  });

  const expresiones = {
    //usuario: /^[a-zA-Z0-9\_\-]{4,16}$/, // Letras, numeros, guion y guion_bajo

    deviceID: /^[a-zA-Z0-9]/,
    tipo: /^[a-z]{1,40}$/, // Letras y espacios, pueden llevar acentos. /// grupo:
    grupoyorg: /^[a-z]{0,25}$/, // 4 a 12 digitos.
    //correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    cantidad: /^\d{1,5}$/, // 7 a 14 numeros.
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const links =
    "https://aiot.constecoin.com/api/administracion-dispositivos/dispositivos/creacionDispositivo";

  function enviarDatos(e) {
    var envio = {
      deviceID: datos.deviceID,
      tipo: datos.tipo,
      organizacion: datos.organizacion,
      grupo: datos.grupo,
    };
    e.preventDefault();

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(envio),
    };

    console.log(JSON.stringify(envio));
    fetch(links, requestOptions).then((response) => console.log(response));
    setDatos({ deviceID: "", tipo: "", grupo: "", organizacion: "" });
    setShow(false);
  }

  const ingreso = (event) => {
    //console.log(event.target.value)
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className=" w3-modal-content{background-color: transparent} w3-animate-zoom"
        style={{ maxwidth: 600 }}
      >
        <Modal.Header closeButton className="w3-rigth"></Modal.Header>
        <Modal.Body>
          <div className="w3-row">
            <div className="">
              <h1 className="texto-titulo titulo-card titulo-pagina">
                Registro
              </h1>

              <form onSubmit={enviarDatos}>
                <table className="w3-table">
                  <tfoot>
                    <tr>
                      <td>
                        {/* deviceID: "",
      tipo: "",
      grupo: "",
      organizacion: "" */}

                        <Tx
                          etiqueta="deviceID"
                          tipo="text"
                          name="deviceID"
                          onChange={ingreso}
                          value={datos.deviceID}
                          expresion={expresiones.deviceID}
                        />
                      </td>
                      <td>
                        <Tx
                          etiqueta="tipo"
                          tipo="text"
                          name="tipo"
                          onChange={ingreso}
                          value={datos.tipo}
                          expresion={expresiones.tipo}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <Tx
                          etiqueta="grupo"
                          tipo="text"
                          name="grupo"
                          onChange={ingreso}
                          value={datos.grupo}
                          expresion={expresiones.grupoyorg}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <Tx
                          etiqueta="organizacion"
                          tipo="text"
                          name="organizacion"
                          onChange={ingreso}
                          value={datos.organizacion}
                          expresion={expresiones.grupoyorg}
                        />
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <Button className="buttonGeneral" type="submit">
                  Crear{" "}
                </Button>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Button className="buttonGeneral" onClick={handleShow}>
        Registro
      </Button>
    </>
  );
}
