import React from 'react'
import { Columnas, ColumnasPrincipales, Filas, Par, Tabla, TrC } from './ProcesosElementos'


function Prevista(props)  {
  return (
    <>

<Tabla>
    <Filas>
        <ColumnasPrincipales>Nombre de Lote</ColumnasPrincipales>
        <ColumnasPrincipales>Tipo de Lote</ColumnasPrincipales>
        <ColumnasPrincipales>Peso de Lote (Libras)</ColumnasPrincipales>
        <ColumnasPrincipales>Línea</ColumnasPrincipales>
        <ColumnasPrincipales>Cajón</ColumnasPrincipales>
        <ColumnasPrincipales>Fecha</ColumnasPrincipales>
        <ColumnasPrincipales>Hora Inicio</ColumnasPrincipales>
    </Filas>
        {
           props?.data.map((item,i)=>{
            return (
                <TrC>
                    <Columnas><Par>{item.nombreLote}</Par></Columnas>
                    <Columnas>{item.tipolote}</Columnas>
                    <Columnas><Par>{item.pesolote}</Par></Columnas>
                    <Columnas>{item.linea}</Columnas>
                    <Columnas><Par>{item.cajon}</Par></Columnas>
                    <Columnas>{item.fecha}</Columnas>
                    <Columnas><Par>{item.hora}</Par></Columnas>
                </TrC>

                )
    })
        }


        
</Tabla>




    </>
  )
}


export default Prevista