import React, { Component } from "react";
import './ListaDispositivosGeneral.css';

class ShowList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      encabezado: ["IDs seleccionados"],
    };
  }

  renderTableHeader = () => {
    // console.log("entra este ", this.props)
    return Object(
    this.state.encabezado).map(attr =>
    <th key={attr}> {attr.toUpperCase()} </th>
    )
  }

  renderTableRows = () => {
    return this.props.list.map(user => {
      return (
        <tr key={user.id}>
          <td className="Tabla">{user}</td>
        </tr>
      )
    })
  }

  render() {
    return (
      <div className="alert alert-secondary span2" role="alert">
        <h5>Configuración Downlink de dispositivos BetaTemp</h5>
        <br />
        <div>
        <table>
          <thead>
            <tr>
              {this.renderTableHeader()}
            </tr>
          </thead>
          <tbody>
            {this.renderTableRows()}
          </tbody>
        </table>
        </div>
      </div>
    );
  }
}
export default ShowList;
