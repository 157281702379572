import ReactDOM from 'react-dom';
import Modal from 'react-modal';

import Api from '../../../services/api-service.js'

import React, { useState, useEffect } from 'react';

import './estilosFormularios.css';



const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement');

export default function ConfiguracionEmpresas(props) {


  // const [email, setEmail] = React.useState(props.item.email);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  // const [organizacion, setOrganizacion] = React.useState(props.organizacion);
  const [stateBetatemp, setStateBetatemp] = React.useState(false);
  const [stateHumitemp, setStateHumitemp] = React.useState(null);
  const [stateCadenaFrio, setStateCadenaFrio] = React.useState(null);
  const [stateCacaoBeta, setStateCacaoBeta] = React.useState(null);
  const [stateGabinetes, setStateGabinetes] = React.useState(null);
  const [stateLGT92, setStateLGT92] = React.useState(null);
  // const [email, setEmail] = React.useState(props.item.email);
  // const [direccion, setDireccion] = React.useState(props.item.direccion);
  // const [estado, setEstado ] = React.useState(props.item.estado);
  // const [cantidadUsuarios, setCantidadUsuarios ] = React.useState(props.item.cantidadUsuarios);

  const [form, setForm ] = React.useState({organizacion: props.item.organizacion});

  
  useEffect(() => {

  // setNombre(props.item.nombre)
  // setEmail(props.item.email)
  // setDireccion(props.item.direccion)
  // setCantidadUsuarios(props.item.cantidadUsuarios)
  // setEstado(props.item.estado)

  },[props]);
  

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    console.log('closeModal')
   setIsOpen(false);
   reset();
  }

  function reset(){
    // setNombre(props.item.nombre)
    // setEmail(props.item.email)
    // setDireccion(props.item.direccion)
    // setCantidadUsuarios(props.item.cantidadUsuarios)
    // setEstado(props.item.estado)
  }
  
  function afterOpenModal() {
    console.log('afterOpenModal')
    console.log(props)

    setStateBetatemp(getState(props.item.configuraciones, 'betatemp'))
    setStateHumitemp(getState(props.item.configuraciones, 'humitemp'))
    setStateCadenaFrio(getState(props.item.configuraciones, 'cadena frio'))
    setStateCacaoBeta(getState(props.item.configuraciones, 'cacao beta'))
    setStateGabinetes(getState(props.item.configuraciones, 'gabinetes'))
    setStateLGT92(getState(props.item.configuraciones, 'LGT92'))

  }

  function getState(configuraciones, modulo){

    let auxReturn = false;
      let auxFilter = configuraciones.filter(item=> item.modulo === modulo)
      // console.log(auxFilter);

      if (auxFilter.length>0){
        // console.log(auxFilter[0].estado)
        if (auxFilter[0].estado=== 'habilitado'){
          auxReturn = true;
        } else {
                 
        }
      } else {
      }

      return auxReturn;
  }



  function handleChangeForm(event){
    console.log(event.target.name, event.target.checked)



    form[event.target.name] = (event.target.checked === 'null')? null: event.target.checked ;

    if(event.target.name === 'betatemp'){setStateBetatemp(event.target.checked)}
    if(event.target.name === 'humitemp'){setStateHumitemp(event.target.checked)}
    if(event.target.name === 'cadena frio'){setStateCadenaFrio(event.target.checked)}
    if(event.target.name === 'cacao beta'){setStateCacaoBeta(event.target.checked)}
    if(event.target.name === 'gabinetes'){setStateGabinetes(event.target.checked)}
    if(event.target.name === 'LGT92'){setStateLGT92(event.target.checked)}

    // if(event.target.name === 'email'){setEmail(event.target.value)}
    // if(event.target.name === 'direccion'){setDireccion(event.target.value)}
    // if(event.target.name === 'cantidadUsuarios'){setCantidadUsuarios(event.target.value)}
    // if(event.target.name === 'estado'){setEstado(event.target.value)}

    console.log(form)


  }

async  function submitForm(){
    console.log('envio info', form);

    let envioConfiguracion = [
      {modulo: 'betatemp', parametro: 'estado', valor: (stateBetatemp)? 'habilitado': 'inhabilitado'},
      {modulo: 'humitemp', parametro: 'estado', valor: (stateHumitemp)? 'habilitado': 'inhabilitado' },
      {modulo: 'cadena frio', parametro: 'estado', valor: (stateCadenaFrio)? 'habilitado': 'inhabilitado' },
      {modulo: 'cacao beta', parametro: 'estado', valor: (stateCacaoBeta)? 'habilitado': 'inhabilitado' },
      {modulo: 'gabinetes', parametro: 'estado', valor: (stateGabinetes)? 'habilitado': 'inhabilitado' },
      {modulo: 'LGT92', parametro: 'estado', valor: (stateLGT92)? 'habilitado': 'inhabilitado' },
    ]

    Promise.all(await envioConfiguracion.map(item=>{
      item.organizacion = props.item.organizacion;

      return new Promise((resolve, reject)=>{
        // console.log('actualizacionConfiguracionEmpresa', item);

        Api.actualizacionConfiguracionEmpresa(item).then(
          result =>{
            // console.log(result)
            resolve(result);
              // props.resultCallback(result.mensaje);
              // closeModal();
          }, error=>{
            // alert(error)
            reject(error);

            console.log(error.error)
          }
        )
      })
      })
    ).then(
      result=>{
        console.log(result)
        props.resultCallback(result);
        closeModal();

      }, error =>{
        alert(error)
        console.log(error)
      }
    )




  }

  return (


    <div>
      <button onClick={openModal}>Configurar</button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
      >
        <div>
          <h1 >Configurar Empresa</h1>
          <div className=''>
          <div className='formulario'>
             <label>Módulos: </label>
             <div className="modulos">
                <input
                  type="checkbox"
                  name={"betatemp"}
                  checked={stateBetatemp}
                  onChange={handleChangeForm}
                  className="checkmark"
                />
                <label className="checkValues">{"Betatemp"}</label>
              </div>

              <div className="modulos">
                <input
                  type="checkbox"
                  name={"humitemp"}
                  checked={stateHumitemp}
                  onChange={handleChangeForm}
                  className="checkmark"
                />
                <label className="checkValues">{"Humitemp"}</label>
              </div>

              <div className="modulos">
                <input
                  type="checkbox"
                  name={"cadena frio"}
                  checked={stateCadenaFrio}
                  onChange={handleChangeForm}
                  className="checkmark"
                /> 
                <label className="checkValues">{"Cadena Frio"}</label>
              </div>

              <div className="modulos">
                <input
                  type="checkbox"
                  name={"cacao beta"}
                  checked={stateCacaoBeta}
                  onChange={handleChangeForm}
                  className="checkmark"
                /> 
                <label className="checkValues">{"Cacao Beta"}</label>
              </div>

              <div className="modulos">
                <input
                  type="checkbox"
                  name={"Gabinetes"}
                  checked={stateGabinetes}
                  onChange={handleChangeForm}
                  className="checkmark"
                /> 
                <label className="checkValues">{"Gabinetes"}</label>
              </div>

              <div className="modulos">
                <input
                  type="checkbox"
                  name={"LGT92"}
                  checked={stateLGT92}
                  onChange={handleChangeForm}
                  className="checkmark"
                /> 
                <label className="checkValues">{"LGT92"}</label>
              </div>


            </div>            
            <div className='div-botones row'>
              <div className='col-6 button-edicion'>
                <button className="buttonGeneral" onClick={()=>submitForm()}>Actualizar</button>
              </div>
              <div className='col-6 button-edicion'>
                <button className="buttonGeneral button-secondary" onClick={()=>closeModal()} >Cancelar</button>
              </div>
            </div>
          </div>

        </div>
      </Modal>
    </div>
  );
}