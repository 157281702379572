import React from "react";
import Modal from "react-modal";

import "./estilosFormularios.css";

import Api from "../../../services/api-service.js";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#elemento');

export default function EdicionGruposHumitemp(props) {
  let _id = props.item._id;
  // let nombre = props.item.nombre;
  // let valMin = props.item.valMin;
  // let mvalMax = props.item.valMax;
  let admin = props.item.admin;
  let grupo = props.item.grupo;

  console.log(props);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [valHMin, setValHMin] = React.useState(props.item.valHMin);
  const [valHMax, setValHMax] = React.useState(props.item.valHMax);
  const [valTMin, setValTMin] = React.useState(props.item.valTMin);
  const [valTMax, setValTMax] = React.useState(props.item.valTMax);
  const [nombre, setNombre] = React.useState(props.item.nombre);

  // let dispositivo = this.props.dispositivo;
  // console.lo(dispositivo);
  function editarNombre() {
    setNombre("ddd");
  }

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    console.log("cerrando");
    setIsOpen(false);
  }

  function changeVal(event) {
    console.log(event.target.name, event.target.value);

    if (event.target.name == "hvmin") {
      setValHMin(Number(event.target.value));
    }
    if (event.target.name == "hvmax") {
      setValHMax(Number(event.target.value));
    }

    if (event.target.name == "tvmin") {
      setValTMin(Number(event.target.value));
    }
    if (event.target.name == "tvmax") {
      setValTMax(Number(event.target.value));
    }

    if (event.target.name == "nombre") {
      setNombre(event.target.value);
    }
  }

  function handleChangeGrupo(event) {
    console.log(event.target.value);
    grupo = event.target.value;
  }

  function submitForm() {
    let actualizacion = {
      _id: _id,
      // nombre: (nombre == '')? null: nombre ,

      valTMin: valTMin == "" ? null : valTMin,
      valTMax: valTMax == "" ? null : valTMax,
      valHMin: valHMin == "" ? null : valHMin,
      valHMax: valHMax == "" ? null : valHMax,
    };

    // if(valHMin || valHMax ){
    //   let auxHumedad = {
    //     valMin:  (valHMin=='')? null: valHMin ,
    //     valMax:  (valHMax=='')? null: valHMax ,
    //   };
    //   actualizacion.humedad = auxHumedad;
    // }

    // if(valTMin || valTMax ){
    //   let auxTemperatura = {
    //     valMin:  (valTMin=='')? null: valTMin ,
    //     valMax:  (valTMax=='')? null: valTMax ,
    //   };
    //   actualizacion.temperatura = auxTemperatura;
    // }

    console.log(actualizacion);
    Api.edicionGrupoHumitemp(actualizacion).then(
      (result) => {
        console.log(result);
        // this.setState({devicesTable: result})
        // this.setState({tablaFiltrada: result})
        closeModal();
        props.eventFinishedOK();
      },
      (error) => {
        console.log("error en la actualización");
        alert("error en la actualización ");
      }
    );
  }

  return (
    <div>
      <button onClick={openModal}>Edición</button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div>
          <h1>Edición Grupos</h1>
          {/* <button onClick={closeModal}>close</button> */}
          {/* <h3>{device}</h3> */}
          {admin && (
            <div className="">
              <div className="formulario">
                <label>Nombre: </label>
                <input
                  type="text"
                  id="lname"
                  name="lname"
                  value={nombre}
                  onChange={changeVal}
                />
              </div>
              <div class="selectores">
                <label for="grupo">Oranización:</label>
                <select id="grupo" onChange={handleChangeGrupo}>
                  <option selected value="null">
                    No Grupo
                  </option>
                  <option value="gpf">GPF</option>
                  <option value="constecoin">CONSTECOIN</option>
                </select>
              </div>
            </div>
          )}
          <h3>Alertas Humedad</h3>
          <div className="formulario">
            <label>Valor Max: </label>
            <input
              type="number"
              id="hvmax"
              name="hvmax"
              value={valHMax}
              onChange={changeVal}
            />
          </div>
          <div className="formulario">
            <label>Valor Min: </label>
            <input
              type="number"
              id="hvmin"
              name="hvmin"
              value={valHMin}
              onChange={changeVal}
            />
          </div>

          <h3>Alertas Temperatura</h3>
          <div className="formulario">
            <label>Valor Max: </label>
            <input
              type="number"
              id="tvmax"
              name="tvmax"
              value={valTMax}
              onChange={changeVal}
            />
          </div>
          <div className="formulario">
            <label>Valor Min: </label>
            <input
              type="number"
              id="tvmin"
              name="tvmin"
              value={valTMin}
              onChange={changeVal}
            />
          </div>

          <div className="div-botones row">
            <div className="col-6 button-edicion">
              <button className="buttonGeneral" onClick={() => submitForm()}>
                Aceptar
              </button>
            </div>
            <div className="col-6 button-edicion">
              <button
                className="buttonGeneral button-secondary"
                onClick={() => closeModal()}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
