import styled from "styled-components";

export const Main = styled.div`
  margin: 25px 30px 25px 30px;
  height: 100%;
`;

export const Texto1 = styled.div``;

export const Texto2 = styled.div``;

export const Mapas = styled.div`
  height: 300px;

  background: red;
  @media screen and (max-width: 768) {
    font-size: 24px;
  }
  @media screen and (max-widtg: 480px) {
    font-size: 18px;
  }
`;

export const ForMap = styled.div`
  justify-content: center;
  align-content: center;
  position: absolute;
  width: 90%;
  height: 300px;
`;
