import React, { Component } from "react";
import './ListaDispositivosGeneral.css';

class CercaSelector extends Component {

  constructor(props) {
    super(props);

    this.state = {
      seleccion: "",
    };
  }

  configCerca= (event) => {
    this.state.seleccion = event.currentTarget.value;
    this.setState({
      seleccion: event.currentTarget.value
    })
    console.log("Seleccion de cerca \n", this.state.seleccion)
    this.props.parentCallback(this.state.seleccion)
  }

  render() {
    return (
      <div>
        <div>
          <label>
            Seleccione el tipo de geocerca:
              <select onChange={this.configCerca}>
                <option value="" selected disabled hidden>Selecciona una opción</option>
                <option value="Circular">Circular</option>
                <option value="Poligonal">Poligonal</option>
              </select>
          </label>
        </div>
      </div>
    );
  }
}
export default CercaSelector;
