
import React from "react";
import 'w3-css/w3.css';
import {Ble,Productos} from "../components/tablasvinc/tabla_productos.js"
import {
  Col,
  Container,
  Row,  
  Card,
  Table,
  Button
} from "react-bootstrap";

import './Vinculacion.css';

import Api from '../services/api-service.js'





class Vinculacion  extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      open:  false,
      productsTable: [],
      bleTable: [],
      users:[],
      isLoading: false,
      isError: false,
      bleVinculacion: null,
      productoVinculacion: null,
      busqueda : null
    };

  this.refreshFreeBLE();
  this.refreshFreeProducts();

  }

  
  refreshFreeBLE = () =>{
    Api.getFreeBle().then(
      async result=>{
        console.log('adquisicion Free BLE',result);

        this.setState({bleTable: result})
        
      }, error=>{

      }
    )
  }

  refreshFreeProducts = () =>{
    Api.getFreeProducts().then(
      async result=>{
        console.log('adquisicion Free PRODUCTS',result);

        this.setState({productsTable: result})
      }, error=>{
      }
    )
  }

  vinculacionItems(){
    console.log(this.state)
    if(!this.state.productoVinculacion || !this.state.bleVinculacion){
      return console.log('falta item');
    }

    let body ={
      ble: this.state.bleVinculacion,
      producto: this.state.productoVinculacion
    }

    Api.matchProductsBle(body).then(
      result=> {
        console.log(result);
        this.refreshFreeBLE();
        this.refreshFreeProducts();

        this.setState({productoVinculacion: null})
        this.setState({bleVinculacion: null})



      }, error =>{
        console.log(error);
      },
      ()=>{

        // this.refreshFreeBLE();
        // this.refreshFreeProducts();

      }
    )


  }


  handleDoubleClick(tipo, item){
    console.log('item', item)
    console.log('tipo', tipo)


    switch(tipo){
      case 'productos':
        this.setState({productoVinculacion: item.codigoDeBarras})
      break;
      case 'ble':
        this.setState({bleVinculacion: item.mac})
      break;
    }

 }
 setSearch(event){

  console.log(event)
  this.setState({busqueda: event})


  let encontrado = null;

  let itemBle = this.state.bleTable.filter(item => item.mac === event);
  let itemProducto = this.state.productsTable.filter(item => item.codigoDeBarras === event);

  if(itemBle.length !== 0){
    console.log('encontrado BLE')
    this.state.bleVinculacion = itemBle[0].mac;
    this.setState({bleVinculacion: itemBle[0].mac})
    this.setState({busqueda: ''})

  }

  if(itemProducto.length !== 0 ){
    console.log('encontrado PRODUCTO')
    this.state.productoVinculacion = itemProducto[0].codigoDeBarras;
    this.setState({productoVinculacion: itemProducto[0].codigoDeBarras})
    this.setState({busqueda: ''})
  }

  console.log(this.state.productoVinculacion, this.state.bleVinculacion)

  if(this.state.productoVinculacion && this.state.bleVinculacion){
    console.log('vnculaciones')

    this.vinculacionItems();

  }
 }


render() {
  return (
    <>
      <Container fluid className="w3-panel ">
        <Row>
         <input className=" busqueda  texto-busqueda"   type="text" placeholder="busqueda" value={this.state.busqueda} onChange={(event) => {
            this.setSearch(event.target.value)
        }}></input>

        <Col md="6">
            <label>Dispositivo BLE: {this.state.bleVinculacion}</label>
        </Col>
          <Col md="6">
            <label>Producto: {this.state.productoVinculacion}</label>
          </Col>
          <Col md="12">
            <Button className="buttonVinculacion" onClick={()=>{this.vinculacionItems()}} variant="primary">Vinculación</Button>
          </Col>
      <Col md="6">
        <Card className="strpied-tabled-with-hover">
                  <Card.Header>
                    <Card.Title as="h4">BLE</Card.Title>
                  </Card.Header>
                  <Card.Body className="table-full-width table-responsive px-0">
                    <Table className="table-hover table-striped">
                      <thead>
                        <tr  >
                          {/* <th className="border-0">ID</th> */}
                          <th className="border-0">MAC</th>
                          <th className="border-0">TIPO</th>
                          {/* <th className="border-0">Codigo Prod</th> */}
                          {/* <th className="border-0">ID</th> */}
                          {/* <th className="border-0">Fecha Último Dato</th>
                          <th className="border-0">Hora Último Dato</th>
                          <th className="border-0">Tiempo Último Dato</th> */}
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.bleTable.map(( item, index ) => {
                          return (
                            <tr className="cursor-mano" key={index} onClick = {this.handleDoubleClick.bind(this, 'ble', item)}>
                            {/* <tr key={index} onDoubleClick = {this.handleDoubleClick.bind(this, 'ble', item)}> */}
                              {/* <td>{item.ubicacion}</td> */}
                              {/* <td>{item.distancia}</td> */}
                              <td>{item.mac}</td>
                              <td>{item.tipo}</td>
                              {/* <td>{item.horaUltimoDato}</td> */}
                              {/* <td>{item.tiempoUltimoDato}</td> */}
                            </tr>
                          );
                        })}

                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
        </Col>
        <Col md="6">
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
                  <Card.Title as="h4">PRODUCTOS</Card.Title>
                  </Card.Header>
                  <Card.Body className="table-full-width table-responsive px-0">
                    <Table className="table-hover table-striped">
                      <thead>
                        <tr>
                          {/* <th className="border-0">ID</th> */}
                          {/* <th className="border-0">Área</th> */}
                          <th className="border-0">Producto</th>
                          <th className="border-0">Codigo Barras</th>
                          {/* <th className="border-0">ID</th> */}
                          {/* <th className="border-0">Fecha Último Dato</th>
                          <th className="border-0">Hora Último Dato</th>
                          <th className="border-0">Tiempo Último Dato</th> */}
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.productsTable.map(( item, index ) => {
                          return (
                            <tr className="cursor-mano" key={index} onClick = {this.handleDoubleClick.bind(this, 'productos', item)}>
                              {/* <td>{item.ubicacion}</td> */}
                              {/* <td>{item.distancia}</td> */}
                              <td>{item.nombre}</td>
                              <td>{item.codigoDeBarras}</td>
                              {/* <td>{item.horaUltimoDato}</td> */}
                              {/* <td>{item.tiempoUltimoDato}</td> */}
                            </tr>
                          );
                        })}

                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
        </Col>
        </Row>
      </Container>
    </>
  );
}
}

export default Vinculacion;



const styles= {
  ButtonVinculacion: {
    fontSize: 200,
  },
};
