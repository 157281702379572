import React, { useEffect, useState } from "react";
import Api from "./../../services/api-service";
import SearchIcon from "@material-ui/icons/Search";
import InputBusqueda from "./../../components/Table/inputBusqueda";
import Button from "../../components/Button/index";
import Auth from "../../services/auth-service.js";
import { useLocation } from "react-router-dom";
import {
  BarraBusqueda,
  BotonCrear,
  ContenedorTabla,
  ContenedorTitulo,
  Main,
  NavbarTabla,
  Th,
  Titulo,
  Tr,
  TablaDispo,
  TrC,
  IconoLupa,
  ContenedorDis,
  Nombredispositivo,
  DescripcionDispo,
  Organizacion,
  Tipo,
  ContenedorFecha,
  Tiempo,
  Fecha,
  BotonMostrar,
  BotonDatos,
} from "./AlcantarillaElements";

const Index = () => {

  const [results, setResults] = useState([]);
  const [controlador, setControlador] = useState(null);
  const [text, setText] = useState("");
  const [postRol, setRol] = useState();
  const location = useLocation();
  const query = new URLSearchParams(useLocation().search);
  const obteOrganizacion = query.get("organizacion");
  const tipo_dispositivo = query.get("tipo");
  // setControlador()

  // function refreshPage() {
  //   // window.location.reload(false);
  // }


  // refreshPage()
  useEffect(() => {
    console.log("Entro por primera ves")
    console.log(text)
    console.log(obteOrganizacion)
    console.log(tipo_dispositivo)
    console.log(location.search)
    setRol(Auth.getRol());
    Api.getDeviceWt32Id(text, obteOrganizacion, tipo_dispositivo).then(
      (result) => {
        setResults(result);
      },
      (error) => {
        console.log("Obtencion erronea");
      }
    );

  }, [text, location.search]);

  return (
    <>
      <Main>
        <ContenedorTitulo>
          <Titulo>Dispositivos STC-01-RJ</Titulo>
        </ContenedorTitulo>

        <ContenedorTabla>
          <NavbarTabla>
            <BarraBusqueda>
              <IconoLupa>
                <SearchIcon />
              </IconoLupa>
              <InputBusqueda setText={setText} text={text} />
            </BarraBusqueda>

            {postRol == "usuario" || postRol == "visualizador" ? null : (
              <Button
                url={`/admin/createWt32?organizacion=${obteOrganizacion}`}
                message="Crear Nuevo"
                style={BotonCrear}
              />
            )}

            {/* <Button
              url={`/admin/viewAllwt32?organizacion=${obteOrganizacion}`}
              message="Mostrar todo"
              style={BotonMostrar}
            /> */}
          </NavbarTabla>
          <TablaDispo>
            <Tr>
              <Th>Dispositivo</Th>
              <Th>Organizacion</Th>
              <Th>Fecha creacion</Th>
              <Th>UTC</Th>
              <Th>Acciones</Th>
            </Tr>
            {results.length > 0 ? (
              results.map((device, i) => (
                <TrC>
                  <td>
                    <ContenedorDis>
                      <Nombredispositivo>{device.nombre}</Nombredispositivo>
                      <DescripcionDispo>{device.deviceID}</DescripcionDispo>
                    </ContenedorDis>
                  </td>

                  <td>
                    <Organizacion>{device.organizacion}</Organizacion>
                  </td>
                  <td>
                    <ContenedorFecha>
                      <Fecha>
                        {device.fechaRegistro
                          .toString()
                          .slice(
                            0,
                            device.fechaRegistro.toString().indexOf("T")
                          )}
                        {" / "}
                        {device.fechaRegistro
                          .toString()
                          .slice(
                            device.fechaRegistro.toString().indexOf("T") + 1,
                            16
                          )}
                      </Fecha>
                    </ContenedorFecha>
                  </td>
                  <td>
                    <Tiempo>{device.utc}</Tiempo>
                  </td>
                  <td>
                    <Button
                      url={`/admin/viewDevicewt32?id=${device.deviceID}&organizacion=${obteOrganizacion}&view=one&tipo=${tipo_dispositivo}`}
                      message="Mostrar Datos"
                      style={BotonDatos}
                    />
                  </td>
                </TrC>
              ))
            ) : (
              <div>No hay resultados</div>
            )}
          </TablaDispo>
        </ContenedorTabla>
      </Main>
    </>
  );
};

export default Index;
