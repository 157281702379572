import React from "react";

  import './IncidentesDispositivosBeta.css';
import Api from '../../services/api-service.js'
import Auth from '../../services/auth-service.js';
import ReactToExcel from 'react-html-table-to-excel';
import {CSVLink} from 'react-csv'


import Beta from '../../components/betatemp/ConsumoBeta'
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { render } from "react-dom";






class DispositivosIoT extends React.Component {

  constructor (props) {
    super(props);

    this.state = {

      selectedDevice: 'all',
      selectedOrganizacion: 'all',
      devicesTable: [],
      organizaciones: [],
      selectedAlerta: 'all',


      open:  false,
      // selectedOrganizacion: null,
      // devicesTable: [],
      tablaDatos: [],
      tablaFiltrada: [],
      rol: Auth.getRol(),
      organizaciones: Auth.getOrganizaciones() ,
      
      tablasShow : {
        showTiempoReporte: false
      },

      // showBetatemp: false,
      // showSigfox: false
    };   
    
    this.buttonClick('reportes');
    this.actualizarOrganizaciones();
    this.actualizarTablaDispositivos();
    this.actualizarDatosDevices();
  }


  //////////////// descarga de organizaciones //////////////
  async actualizarOrganizaciones(){
    let auxOrganizaciones = await Auth.getOrganizaciones();

    if(!auxOrganizaciones){
      this.setState({organizaciones: []})
      console.log('auxOrganizaciones',auxOrganizaciones)
    } else {
      this.setState({organizaciones: auxOrganizaciones})
      console.log('auxOrganizaciones',this.state.organizaciones)
    }
  }

  actualizarTablaDispositivos(){

    Api.obtenerDispositivosHumitemp({organizacion: this.state.selectedOrganizacion, estado: "all"}).then(
      result=>{
        console.log(result);
        this.setState({devicesTable: result})
      }, error=>{
        console.log('error en la adquisicion de datos');
      }
    )

  }



  //////////////// descarga de dispositivos  //////////////
  // actualizarDataDevices(){
  //   Api.obtenerDatosDispoHumitemp({dispositivos: this.state.selectedDevice, 
  //               organizacion: this.state.selectedOrganizacion, estado: "all"}).then(
  //     result=>{
  //       console.log(result);
  //       this.setState({tablaDatos: result})
        
  //       this.setState({tablaFiltrada: result})

  //     }, error=>{
  //       console.log('error en la adquisicion de datos');
  //     }
  //   )
  // }


  handleChangeAlerta = (event) => {
    this.state.selectedAlerta =  event.target.value;
    this.actualizarDatosDevices();
  };
  handleChangeOrganizacion = (event) => {
    this.state.selectedOrganizacion = event.target.value;
    this.actualizarDatosDevices();
  };
  handleChangeDevice = (event) => {
    this.state.selectedDevice = event.target.value;
    this.actualizarDatosDevices();
  };


  

  buttonClick(tipo){

    if(tipo === 'reportes'){
      this.setState({tablasShow: {showTiempoReporte: true}})
      
      // console.log(Auth.getJWT());
    }




  }

  actualizarDatosDevices(){
    Api.adquirirIncidentDevicesBeta({organizacion: this.state.selectedOrganizacion, deviceID: this.state.selectedDevice , estado: this.state.selectedAlerta}).then(
      result=>{
        console.log(result);
        this.setState({tablaDatos: result})
        this.setState({tablaFiltrada: result})
        // this.state.devicesTable = result;
        // this.filtroTablaAlertas(result, this.state.selectedOrganizacion);

      }, error=>{
        console.log('error en la adquisicion de datos');
      }
    )
  }



  showData = () => {
    console.log(this.state.tablaDatos)
  }

  
  filtroTablaAlertas(tabla, alerta){
    console.log('filtroTablaAlertas', this.state.selectedOrganizacion, alerta)
    this.filtroTabla(tabla, this.state.selectedOrganizacion, alerta)
  }

  filtroTablaOrganizacion(tabla, organizacion){
    console.log('filtroTablaOrganizacion', organizacion, this.state.selectedAlerta)

    this.filtroTabla(tabla, organizacion, 'all')
    // this.filtroTabla(tabla, organizacion, this.state.selectedAlerta)

  }

  filtroTabla(tabla, organizacion, alerta){
  
    const result = tabla.filter(item => {
      console.log(item);
      let auxOrganizacion = false, auxAlerta = true;


      //////////////////////////////////////////////   ORGANIZACION ///////////////////////////////
      if(organizacion === 'all'){
        auxOrganizacion = true;
      }
      if(organizacion === null){
        auxOrganizacion = true;
      }
      else {
        if(item.organizacion === organizacion){
          auxOrganizacion = true;
        }
      }

      //////////////////////////////////////////////   ALERTAS  ///////////////////////////////


      if(alerta === 'all'){
        auxAlerta = true;
      }
      if(alerta === null){
        auxAlerta = true;
      }
      else {
        if(item.revisado ==  alerta){
          auxAlerta = true;
        }
      }

      // if(auxOrganizacion && auxTipo){
      //   console.log(true);
      // }
      console.log(auxOrganizacion, auxAlerta)
      return (auxOrganizacion && auxAlerta);
    });

    this.setState({tablaFiltrada: result})

  }
  
render() {
  return (
    <>
      <Container fluid>
          {/* <div class="buttonsElements">
            <ul>
              <li><a onClick={()=>this.buttonClick('reportes')}>Tiempos Reportes</a></li>
            </ul>
          </div> */}
          {/* {this.state.tablasShow.showTiempoReporte && */}
          <Row>


            <Col md="2">
              <div class="selectores">
                <label for="organizacion">Organización:</label>
                <select id="organizacion" onChange={this.handleChangeOrganizacion}>
                  {/* <option value="" disabled  hidden>seleccionar</option> */}
                  <option selected value="all">Todos</option>
                  {this.state.organizaciones.map(( item, index ) => {
                        return (
                          <option value={item.organizacion}>{item.organizacion}</option>
                        )
                      })}
                  {/* <option value="semagro">Semagro</option>
                  <option value="constecoin">Constecoin</option> */}
                </select>
              </div> 
            </Col>

            <Col md="2">
              <div class="selectores">
                <label for="dispositivos">Dispositivos:</label>
                <select id="dispositivos" onChange={this.handleChangeDevice}>
                  <option selected value="all">Todos</option>
                  {this.state.devicesTable.map(( item, index ) => {
                            return (
                              <option value={item.deviceID}>{item.deviceID}</option>
                            )
                          })}
                </select>
              </div> 
            </Col>


            <Col md="2">
              <div class="selectores">
                <label for="tipo">Revisados:</label>
                <select id="tipo" onChange={this.handleChangeAlerta}>
                  {/* <option value="" disabled selected hidden>seleccionar</option> */}
                  <option selected value="all">TODO</option>
                  <option value="true">Revisados</option>
                  <option value="false">No revisados</option>
                  {/* <option value="sigfox">Sigfox</option>
                  <option value="celular">Celular</option> */}
                </select>
              </div> 
            </Col>

            
            <Col md="2">
              <div>
              <ReactToExcel
              className="btn btn-primary  buttonGeneral"
              table="tabla-obtenida"
              filename="iotTablaConste"
              sheet="sheet 1"
              buttonText="Exportar como xls"
              />
              </div> 
            </Col>

            <Col md="2">
              <div>
                <CSVLink data={this.state.tablaDatos} filename={"iotTablaConste.csv"}>
                  <Button className="buttonGeneral"> Exportar como csv</Button>
                </CSVLink>
              </div> 
            </Col>


            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">Devices</Card.Title>
                  <p className="card-category">
                    Alertas de dispositivos
                  </p>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table className="table-hover table-striped" id="tabla-obtenida">
                    <thead>
                      <tr>
                        {/* <th className="border-0">ID</th> */}
                        <th className="border-0">Device ID</th>
                        <th className="border-0">Organización</th>
                        <th className="border-0">Tipo Alerta</th>
                        <th className="border-0">Fecha Alerta</th>
                        <th className="border-0">Hora Alerta</th>
                        <th className="border-0">Revisado</th>
                        {/* <th className="border-0">Tiempo Último Dato</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.tablaFiltrada.map(( item, index ) => {
                        return (
                          <tr key={index}>
                            {/* <td>{item._id}</td> */}
                            <td>{item?.deviceID}</td>
                            <td>{item?.organizacion}</td>
                            <td>{item?.tipoAlerta}</td>
                            <td>{item?.fecha.toString().slice(0, item?.fecha.toString().indexOf('T'))}</td>
                            <td>{item?.fecha.toString().slice(item?.fecha.toString().indexOf('T')+1, item?.fecha.length - 5)}</td>
                            <td>{item?.revisado.toString()}</td>
                            {/* <td>{item.horaUltimoDato}</td> */}
                            {/* <td>{item.tiempoUltimoDato}</td> */}
                          </tr>
                        );
                      })}

                    </tbody>
                  </Table>
               
                </Card.Body>
              </Card>
            </Col>

        </Row>
        {/* } */}

        {this.state.showBetatemp &&
         <Row>

          <Beta></Beta>
          </Row>}

      </Container>
    </>
  );
}
}





export default DispositivosIoT;





