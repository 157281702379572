import React, {useState,useEffect} from 'react'
import { Button,Modal } from 'react-bootstrap'
import 'w3-css/w3.css';
import Tx from "../input";
import './estiloform.css'


export  function BootstrapModa(props) {

  const  errarse ='http://iot.constecoin.com/api/inv/inventario/borrarProducto'
 
  const expresiones = {
      //usuario: /^[a-zA-Z0-9\_\-]{4,16}$/, // Letras, numeros, guion y guion_bajo
    mac: /^[a-zA-Z0-9\_\-]{1,12}$/, // Letras y espacios, pueden llevar acentos.
      tipo: /^[a-zA-Z0-9\_\-]/, // 4 a 12 digitos.
      //correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  }
   

  var [datos, setDatos] = useState({
      mac:'',
      tipo:'',
      isvgID:'',
      _id:''
  })
    
    useEffect(() => {
      setDatos(props.item);
      //onsole.log(datos)
  }, [props.item])
   
 
    function enviarDatos (e) {
      var envio  ={ _id:datos._id,
      mac:datos.mac,
    tipo:datos.tipo,
    svgID:datos.svgID }
        e.preventDefault ();
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(envio)}
       
        console.log(JSON.stringify(envio)  )
        fetch(links, requestOptions)
        .then(response => console.log(response))
    props.handleClose()
    }


    function borrar (id){
  
      var eliminar ={_id:id}
      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(eliminar)}

        fetch(errarse, requestOptions)
        .then(response => console.log(response))
       
        
   
       
    } 

  
    const  links ='https://iot.constecoin.com/api/sigfox/sigfoxWeb/setParametersDevice'


 
    
    const ingreso = (event)=>{
    
      setDatos( datos=> ({
        ...datos ,[event.target.name] : event.target.value
    }));
    }

       
     
    
    
    return (
        
        <>
         
          <Modal
      
            show={props.setShow}
            onHide={props.handleClose}
            backdrop="static"
            keyboard={false}
            className=" w3-modal-content{background-color: transparent} w3-animate-zoom" style={{maxwidth:600}}>
          
            <Modal.Header closeButton className="w3-rigth">
            
            </Modal.Header>
            <Modal.Body>
         
  
             
      <div className="w3-row">  
        
        <div className="w3-rest  "   >
           <h1>Edición BLE</h1>
    
        <form onSubmit={enviarDatos}>
            <table className="w3-table">
           <tfoot>
           <tr>
          <td  colSpan="2">
          <p>{datos.mac}</p>
          
          
           
          </td>
         </tr>
        <tr>
      
          <td> 
        
            <Tx
            
             etiqueta = "Mac"
             tipo = "text"
             name='mac'
             onChange={ingreso}
             value ={datos.mac}
             expresion ={expresiones.mac}
            />
          </td>
          <td>
          <Tx 
          etiqueta ="Tipo"
          tipo = "text"
          name='tipo'
          onChange={ingreso}
          value ={datos.tipo}
          expresion ={expresiones.tipo}

            /> 
          </td>
        </tr>
        <tr>
          <td  colSpan="2">
          <Tx
           etiqueta ="zona"
           tipo = "text" 
           name='svgID'
           onChange={ingreso}
           value ={datos.isvgID}
           expresion ={expresiones.password} /> 
          </td>
         </tr>
        
       
        </tfoot>
           </table>
           <Button  className="buttonGeneral "  type="submit">Actualizar BLE</Button>
           <Button  className="buttonGeneral button-secondary"  onClick={()=>borrar(props.id)} >Eliminar  </Button>
           </form>

        </div>
        </div>
  
            </Modal.Body>
           
          </Modal>

         
        </>
      );
    }
    export function  BotonModal (props) {   
  
        return(
      <Button  className="buttonGeneral"  onClick={props.onClick}>
      {props.etiqueta}
    
    </Button>);
    }