import { Button } from "bootstrap";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import CustomMap from "./../Alcantarillas/ActualizarMarcador/index";

const YOUR_GOOGLE_API_KEY_GOES_HERE = "AIzaSyBYNDUEf0g8OQPJfiEW7ybizYPiMN_7XOU";

const styleMapa = {
  width: "60%",
  height: "60%",
};

var id;
var lt;
var ln;

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 250,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.75)",
  },
};

const Mapa = (props) => {
  const [lat, setLat] = useState(props.lat);
  const [lng, setLng] = useState(props.lng);
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    setLat(props.lat);
    setLng(props.lng);
  }, []);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        Seleccione la nueva ubicacion del Dispositivos
        <br />
        <label>ID</label>
        <input id="id" name="id" value={id} readOnly></input>
        <br />
        <button onClick={closeModal}>Cancelar</button>
        <CustomMap lat={lt} lng={ln} />
      </Modal>

      <button
        onClick={() => {
          id = document.getElementById("id").value;
          lt = document.getElementById("lat").value;
          ln = document.getElementById("lng").value;
          openModal();
        }}
      >
        Editar
      </button>

      <Map
        google={window.google}
        initialCenter={{
          lat: lat,
          lng: lng,
        }}
        style={styleMapa}
        zoom={12}
      >
        <Marker
          position={{
            lat: lat,
            lng: lng,
          }}
          onClick={() => {
            id = document.getElementById("id").value;
            lt = document.getElementById("lat").value;
            ln = document.getElementById("lng").value;
            openModal();
          }}
        />
      </Map>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: YOUR_GOOGLE_API_KEY_GOES_HERE,
})(Mapa);
