import React, { Component } from "react";
import './ListaDispositivosGeneral.css';

class UplinkBetatemp extends Component {

  constructor(props) {
    super(props);

    this.state = {

    };
  }


  render() {
    return (
      <div>
        Configuración BetaTemp Uplink
      </div>
    );
  }
}
export default UplinkBetatemp;
