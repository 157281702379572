// TableContainer.js
import React from "react";
import { useMemo } from "react";
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import { Table, Row, Col, Button, Input, CustomInput } from "reactstrap";

const TableContainer = ({ data }) => {
  console.log("PAGINACIOOOOOOOOOOOOON");
  console.log(data);
  const columns = useMemo(
    () => [
      {
        Header: "Dispositivo",
        // accessor: "nombre",
        accessor: (values) => {
          // const nombre = values;
          if (!values.nombre) {
            return values.deviceID;
          } else {
            return values.nombre;
          }
        },
      },
      {
        Header: "T ambiente",
        accessor: "Tambiente",
      },
      {
        Header: "H Ambiente",
        accessor: "Hambiente",
      },
      {
        Header: "V bat (%)",
        accessor: "voltajePorcentual",
      },
      {
        Header: "Fecha",
        // accessor: "fechaCompleta",
        accessor: (values) => {
          const fechaCompleta = values.fechaCompleta;

          return (
            fechaCompleta
              .toString()
              .slice(0, fechaCompleta.toString().indexOf("T")) +
            "   " +
            fechaCompleta
              .toString()
              .slice(
                fechaCompleta.toString().indexOf("T") + 1,
                fechaCompleta.length - 5
              )
          );
        },
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    // prepareRow,

    page,
    prepareRow,

    // below new props related to 'usePagination' hook
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,

      initialState: { pageIndex: 0, pageSize: 30 },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    // If you're curious what props we get as a result of calling our getter functions (getTableProps(), getRowProps())
    // Feel free to use console.log()  This will help you better understand how react table works underhood.
    <div>
      <table bordered hover {...getTableProps()} className="col-12">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div>
        <Table>{/* our table code here ... */}</Table>
        <Row style={{ maxWidth: 1000, margin: "0 auto", textAlign: "center" }}>
          <Col md={3}>
            <Button
              color="primary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </Col>
          <Col md={2} style={{ marginTop: 7 }}>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>
          <Col md={2}>
            <Input
              type="number"
              min={1}
              style={{ width: 70 }}
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={onChangeInInput}
            />
          </Col>
          <Col md={2}>
            <CustomInput
              type="select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Mostrar {pageSize}
                </option>
              ))}
            </CustomInput>
          </Col>
          <Col md={3}>
            <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TableContainer;
