import React, {useState,useEffect} from 'react'
import { Button,Modal } from 'react-bootstrap'
// import 'w3-css/w3.css';
import Tx from  '../../components/input';
import '../../views/formulario/form.css'


export  function BootstrapModa(props) {

  const  errarse ='https://iot.constecoin.com/api/cadena-frio/inventario/inventario/borrarProducto'
 
  const expresiones = {
      //usuario: /^[a-zA-Z0-9\_\-]{4,16}$/, // Letras, numeros, guion y guion_bajo
      // nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
      // password: /^.{12}$/, // 4 a 12 digitos.
      //correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
      // cantidad: /^\d{1,5}$/ // 7 a 14 numeros.
  }
   

  var [datos, setDatos] = useState({
      nombre:props.nombre,
     cantidad:props.cantidad,
     max:props.max,
     min:props.min,
     tiempoOut:props.tiempoOut,

     codigoDeBarras:'',
      descripcion:'',
      _id:'',
      valido:null    } )

   
      // console.log(datos);
    useEffect(() => {
      setDatos(props.item);
      //onsole.log(datos)
  }, [props.item])
   
 
    function enviarDatos (e) {
      var envio  ={ _id:datos._id, producto:{
        nombre:datos.nombre,
       cantidad:parseInt(datos.cantidad),
       codigoDeBarras:datos.codigoDeBarras,
       descripcion:datos.descripcion,

       variables : {}
      }
      }

      let varTemperatura = {
        max: parseInt(datos.max),
        min: parseInt(datos.min),
        tiempoOut: parseInt(datos.tiempoOut),
      }

      envio.producto.variables.temperatura = varTemperatura;


      console.log(envio);
        e.preventDefault ();
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(envio)}
       
        console.log(JSON.stringify(envio)  )
        fetch(links, requestOptions)
        .then(response => response.json())
        .then(data => console.log(data))
        props.handleClose()
    }


    function borrar (id){
  
      var eliminar ={_id:id}
      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(eliminar)}

        fetch(errarse, requestOptions)
        .then(response => console.log(response))
       
        
   
       
    } 

  
    const  links ='https://iot.constecoin.com/api/cadena-frio/inventario/inventario/editarProducto'


 
    
    const ingreso = (event)=>{
    

      // if(event.target.name === 'tempMax')

      setDatos( datos=> ({
        ...datos ,[event.target.name] : event.target.value
    }));
    }

       
     
    
    
    return (
        
        <>
         
          <Modal
      
            show={props.setShow}
            onHide={props.handleClose}
            backdrop="static"
            keyboard={false}
            className=" w3-modal-content{background-color: transparent} w3-animate-zoom" style={{maxwidth:600}}>
          
            <Modal.Header closeButton className="w3-rigth">
            
            </Modal.Header>
            <Modal.Body>
         
  
             
      <div className="w3-row">  
      
        
        <div className="w3-rest  "   >
           <h1 className="texto-titulo titulo-card titulo-pagina">{datos.nombre}</h1>
    
        <form onSubmit={enviarDatos}>
          {/* <div className="row">
            <Tx
              etiqueta = "Nombre"
              tipo = "text"
              name='nombre'
              onChange={ingreso}
              value ={datos.nombre}
              expresion ={expresiones.nombre}
              />

          </div> */}



            <table className="w3-table">
              <tr>
                <td>

            {/* <div className="row"> */}
            <Tx
              
              etiqueta = "Nombre"
              tipo = "text"
              name='nombre'
              onChange={ingreso}
              value ={datos.nombre}
              expresion ={expresiones.nombre}
              />

          {/* </div> */}
           {/* <tfoot> */}
           {/* <tr> */}
          {/* <td  colSpan="2"> */}
          {/* <p> ID= {datos.nombre}</p> */}
          
          
           
          {/* </td> */}
         {/* </tr> */}
        {/* <tr> */}
      
          {/* <td>  */}
        

        
            {/* </td> */}
          {/* <td> */}
          <Tx 
          etiqueta ="Cantidad"
          tipo = "number"
          name='cantidad'
          onChange={ingreso}
          value ={datos.cantidad}
          expresion ={expresiones.cantidad}

            /> 
          </td>
        </tr>
        <tr>
          <td  colSpan="2">
          <Tx
           etiqueta ="Codigo de barras"
           tipo = "text" 
           name='codigoDeBarras'
           onChange={ingreso}
           value ={datos.codigoDeBarras}
           expresion ={expresiones.password} /> 
          </td>
        </tr>
        <tr>
        <td  colSpan="2">
          <Tx
           etiqueta ="Descripción"
           tipo = "text"
           name='descripcion'
           onChange={ingreso}
           value ={datos.descripcion}
           expresion ={expresiones.nombre}  /> 
          </td>
        </tr>

        <tr>
          <td>
        <Tx  
             etiqueta = "Temp Max"
             tipo = "number"
             name='max'
             onChange={ingreso}
             value ={datos.max}
             expresion ={expresiones.max}
            />          
        <Tx  
             etiqueta = "Temp Mín"
             tipo = "number"
             name='min'
             onChange={ingreso}
             value ={datos.min}
             expresion ={expresiones.min}
            />          
        <Tx  
             etiqueta = "Tiempo Fuera Rango Permitido"
             tipo = "number"
             name='tiempoOut'
             onChange={ingreso}
             value ={datos.tiempoOut}
             expresion ={expresiones.tiempoOut}
            />          
          </td>
        </tr>



        {/* </tfoot> */}
           </table>
           <Button  className="buttonGeneral" variant="primary" type="submit">Actualizar Producto</Button>
           <Button  className="buttonGeneral button-secondary" variant="primary" onClick={()=>borrar(props.id)} >Eliminar  </Button>
           </form>

        </div>
        </div>
  
            </Modal.Body>
           
          </Modal>

         
        </>
      );
    }
    export function  BotonModal (props) {   
  
        return(
      <Button  className="buttonGeneral" onClick={props.onClick}>
      {props.etiqueta}
    
    </Button>);
    }











    // let producto = { 
    //   "nombre":"leche", 
    //   "cantidad": 40 , 
    //   "codigoDeBarras":"151451515", 
    //   "descripcion":"nada que hacer",
    //   variables: [
    //     {
    //       tipo: 'temperatura',
    //       max: 58,
    //       min: 32,
    //       tiempoOutLimit: 50
    //     },
    //     {
    //       tipo: 'humedad',
    //       max: 57,
    //       min: 10,
    //       tiempoOutLimit: 80
    //     }
    
    //   ]
    // }