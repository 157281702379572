import React from "react";
import 'w3-css/w3.css';
import ConsumoBLE from "../components/registroble/ConsumoBLE.jsx"
import Consumo2BLE from "../components/registroble/Consumo2BLE.js"
import {Container, Row} from "react-bootstrap";

class Registro extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      open:  false,
      devicesTable: [],
      users:[],
      isLoading: false,
      isError: false
    };
  }

render() {
  return (
    <>
      <Container fluid className="w3-panel ">
        <Row>
          <ConsumoBLE/>
        </Row>
      </Container>
    </>
  );
}
}

export default Registro;



