import React, { Component } from "react";
import "../../styles.css";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import punto from './rec.svg';
import camion from './camion.svg';

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

const YOUR_GOOGLE_API_KEY_GOES_HERE = "AIzaSyBYNDUEf0g8OQPJfiEW7ybizYPiMN_7XOU";
const containerStyle = {
  // position: 'relative',  
  width: '100%',
  height: '100%'
};

const style = {
  width: '100%',
  height: '100%'
}

export class TransporteComponent extends Component {

  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  };

  constructor(props) {
    super(props);

    this.state = {
      posiciones: [{
        lat: "-0.163202", lon: "-78.481166"
      }
        // ,{
        //   lat: "-0.162384", lon: "-78.480995"
        // },{
        //   lat: "-0.162443", lon: "-78.480486"
        // },{
        //   lat: "-0.162550", lon: "-78.479928"
        // }
      ]
    };
  }

  render() {
    var positions = this.state.posiciones;
    return (
      <Map google={this.props.google}
        initialCenter={{
          lat: -0.163202,
          lng: -78.481166
        }}
        style={style}
        zoom={16}
      >

        {positions.map((item) => (
          <Marker
            // key={item.id}
            // title={item.name}
            // name={item.name}
            position={{ lat: item.lat, lng: item.lon }}
            icon={{
              url: punto,
              scaledSize: new this.props.google.maps.Size(20, 20)
            }}
          />
        )
        )
        }

        <Marker
          // key={item.id}
          // title={item.name}
          // name={item.name}
          position={{ lat: this.state.posiciones[this.state.posiciones.length - 1].lat, lng: this.state.posiciones[this.state.posiciones.length - 1].lon }}
          icon={{
            url: camion,
            scaledSize: new this.props.google.maps.Size(30, 30)
          }}
        />

      </Map>
    );
  }
}

// export default BodegaComponent;


export default GoogleApiWrapper({
  apiKey: (YOUR_GOOGLE_API_KEY_GOES_HERE)
})(TransporteComponent)
