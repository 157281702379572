import React, { useState, useEffect } from "react";
import { ForMap, Mapas } from "./ElementsMarcador";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
const YOUR_GOOGLE_API_KEY_GOES_HERE = "AIzaSyBYNDUEf0g8OQPJfiEW7ybizYPiMN_7XOU";

const styleMapa = {
  width: "100%",
  height: "100%",
};

const IngresarMarcador = (props) => {
  // const [test, setTest] = useState({
  //   lat: 4.7651026,
  //   lng: -74.2965917,
  // });

  // function obtenercoordendas() {
  //   navigator.geolocation.getCurrentPosition(
  //     (position) => {
  //       console.log("Coordenadas Obtenidas");
  //       console.log("Latitud " + position.coords.latitude);
  //       console.log("Longitud " + position.coords.longitude);

  //       setTest({
  //         lat: position.coords.latitude,
  //         lng: position.coords.longitude,
  //       });
  //     },
  //     (error) => {}
  //   );
  // }
  // useEffect(() => {
  //   obtenercoordendas();
  // }, []);

  return (
    <>
      <Mapas>
        <ForMap>
          <Map
            google={window.google}
            initialCenter={{
              lat: props.position.lat,
              lng: props.position.lng,
            }}
            style={styleMapa}
            zoom={props.zoom}
            onClick={(x, y, LatLng, ev) => {
              props.setFunction({
                lat: LatLng.latLng.lat(),
                lng: LatLng.latLng.lng(),
              });
            }}
          >
            <Marker
              position={{ lat: props.position.lat, lng: props.position.lng }}
              draggable={true}
              name={"Current location"}
            />
          </Map>
        </ForMap>
      </Mapas>
    </>
  );
};
export default GoogleApiWrapper({
  apiKey: YOUR_GOOGLE_API_KEY_GOES_HERE,
})(IngresarMarcador);
