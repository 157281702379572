import styled from 'styled-components'

export const Main = styled.div`
margin: 25px 30px 25px 30px;
height: 100%;
${'' /* background: red; */}
`
export const ContenedorTitulo = styled.div`
padding: 10px 15px;
height: 45px;
`
export const Titulo = styled.div`

`
export const ContenedorCategorias = styled.div`
`
export const Categorias = styled.div`
display: inline-block;
margin-left: 25px;
`
export const ContenedorTabla = styled.div`

`
export const NavbarTabla = styled.div`
${'' /* width: 100%; */}
margin: 30px 15px;
background: red;
${'' /* text-align: center; */}
`
export const BotonFiltro = styled.div`
display: inline-block;
${'' /* display: inline-block;
height: 40px;
width: 95.80007934570312px;
left: 20.373291015625px;
top: 117px;
border-radius: 6px;
background-color: transparent;
border:none; */}
`
export const BarraBusqueda = styled.div`
display: inline-block;
margin-left: 42px;
margin-right: 320px;
height: 50px;
width: 392px;
background: #EFEFEF;
${'' /* top: 50%; */}
padding: 1% 0;

${'' /* left: 0px;
top: 0px;
border-radius: 6px;
left: 0%;
right: 0%;
top: 0%;
bottom: 0%;
box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
border-radius: 6px; */}

align-content: center;
justify-content: flex-start;
align-items: center;

`

export const IconoLupa = styled.div`
display: inline-block;
margin: 0 10px;
`
export const InputBusqueda = styled.input`
display: inline-block;
width: 320px;
border: none;
background-color: transparent;
&:focus {
        outline: none;
    }
${'' /* border-radius: 1px; */}

`

export const BotonCrear = styled.div`
display: inline-block;
float: right;

background: #114396;
color: white;
border-radius: 5px;
height: 49.999996185302734px;
width: 210px;
${'' /* align-content: center; */}
text-align: center;
${'' /* align-items: center; */}


${'' /* display: inline-block;
background: #114396;
color: white;
border-radius: 5px;
height: 49.999996185302734px;
width: 210px;
border:none; */}


${'' /* left: -0.1253662109375px;
box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
border-radius: 5px;
left: -0.06%;
right: 0.06%;
bottom: -0.39%;
box-shadow: 6px 0px 18px 0px #0000000F; */}

`




export const Tr = styled.tr`
${'' /* position: absolute; */}
left: 0.03%;
right: 0.06%;
top: 19.77%;
bottom: 75.08%;
background: #EFEFEF;
height: 45px;
`

export const Th = styled.th`
left: 25.01%;
right: 61.36%;
top: 21.49%;
bottom: 76.46%;
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 130%;
color: #4F4F4F;
text-align: center;
padding: 15px;
`

export const TablaDispo = styled.table`
width: 100%;
border-collapse: collapse;
`

export const TrC = styled.tr`
text-align: center;
${'' /* margin: 15px 0 15px 0; */}
${'' /* border: solid; */}
border-width: 1px 0;
border-color: #dadada;
${'' /* height: 30px; */}
${'' /* background: red; */}

`


export const CoFiltroIn = styled.div`
height: 45px;
width: 100%;
background: #EFEFEF;
text-align: right;

`

export const Divfiltro = styled.div`
display: inline-block;
/* Filas por página : 10 */
margin: 10px 30px 0 30px;


/* Table/Footer */

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 15px;
/* identical to box height */

letter-spacing: 0.05em;

/* text */
`

export const Stiemererpo = styled.td`

`

export const SelectFiltro = styled.select`
border:none;
background: #EFEFEF;
`
// dsdsd

export const ContenedorDis = styled.div`
`
export const Nombredispositivo = styled.div`
color: #114396;

`
export const DescripcionDispo = styled.div`
color: #4F4F4F;

font-family: Montserrat;
font-size: 14px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
${'' /* text-align: left; */}


`
export const Organizacion = styled.div`
color: #114396;

`
export const Tipo = styled.div`
color: #4F4F4F;

`
export const ContenedorFecha = styled.div`
`
export const Estado = styled.div`
color: #114396;


`
export const Fecha = styled.div`
color: #4F4F4F;
font-family: Montserrat;
font-size: 12px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
${'' /* text-align: left; */}


`
export const Tiempo = styled.div`
color: #114396;

`
export const Puntos = styled.div`
color: #4F4F4F;

`

// nombre
// organizacion
// cantidadUsuarios
// estado