import React, { Component } from "react";
import "../../styles.css";
// import CustomInput from "../forms/CustomInput";
// import Button from "../forms/Button.js";
// import GoogleMapReact from 'google-map-react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';

import Api from '../../services/api-service.js'

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

const style = {
  width: '100%',
  height: '100%'
}

const styleFigura ={
  stroke:"#000000",
  "strokeWidth":0.72,
  "strokeLinecap":"round",
  "strokeLinejoin":"round",
  "strokeMiterlimit":10
}


// function adquisicionConectedDevices (tipo){

//   return new Promise((resolve, reject) => {
//     fetch("http://iot-api.constecoin.com:8080/api/beacons/beacon/test/getActiveDevices?numeroDatos=5&tipo="+tipo)
//       .then(res => res.json())
//       .then(
//         (result) => {

//           resolve(result);
//         },
//         (error) => {
//           console.log(error);
//           reject(error);
//         }
//       )
//   })
// }



export class BodegaComponent extends Component {

  intervalID = 0;
  constructor (props) {
    super(props);

    this.state={
      styleGateway1:{
        gateway: {...styleFigura, fill: "#00ff00", opacity: 1},
        area1: {...styleFigura, fill: "#2EDEFF", opacity: 0},
        area2: {...styleFigura, fill: "#FFA017", opacity: 0} ,
        area3: {...styleFigura, fill: "#F3FF5C", opacity: 0},
        area4: {...styleFigura, fill: "#FAB9FF", opacity: 0},
      },
      styleGateway2:{
        gateway: {...styleFigura, fill: "#00ff00", opacity: 1},
        area1: {...styleFigura, fill: "#2EDEFF", opacity: 0},
        area2: {...styleFigura, fill: "#FFA017", opacity: 0} ,
        area3: {...styleFigura, fill: "#F3FF5C", opacity: 0},
        area4: {...styleFigura, fill: "#FAB9FF", opacity: 0},      
      },
      styleGateway3:{
        gateway: {...styleFigura, fill: "#00ff00", opacity: 1},
        area1: {...styleFigura, fill: "#2EDEFF", opacity: 0},
        area2: {...styleFigura, fill: "#FFA017", opacity: 0} ,
        area3: {...styleFigura, fill: "#F3FF5C", opacity: 0},
        area4: {...styleFigura, fill: "#FAB9FF", opacity: 0},
      },
    }
    console.log(this.state);
  }

    
  
  
  intervalID = setInterval(() => {
      this.actualizacionDevices();
      console.log('actualización');
    }, 5000);

    actualizacionDevices = () =>{
      Api.adquisicionConectedDevices('gateway', this.props.bodega).then(
        async result=>{
          console.log('gateway', result)
          let auxResult= [];
          auxResult = result;

          let auxiliar1 = await auxResult.filter(item=> {return (item.svgID === 'ID1')})
          console.log('auxiliar1',auxiliar1)

          if(auxiliar1.length !== 0){
            console.log('detectado')
            // deteccion ok del gateway 1
            this.setState({ styleGateway1: {...this.state.styleGateway1, gateway: {...styleFigura, fill: "#0000FF", opacity: 1}}});
          } else{
            console.log('NO detectado')
            // deteccion ERROR del gateway 1
            this.setState({ styleGateway1: {...this.state.styleGateway1, gateway: {...styleFigura, fill: "#ff0000", opacity: 1}}});
          }

          let auxiliar2 = await auxResult.filter(item=> {return (item.svgID === 'ID2')})
          // console.log('auxiliar2',auxiliar2)
          if(auxiliar2.length !== 0){
            // console.log('detectado')
            // deteccion ok del gateway 1
            this.setState({ styleGateway2: {...this.state.styleGateway2, gateway: {...styleFigura, fill: "#0000FF", opacity: 1}}});
          } else{
            // console.log('NOO detectado')
            // deteccion ERROR del gateway 1
            this.setState({ styleGateway2: {...this.state.styleGateway2, gateway: {...styleFigura, fill: "#ff0000", opacity: 1}}});
          }

          let auxiliar3 = await auxResult.filter(item=> {return (item.svgID === 'ID3')})
          // console.log('auxiliar3',auxiliar3)
          if(auxiliar3.length !== 0){
            // console.log('detectado')
            // deteccion ok del gateway 1
            this.setState({ styleGateway3: {...this.state.styleGateway3, gateway: {...styleFigura, fill: "#0000FF", opacity: 1}}});
          } else{
            // console.log('NOO detectado')
            // deteccion ERROR del gateway 1
            this.setState({ styleGateway3: {...this.state.styleGateway3, gateway: {...styleFigura, fill: "#ff0000", opacity: 1}}});
          }
          let timeout = setTimeout(() => {
            console.log('timeout' , this.state);
          }, 200);
        },
        error=>{
       }
      )
      if(this.props.posicion === undefined || this.props.posicion === null){
        this.setState({ styleGateway3: {...this.state.styleGateway3, area3: {...styleFigura, fill: "#F3FF5C", opacity: 0}}});
        this.setState({ styleGateway1: {...this.state.styleGateway1, area3: {...styleFigura, fill: "#F3FF5C", opacity: 0}}});
        this.setState({ styleGateway2: {...this.state.styleGateway2, area3: {...styleFigura, fill: "#F3FF5C", opacity: 0}}});
        return ;
      }
      /////////////////////////////////// ACTUALIZACION DE DISPOSITIVOS //////////////////////////
      console.log('/////////////////////////////////// ACTUALIZACION DE DISPOSITIVOS', this.props.posicion);

      if(this.props.posicion === 'ID2'){
        this.setState({ styleGateway3: {...this.state.styleGateway3, area3: {...styleFigura, fill: "#F3FF5C", opacity: 0}}});
        this.setState({ styleGateway1: {...this.state.styleGateway1, area3: {...styleFigura, fill: "#F3FF5C", opacity: 0}}});
        this.setState({ styleGateway2: {...this.state.styleGateway2, area3: {...styleFigura, fill: "#F3FF5C", opacity: 0.6}}});
      } else if(this.props.posicion === 'ID3') {
        this.setState({ styleGateway1: {...this.state.styleGateway1, area3: {...styleFigura, fill: "#F3FF5C", opacity: 0}}});
        this.setState({ styleGateway2: {...this.state.styleGateway2, area3: {...styleFigura, fill: "#F3FF5C", opacity: 0}}});
        this.setState({ styleGateway3: {...this.state.styleGateway3, area3: {...styleFigura, fill: "#F3FF5C", opacity: 0.6}}});
      } else if(this.props.posicion === 'ID1'){
        this.setState({ styleGateway3: {...this.state.styleGateway3, area3: {...styleFigura, fill: "#F3FF5C", opacity: 0}}});
        this.setState({ styleGateway2: {...this.state.styleGateway2, area3: {...styleFigura, fill: "#F3FF5C", opacity: 0}}});
        this.setState({ styleGateway1: {...this.state.styleGateway1, area3: {...styleFigura, fill: "#F3FF5C", opacity: 0.6}}});
      }


      // if(this.props.ble1.gateway === 'AC233FC0A769'){
      //   this.setState({ styleGateway3: {...this.state.styleGateway3, area3: {...styleFigura, fill: "#F3FF5C", opacity: 0}}});
      //   this.setState({ styleGateway1: {...this.state.styleGateway1, area3: {...styleFigura, fill: "#F3FF5C", opacity: 0}}});
      //   this.setState({ styleGateway2: {...this.state.styleGateway2, area3: {...styleFigura, fill: "#F3FF5C", opacity: 0.6}}});
      // } else if (this.props.ble1.gateway === 'AC233FC0A75F'){
      //   this.setState({ styleGateway1: {...this.state.styleGateway1, area3: {...styleFigura, fill: "#F3FF5C", opacity: 0}}});
      //   this.setState({ styleGateway2: {...this.state.styleGateway2, area3: {...styleFigura, fill: "#F3FF5C", opacity: 0}}});
      //   this.setState({ styleGateway3: {...this.state.styleGateway3, area3: {...styleFigura, fill: "#F3FF5C", opacity: 0.6}}});
      // }
      // else if (this.props.ble1.gateway === 'AC233FC0A766'){
      //   // this.setState({ styleGateway2: {...this.state.styleGateway2, area3: {...styleFigura, fill: "#F3FF5C", opacity: 0}}});
      //   this.setState({ styleGateway3: {...this.state.styleGateway3, area3: {...styleFigura, fill: "#F3FF5C", opacity: 0}}});
      //   this.setState({ styleGateway2: {...this.state.styleGateway2, area3: {...styleFigura, fill: "#F3FF5C", opacity: 0}}});
      //   this.setState({ styleGateway1: {...this.state.styleGateway1, area3: {...styleFigura, fill: "#F3FF5C", opacity: 0.6}}});
      // }

      ///////////////////////////////////////////S1 ///////////////////////
      //  adquisicionConectedDevices('gateway').then(
      //   async result=>{
      //     console.log(result)
      //     let auxResult= [];
      //     auxResult = result;

      //     let auxiliar1 = await auxResult.filter(item=> {return (item.svgID === 'ID1')})
      //     console.log('auxiliar1',auxiliar1)

      //     if(auxiliar1.length !== 0){
      //       console.log('detectado')
      //       // deteccion ok del gateway 1
      //       this.setState({ styleGateway1: {...this.state.styleGateway1, gateway: {...styleFigura, fill: "#0000FF", opacity: 1}}});
      //     } else{
      //       console.log('NOO detectado')
      //       // deteccion ERROR del gateway 1
      //       this.setState({ styleGateway1: {...this.state.styleGateway1, gateway: {...styleFigura, fill: "#ff0000", opacity: 1}}});
      //     }

      //     let auxiliar2 = await auxResult.filter(item=> {return (item.svgID === 'ID2')})
      //     console.log('auxiliar2',auxiliar2)
      //     if(auxiliar2.length !== 0){
      //       console.log('detectado')
      //       // deteccion ok del gateway 1
      //       this.setState({ styleGateway2: {...this.state.styleGateway2, gateway: {...styleFigura, fill: "#0000FF", opacity: 1}}});
      //     } else{
      //       console.log('NOO detectado')
      //       // deteccion ERROR del gateway 1
      //       this.setState({ styleGateway2: {...this.state.styleGateway2, gateway: {...styleFigura, fill: "#ff0000", opacity: 1}}});
      //     }

      //     let auxiliar3 = await auxResult.filter(item=> {return (item.svgID === 'ID3')})
      //     console.log('auxiliar3',auxiliar3)
      //     if(auxiliar3.length !== 0){
      //       console.log('detectado')
      //       // deteccion ok del gateway 1
      //       this.setState({ styleGateway3: {...this.state.styleGateway3, gateway: {...styleFigura, fill: "#0000FF", opacity: 1}}});
      //     } else{
      //       console.log('NOO detectado')
      //       // deteccion ERROR del gateway 1
      //       this.setState({ styleGateway3: {...this.state.styleGateway3, gateway: {...styleFigura, fill: "#ff0000", opacity: 1}}});
      //     }
      //     let timeout = setTimeout(() => {
      //       console.log('timeout' , this.state);
      //     }, 200);
      //   },
      //   error=>{
      //  }
      // )

    }

    datos = () =>{
        let estiloSala1 = {
            fill: "#000000"
          }
          let estiloSala2= {
            fill: "#000000"
          }
          let estiloSala3= {        
            fill: "#000000"
        };
    
          // console.log(estiloSala1, estiloSala2, estiloSala3)
          // this.setState(() => ({estiloSala1}));
          // this.setState(() => ({estiloSala2}));
          // this.setState(() => ({estiloSala3}));

    } 
    
    
componentWillUnmount() {
  clearInterval(this.intervalID);
}

      render() {
        return (
            <div className={this.props.className}>
            <svg
              id="prefix__Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x={0}
              y={0}
              viewBox="0 0 623 273"
              xmlSpace="preserve"
              // {...props}
            >
              <style>
                {".st0{fill:none;stroke:#000000;strokeWidth:0.72;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"}
	            {/* // ".st1{fill:#1CC4C4;stroke:#000000;stroke-width:0.72;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"+
              // ".st2{fill:#FAB9FF;stroke:#000000;stroke-width:0.72;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"+
              // ".st3{fill:#F3FF5C;stroke:#000000;stroke-width:0.72;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"+
              // ".st4{fill:#FFA017;stroke:#000000;stroke-width:0.72;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"+
              // ".st5{fill:#2EDEFF;stroke:#000000;stroke-width:0.72;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"+
              // ".st6{fill:#0092FF;stroke:#000000;stroke-width:0.72;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"} */}
              </style>
                           
              <path className="st0" d="M506.6,16.1h-8.9 M609.9,16.1h-23.8 M609.9,260.2V11.8"/>
              <polyline className="st0" points="480.4,16.1 443.9,16.1 353.2,16.1 279.8,16.1 253.9,16.1 178.3,16.1 "/>
              <path className="st0" d="M13.1,208.1V11.8 M609.9,255.9H65.1 M609.9,260.2H62.8 M182.6,16.1v157.7 M178.3,16.1v162 M293.1,173.8H178.3
                M502,195.4l-209,0.5"/>
              <polyline className="st0" points="17.5,202.5 17.5,11.8 609.9,11.8 "/>
              <path className="st0" d="M609.9,16.1v-4.3 M609.9,260.2v-4.3 M497.7,195.4V37.3 M502,195.4V37.7 M65.1,255.9l-47.6-53.4 M62.8,260.2
                l-49.7-52.1 M17.5,16.1h592.4"/>
              <polyline className="st0" points="228.8,173.8 228.7,114.9 242,114.9 "/>
              <path className="st0" d="M232,84.5v-4.2 M246.3,179.1v-68 M242,179.1v-68 M267.7,80.7V16.1 M263.3,85V16.1 M232.4,80.3l-49.8-0.2
                M232,84.3l-49.4,0.1"/>
              <polyline className="st0" points="213.4,86.7 213.7,89.8 214.4,92.8 215.6,95.6 217.1,98.2 219.2,100.5 221.5,102.5 224.1,104.1 
                226.9,105.3 229.9,106 232.9,106.3 "/>
              <polyline className="st0" points="252.1,65.1 249.1,65.3 246.1,66.1 243.3,67.3 240.7,68.8 238.4,70.9 236.3,73.1 234.8,75.8 
                233.6,78.5 232.9,81.5 232.6,84.5 "/>
              <path className="st0" d="M405.5,191.1v-68 M401.2,191.1v-68 M334.7,113.7h4.3 M233,111.2h42 M409.4,103.6V16.1 M405.1,103.6V16.1
                M263.3,85h76"/>
              <polyline className="st0" points="405.5,142.5 408.5,142.3 411.5,141.5 414.3,140.5 416.9,138.8 419.2,136.9 421.3,134.5 422.8,131.9 
                424,129.1 424.7,126.2 425,123.1 "/>
              <path className="st0" d="M409.4,99.3h62.4 M409.3,116.6h88.3 M409.3,112.3h88.3"/>
              <polyline className="st0" points="409.4,103.6 471.8,103.6 471.8,16.1 "/>
              <line className="st0" x1="467.5" y1="103.6" x2="467.5" y2="16.1"/>
              <polyline className="st0" points="428.8,103.6 428.6,100.6 427.9,97.6 426.7,94.7 425.1,92.2 423.2,89.8 420.8,87.9 418.3,86.3 
                415.4,85.1 412.4,84.4 409.4,84.2 "/>
              <path className="st0" d="M428.8,103.6v-4.3 M428.8,127.4v-4.3"/>
              <polyline className="st0" points="480.4,16.1 480.5,19.1 481.3,22.3 482.3,25.1 483.8,27.8 485.7,30.3 487.9,32.5 490.3,34.3 493,35.8 
                495.9,36.9 498.9,37.5 502,37.7 "/>
              <path className="st0" d="M405.1,123.1h4.3 M334.7,191.1v-77.4 M339.1,191.1v-77.3 M293.1,191.1v-77.4 M297.4,191.1v-77.4"/>
              <polyline className="st0" points="267.7,80.7 339.3,80.7 339.3,85 "/>
              <line className="st0" x1="293.1" y1="113.7" x2="297.4" y2="113.7"/>
              <polyline className="st0" points="293,195.9 293,178.1 178.3,178.1 "/>
              <path className="st0" d="M13.1,11.8h4.3 M500,191.5l-202.6-0.4"/>
              <circle style={this.state.styleGateway1.area4} cx="235.7" cy="120.5" r="136.5"/>
              <circle style={this.state.styleGateway1.area3} cx="200.7" cy="160.5" r="91"/>
              <circle style={this.state.styleGateway1.area2} cx="189.5" cy="87.5" r="72.8"/>
              <circle style={this.state.styleGateway1.area1} cx="233.5" cy="120.5" r="54.6"/>
              <rect x="180.5" y="80.6" transform="matrix(4.481577e-11 -1 1 4.481577e-11 112.9202 353.8799)" style={this.state.styleGateway1.gateway} width="13.8" height="13.8"/>

              <circle style={this.state.styleGateway2.area4} cx="346.2" cy="23" r="136.5"/>
              <circle style={this.state.styleGateway2.area3} cx="346.2" cy="23" r="91"/>
              <circle style={this.state.styleGateway2.area2} cx="346.2" cy="23" r="72.8"/>
              <circle style={this.state.styleGateway2.area1} cx="346.1" cy="23" r="54.6"/>
              
              <circle style={this.state.styleGateway3.area1} cx="413.1" cy="157.1" r="54.6"/>
              <circle style={this.state.styleGateway3.area4} cx="413.1" cy="157.1" r="136.5"/>
              <circle style={this.state.styleGateway3.area3} cx="413.1" cy="157.1" r="91"/>
              <circle style={this.state.styleGateway3.area2} cx="413.1" cy="157.1" r="72.8"/>
              <rect x="339.3" y="16.1" transform="matrix(4.507956e-11 -1 1 4.507956e-11 323.1601 369.2399)" style={this.state.styleGateway2.gateway} width="13.8" height="13.8"/>
              <rect x="406.2" y="150.2" transform="matrix(4.494722e-11 -1 1 4.494722e-11 256.0201 570.1798)" style={this.state.styleGateway3.gateway} width="13.8" height="13.8"/>

            </svg>
          </div>  
        );
      }
}

export default BodegaComponent;

