import styled from "styled-components";

export const InputBusqueda = styled.input`
  display: inline-block;
  width: 320px;
  border: none;
  background-color: transparent;
  &:focus {
    outline: none;
  }
  ${"" /* border-radius: 1px; */}
`;
