import React from "react";
import "./CuadroTemp.css"
import Thermometer from 'react-thermometer-component'


class CuadroTemp extends React.Component {

  constructor(props) {
    super(props);
    // console.log("fila:", this.props.fila, "Linea:", this.props.linea)
    this.state = {
    };
  }

  render() {
    // console.log("props", this.props.info)
    return (
      <>

        <Thermometer
          theme="light"
          value={this.props.info}
          max="50"
          steps=""
          format="°C"
          size="normal"
          height="180"
        />

      </>
    );
  }
}

export default CuadroTemp;