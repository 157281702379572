import React from 'react';
import 'w3-css'
import './estiloform.css'
import Tx from "../input";
import {Button,Modal } from "react-bootstrap";

class ModalBLE extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      showModal: false,
      datos: {
        mac:'',
        tipo:'',
        isvgID:'',
        _id:''
      },
      expresiones: {
        //usuario: /^[a-zA-Z0-9\_\-]{4,16}$/, // Letras, numeros, guion y guion_bajo
        mac: /^[a-zA-Z0-9\_\-]{1,12}$/, // Letras y espacios, pueden llevar acentos.
        tipo: /^[a-zA-Z0-9\_\-]/, // 4 a 12 digitos.
        //correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
      }
    };
  }
  
  cerrarModal () {
    // console.log("Estado IN cerrarModalDL", this.state)
    this.state.showModal = false;
    this.setState({
    showModal: false
    })
    this.props.parentCallback(this.state);
    // console.log("Estado OUT cerrarModalDL en BootstrapModal.js", this.state)
  };

  enviarDatos = (e) => {
    var envio  ={ _id:this.state.datos._id,
                  mac:this.state.datos.mac,
                  tipo:this.state.datos.tipo,
                  svgID:this.state.datos.isvgID }
    e.preventDefault ();
    const requestOptions = {
                            method: 'PUT',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(envio)}
    console.log("Datos a enviar a rest",JSON.stringify(envio))
      // fetch(this.props.links, requestOptions)
      // .then(response => console.log(response))
    this.cerrarModal()
  }

  ingreso = (event)=>{
    this.setState({
      datos:{
        [event.target.name]: event.target.value
      }
    })
  }

  borrar=(id)=>{
  
    var eliminar ={_id:id}
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(eliminar)}

      // fetch(this.props.errarse, requestOptions)
      // .then(response => console.log(response))     
      console.log("Este es el ID a borrar", requestOptions)
      this.cerrarModal()
  } 


render() {
  return (
    <>
          <Modal
            show={this.props.show}
            onHide={this.cerrarModal}
            backdrop="static"
            keyboard={false}
            className=" w3-modal-content{background-color: transparent} w3-animate-zoom"
            style={{maxwidth:600}}
            >
          
            <Modal.Header closeButton className="w3-rigth">
            </Modal.Header>

            <Modal.Body>
              <div className="w3-row">   
                <div className="w3-rest  ">
                  <h1>Edición BLE</h1>
                  <form onSubmit={this.enviarDatos}>
                    <table className="w3-table">
                      <tfoot>
                        <tr>
                          <td  colSpan="2">
                            <p>{this.state.datos.mac}</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Tx
                            etiqueta = "Mac"
                            tipo = "text"
                            name='mac'
                            onChange={this.ingreso}
                            value ={this.state.datos.mac}
                            expresion ={this.state.expresiones.mac}
                            />
                          </td>
                          <td>
                            <Tx 
                            etiqueta ="Tipo"
                            tipo = "text"
                            name='tipo'
                            onChange={this.ingreso}
                            value ={this.state.datos.tipo}
                            expresion ={this.state.expresiones.tipo}
                            /> 
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <Tx
                            etiqueta ="zona"
                            tipo = "text" 
                            name='svgID'
                            onChange={this.ingreso}
                            value ={this.state.datos.isvgID}
                            expresion ={this.state.expresiones.password} /> 
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                    <Button  className="buttonGeneral "  type="submit"> Actualizar BLE </Button>
                    <Button  className="buttonGeneral button-secondary"  onClick={()=>this.borrar(this.state.datos._id)}> Eliminar  </Button>
                  </form>
                </div>
              </div>
            </Modal.Body>
          </Modal>
    </>
  );
}
}

export default ModalBLE;