import React, { useEffect, useState, useMemo } from "react";
import ActualizarMapa from "../../../components/Map/Actualizar Marcador/index";
import swal from "sweetalert";
import Swal2 from 'sweetalert2'
import { useHistory } from "react-router-dom";
import ImagenAbierta from "./abierto.png";
import ImagenCerrada from "./cerrado.png";
import Prendido from "./prendido.png";
import Apagado from "./apagado.png";
import HumoEncendido from "./humoA.svg";
import HumoApagado from "./humoD.svg";
import Mapa from "../../../components/Mapa/CustomMarker";
import Select from "../../../components/Entradas/Select";
import Buttonn from "../../../components/Button";
import Probando from "../Create/index";
import Veruno from "../../../components/Map/Ver Marcador/index";
import MapaOne from "../../../components/Alcantarillas/VerUnaAlcantarilla/index";
import Modal from "react-modal";
import Loading from "../../../components/Loading"
import LoadingOverlay from 'react-loading-overlay';
import ReactApexChart from "react-apexcharts";
import CuadroTemp from "../../Gabinete/CuadroTempC.js";
import RadioHum from "../../Gabinete/indicadorHumedadC.js";
import Selectt from "../../../components/Entradas/Select";



import {
  BarraBusqueda,
  BotonCrear,
  BotonFiltro,
  Categorias,
  ContenedorCategorias,
  ContenedorTabla,
  ContenedorTitulo,
  Main,
  NavbarTabla,
  Th,
  Titulo,
  Tr,
  TablaDispo,
  TrC,
  CoFiltroIn,
  Divfiltro,
  SelectFiltro,
  IconoLupa,
  InputBusqueda,
  ContenedorDis,
  Nombredispositivo,
  DescripcionDispo,
  Organizacion,
  Tipo,
  ContenedorFecha,
  Estado,
  Tiempo,
  Fecha,
  BotonMostrar,
  BotonDevice,
  ContenedorButton,
  ContenedorWidget,
  Widget,
  ParaImagen
} from "../AlcantarillaElements";
import Inputt from "../../../components/Entradas/Input";
import { useLocation } from "react-router-dom";
import Auth from '../../../services/auth-service.js';
import Api from "../../../services/api-service.js";
import CustomMap from "../../../components/Alcantarillas/ActualizarMarcador/index";
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import { Table, Row, Col, Button, Input, CustomInput } from "reactstrap";
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import PaginationComponent from "react-reactstrap-pagination";
import PageIndex from "../../../components/Table/PageIndex";
import ReactLoading from 'react-loading';

const Roles = [
  {
    value: "5",
    label: "5",
  },
  {
    value: "10",
    label: "10",
  },
  {
    value: "20",
    label: "20",
  },
  {
    value: "50",
    label: "50",
  },
  {
    value: "100",
    label: "100",
  },
  {
    value: "all",
    label: "Todos",
  },
];
const TipoAlera = [
  {
    value: "Reporte",
    label: "Reporte",
  },
  {
    value: "Alerta",
    label: "Alerta",
  },
];

// const customStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     transform: "translate(-50%, -50%)",
//   },
// };

let series = [
  {
    name: "series-1",
    data: [40, 45, 50, 49, 60, 70, 91]
  }
]

let options = {
  chart: {
    id: "basic-bar"
  },
  xaxis: {
    categories: [1991, 1992, 1993, 1994, 1995, 1996]
  }
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "800px",
    height: "620px",
    transform: "translate(-50%, -50%)",
  },
};

const ViewOne = () => {


  var history = useHistory();
  const [devices, setDevices] = useState([]);
  const [coordendas, setCoordendas] = useState({
    lat: null,
    lng: null,
  });
  const [report, setReport] = useState();
  const [viewData, setViewData] = useState(true);
  const [latitud, setLatitud] = useState(null);
  const [longitud, setLongitud] = useState(null);
  const query = new URLSearchParams(useLocation().search);
  const term = query.get("id");
  const obteOrganizacion = query.get("organizacion");
  const tipoDispositivo = query.get("tipo");
  const Dview = query.get("view");

  const [modalIsOpen, setIsOpen] = useState(false);
  const [time, setTime] = useState(new Date().toLocaleTimeString());
  const [columnaHumo, setColumnaHumo] = useState(false);
  const [nombre, setNombre] = useState();
  const [Newnombre, setNewNombre] = useState("");
  const [sinData, setSinData] = useState(true);
  const [postRol, setRol] = useState();

  const [arregloFecha, setArregloFecha] = useState([])
  const [arregloHumedad, setArregloHumedad] = useState([])
  const [arregloTemperatura, setArregloTemperatura] = useState([])
  const [Cmostrar, setCmostrar] = useState(5)
  const [Npagina, setNpagina] = useState(1)

  const [chartData, setChartData] = useState({
    options: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      yaxis: {
        tickAmount: 10,
        min: -40,
        max: 100,
        decimalsInFloat: 2,
      },
      xaxis: {
        type: "datetime",
        // min: fechaInicial.getTime(),
        categories: [],
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
    series: [
      {
        name: "series-1",
        data: []
      }
    ]
  });
  const [temperatura, setTemperatura] = useState({
    options: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      yaxis: {
        tickAmount: 10,
        min: -40,
        max: 100,
        decimalsInFloat: 2,
      },
      xaxis: {
        type: "datetime",
        // min: fechaInicial.getTime(),
        categories: [],
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
    series: [
      {
        name: "series-1",
        data: []
      }
    ]
  });

  function sendData() {
    console.log("*******************-----------------***************");
    console.log(Newnombre);
    console.log(latitud);
    console.log(coordendas.lat);

    if (
      coordendas.lat == latitud &&
      coordendas.lng == longitud &&
      Newnombre == `${nombre}`
    ) {
      console.log("Entro aqui 1");
      swal({
        icon: "error",
        title: "Oops...",
        text: "Debe ingresar algun cambio para guardar",
      });
    } else if (
      coordendas.lat == null &&
      coordendas.lng == null &&
      Newnombre == `${nombre}`
    ) {
      console.log("Entro aqui 2");
      swal({
        icon: "error",
        title: "Oops...",
        text: "Debe ingresar algun cambio para guardar",
      });
    } else if (
      coordendas.lat == null &&
      coordendas.lng == null &&
      Newnombre != `${nombre}`
    ) {
      console.log("Entro aqui 3");
      swal("Ok!", "Se actualizo el nombre!!", "success");
      Api.updateDeviceWT32(
        report,
        obteOrganizacion,
        latitud,
        longitud,
        Newnombre
      );
      setIsOpen(false);
    } else if (
      coordendas.lat != null &&
      coordendas.lng != null &&
      Newnombre == `${nombre}`
    ) {
      console.log("Entro aqui 4");

      swal("Ok!", "Se actualizo las coordenadas!!", "success");
      Api.updateDeviceWT32(
        report,
        obteOrganizacion,
        coordendas.lat,
        coordendas.lng,
        Newnombre
      );
      setIsOpen(false);
    } else if (
      coordendas.lat != null &&
      coordendas.lng != null &&
      Newnombre != `${nombre}`
    ) {
      console.log("Entro aqui 5");

      swal("Ok!", "Guardado!!", "success");
      Api.updateDeviceWT32(
        report,
        obteOrganizacion,
        coordendas.lat,
        coordendas.lng,
        Newnombre
      );
      setIsOpen(false);
    }
  }

  function nuevoNombre(e) {
    setNewNombre(e.target.value);
  }

  function Borrar() {
    Swal2.fire({
      title: '¿Quiere eliminar el dispositivo?',
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      denyButtonText: `Eliminar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isDenied) {
        Api.EliminarWT32(report).then((res) => {
          Swal2.fire('Dispositivo eliminado', '', 'success')
          var url = `/admin/wt32?organizacion=${obteOrganizacion}&tipo=${tipoDispositivo}`
          history.push(url);
        }, (error) => {
          Swal2.fire('Error al eliminar', '', 'error')
        })
      }
    })
  }


  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setSinData(false)
    setIsOpen(true);
  }

  function tiempoA() {
    setTimeout(() => {
      setTime(new Date().toLocaleTimeString());
    }, 60000);
  }


  useEffect(() => {
    setRol(Auth.getRol())
    Api.getDeviceWt32ById(term, Cmostrar, Npagina).then(
      (response) => {
        console.log("-----------------------------*-------------------");
        console.log(response);
        console.log("-----------------------------*-------------------");
        console.log(response.data.length);
        if (response.data.length <= 0) {
          // setSinData(true);
        } else {
          response.data.map((fecha) => {
            arregloFecha.push(fecha.fecha)
            arregloHumedad.push(fecha.hum)
            arregloTemperatura.push(fecha.temp)
          })
          setSinData(false);
          if (response.data[0].humo != undefined) {
            setColumnaHumo(true);
          } else {
            setColumnaHumo(false);
          }
        }


        setArregloFecha()
        setArregloHumedad()
        setArregloTemperatura()
        setArregloFecha(arregloFecha)
        setArregloHumedad(arregloHumedad)
        setArregloTemperatura(arregloTemperatura)

        setChartData({
          ...chartData,
          options: {
            ...chartData.options,
            xaxis: {
              ...chartData.options.xaxis,
              categories: arregloFecha
            }
          },
          series: [
            {
              ...chartData.series[0],
              data: arregloHumedad
            }
          ]
        });
        setTemperatura({
          ...chartData,
          options: {
            ...chartData.options,
            xaxis: {
              ...chartData.options.xaxis,
              categories: arregloFecha
            }
          },
          series: [
            {
              ...chartData.series[0],
              data: arregloTemperatura
            }
          ]
        });
        setLatitud(response.coordenadas.lat);
        setLongitud(response.coordenadas.lng);

        setDevices(response.data);
        setReport(response.mac);
        setNombre(response.nombre);
        setNewNombre(response.nombre);
        tiempoA()
      },
      (error) => {
        console.log("Obtencion erronea");
        console.log(error)
      }
    );
  }, [modalIsOpen, time, Cmostrar]);



  return (
<>
    <LoadingOverlay
      active={sinData}
      spinner
      text='Cargando Datos...'
    >
      <>
        <Main>
          <p>Datos actualizados hasta la hora: {time}</p>
          <ContenedorTitulo>
            <Titulo>Datos</Titulo>
          </ContenedorTitulo>

          <Inputt
            message="Nombre del dispositivo"
            value={nombre}
            id="filled-required"
          />
          <Inputt message="MAC ID" value={report} id="filled-required" />

          {devices.length <= 0 ? null :
            <ContenedorWidget>
              <Widget>
                {devices[0].puerta ? (
                  <>
                    <ParaImagen src={ImagenCerrada} />
                    <p>Puerta Cerrada</p>
                  </>

                ) : (
                  <>
                    <ParaImagen src={ImagenAbierta} />
                    <p>Puerta Abierta</p>
                  </>
                )}
              </Widget>

              <Widget>
                {devices[0].energia ? (

                  <>
                    <ParaImagen src={Apagado} />
                    <p>Sin energia</p>
                  </>
                ) : (
                  <>
                    <ParaImagen src={Prendido} />
                    <p>Con energia</p>
                  </>
                )}
              </Widget>

              {columnaHumo ? <Widget>
                {devices[0].humo ? (


                  <>
                    <ParaImagen src={HumoEncendido} />
                    <p>Con Humo</p>
                  </>
                ) : (
                  <>
                    <ParaImagen src={HumoApagado} />
                    <p>Sin Humo</p>
                  </>
                )}
              </Widget> : null}

              <Widget>
                <RadioHum seriesData={devices[0].hum}></RadioHum>
              </Widget>
              <Widget>
                <CuadroTemp info={devices[0].temp} />
              </Widget>

            </ContenedorWidget>
          }

          <h1>Humedad</h1>
          <div id="chart">
            <ReactApexChart options={chartData.options} series={chartData.series} type="area" height={350} />
          </div>

          <h1>Temperatura</h1>
          <div id="chart">
            <ReactApexChart options={temperatura.options} series={temperatura.series} type="area" height={350} />
          </div>

          <ContenedorTabla>
            <TablaDispo>
              <Tr>
                <Th>Fecha</Th>
                <Th>Hora</Th>
                <Th>Temperatura °C</Th>
                <Th>Humedad %</Th>
                <Th>Puerta</Th>
                <Th>Energia</Th>
                {columnaHumo ? <Th>Humo</Th> : null}
              </Tr>

              {devices.map((data, i) => (
                <TrC>
                  <td>
                    <ContenedorFecha>
                      <Fecha> {data.fecha.slice(0, 10)}</Fecha>
                    </ContenedorFecha>
                  </td>
                  <td>
                    <ContenedorFecha>
                      <Fecha> {data.fecha.slice(11, 16)}</Fecha>
                    </ContenedorFecha>
                  </td>
                  <td>
                    <ContenedorDis>
                      <DescripcionDispo>{data.temp}</DescripcionDispo>
                    </ContenedorDis>
                  </td>
                  <td>
                    <Organizacion>{data.hum}</Organizacion>
                  </td>
                  <td>
                    <Organizacion>
                      {data.puerta ? (
                        <p>Puerta cerrada</p>
                      ) : (
                        <p>Puerta abierta</p>
                      )}
                    </Organizacion>
                  </td>
                  <td>
                    <Organizacion>
                      {data.energia ? <p>Sin energia</p> : <p>Con energía</p>}
                    </Organizacion>
                  </td>
                  {columnaHumo ? (
                    <td>
                      <Organizacion>
                        {data.humo ? <p>Con Humo</p> : <p>Sin Humo</p>}
                      </Organizacion>
                    </td>
                  ) : null}
                </TrC>
              ))}
            </TablaDispo>
            <Selectt
              message="Rol"
              value={Cmostrar}
              setFunction={setCmostrar}
              data={Roles}
              id="outlined-disabled"
            />
          </ContenedorTabla>

          {/* <ContenedorButton>
            {Dview == "one" ? (
              <Buttonn
                url={`/admin/wt32?organizacion=${obteOrganizacion}&tipo=${tipoDispositivo}`}
                message="Regresar"
                style={BotonDevice}
              >
                Regresar
              </Buttonn>
            ) : (
              <Buttonn
                url={`/admin/viewAllwt32?organizacion=${obteOrganizacion}&tipo=${tipoDispositivo}`}
                message="Regresar"
                style={BotonDevice}
              >
                Regresar
              </Buttonn>
            )}

            {postRol == "usuario" || postRol == "visualizador" ? null : <><BotonDevice
              onClick={() => {
                openModal();
              }}
            >
              Editar
            </BotonDevice>
              <BotonDevice
                onClick={() => {
                  Borrar();
                }}
              >
                Borrar
              </BotonDevice></>}


          </ContenedorButton> */}



          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <ContenedorTitulo>
              <Titulo>Edicion del dispositivo</Titulo>
            </ContenedorTitulo>

            {/* <Inputt
              message="ID del dispositivo"
              value={nombre}
              id="filled-required"
            /> */}
            <input defaultValue={nombre} onChange={(e) => nuevoNombre(e)} />
            <p>*Selecciona la nueva ubicacion del dispositivo</p>
            {/* <button onClick={closeModal}>Cancelar</button> */}
            {latitud ? (
              <ActualizarMapa
                lat={latitud}
                lng={longitud}
                function={setCoordendas}
                coordendas={coordendas}
              />
            ) : null}
            <ContenedorButton>
              <BotonDevice
                onClick={() => {
                  sendData();
                }}
              >

                Guardar
              </BotonDevice>
              <BotonDevice onClick={() => setIsOpen(false)}>Cancelar</BotonDevice>
            </ContenedorButton>
          </Modal>
        </Main>
      </>
    </LoadingOverlay>
        {latitud ? <Veruno lat={latitud} lng={longitud} /> : null}
        <ContenedorButton>
            {Dview == "one" ? (
              <Buttonn
                url={`/admin/wt32?organizacion=${obteOrganizacion}&tipo=${tipoDispositivo}`}
                message="Regresar"
                style={BotonDevice}
              >
                Regresar
              </Buttonn>
            ) : (
              <Buttonn
                url={`/admin/viewAllwt32?organizacion=${obteOrganizacion}&tipo=${tipoDispositivo}`}
                message="Regresar"
                style={BotonDevice}
              >
                Regresar
              </Buttonn>
            )}

            {postRol == "usuario" || postRol == "visualizador" ? null : <><BotonDevice
              onClick={() => {
                openModal();
              }}
            >
              Editar
            </BotonDevice>
              <BotonDevice
                onClick={() => {
                  Borrar();
                }}
              >
                Borrar
              </BotonDevice></>}


          </ContenedorButton>
</>
  )
};

export default ViewOne;
