import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';

import Api from '../../../services/api-service.js'


import './estilosFormularios.css';



const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement');

export default function AgregarUsuariosTemperatura(props) {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [rol, setRol] = React.useState(null);
  const [organizaciones, setOrganizaciones] = React.useState(props.organizaciones);


  let form = {
    usuario: null,
    nombre: null,
    email: null,
    rol: null
  }

  // function 
  

  function openModal() {
    setIsOpen(true);
    let auxOrganizaciones = organizaciones.filter(item=>item.organizacion === 'gpf');
    setRol(auxOrganizaciones[0].rol);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  // function handleChangeOrganizacion(event){

  //   console.log(event)

  // }

  function handleChangeForm(event){
    console.log(event.target.name)

    form[event.target.name] = (event.target.value === 'null')? null: event.target.value ;

    console.log(form);


  }

  function submitForm(){

    form['password'] = 'pass'
    form['confirmacionPassword'] = 'pass'
    
    form['organizacion'] = 'gpf'
    form['rol'] = 'usuario'


    console.log('envio info', form);
    Api.registro(form).then(
      result =>{
        console.log(result)


        if(!result.mensaje){
          alert(result.error)
        } else {
          alert(result.mensaje)
        }

      }, error=>{
        alert(error)
        console.log(error.error)


      }
    )
  }

  return (


    <div>
      <button onClick={openModal}>Agregar Usuario</button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        // contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div>
          <h1 >Nuevo Usuario</h1>
          {/* <button onClick={closeModal}>close</button> */}
          {/* <h2>I am a modal</h2> */}
          <div className=''>
          <div className='formulario'>
             <label>Username: </label>
             <input type="text" id="usuario" name="usuario" onChange={handleChangeForm}/>
            </div>
            <div className='formulario'>
             <label>Nombre: </label>
             <input type="text" id="nombre" name="nombre" onChange={handleChangeForm}/>
            </div>
            <div className='formulario'>
             <label>Email: </label>
             <input type="text" id="email" name="email" onChange={handleChangeForm}/>
            </div>

            <label>default password: pass </label>

            { (rol=='administrador general') && 
            <div class="selectores">
                <label for="grupo">Organización:</label>
                <select id="grupo" name="organizacion" onChange={handleChangeForm}>
                  <option selected value="null">No Organizacion</option>
                  {/* <option value="constecoin">Constecoin</option> */}
                  <option value="gpf">GPF</option>
                </select>
              </div>
            }

            {/* <h3>Alertas</h3>
            <div className='formulario'>
             <label>Valor Min: </label>
             <input type="text" id="vmin" name="vmin"/>
            </div>
            <div className='formulario'>
             <label>Valor Max: </label>
             <input type="text" id="vmax" name="vmax"/>
            </div> */}
            
            <div className='div-botones row'>
              <div className='col-6 button-edicion'>
                <button className="buttonGeneral" onClick={()=>submitForm()}>Crear</button>
              </div>
              <div className='col-6 button-edicion'>
                <button className="buttonGeneral button-secondary" onClick={()=>closeModal()} >Cancelar</button>
              </div>
            </div>
          </div>

        </div>
      </Modal>
    </div>
  );
}