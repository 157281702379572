import General from "./views/Usuario/General.js";
import CuentaUsuario from "./views/Usuario/CuentaUsuario.js";
import Home from "./views/Home.js";
// import AdminSigfox from "./views/Administracion/AdminSigfox.js"
import AdminBeta from "./views/Administracion/AdminBeta.js";
import Formulario from "./views/Administracion/Form/FormularioCacao.js";
import TablaDispositivos from "./views/TablaDispositivos/index";
import AdminGPS from "./views/Administracion/AdminGPS.js";
import DispositivosIoT from "./views/Administracion/DispositivosIoT.js";
import CadenaFrio from "./views/CadenaFrio.js";
import CacaoTempSens from "./views/CacaoTempSens.js";
import IoTCacao from "./views/IoTCacao.js";
import RegistroProductos from "./views/CadenaFrio/RegistroProductos.js";
import Dashboard from "./views/Dashboard/Dashboard.js";
import VerificacionConexiones from "./views/VerificacionConexiones/VerificacionConexiones.js";
import Dashboard2 from "./views/Dashboard/Dashboard2.js";
import Wt32 from "./views/GabineteBLE/index.js"

import StatusGateway from "./views/Gateway/index";

// import RegistroBle from "./views/registroble/RegistroBLE.js"
import Vinculacion from "./views/Vinculacion.js";
import Matches from "./views/Matches.js";
import IncidentesDispositivosBeta from "./views/Administracion/IncidentesDispositivosBeta.js";
import StatusDispositivos from "./views/Administracion/StatusDispositivos.js";

import RegistroBle from "./views/RegistroBLE.js";

import tablasDatosMonitorizacionTemperatura from "./views/monitorizacionTemperatura/tablasDatosMonitorizacionTemperatura.js";
import tablasDatosMonitorizacionTemperatura1 from "./views/Elicrom/Gabinete";
import DashboardMonitorizacion from "./views/monitorizacionTemperatura/DashboardMonitorizacion.js";
import MapaUbicacionSensores from "./views/monitorizacionTemperatura/mapaUbicacionSensores.js";
import tablasDispositivosHumitemp from "./views/monitorizacionTemperatura/tablasDispositivosHumitemp.js";
import tablasGruposHumitemp from "./views/monitorizacionTemperatura/tablasGruposHumitemp.js";
import IncidenciasDispositivos from "./views/monitorizacionTemperatura/IncidenciasDispositivos.js";
import AlertasDispositivosHumitemp from "./views/monitorizacionTemperatura/AlertasDispositivos.js";
import AdministracionTemperatura from "./views/monitorizacionTemperatura/Administracion.js";

import AdministracionCuentas from "./views/Administracion/AdministracionCuentas.js";
import AdministracionEmpresas from "./views/Administracion/AdministracionEmpresas.js";
import Notificacion from "./views/Notificacion/index.js";
import TablaProcesos from "./views/Procesos/index";
import Gabinete from "./views/Gabinete.js";

import Mapas from "./views/Alcantarilla/Create/index";
import ViewAllDevices from "./views/Alcantarilla/View All/index";
import ViewCustomMarker from "./views/Alcantarillas/CustomMarker";
import ListDevices from "./views/Alcantarillas/TableDevices";
import DataDevice from "./views/Alcantarilla/View One/index";
import UpdateDevice from "./components/Alcantarillas/ActualizarMarcador/index";
import Excela from "./views/LoadExcel/index";
import Notificaciones from "./views/Registros/index.js";
import AlcantarillaView from "./views/Alcantarilla/index";
import creacionGabinete from "./views/Data Center/Administracion/Gabitene/creacion";
import agregarGabinete from "./views/Data Center/Administracion/Gabitene/agregar";
import ControlAccesro from "./views/Data Center/Administracion/Gabitene/puertas";
import ExcelHoras from "./views/ExportarEXCEL/index";
import UbicacionS1 from "./views/S1/mapaUbicacionSensores";
import DashboardMonitorizacionS1 from "./views/S1/DashboardMonitorizacion";
import createWt32 from "./views/GabineteBLE/Create/index";
import viewAllwt32 from "./views/GabineteBLE/View All/index";
import viewDevicewt32 from "./views/GabineteBLE/View One/index";
import dataDeviceWt32 from "./views/GabineteBLE/View One/index";
import cuentaUsuario from "./views/Usuario Cuentas/index"
import editUserOne from "./views/Usuario Cuentas/UserOne/index"
import createUser from "./views/Usuario Cuentas/createUser/index"
import indexAlertas from "./views/Alertas/index"
import createAlert from "./views/Alertas/Create/index"
import crearCondicional from "./views/Alertas/Condicionales/index"
import crearMensajeC from "./views/Alertas/Mensanje"
import mediosAlertas from "./views/Alertas/Medios"
import viewOneALert from "./views/Alertas/viewLogs"
import TempeHumedad from "./views/Elicrom/GabineteDos"
import CroquisD from "./views/CopyS1/mapaUbicacionSensores"
import viweDispositivosGPFF from "./views/GPFADmin/dispositivos"
import verGrupos from "./views/GPFADmin/Grupos"
import viewTable from "./views/GPFADmin/tablaTemperaturas"
import getAlertGPF from "./views/GPFADmin/alertas"
import getIncidencias from "./views/GPFADmin/incidencias"

const dashboardRoutes = [
  {
    path: "/home",
    component: Home,
    layout: "/login",
  },
  {
    path: "/general",
    component: General,
    layout: "/admin",
  },
  {
    path: "/cuentaUsuario",
    component: CuentaUsuario,
    layout: "/admin",
  },
  {
    path: "/controlAcceso",
    component: ControlAccesro,
    layout: "/admin",
  },

  {
    path: "/crearGabinete",
    component: agregarGabinete,
    layout: "/admin",
  },
  {
    path: "/AdminBeta",
    component: AdminBeta,
    layout: "/admin",
  },
  {
    path: "/viewHours",
    component: ExcelHoras,
    layout: "/admin",
  },
  {
    path: "/AdminGPS",
    component: AdminGPS,
    layout: "/admin",
  },
  {
    path: "/formulario",
    component: Formulario,
    layout: "/admin",
  },
  {
    path: "/creacionGabinete",
    component: creacionGabinete,
    layout: "/admin",
  },
  {
    path: "/TablaDispositivos",
    component: TablaDispositivos,
    layout: "/admin",
  },
  {
    path: "/viewAllDeviceAlcantarilla",
    component: AlcantarillaView,
    layout: "/admin",
  },
  {
    path: "/MapaAlacantarilla",
    component: Mapas,
    layout: "/admin",
  },
  {
    path: "/TablaProcesos",
    component: TablaProcesos,
    layout: "/admin",
  },
  {
    path: "/Notificacion",
    component: Notificacion,
    layout: "/admin",
  },
  {
    path: "/devicesIoT",
    component: DispositivosIoT,
    layout: "/admin",
  },
  {
    path: "/home",
    component: Home,
    layout: "/admin",
  },
  {
    path: "/CadenaFrio",
    component: CadenaFrio,
    layout: "/admin",
  },
  {
    path: "/CacaoTempSens",
    component: CacaoTempSens,
    layout: "/admin",
  },
  {
    path: "/IoTCacao",
    component: IoTCacao,
    layout: "/admin",
  },
  {
    path: "/Gabinete",
    component: Gabinete,
    layout: "/admin",
  },
  {
    path: "/registroProductos",
    component: RegistroProductos,
    layout: "/admin",
  },
  {
    path: "/Dashboard",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/Dashboard2",
    component: Dashboard2,
    layout: "/admin",
  },
  {
    path: "/RegistroBle",
    component: RegistroBle,
    layout: "/admin",
  },
  {
    path: "/LGT92",
    component: Notificaciones,
    layout: "/admin",
  },
  {
    path: "/Vinculacion",
    component: Vinculacion,
    layout: "/admin",
  },
  {
    path: "/Matches",
    component: Matches,
    layout: "/admin",
  },
  {
    path: "/Incidentes",
    component: IncidentesDispositivosBeta,
    layout: "/admin",
  },
  {
    path: "/VerificacionConexiones",
    component: VerificacionConexiones,
    layout: "/admin",
  },
  {
    path: "/StatusDispositivos",
    component: StatusDispositivos,
    layout: "/admin",
  },
  {
    path: "/monitorizacionTemperatura",
    component: tablasDatosMonitorizacionTemperatura,
    layout: "/admin",
  },
  {
    path: "/calibracion",
    component: tablasDatosMonitorizacionTemperatura1,
    layout: "/admin",
  },
  {
    path: "/dashboardMonitorizacion",
    component: DashboardMonitorizacion,
    layout: "/admin",
  },
  {
    path: "/dashboardMonitorizacionS1",
    component: DashboardMonitorizacionS1,
    layout: "/admin",
  },
  {
    path: "/graficosDinamicosTemperatura",
    component: MapaUbicacionSensores,
    layout: "/admin",
  },
  {
    path: "/graficosDinamicosTemperaturaS1",
    component: UbicacionS1,
    layout: "/admin",
  },

  {
    path: "/tablasDispositivosHumitemp",
    component: tablasDispositivosHumitemp,
    layout: "/admin",
  },
  {
    path: "/tablasGruposHumitemp",
    component: tablasGruposHumitemp,
    layout: "/admin",
  },
  {
    path: "/AlertasHumitemp",
    component: AlertasDispositivosHumitemp,
    layout: "/admin",
  },
  {
    path: "/IncidentesHumitemp",
    component: IncidenciasDispositivos,
    layout: "/admin",
  },
  {
    path: "/AdministracionTemp",
    component: AdministracionTemperatura,
    layout: "/admin",
  },

  {
    path: "/adminCuentas",
    component: AdministracionCuentas,
    layout: "/admin",
  },
  {
    path: "/adminEmpresas",
    component: AdministracionEmpresas,
    layout: "/admin",
  },
  {
    path: "/viewDevices",
    component: ViewAllDevices,
    layout: "/admin",
  },
  {
    path: "/viewDevice",
    component: ViewCustomMarker,
    layout: "/admin",
  },
  {
    path: "/listDevices",
    component: ListDevices,
    layout: "/admin",
  },
  {
    path: "/dataDevice",
    component: DataDevice,
    layout: "/admin",
  },
  {
    path: "/updateDevice",
    component: UpdateDevice,
    layout: "/admin",
  },
  {
    path: "/loadExcel",
    component: Excela,
    layout: "/admin",
  },
  {
    path: "/statusGateway",
    component: StatusGateway,
    layout: "/admin",
  },
  {
    path: "/wt32",
    component: Wt32,
    layout: "/admin",
  },
  {
    path: "/createWt32",
    component: createWt32,
    layout: "/admin",
  },
  {
    path: "/viewAllwt32",
    component: viewAllwt32,
    layout: "/admin",
  },
  {
    path: "/viewDevicewt32",
    component: viewDevicewt32,
    layout: "/admin",
  },
  {
    path: "/userCount",
    component: cuentaUsuario,
    layout: "/admin",
  },
  {
    path: "/editUserOne",
    component: editUserOne,
    layout: "/admin",
  },
  {
    path: "/createUser",
    component: createUser,
    layout: "/admin",
  },
  {
    path: "/alertas",
    component: indexAlertas,
    layout: "/admin",
  },
  {
    path: "/createAlert",
    component: createAlert,
    layout: "/admin",
  },
  {
    path: "/createCondition",
    component: crearCondicional,
    layout: "/admin",
  },
  {
    path: "/messageAlert",
    component: crearMensajeC,
    layout: "/admin",
  },
  {
    path: "/mediaAlert",
    component: mediosAlertas,
    layout: "/admin",
  },
  {
    path: "/viewAlert",
    component: viewOneALert,
    layout: "/admin",
  },
  //aqui comienza el api de prueba
  {
    path: "/temperaturaHumedad",
    component: TempeHumedad,
    layout: "/admin",
  },
  {
    path: "/ubicacionCroquis",
    component: CroquisD,
    layout: "/admin",
  },
  {
    path: "/viewDevicesGPF",
    component: viweDispositivosGPFF,
    layout: "/admin",
  },
  {
    path: "/viewGroups",
    component: verGrupos,
    layout: "/admin",
  },
  {
    path: "/viewTable",
    component: viewTable,
    layout: "/admin",
  },
  {
    path: "/getAlertas",
    component: getAlertGPF,
    layout: "/admin",
  },
  {
    path: "/getIncidencias",
    component: getIncidencias,
    layout: "/admin",
  },
  //Dispositivo wt32_2

];

export default dashboardRoutes;
