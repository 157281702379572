import React,{useState} from "react";
import {
  ThemeProvider,
  createTheme,
} from '@material-ui/core/styles';

// import { makeStyles } from '@material-ui/styles';
import { makeStyles } from '@mui/styles';

import TextField from '@mui/material/TextField';
import { green } from '@material-ui/core/colors';
import "../assets/css/input.css"


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    // margin: theme.spacing(0.5),
  },
}));



const theme = createTheme({
  palette: {
    light: '#757ce8',
    main: '#3f50b5',
    dark: '#002884',
    contrastText: '#fff',
  },
});


export default function CuadroText(props) {
  // console.log(props.value)
  const [error ,setError]=useState(false)
  const [leyenda, setLeyenda]= useState("")
  
  
  
  const validacion =()=>{
    if(props.expresion){
      if(props.expresion.test(props.value)){
        setError(false)
        
        console.log('corecto')}
        else {
          setError(true)
          setLeyenda("Parametro incorrecto ")
          console.log('incorrecto ')
        }

    }

  }

  const classes = useStyles();
    return (

      <>
      
      <ThemeProvider theme={theme}>
      <TextField className="inputborde"


      id={props.name}
      placeholder={props.etiqueta} 
      name={props.name}
      autoComplete="off" 
      onChange={props.onChange}
      value={props.value} 
     
      type={props.tipo}
      onKeyUp={validacion}
      onBlur={validacion}
          className={classes.margin}
          label={props.etiqueta}
          variant="outlined"
          error={error}
          helperText={leyenda}
          
      />
      </ThemeProvider>
   </>
               
     
    );
  }

