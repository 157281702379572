import React from "react";
// react-bootstrap components
import { Card, Table, Container, Row, Col } from "react-bootstrap";
//import { render } from "react-dom";

import Api from "../../services/api-service.js";
import Auth from "../../services/auth-service.js";

// import ModalEdicion from './formularios/edicion';
import EdicionGruposHumitemp from "./formularios/EdicionGruposHumitemp";
import AgregarGruposHumitemp from "./formularios/AgregarGruposHumitemp";

class tablasGruposHumitemp extends React.Component {
  constructor(props) {
    super(props);

    let x1 = this.props.location.search;
    console.log("this.props.location.search", this.props.location.search);
    x1 = x1.replace("?organizacion=", "");

    this.state = {
      open: false,
      selectedTipo: null,
      selectedOrganizacion: x1,
      devicesTable: [],
      tablaFiltrada: [],
      showBetatemp: false,
      showSigfox: false,
      selectedItem: null,
      showEdit: false,
      isModalOpen: false,
      children: null,
      ModalSetup: null,
      rol: Auth.getRol(),
    };
    this.buttonClick("sigfox");
    // this.setState({showBetatemp: false, showSigfox: true})

    this.actualizarTablaGrupos();
  }

  actualizarTablaGrupos() {
    Api.obtenerGruposHumitemp({
      organizacion: this.state.selectedOrganizacion,
      estado: "all",
    }).then(
      (result) => {
        console.log(result);
        this.setState({ devicesTable: result });
        this.setState({ tablaFiltrada: result });
      },
      (error) => {
        console.log("error en la adquisicion de datos");
      }
    );
  }

  actualizacionTabla = () => {
    console.log("actualizacion ok");
    this.actualizarTablaGrupos();
    // this.actualizarTablaDispositivos();
  };

  handleModalOpen = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };
  dismissable = () => {
    console.log("salir");
    this.setState({
      isModalOpen: false,
    });
  };

  handleChangeTipo = (event) => {
    this.setState({ selectedTipo: event.target.value });
    console.log(event.target.value);
  };
  handleChangeOrganizacion = (event) => {
    this.setState({ selectedOrganizacion: event.target.value });
    console.log(event.target.value);
    this.filtroTablaDevices(this.state.devicesTable, event.target.value);
  };

  handleShow = (item) => {
    this.setState({ selectedItem: item });

    if (item) {
      this.setState({ showEdit: true });
    } else {
      this.setState({ showEdit: false });
    }
  };

  buttonClick(tipo) {
    if (tipo === "sigfox") {
      this.setState({ showBetatemp: false, showSigfox: true });

      console.log(Auth.getJWT());
    }
    if (tipo === "lora") {
      console.log(Auth.getJWT());
    }
    if (tipo === "adquirir") {
      console.log(Auth.getJWT());
      this.setState({ showBetatemp: true, showSigfox: false });
    }
    if (tipo === "cerrar") {
      Auth.saveJWT(null);
      this.props.history.push("/login/ingreso");
    } else {
      // }
      // Api.adquirirDevices().then(
      //   result=>{
      //     console.log(result);
      //     this.setState({devicesTable: result})
      //     // this.state.devicesTable = result;
      //     this.filtroTablaDevices(result, this.state.selectedOrganizacion);
      //   }, error=>{
      //     console.log('error en la adquisicion de datos');
      //   }
      // )
    }
  }

  showData = () => {
    console.log(this.state.devicesTable);
  };
  filtroTablaDevices(tabla, organizacion) {
    const result = tabla.filter((item) => {
      console.log(item);
      let auxOrganizacion = false,
        auxTipo = true;
      if (organizacion === "all") {
        auxOrganizacion = true;
      }
      if (organizacion === null) {
        auxOrganizacion = true;
      } else {
        if (item.organizacion === organizacion) {
          auxOrganizacion = true;
        }
      }
      // if(this.state.selectedTipo === null){
      //   auxTipo = true;
      // }
      // else {
      //   if(item.tipo === this.state.selectedTipo){
      //     auxTipo = true;
      //   }
      // }

      if (auxOrganizacion && auxTipo) {
        console.log(true);
      }

      return auxOrganizacion && auxTipo;
    });

    this.setState({ tablaFiltrada: result });
  }

  render() {
    const { isModalOpen } = this.state;

    if (isModalOpen) {
    }
    return (
      <>
        <Container fluid>
          <Row>
            <section className="w3-center w3-panel">
              {/* <Ventana className="w3-container w3-modal-content w3-teal"/>  */}
            </section>
            {this.state.rol == "administrador general" && (
              <Col md="2">
                <AgregarGruposHumitemp
                  eventFinishedOK={this.actualizacionTabla}
                  organizacion={this.state.selectedOrganizacion}
                ></AgregarGruposHumitemp>
              </Col>
            )}

            {/* <Col md="2">
              <div class="selectores">
                <label for="tipo">Tipo Dispositivo:</label>
                <select id="tipo" onChange={this.handleChangeTipo}>
                  <option value="" disabled selected hidden>seleccionar</option>
                  <option value="wifi">Wifi</option>
                  <option value="lora">Lora</option>
                  <option value="sigfox">Sigfox</option>
                  <option value="celular">Celular</option>
                </select>
              </div> 
            </Col> */}

            {/* <Col md="2">
              <div>
              <ReactToExcel
              className="btn btn-primary buttonGeneral"
              table="tabla-obtenida"
              filename="iotTablaConste"
              sheet="sheet 1"
              buttonText="Exportar como xls"
              />
              </div> 
            </Col>

            <Col md="2">
              <div>
                <CSVLink data={this.state.devicesTable} filename={"iotTablaConste.csv"}>
                  <Button className="buttonGeneral"> Exportar como csv</Button>
                </CSVLink>
              </div> 
            </Col> */}

            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">Devices</Card.Title>
                  <p className="card-category">
                    Tabla de dispositivos conectados
                  </p>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table
                    className="table-hover table-striped"
                    id="tabla-obtenida"
                  >
                    <thead>
                      <tr>
                        <th className="border-0">GRUPO</th>
                        {/* <th className="border-0">ID</th> */}
                        {/* <th className="border-0">Device ID</th> */}
                        <th className="border-0">Organización</th>
                        {/* <th className="border-0">NOMBRE</th> */}
                        {/* <th className="border-0">SIGFOX</th>
                        <th className="border-0">MAC</th> */}
                        {this.state.rol == "administrador general" && (
                          <th className="border-0">Edición</th>
                        )}

                        {/* <th className="border-0">Tiempo Último Dato</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.tablaFiltrada.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.grupo}</td>
                            {/* <td>{item._id}</td> */}
                            {/* <td>{item.deviceID}</td> */}
                            <td>{item.organizacion}</td>
                            {/* <td>{item.nombre}</td> */}
                            {/* <td>{item.idSigfox}</td>
                            <td>{item.idWifi}</td> */}
                            {this.state.rol == "administrador general" && (
                              <td>
                                <EdicionGruposHumitemp
                                  item={item}
                                  eventFinishedOK={this.actualizacionTabla}
                                ></EdicionGruposHumitemp>
                                {/* <button onClick={this.handleModalOpen}>Editar</button>   */}
                                {/* <ModalEdicion></ModalEdicion> */}
                                {/* <BotonModal className ="buttonGeneral" etiqueta ="Editar" onClick={()=>this.handleShow(item)} ></BotonModal>  */}
                              </td>
                            )}

                            {/* <td>{item.tiempoUltimoDato}</td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* <ModalSetup
             visible={isModalOpen}
             dismiss={this.dissmissable}
             children={children} 
          /> */}

        {/* {this.state.isModalOpen && <ModalEdicion />} */}

        {/* <ModalEdicion 
          visible={isModalOpen}
          dismiss={this.dissmissable}
          children={children} 
      /> */}

        {/* <BootstrapModa 
            setShow ={this.state.showEdit}
            handleClose={()=>this.handleShow(null)}
            item={this.state.selectedItem}
            // id={item._id}
            // nombre={this.state.selectedItem.nombre}
            // organization={item.organization}
            // descripcion={item.descripcion}
            // codigoDeBarras={item.codigoDeBarras}
        
            
          > 
          test 
          <h1>holaaa</h1>
          </BootstrapModa> */}
      </>
    );
  }
}

export default tablasGruposHumitemp;
