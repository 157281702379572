import React, { useEffect, useState } from "react";
import Mapa from "../../components/Mapa/CustomMarker";
import Api from "../../services/api-service";
import { useLocation } from "react-router-dom";


const Mostrar = (props) => {
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(1);
  const query = new URLSearchParams(useLocation().search);
  const term = query.get("id");

  useEffect(() => {
    Api.getDeviceById(term).then(
      (result) =>{
        console.log(result)
        setLat(result.coordenadas.lat);
        setLng(result.coordenadas.lng);
      }
    )
  }, []);

  return (
    //datos id tipo
    // button edit
    
    <>      
      <Mapa lat={lat} lng={lng} />
    </>
    //componente tabla
  );
};

export default Mostrar;
