import React, { useState, useEffect } from 'react'
import API from "../../services/api-service"
import {
    Container,
    Row,
    Col,
    Card,
    Table,
    Button
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import ReactToExcel from "react-html-table-to-excel";
import { CSVLink } from "react-csv"

const Alertas = () => {


    const query = new URLSearchParams(useLocation().search);
    const idAlerta = "64356e5890aefd977d573419"
    const obteOrganizacion = query.get("organizacion");
    const [estado, setEstado] = useState("all")
    const [tipo, setTipo] = useState("alerta")

    const [data, setData] = useState([])

    const selectDispositivos = () => {

    }
    const selectRevisados = () => {

    }

    useEffect(() => {
        API.getAlertOne(idAlerta, obteOrganizacion, estado, tipo).then(
            (response) => {
                if (response.status == "Ok") {
                    console.log("first")
                    console.log("first")
                    console.log("first")
                    console.log(response.result)
                    console.log("first")
                    console.log("first")
                    console.log("first")
                    console.log("first")
                    setData(response.result)
                } else {

                }
            },
            (error) => {
                console.log("Obtencion erronea");
            }
        );
    }, [estado]);


    return (
        <>
            <Container fluid>
                <Row>
                    <Col md="2">
                        <div class="selectores">
                            <label for="dispositivos">Dispositivos:</label>
                            <select id="dispositivos" onChange={selectDispositivos}>
                                <option selected value="all">
                                    Todos
                                </option>
                                {/* {data.length > 0 ? data.map((item, index) => {
                                    return (
                                        <option value={item.deviceID}>
                                            {item?.nombre ? item?.nombre : item?.deviceID}
                                        </option>
                                    )
                                }) : null} */}
                            </select>
                        </div>
                    </Col>

                    <Col md="2">
                        <div class="selectores">
                            <label for="tipo">Revisados:</label>
                            <select id="tipo" onChange={selectRevisados}>
                                <option selected value="all">
                                    TODO
                                </option>
                                <option value="true">Revisados</option>
                                <option value="false">No revisados</option>
                            </select>
                        </div>
                    </Col>

                    <Col md="2">
                        <div>
                            <ReactToExcel
                                className="btn btn-primary  buttonGeneral"
                                table="tabla-obtenida"
                                filename="iotTablaConste"
                                sheet="sheet 1"
                                buttonText="Exportar como xls"
                            />
                        </div>
                    </Col>

                    <Col md="2">
                        <div>
                            <CSVLink
                                data={data}
                                filename={"iotTablaConste.csv"}
                            >
                                <Button className="buttonGeneral"> Exportar como csv</Button>
                            </CSVLink>
                        </div>
                    </Col>

                    <Col md="12">
                        <Card className="strpied-tabled-with-hover">
                            <Card.Header>
                                <Card.Title as="h4">Devices</Card.Title>
                                <p className="card-category">Alertas de dispositivos</p>
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                <Table
                                    className="table-hover table-striped"
                                    id="tabla-obtenida"
                                >
                                    <thead>
                                        <tr>
                                            <th className="border-0">Device ID</th>
                                            <th className="border-0">Nombre</th>
                                            <th className="border-0">Organización</th>
                                            <th className="border-0">Tipo Alerta</th>
                                            <th className="border-0">Fecha Alerta</th>
                                            <th className="border-0">Hora Alerta</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {data.length > 0 ? (data.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item?.deviceID}</td>
                                                    {item?.deviceID == "AC233FA921BC" ? (<td>bodegaGyq2</td>) : (<td>bodegaGyq1</td>)}
                                                    <td>{item?.organizacion}</td>
                                                    <td>{item?.tipo}</td>
                                                    <td>
                                                        {item?.fecha
                                                            .toString()
                                                            .slice(0, item?.fecha.toString().indexOf("T"))}
                                                    </td>
                                                    <td>
                                                        {item?.fecha
                                                            .toString()
                                                            .slice(
                                                                item?.fecha.toString().indexOf("T") + 1,
                                                                item?.fecha.length - 5
                                                            )}
                                                    </td>
                                                </tr>
                                            );
                                        })) : null}


                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Alertas