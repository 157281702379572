import React from "react";
import imagen1 from "../../../assets/img/aproca/cacao.png";
import imagen2 from "../../../assets/img/aproca/cacao2.png";
import {
  Main,
  ContenedorTitulo,
  ContenedorEncabezado,
  Imagen,
  BotonesDerecha,
  BotonesIzquierda,
  ContendorBotones,
  Tabla,
  Td,
  Th,
  ParaSelect,
  LabelSelect,
  Tr,
  Divfiltro,
  CoFiltroIn,
  SelectFiltro,
  Trc,
  BotonModal,
  TituloModal,
  Mapaa,
  CoFiltroInD,
  DivfiltroD,
  SelectFiltroD,
  ContenedorImagenes,
} from "./ElementsAproca";

const Aproca = () => {
  return (
    <>
      <Main>
        <ContenedorEncabezado>
          <ContenedorTitulo>
            MONITOREO PROCESO DE FERMENTACIÓN CACAO{" "}
          </ContenedorTitulo>
        </ContenedorEncabezado>
        <ContenedorImagenes>
          <Imagen src={imagen1} alt="MDN" />
          <Imagen src={imagen2} alt="MDN" />
        </ContenedorImagenes>
      </Main>
    </>
  );
};

export default Aproca;
