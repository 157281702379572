import React from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { Main, ForMap, Texto1, Texto2, Mapas } from "./ElementsMarcador";

const YOUR_GOOGLE_API_KEY_GOES_HERE = "AIzaSyBYNDUEf0g8OQPJfiEW7ybizYPiMN_7XOU";

const Actualizar = (props) => {
  return (
    <>
      <Main>
        <Mapas>
          <ForMap>
            <Map
              google={window.google}
              initialCenter={{
                lat: props.lat,
                lng: props.lng,
              }}
              center={{
                lat: props.lat,
                lng: props.lng,
              }}
              zoom={22}
            >
              <Marker
                position={{
                  lat: props.lat,
                  lng: props.lng,
                }}
                draggable={true}
                name={"Current location"}
              />
            </Map>
          </ForMap>
        </Mapas>
      </Main>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: YOUR_GOOGLE_API_KEY_GOES_HERE,
})(Actualizar);
