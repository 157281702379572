import React, { useState, useEffect } from "react";
import Button from "../../../components/Button/index";
import Map from "../../../components/Map/index";
import { useLocation } from "react-router-dom";
import {
  ContenedorTitulo,
  Main,
  Titulo,
  BotonDatos,
  ParaBoton,
  ParaMapa,
  BotonAzul,
} from "../AlcantarillaElements";

const styleMapa = {
  width: "70%",
  height: "60%",
};

const Index = () => {
  const [devices, setDevices] = useState([]);

  const location = useLocation();
  const query = new URLSearchParams(useLocation().search);
  const obteOrganizacion = query.get("organizacion");
  const tipo = query.get("tipo");

  useEffect(async () => {
    console.log("ORGANIZAAAAAAAAAAAAAAAAa");
    console.log(obteOrganizacion);
    let url = `https://aiot.constecoin.com/api/wt32/dashboard/getAllDisp?organizacion=${obteOrganizacion}&tipo=${tipo}`
    // let url = "https://aiot.constecoin.com/api/alcantarillas/getAllDisp" + {}
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setDevices(data);
      });
  }, [location.search]);

  return (
    <>
      <Main>
        <ContenedorTitulo>
          <Titulo>Ubicacion de los dispositivos</Titulo>
        </ContenedorTitulo>
        <ParaBoton>
          {/* <Button
            url={`/admin/wt32?organizacion=${obteOrganizacion}`}
            message="Regresar"
            style={BotonAzul}
          /> */}
        </ParaBoton>
        {devices.length ? (
          <ParaMapa>
            <Map devices={devices} styleMapa={styleMapa} />
          </ParaMapa>
        ) : (
          <p>No existe resultados</p>
        )}
      </Main>
    </>
  );
};

export default Index;
