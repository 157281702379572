import React from 'react';
import Ventana from './BLE_form'
import 'w3-css'
import './estiloform.css'
import ModalBLe from './BLE_modal2.js'

import "../../../src/assets/css/busqueda.css"
import "../../../src/assets/css/texto.css"
import Api from '../../services/api-service.js'

import {Card,Table,Col,Button } from "react-bootstrap";

class Consumo2BLE extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      links:'https://iot.constecoin.com/api/cadenaFrio/administracion-ble/administracion/getDevices',
      datos: [],
      search: '',
      show: false,
      item: [],
    };
  }

  handleClose= () =>{
    this.state.show = false;
    // this.setState({
    //   show: false
    // })
  }

  handleShow = (i) =>{
    this.state.show = true;
    this.state.item = i;
    this.setState({
      show: true,
      item: i
    })
  }

  peticionGet =(link)=>{
    Api.peticionGet(link).then(
      result =>{
        // console.log("resultado",result)
        this.state.datos= result;
        // console.log("this.state.datos", this.state.datos)
        // this.setState({
        //   datos: result
        // })
      }
    )
  }

  parentCModal = (e) =>{
    this.state.show = e.showModal;
    this.setState({
      show: e.showModal
    })
  }
  
render() {
  this.peticionGet(this.state.links)
  // console.log(this.state.datos)
  return (
    <>
      <div className="w3-left">
      </div>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4" className="texto-titulo titulo-card">DISPOSITIVOS BLE </Card.Title>
              <p className="subtitulo-card">
              Tabla de registro de dispositivos BLE 
              </p>
              <input className=" busqueda  texto-busqueda"  type="text" placeholder="busqueda por mac " onChange={(event) => {
              this.state.search(event.target.value)
              }}>
              </input>
              <section> <Ventana className="w3-container w3-modal-content w3-teal registro"/> </section>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <Table className="table-hover table-striped">
                <thead>
                  <tr className="cuadro-titulo">
                    {/* <th className="border-0">ID</th> */}
                    <th className="border-0 texto-titulo w-25">Mac</th>
                    <th className="border-0 texto-titulo w-25">Fecha de Registro</th>
                    <th className="border-0 texto-titulo w-10">tipo</th>
                    <th className="border-0 texto-titulo w-10">Zona</th>
                    <th className="border-0 texto-titulo w-15">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.datos.filter((value)=>{
                      if (this.state.search==""){
                        return value
                      }
                      else if (value.mac.toLowerCase().includes(this.state.search.toLowerCase())){
                        return value
                      }
                    }).map(( item, index ) => {
                        return (
                          <tr key={index}>
                            {/* <td>{item._id}</td> */}
                            <td className="text-tabla">{item.mac}</td>
                            <td className="text-tabla">{item.fechaRegistro}</td>
                            <td className="text-tabla">{item.tipo} </td>
                            <td className="text-tabla">{item.svgID}</td>
                            <td>
                              <Button  className="buttonGeneral buttonGeneral"  onClick={this.handleShow(item)}>
                                Editar
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <ModalBLe 
          setShow ={this.state.show}
          item={this.state.item}
          parentcallback = {this.parentCModal}
          links = {this.state.links}
          errarse = {this.state.errarse}
        />
    </>
  );
}
}

export default Consumo2BLE;