import React, { Component } from "react";

import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';
import ReactTooltip from "react-tooltip";


import { Redirect } from "react-router-dom";


function showNotification(titulo, mensaje, tipo ){

  store.addNotification({
    title: titulo,
    message: mensaje,
    type: tipo,
    insert: "top",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: false
    }
  });

}


const style = {
  width: '100%',
  height: '100%'
}

const styleFigura ={
  stroke:"#000000",
  "strokeWidth":0.72,
  "strokeLinecap":"round",
  "strokeLinejoin":"round",
  "strokeMiterlimit":10,
}


// function adquisicionConectedDevices (tipo){

//   return new Promise((resolve, reject) => {
//     fetch("http://iot-api.constecoin.com:8080/api/beacons/beacon/test/getActiveDevices?numeroDatos=5&tipo="+tipo)
//       .then(res => res.json())
//       .then(
//         (result) => {

//           resolve(result);
//         },
//         (error) => {
//           console.log(error);
//           reject(error);
//         }
//       )
//   })
// }



export class SvgUbicacionComponent extends Component {
  variableStyle = {backgroundImage: 'url("../../assets/img/distribucion-01.png")'};

  
  intervalID = 0;
  constructor (props) {
    super(props);


    this.state={
      styleGateway1:{
        gateway: {...styleFigura, fill: "#00ff00", opacity: 1,   cursor: "pointer" },
        area1: {...styleFigura, fill: "#2EDEFF", opacity: 0},
        area2: {...styleFigura, fill: "#FFA017", opacity: 0} ,
        area3: {...styleFigura, fill: "#F3FF5C", opacity: 0},
        area4: {...styleFigura, fill: "#FAB9FF", opacity: 0},
      },
      styleGateway2:{
        gateway: {...styleFigura, fill: "#00ff00", opacity: 1, cursor: "pointer"},
        area1: {...styleFigura, fill: "#2EDEFF", opacity: 0},
        area2: {...styleFigura, fill: "#FFA017", opacity: 0} ,
        area3: {...styleFigura, fill: "#F3FF5C", opacity: 0},
        area4: {...styleFigura, fill: "#FAB9FF", opacity: 0},      
      },
      styleGateway3:{
        gateway: {...styleFigura, fill: "#00ff00", opacity: 1, cursor: "pointer"},
        area1: {...styleFigura, fill: "#2EDEFF", opacity: 0},
        area2: {...styleFigura, fill: "#FFA017", opacity: 0} ,
        area3: {...styleFigura, fill: "#F3FF5C", opacity: 0},
        area4: {...styleFigura, fill: "#FAB9FF", opacity: 0},
      },
      devicesTable:this.props.devicesTable,

      tooltipOpen: false,
      tooltipMessage : '' 

    }

    console.log(this.props)
    this.toggle = this.toggle.bind(this);
 
  }
  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  componentWillMount(){
    // this.setState({devicesTable: this.props.devicesTable});
    console.log('componentWillMount', this.state, this.props.devicesTable);

  }
    
  mostrarNotificacion(){
    showNotification('Visualización', 'datos de dispositivo', 'success');
    console.log('textos');

  }

  // onMouseEnter={() => setIsShown(true)}
  //       onMouseLeave={() => setIsShown(false)}>

  tooltipMessage(item, state){

    console.log(item, state)
    this.setState({tooltipMessage: ((item.nombre)? item.nombre : item.deviceID) })
    // this.setState({tooltipMessage: item.name })
  }
  
  
  // intervalID = setInterval(() => {
  //     this.actualizacionDevices();
  //     console.log('actualización');
  //   }, 5000);

  redirectTo = (url, item) => {
    console.log('redireccionamiento')
    // history.push('/admin/home')
    // return <Redirect to={'/admin/home'} />
    // console.log(this.props.history);
    // browserHistory.push('/admin/home');
    // const navigate = useNavigate()
    // navigate("/404")
    this.setState({ redirect: url , queryParams: "?device="+item.deviceID+'&organizacion='+item.organizacion});
    console.log({ redirect: url , queryParams: "?device="+item.deviceID})
    console.log(item)
  }
    

      render() {
        if (this.state.redirect) {
          return <Redirect
          to={{
            pathname: this.state.redirect,
            search: this.state.queryParams
          }}
        />
        }
        // console.log(this.props.style, this.props.className);

        return (
            <div className={this.props.className} style={this.props.style}>
      <button data-tip data-for="registerTip"  onMouseOver={()=> this.tooltipMessage({nombre: 'Nombre sensor'}, false)}>
        Sensores
      </button>

              <ReactTooltip id="registerTip" place="top" effect="solid">
                {this.state.tooltipMessage}
              </ReactTooltip>
            <svg
              id="prefix__Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x={0}
              y={0}
              viewBox="0 0 623 273"
              xmlSpace="preserve"
              // {...props}
            >
              <style>
                {".st0{fill:none;stroke:#000000;strokeWidth:0.72;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"}
	            {/* // ".st1{fill:#1CC4C4;stroke:#000000;stroke-width:0.72;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"+
              // ".st2{fill:#FAB9FF;stroke:#000000;stroke-width:0.72;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"+
              // ".st3{fill:#F3FF5C;stroke:#000000;stroke-width:0.72;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"+
              // ".st4{fill:#FFA017;stroke:#000000;stroke-width:0.72;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"+
              // ".st5{fill:#2EDEFF;stroke:#000000;stroke-width:0.72;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"+
              // ".st6{fill:#0092FF;stroke:#000000;stroke-width:0.72;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"} */}
              </style>
              {/* { 
                this.props.devicesTable !== undefined &&
                <div>text</div>
                // <Tooltip placement="right" isOpen={this.state.tooltipOpen}  toggle={this.toggle}>
                //   Hello world!
                // </Tooltip>

              } */}
               {this.props.devicesTable.map(( item, index ) => {
                    return (
                      // id="TooltipExample"
                      <g>
                        {/* <ReactTooltip id={"registerTip-"+index} place="top" effect="solid">
                          Tooltip for the register button
                        </ReactTooltip> */}
                        {/* <div></div> */}
                        {/* <Tooltip placement="right" isOpen={this.state.tooltipOpen} target={'Tooltip-' + index} 
                          toggle={this.toggle}>
                          Tooltip Content!
                        </Tooltip> */}
                        {/* <Tooltip placement="right" isOpen={this.state.tooltipOpen}  toggle={this.toggle}>
                          Hello world!
                        </Tooltip> */}
                        <rect 
                          y={item.posicionSVG?.posx} x={item.posicionSVG?.posy} transform="matrix(4.507956e-11 -1 1 4.507956e-11 323.1601 369.2399)" 
                              onClick={()=>{this.redirectTo('/admin/dashboardMonitorizacion', item)}}
                              // id={'Tooltip-' + this.index}
                              data-tip data-for={"registerTip"}
                              onMouseOver={()=> this.tooltipMessage(item, false)}
                          // <rect x={item.posicionSVG?.posx} y={item.posicionSVG?.posx} transform="matrix(4.481577e-11 -1 1 4.481577e-11 112.9202 353.8799)" 
                            // onClick={()=>{this.mostrarNotificacion()}}  
                            style={this.state.styleGateway1.gateway} width="8" height="8"/>
                      </g>
                      )
                    }
                  )
                }            


              {/* <circle style={this.state.styleGateway2.area4} cx="346.2" cy="23" r="136.5"/>
              <circle style={this.state.styleGateway2.area3} cx="346.2" cy="23" r="91"/>
              <circle style={this.state.styleGateway2.area2} cx="346.2" cy="23" r="72.8"/>
              <circle style={this.state.styleGateway2.area1} cx="346.1" cy="23" r="54.6"/>
              
              <circle style={this.state.styleGateway3.area1} cx="413.1" cy="157.1" r="54.6"/>
              <circle style={this.state.styleGateway3.area4} cx="413.1" cy="157.1" r="136.5"/>
              <circle style={this.state.styleGateway3.area3} cx="413.1" cy="157.1" r="91"/>
              <circle style={this.state.styleGateway3.area2} cx="413.1" cy="157.1" r="72.8"/>
              <rect x="339.3" y="16.1" transform="matrix(4.507956e-11 -1 1 4.507956e-11 323.1601 369.2399)" 
                onClick={()=>{this.mostrarNotificacion()}}  style={this.state.styleGateway2.gateway} width="13.8" height="13.8"/>
              <rect x="406.2" y="150.2" transform="matrix(4.494722e-11 -1 1 4.494722e-11 256.0201 570.1798)" 
                 onClick={()=>{this.mostrarNotificacion()}}  style={this.state.styleGateway3.gateway} width="13.8" height="13.8"/> */}
            </svg>
          </div>  
        );
      }
}


export default SvgUbicacionComponent;

