import React from "react";
import { useHistory } from "react-router-dom";

const HomeButton = (props) => {
  let history = useHistory();
  return (
    <button
      onClick={() => {
        var url = "/admin/listDevices";
        history.push(url);
      }}
    >
      {props.message}
    </button>
  );
};

export default HomeButton;
