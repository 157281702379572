import styled from "styled-components";

export const BotonDatos = styled.button`
  display: inline-block;
  ${"" /* float: right; */}
  margin: 10px;
  background: #0d6efd2e;
  color: black;
  border-radius: 5px;
  height: 42px;
  width: 110px;
  text-align: center;
  border: none;
`;