import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';

import './EstilosModals.css';

import Api from '../../../services/api-service.js'



const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#elemento');

export default function ModalDowlinks(props) {
  // let device = props.item.deviceID;
  // let nombre = props.item.nombre;
  // let grupo = props.item.grupo;
  // let rol = props.rol;

  let selectores = [
    {value:"cfg temp 1", text: 'Config Temperatura 1'},
    {value:"cfg temp 2", text: 'Config Temperatura 2'},
    {value:"cfg succion", text: 'Config Succión'},
    {value:"cfg bateria", text: 'Config Batería'},
    {value:"cfg vac", text: 'Config Vac'},
    {value:"cfg iac", text: 'Config Iac'},
    {value:"cfg gen", text: 'Config General'},
    {value:"comando", text: 'Comando directo'},
  ]

  // let optionSelectorType = [];

  let opcionSensors = [
    {value:"04", text: 'Habilitación / Deshabilitación'},
    {value:"05", text: 'Ajuste de lectura'},
    {value:"06", text: 'Límite inferior de Alarma'},
    {value:"07", text: 'Límite superior de Alarma'},
    {value:"08", text: 'Retardo de tiempo de activación de Alarma'},
  ]

  let optionGeneralConfig = [
    {value:"01", text: 'Tiempo de publicación'},
    {value:"04", text: 'Reinicio de dispositivo'},
    {value:"05", text: 'Restaurar configuraciones de fábrica'},
    {value:"08", text: 'Numero de mensajes entre Downlinks'},
  ]


  console.log(props)

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [typeSelector, setTypeSelector] = React.useState([]);
  const [typeSelected, setTypeSelected] = React.useState(null);
  const [valor, setValor] = React.useState(props.item?.valor);
  const [selector, setSelector] = React.useState(null);
  const [dispositivos, setDispositivos] = React.useState(props.dispositivos);
  // const [rol, setRol] = React.useState(props.rol);

  // setDispositivos(props.dispositivos)


  function openModal() {
    setIsOpen(true);
    setDispositivos(props.dispositivos)
    console.log(props.dispositivos)
    setSelector(null)
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    console.log('cerrando')
    setIsOpen(false);
  }



  //// ACTUALIZACION DE NOMBRES Y PARAMETROS
  // function changeComando(event){
  //   console.log(event.target.value)
  //   setComando(event.target.value);
  // }

  function changeValor(event){
    console.log(event.target.value)
    setValor(event.target.value);


  }

  function changeSVGx(event){
    console.log(event.target.value)
    // setPosSVGx(event.target.value);
  }

  function changeSVGy(event){
    console.log(event.target.value)
    // setPosSVGy(event.target.value);
  }




  function handleChangeSelector(event){
    console.log(event.target.value);
    setSelector(event.target.value);

    switch (event.target.value){
      case 'cfg temp 1':
        setTypeSelector(opcionSensors);
      break;
      case 'cfg temp 2':
        setTypeSelector(opcionSensors);
      break;
      case 'cfg succion':
        setTypeSelector(opcionSensors);
      break;
      case 'cfg bateria':
        setTypeSelector(opcionSensors);
      break;
      case 'cfg vac':
        setTypeSelector(opcionSensors);
      break;
      case 'cfg iac':
        setTypeSelector(opcionSensors);
      break;
      case 'cfg gen':
        setTypeSelector(optionGeneralConfig);
      break;
      case 'comando':
        setTypeSelector([]);
      break;

  
      default: 


    }
    


  }

  function handleChangeTypeSelector(event){
    console.log(event.target.value);
    setTypeSelected(event.target.value);

    // switch (event.target.value){
    //   case '':
    //     // opcionSensors
    //   break;
    //   default: 


    // }
    


  }

  

  const esHexadecimal = numeroHexadecimal => /^[0-9A-F]+$/ig.test(numeroHexadecimal);

  function submitForm(){
    console.log(selector);

    let valorCommand = '';

    switch(selector){
      case 'cfg temp 1':
        if (valor < -400 || valor>800){
          return alert ('fuera de rango');
        }      
        valorCommand = getOmicronCodes(1, 0, typeSelected, valor)
      break;
      case 'cfg temp 2':
        if (valor < -400 || valor>800){
          return alert ('fuera de rango');
        }      
        valorCommand = getOmicronCodes(2, 0, typeSelected, valor)
      break;
      case 'cfg vac':

        if(typeSelected == '06' || typeSelected == '07' ){
          if (valor < 0 || valor > 250){
            return alert ('valores erroneos, solo permitidos 0 (0v) a 250 (250v)');
          }
        } else if (typeSelected == '05'){
          if (valor < -200 || valor > 200){
            return alert ('valores erroneos, solo permitidos -200 (-20v) a 200 (20v)');
          }      
        }
        valorCommand = getOmicronCodes(5, 0, typeSelected, valor)
      break;
      case 'cfg iac':
        if(typeSelected === "05"){
          if (valor !== 60 && valor !== 36){
            return alert ('valores erroneos, solo permitidos 50A => 36 & 30A => 60');
          }      
        } else {
          if (valor < -200 && valor >  200){
            return alert ('valores erroneos, solo permitidos -200 (-20A) a 200 (20A)');
          }      
        }
        
        valorCommand = getOmicronCodes(6, 0, typeSelected, valor)
      break;
      case 'cfg gen':
        console.log('typeSelected',typeSelected, valor);
        if (typeSelected === '01'){
          if (valor < 5 || valor > 30){ 
            return alert ('fuera de rango');
          }  
        } else if (typeSelected === '08'){
          if (valor < 1 || valor > 20){
            return alert ('fuera de rango');
          }  
        } else if (typeSelected === '04'){
          if (valor != 1 && valor != 0){
            return alert ('valor no permitido, (0,1)');
          }  
        } else if (typeSelected === '05'){
          if (valor !== 1 ){
            return alert ('solo se permite 0 o 1');
          }  
        } else {
          return alert ('comando no aceptado');
        }
        valorCommand = getOmicronCodes(20, 0, typeSelected, valor)
      break;

      case 'comando':
        console.log('typeSelected',typeSelected, valor);
        valorCommand = valor
      break;

      case 'cfg succion':
      case 'cfg bateria':
      


      default:  
        return alert ('por implementar');
    }

    
  


    let actualizacion ={ 
      // deviceID: device,
      parametros: (valorCommand == '')? null: valorCommand ,
      // grupo:  (grupo=='null')? null: grupo ,
      // posicionSVG: (posSVGx === '' || posSVGy === '')? null: {posx: Number(posSVGx), posy: Number(posSVGy)}
    }


    if(actualizacion.parametros.length == 14){

      if(esHexadecimal(actualizacion.parametros)){
        console.log(actualizacion)

        dispositivos.map(item=>{
          console.log(item, actualizacion)

          let bodySend = {
            data: actualizacion.parametros,
            deviceID: item
          }


          Api.setDownlinkBetatemp(bodySend).then(
          result=>{
            console.log(result);
            // this.setState({devicesTable: result})
            // this.setState({tablaFiltrada: result})
            closeModal();
            // props.eventFinishedOK();
          }, error=>{
            console.log('error en la actualización', error);
            alert('error en la actualización ')
          }
        )

        })




      } else{
        console.log('error de valor')
        alert('error de valor')
      }
      

    }else{
      console.log('error de longitud de caracteres')
      alert('error de longitud de caracteres')

    }

  
  }
  
  function getOmicronCodes(grupo, subgrupo, parametro, valor){
    // no envia el ultimo parametro, ese es controlado por el sistema de downlinks

    var payload = FixedNumber(grupo, 2) + FixedNumber(subgrupo, 2)+FixedNumber(parametro, 2)+FixedNumber(valor, 8);
    

    console.log(payload);

    return payload;

  }

  function FixedNumber(number, targetLength) {
    let output = null;
    if (Number(number)>=0){
      output = (Number(number)).toString(16).toUpperCase() + '';
    }else {
      output = ((Number(number))>>>0).toString(16).toUpperCase() + '';
    }


      while (output.length < targetLength) {
          output = '0' + output;
      }
      return output;
  }


  return (
    <div>
      <button onClick={openModal}>Downlinks</button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
                >
        <div>
          <h1 >Edición Dispositivo</h1>
          {/* <button onClick={closeModal}>close</button> */}
          {/* <h3>{device}</h3> */}
          <div className=''>
              <div class="selectores">
                  <label for="grupo">Grupo:</label>
                  <select id="grupo" onChange={handleChangeSelector}>
                    <option selected hidden value="null">No Seleccionado</option>
                      {selectores.map(item=>{

                        return <option value={item.value}>{item.text}</option>
                      })
                    }
                  </select>
                </div>
                {(selector=='cfg temp 1' || selector=='cfg temp 2'|| selector=='cfg succion' || selector=='cfg bateria'
                || selector=='cfg vac' || selector=='cfg iac' || selector=='cfg gen') &&<div>

                  <h3>Tipo de configuración</h3>
                  <div className='formulario'>
                  <select id="TipoConfig" onChange={handleChangeTypeSelector}>
                    <option selected hidden value="null">No Seleccionado</option>
                      {typeSelector.map(item=>{

                        return <option value={item.value}>{item.text}</option>
                      })
                    }
                  </select>
                 </div>

                  <h3>Comando</h3>
                  <div className='formulario'>
                  <label>Valor: </label>
                  <input type="text" id="valor" name="valor"  value={valor} onChange={changeValor}/>
                  </div>
                  </div>
                  }

                {(selector=='comando') &&<div>
                  <h3>Comandos</h3>
                  <div className='formulario'>
                  <label>Comando: </label>
                  <input type="text" id="comando" name="comando"  value={valor} onChange={changeValor}/>
                  </div>
                  </div>
                  }



                  <div className='div-botones row'>
                    <div className='col-6 button-edicion'>
                      <button className="buttonGeneral" onClick={()=>submitForm()}>Enviar</button>
                    </div>
                    <div className='col-6 button-edicion'>
                      <button className="buttonGeneral button-secondary" onClick={()=>closeModal()}>Cancelar</button>
                    </div>
                  </div>
            </div>

        </div>
      </Modal>
    </div>
  );
}