import React from 'react';
import './AdminSigfox.css';
import '../../components/Administracion GPS/w3.css';
import 'bootstrap';
import BootstrapModal from '../../components/Administracion GPS/BootstrapModal.js';
import { Component } from 'react';
import ShowList from '../../components/Administracion GPS/ShowList.js';
import ShowList2 from '../../components/Administracion GPS/ShowList2.js';
import CercaSelector from '../../components/Administracion GPS/CercaSelector.js';
import Api from '../../services/api-service.js'
import { Button,Modal } from 'react-bootstrap'
import '../../components/Administracion GPS/ListaDispositivosGeneral.css'
import MapaOrManual from '../../components/Administracion GPS/MapaOrManual';

class AdminGPS extends Component {

constructor(props) {
    super(props)
    this.cbFnBootstrapModal = this.cbFnBootstrapModal.bind(this);
    this.state = {
        //VARIABLES DE DL
        lista: [], //Guarda la lista de dispositivos obtenidos en el selector y checkbox
        showComponentDL: false, //Controla el mostrar o no componente de DL (Tabla y botones de config)
        showSubCompDL: false, //Mostrar resultado de 
        showResultDL: false,
        show: false, //para cerrar el modal de configuración
        showNG: false, //Modal de borrar configuración de geocerca
        resultPromises: [{}],
        ///////// Datos de configuración de cerca
        tipoCerca: "", //Tipo de cerca seleccionada
        datosPoligal: [{}],
        datosCircular: [{radio: "", lat: "", lon:""}], // Cerca circular
        seleccBorrarGeo: false, //Da la opcion de borrar en el rest 
        //Variables de UL
        showComponentUL: false, //Controla el mostrar o no componente de UL (Obtenicón de un dispositivo)
        //Rest de obtención de un dispositivo
        showSubCompUL: false, //controla el subcomponente del comp de UL 1D
        tipoRest: "", //tipo de rest obtenido de Uplink
        mensaje1D_UL: [{}], //Resultado de información de unDispositivo
        //Rest de estado de servicio
        mensajeUL: "", // Mostrar estado de servicio desde Uplink
        estadoMensajeUL: false, //Estado para controlar mostrar mensaje de estado de servicio
        //Rest de creación de dispositivo
        nuevoID: "", //ID del dispositio a crear
        mensajeResult:"", //Muestra si se creó o no
    };
  }

/////////Funciones//////////

cbFnBootstrapModal = (estadoBootstrapModal) => {
  console.log("Estado Recibido en Adminsigfox desde BootstrapModal", estadoBootstrapModal)
    this.state.lista = estadoBootstrapModal.lista;
    this.state.showComponentDL = estadoBootstrapModal.mostrarComponenteDL;
    this.state.showComponentUL = estadoBootstrapModal.mostrarComponenteUL;
    this.setState({
      lista: estadoBootstrapModal.lista,
      showComponentDL: estadoBootstrapModal.mostrarComponenteDL,
      showComponentUL: estadoBootstrapModal.mostrarComponenteUL
    })
    //////Seteando el tipo de rest obtenido desde Uplink
    this.state.tipoRest = estadoBootstrapModal.tipoRest;
    this.setState({
      tipoRest: estadoBootstrapModal.tipoRest
    })
    //////Para estado de servicio (rest de UplinkGPSTracjer.js)
    this.state.mensajeUL = estadoBootstrapModal.respuestaServiceStatus;
    this.state.estadoMensajeUL = estadoBootstrapModal.estadoMostrarServiceStatusUL;
    this.setState({
      mensajeUL: estadoBootstrapModal.respuestaServiceStatus,
      estadoMensajeUL: estadoBootstrapModal.estadoMostrarServiceStatusUL
    })
    console.log("Estado guardado en AdminSigfox \n", this.state)
}

  ///Elimina datos seleccionados en configuración
    cancelar = () => {
      console.log("Estado al entrar a cancelar", this.state)
      this.state.lista = [];
      this.state.tipoRest = "";
      this.state.showComponentDL = false;
      this.state.resultPromises = [{}];
      this.state.showResultDL = false;
      this.setState({
      tipoRest: "",
      mensaje1D_UL: [{}],
      lista: [],
      tipo: "",
      showComponentDL: false,
      resultPromises: [{}],
      showResultDL: false,
    })
    console.log("Estado al salir de cancelar", this.state)
    }

    ////Elimina los datos del rest de un dispositivo en UPLINK
    cancelar1D = () => {
      console.log("Estado al entrar a cancelar1D", this.state)
      this.state.showComponentUL = false;
      this.state.showSubCompUL = false;
      this.state.tipoRest = "";
      this.setState({
      tipoRest: "",
      mensaje1D_UL: [{}],
      showComponentUL: false,
      showSubCompUL: false
    })
    console.log("Estado al salir de cancelar1D", this.state)
    }

    ///Hace aparecer modal de configuración
    configurar = () => {
      console.log("Estado al entrar a configurar", this.state)
      this.state.showComponentUL = false;
      this.setState({
      showComponentUL: false,
      show: true,
      })
      console.log("Estado al salir de configurar", this.state)
  };

  ////Funcion al abrir el modal de borrar configuracion de dispositivo
  borrarGeoNG = () => {
    console.log("Estado al entrar a borrarGeoNG", this.state)
      this.state.showComponentUL = false;
      this.state.seleccBorrarGeo = true;
      this.setState({
      showComponentUL: false,
      showNG: true,
      seleccBorrarGeo: true,
      })
      console.log("Estado al salir de borrarGeoNG", this.state)
  }

    // Configuración de UPLINK para buscar info de un dispositivo

    configurar1D = (a) => {
      console.log("Estado al entrar a configurar 1D", this.state)
      Api.adquirirUnGPSTracker(a.currentTarget.value)
      .then(
      result=>{
        // result = (JSON.stringify(result));
        console.log(result)
        this.state.mensaje1D_UL = [result];
        this.setState({
          mensaje1D_UL: [result]
        })
      }, error=>{
        console.log(error);
        this.state.mensaje1D_UL = [{}];
        this.setState({
          mensaje1D_UL: [{}]
        })
      }
    );
    console.log("Estado al salir de configurar 1D", this.state)
    }

    /////Muestra información del resultado de 1D
    showResult1D = () => {
      console.log("Estado al entrar a showResult1D", this.state)
      this.state.showSubCompUL = true;
      this.state.showComponentDL = false;
      this.setState({
        showSubCompUL: true,
        showComponentDL: false
      })
      console.log("Estado al salir de showResult1D", this.state)
    }

    ////if de resultado de 1D
    mostrarSi = () => {
      if(this.state.mensaje1D_UL[0]._id !== undefined){
        return (
          <>
          <pre>
            {JSON.stringify(this.state.mensaje1D_UL[0], null, 2) }
          </pre>
          </>
        );
      } else {
        return (
          <>
           <div>No existe registro del dispositivo Ingresado</div>
          </>
        );
      }
    }

    ////Guardar información del modal de config
    guardarModal = async() => {
      console.log("Estado al entrar a guardarModal", this.state)
      this.state.show = false;
      this.state.showSubCompDL = false;
      this.state.showResultDL = true;
      this.setState({
      showSubCompDL: false,
      show: false,
      showResultDL: true,
      });
      if (this.state.tipoCerca === "Circular"){
        console.log(this.state.tipoCerca, this.state.datosCircular)
      }
      ////////////Hacer el consumo para configuracion de dispositivos
      Promise.all(
        await this.state.lista.map(async elemento => {
          return await Api.updDispoGPS(this.state, elemento)
        })
      ).then(results => {
        console.log("Resultado OK promise all config", results)
        this.state.resultPromises = results;
          this.setState({
            resultPromises: results,
          })
      }, error => {
        console.log("Resultado error promise all config", error)
        this.state.resultPromises = error;
          this.setState({
            resultPromises: error,
          })
      })
      console.log("Estado al salir de guardarModal", this.state)
    }

    ////Cierra modal
    cancelmodal = () => {
      console.log("Estado al entrar a cancelmodal", this.state)
      /////Colocar vacío al cancelar
      this.state.datosCircular = [{radio: "", lat: "", lon:""}];
      this.state.resultPromises = [{}];
      this.state.showResultDL = false;
      ////Colocar en off
      this.state.show = false;
      this.state.showSubCompDL = false;
      ///////Setear cambios
      this.setState({
        show: false,
        showSubCompDL: false,
        datosCircular: [{radio: "", lat: "", lon:""}],
        resultPromises: [{}],
        showResultDL: false,
      });
      console.log("Estado al salir de cancelmodal", this.state)
    }

    ///////Obtiene valor de cerca
    selecCerca = (cerca) => {
      console.log("Estado al entrar a selecCerca", this.state)
      this.setState({
      tipoCerca: cerca,
      showSubCompDL: true
      })
      console.log("Estado al salir de selecCerca", this.state)
    }

    /////Guarda ID del dispositivo a ser creado
    obtenerNuevoID = (a) =>{
      console.log("Estado al entrar a obtenerNuevoID", this.state)
      this.state.nuevoID = a.currentTarget.value;
      this.setState({
        nuevoID: a.currentTarget.value
      })
      console.log("Estado al salir de obtenerNuevoID", this.state)
    }

    //////función para FORM de creación de dispositivo en UL
    crearNuevoDisp = () => {
      console.log("Estado al entrar a crearNuevoDisp", this.state)
      if (this.state.nuevoID !== ""){
          Api.crearNuevoDispo(this.state.nuevoID)
          .then(
          result=>{
            console.log(result)
            this.state.showSubCompUL = true;
            this.state.mensajeResult = "Dispositivo creado con éxito"
            this.setState({
              showSubCompUL: true,
              mensajeResult: "Dispositivo creado con éxito"
            })
          }, error=>{
            console.log(error);
            this.state.showSubCompUL = true;
            this.state.mensajeResult = "Error al crear dispositivo, inténtelo de nuevo"
            this.setState({
              showSubCompUL: true,
              mensajeResult: "Error al crear dispositivo, inténtelo de nuevo"
            })
          }
          );
      } else {
        console.log("No ingresó ID")
      }
      console.log("Estado al salir de crearNuevoDisp", this.state)
    }

    //////función para cancelar la creación de dispositivo en UL
    cancelNuevoDisp = () => {
      console.log("Estado al entrar a cancelNuevoDisp", this.state)
      this.state.nuevoID = "";
      this.state.showComponentUL = false;
      this.state.mensajeResult = "";
      this.state.showSubCompUL = false;
      this.setState({
        nuevoID: "",
        showComponentUL: false,
        mensajeResult: "",
        showSubCompUL: false,
      })
      console.log("Estado al salir de cancelNuevoDisp", this.state)
    }

    /////////Hace el rest para borrar
    borrarCerca = async() =>{
      console.log("Estado al entrar a borrarCerca", this.state)
      this.state.showNG = false;
      this.state.showResultDL = true;
      this.setState({
      showNG: false,
      showResultDL: true,
      });
      if (this.state.seleccBorrarGeo === true) {
        Promise.all(
          await this.state.lista.map(async elemento => {
            return await Api.updDispoGPS(this.state, elemento)
          })
        ).then(results => {
          console.log("Resultado OK promise all borrar geo", results)
          this.state.resultPromises = results;
          this.setState({
            resultPromises: results,
          })
        }, error => {
          console.log("Resultado error promise all borrar geo", error)
          this.state.resultPromises = error;
          this.setState({
            resultPromises: error,
          })
        })
      }
      console.log("Estado al salir de borrarCerca", this.state)
    }

    ////Funcion para cancelar el borrar la configuracion de geocercas
    cancelarBorrar = () => {
      console.log("Estado al entrar a cancelarBorrar", this.state)
      ////Colocar en off
      this.state.showNG = false;
      this.state.seleccBorrarGeo = false;
      this.state.resultPromises = [{}];
      this.state.showResultDL = false;
      ///////Setear cambios
      this.setState({
        showNG: false,
        seleccBorrarGeo: false,
        resultPromises: [{}],
        showResultDL: false,
       });
      console.log("Estado al salir de cancelarBorrar", this.state)
    }

    ///////Al cerrar el modl de borrar geocerca
    cancelmodalGeo = () =>{
      console.log("Estado al entrar a cancelmodalGeo", this.state)
      ////Colocar en off
      this.state.showNG = false;
      this.state.resultPromises = [{}];
      this.state.showResultDL = false;
      ///////Setear cambios
      this.setState({
        showNG: false,
        resultPromises: [{}],
        showResultDL: false,
      });
      console.log("Estado al salir de cancelmodalGeo", this.state)
    }

    ////////////Esconder resultados de configurar/borrar cerca
    hideResult = () => {
      console.log("Estado al entrar a hideResult", this.state)
        this.state.showResultDL = false;
        this.state.resultPromises = [{}];
        this.setState({
          showResultDL: false,
          resultPromises: [{}],
        })
      console.log("Estado al salir de hideResult", this.state)
    }


    //////Callback MapaOrManual
    cbkMapaOrManual = (estado) =>{
      console.log("Estado al entrar en cbkMapaOrManual", this.state)
      if (this.state.tipoCerca === "Circular") {
      this.state.datosCircular = estado.datosCircular;
      this.setState({
        datosCircular: estado.datosCircular,
      })
    }
    if (this.state.tipoCerca === "Poligonal") {
      this.state.datosPoligal = estado.datosPoligal;
      this.setState({
        datosPoligal: estado.datosPoligal,
      })
    }
    console.log("Estado al salir de cbkMapaOrManual", this.state)
    }

  render (){
      return (
      <>
        <div>
          <section className="w3-center w3-panel">
          <BootstrapModal className="w3-teal w3-amber" parentCallback = {this.cbFnBootstrapModal.bind(this)}></BootstrapModal>
          </section>
        </div>

        {/* {/////////////////////////////////// BOTÓN DOWNLINK (INICIO) ///////////////////////////////////////////////} */}
          <>
        {/* {LISTA DE DISPOSITIVOS OBTENIDOS} */}
        <div>
          {this.state.showComponentDL && this.state.lista.length>0 && <ShowList list={this.state.lista}/>}
        </div>

        {/* {CONFIGURADORES DE CERCAS} */}
        {this.state.showComponentDL && this.state.lista.length>0 && <div>
        <Button className="btn btn-primary buttonGeneral"  onClick={this.configurar.bind(this)}>
          Configurar cerca
        </Button>
        <Button className="btn btn-primary buttonGeneral"  onClick={this.borrarGeoNG.bind(this)}>
          Borrar geocerca
        </Button>
        <Button className="btn btn-primary buttonGeneral button-secondary"  onClick={this.cancelar.bind(this)}>
          Cancelar
        </Button>
        </div>}

        {/* {Muestra resultado de promise all Actualizado/Borrado INICIO} */}
        <>
        {this.state.showResultDL === true && this.state.resultPromises !== "{[]}" && <div div class="alert alert-info" role="alert">
        <h6>Resultado</h6>
        <div>
        {JSON.stringify(this.state.resultPromises)}
        </div>
        <br></br>
        <Button className="boton" variant="secondary" onClick={this.hideResult.bind(this)}>Listo</Button>
          </div>}
        </>
        {/* {Muestra resultado de promise all Actualizado/Borrado FIN} */}

        {/* {MODAL DE CONFIGURACIÓN DE CERCAS INICIO} */}
        <>
        <Modal
          show={this.state.show}
          onHide={this.cancelmodal}
          // backdrop="static"
          keyboard={false}
          className="w3-modal-content{background-color: transparent} w3-animate-zoom" style={{maxwidth:600}}>
          <Modal.Header closeButton className="w3-rigth">
          <h5>Configuración de Geocerca</h5>
          </Modal.Header>
          <Modal.Body>
            {/* Selector de Configuración BetaTemp */}
            <CercaSelector parentCallback={this.selecCerca.bind(this)}/>
            {/* {Botones de ingreso manual o mapa} */}
            {this.state.showSubCompDL && <MapaOrManual tipoCerca={this.state.tipoCerca} parentCallback={this.cbkMapaOrManual.bind(this)}/>}
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-primary buttonGeneral"  onClick={this.guardarModal.bind(this)}>
              Configurar
            </Button>
            <Button className="btn btn-primary buttonGeneral button-secondary"  onClick={this.cancelmodal.bind(this)}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
        </>
         {/* {MODAL DE CONFIGURACIÓN DE CERCAS FIN} */}

          {/* {MODAL DE "NO GEOCERCA"}  INICIO */}
            <>
          <Modal
          show={this.state.showNG}
          onHide={this.cancelmodalGeo}
          // backdrop="static"
          keyboard={false}
          className="w3-modal-content{background-color: transparent} w3-animate-zoom" style={{maxwidth:600}}>
          <Modal.Header closeButton className="w3-rigth">
          
          </Modal.Header>
          <Modal.Body>
            <h6>¿Desea borrar la configuración de la geocerca guardada en los siguientes dispositivos?</h6>
            <br></br>
            <ShowList2 list={this.state.lista}/>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-primary buttonGeneral " onClick={this.borrarCerca.bind(this)}>
              Aceptar
            </Button>
            <Button className="btn btn-primary buttonGeneral button-secondary"  onClick={this.cancelarBorrar.bind(this)}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
            </>
          {/* {MODAL DE "NO GEOCERCA"}  INICIO */}

         </>
        {/* {///////////////////////////////////// BOTÓN DOWNLINK (FIN) /////////////////////////////////////////////} */}

        {/* {/////////////////////////////////// BOTÓN UPLINK (INICIO) ///////////////////////////////////////////////} */}
      <>
        
        {/* {Rest de un solo dispositivo (INICIO)} */}
        <>
        {this.state.tipoRest === "unDispo" && this.state.showComponentUL === true && <div>
          <div>
            <div>
              <h5>Revisar información de un dispositivo GPS Tracker</h5>
              <br></br>
              <h6>Ingrese el ID del dispositivo que desea obtener información</h6>
            </div>
          <form>
            <label for="ID"> ID:</label>
            <input type="text" id="ID" name="ID1" onChange={this.configurar1D.bind(this)}/>
            <br/>
            <br/>
          </form>
          </div>
        <Button className="btn btn-primary buttonGeneral" onClick={this.showResult1D.bind(this)}>
          Ok
        </Button>
        <Button className="btn btn-primary buttonGeneral button-secondary"  onClick={this.cancelar1D.bind(this)}>
          Cancelar
        </Button>  
        </div>}

        {this.state.showSubCompUL === true && this.state.tipoRest === "unDispo" && <div class="alert alert-secondary span2" role="alert">
          <div>
            <h5>Información del dispositivo Ingresado</h5>
          </div>
          <div>
            {this.mostrarSi()}
            </div>
          </div>
          }
          </>
          {/* {Rest de un solo dispositivo (FIN)} */}

         {/* {Rest de creación de dispositivo INICIO}  */}
         <>
          {this.state.tipoRest === "crearDispoUL" && this.state.showComponentUL === true && <div>
            <div>
              <h5>Creación de un nuevo dispositivo GPS Tracker</h5>
            </div>
          <form >
            <label for="nuevoID"> Ingrese el nuevo ID:</label>
            <input type="text" id="nuevoID" name="nuevoID1" onChange={this.obtenerNuevoID.bind(this)}/>
            <br/><br/>
            <Button className="btn btn-primary buttonGeneral" onClick={this.crearNuevoDisp.bind(this)}>
              Registrar
            </Button>
            <Button className="btn btn-primary buttonGeneral button-secondary"  onClick={this.cancelNuevoDisp.bind(this)}>
              Cerrar
            </Button>
          </form>
          </div>}
          {this.state.showSubCompUL && this.state.tipoRest === "crearDispoUL" && <div div class="alert alert-secondary" role="alert">
              {this.state.mensajeResult}
            </div>}
         </>
         {/* {Rest de creación de dispositivo FIN}  */}

         </>
        {/* {/////////////////////////////////// BOTÓN UPLINK (FIN) ///////////////////////////////////////////////} */}

      </>
      
      );
    }
}
export default AdminGPS;