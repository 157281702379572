import styled from 'styled-components'

export const Main = styled.div`
margin: 25px 30px 25px 30px;
border: White 2px solid;
height: 100%;

`

export const Contenedor = styled.div`
text-align: center;
padding: 30px 0;
font-weight: bold;
`

export const ContenedorFormulario = styled.div`
padding: 0 0 30px 15px;
`

export const Formulario = styled.div`

`
export const Lorem = styled.div`
display: inline-block;
width: 85px;
`

export const Input = styled.input`
display: inline-block;
`
export const Select = styled.select`
display: inline-block;
`
export const Infor = styled.div`
display: inline-block;
`