import React from "react";
import logo from "../../../assets/img/elicrom/logo.jpg";
import header from "../../../assets/img/elicrom/header.jpeg";
import {
  Main,
  ContenedorTitulo,
  ContenedorEncabezado,
  Imagen,
  BotonesDerecha,
  BotonesIzquierda,
  ContendorBotones,
  Tabla,
  Td,
  Th,
  ParaSelect,
  LabelSelect,
  Tr,
  Divfiltro,
  CoFiltroIn,
  SelectFiltro,
  Trc,
  BotonModal,
  TituloModal,
  Mapaa,
  CoFiltroInD,
  DivfiltroD,
  SelectFiltroD,
  ContenedorImagenes,
  Imagen1,
} from "./ElementsElicrom";

const Index = () => {
  return (
    <>
      <Main>
        <ContenedorImagenes>
          <Imagen1 src={logo} alt="MDN" />
        </ContenedorImagenes>
        <ContenedorEncabezado>
          <ContenedorTitulo>CALIBRACIÓN DE DISPOSITIVOS</ContenedorTitulo>
        </ContenedorEncabezado>
        <ContenedorImagenes>
          <Imagen src={header} />
        </ContenedorImagenes>
      </Main>
    </>
  );
};

export default Index;
