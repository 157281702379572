import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import React, { useState } from "react";
import API from "../../../services/api-service";
import HomeButton from "./../../../components/Buttons/HomeSewer";
import { useHistory } from "react-router-dom";
const YOUR_GOOGLE_API_KEY_GOES_HERE = "AIzaSyBYNDUEf0g8OQPJfiEW7ybizYPiMN_7XOU";
const styleMapa = {
  width: "60%",
  height: "60%",
};
var lati;
var lngi;
var colocado = false;

function sendData() {
  var id = document.getElementById("id");
  var type = document.getElementById("type");
  var utc = document.getElementById("utc");

  API.saveDevice(id.value, lati, lngi, type.value, utc.value);
  alert("Dispositivo agregado correctamente");
}

const Mapa = () => {
  const [position, setPosition] = useState({ lat: 0, lng: 0 });
  var history = useHistory();
  return (
    <>
      <button
        onClick={() => {
          var id = document.getElementById("id");
          if (!colocado || id.value == "") {
            alert(
              "Ingrese la ubicacion del dispositivo y llene todos los datos"
            );
          } else {
            sendData();
            var url = "/admin/listDevices";
            history.push(url);
          }
        }}
      >
        Guardar
      </button>
      <HomeButton message="Cancelar" />
      <br />
      <br />
      <Map
        google={window.google}
        initialCenter={{
          lat: -0.1632261,
          lng: -78.4832682,
        }}
        style={styleMapa}
        zoom={18}
        onClick={(x, y, LatLng, ev) => {
          lati = LatLng.latLng.lat();
          lngi = LatLng.latLng.lng();
          colocado = true;
          setPosition({ lat: lati, lng: lngi });
        }}
      >
        <Marker
          position={{ lat: position.lat, lng: position.lng }}
          draggable={true}
          name={"Current location"}
        />
      </Map>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: YOUR_GOOGLE_API_KEY_GOES_HERE,
})(Mapa);
