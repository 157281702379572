import React from 'react';
// import '../AdminSigfox.css';
import '../../components/Administracion Beta/w3.css';
import 'bootstrap';
import BootstrapModal from '../../components/Administracion Beta/BootstrapModal.js';
import { Component } from 'react';
import ShowList from '../../components/Administracion Beta/ShowList.js';
import Api from '../../services/api-service.js'
import { Button,Modal } from 'react-bootstrap'
import '../../components/Administracion Beta/ListaDispositivosGeneral.css'

import ModalDowlinks from '../../components/Administracion Beta/Modals/ModalDownlinks'


class AdminBeta extends Component {

constructor(props) {
    super(props)
    this.cbFnBootstrapModal = this.cbFnBootstrapModal.bind(this);
    this.state = {
        tipoDispSelecc: "",//Tipo de dispositivo seleccionado en DL o UL
        //VARIABLES DE DL
        lista: [], //Guarda la lista de dispositivos obtenidos en el selector y checkbox
        showComponentDL: false, //Controla el mostrar o no componente de DL (Tabla y botones de config)
        showSubCompDL: false, //Mostrar resultado de 
        showResultDL: false,
        show: false, //para cerrar el modal de configuración
        showNG: false, //Modal de borrar configuración de geocerca
        resultPromises: [{}],
        ///////// Datos de configuración de cerca
        tipoCerca: "", //Tipo de cerca seleccionada
        datosPoligal: [{}],
        datosCircular: [{radio: "", lat: "", lon:""}], // Cerca circular
        datosCuadrada: [{v1: [{lat: "", lon: ""}],
                         v2: [{lat: "", lon: ""}], 
                         v3: [{lat: "", lon: ""}], 
                         v4:[{lat: "", lon: ""}]}], // Cerca cuadrada
        datosTriangular: [{v1: [{lat: "", lon: ""}], 
                          v2: [{lat: "", lon: ""}], 
                          v3: [{lat: "", lon: ""}]}], // Cerca triangular
        //Variables de UL
        showComponentUL: false, //Controla el mostrar o no componente de UL (Obtenicón de un dispositivo)
        //Rest de obtención de un dispositivo
        showSubCompUL: false, //controla el subcomponente del comp de UL 1D
        tipoRest: "", //tipo de rest obtenido de Uplink
        mensaje1D_UL: [{}], //Resultado de información de unDispositivo
        //Rest de estado de servicio
        mensajeUL: "", // Mostrar estado de servicio desde Uplink
        estadoMensajeUL: false, //Estado para controlar mostrar mensaje de estado de servicio
        //Rest de creación de dispositivo
        nuevoID: "", //ID del dispositio a crear
        mensajeResult:"", //Muestra si se creó o no
    };
  }

/////////Funciones//////////

cbFnBootstrapModal = (estadoBootstrapModal) => {
  console.log("Estado Recibido en Adminsigfox desde BootstrapModal", estadoBootstrapModal)
    this.state.lista = estadoBootstrapModal.lista;
    this.state.showComponentDL = estadoBootstrapModal.mostrarComponenteDL;
    this.state.showComponentUL = estadoBootstrapModal.mostrarComponenteUL;
    this.setState({
      lista: estadoBootstrapModal.lista,
      showComponentDL: estadoBootstrapModal.mostrarComponenteDL,
      showComponentUL: estadoBootstrapModal.mostrarComponenteUL
    })
    /////Downlink: para mostrar el tipo de dispositivo seleccionado
    if (estadoBootstrapModal.tipoDispSelecDL === ""){
      this.state.tipoDispSelecc = estadoBootstrapModal.tipoDispSelecUL
      this.setState({
        tipoDispSelecc: estadoBootstrapModal.tipoDispSelecUL
      })
      console.log("Tipo cambiado", this.state.tipoDispSelecc)
    } else if (estadoBootstrapModal.tipoDispSelecUL === "") {
      this.state.tipoDispSelecc = estadoBootstrapModal.tipoDispSelecDL
      this.setState({
        tipoDispSelecc: estadoBootstrapModal.tipoDispSelecDL
      })
      console.log("Tipo cambiado", this.state.tipoDispSelecc)
    }
    //////Seteando el tipo de rest obtenido desde Uplink
    this.state.tipoRest = estadoBootstrapModal.tipoRest;
    this.setState({
      tipoRest: estadoBootstrapModal.tipoRest
    })
    //////Para estado de servicio (rest de UplinkGPSTracjer.js)
    this.state.mensajeUL = estadoBootstrapModal.respuestaServiceStatus;
    this.state.estadoMensajeUL = estadoBootstrapModal.estadoMostrarServiceStatusUL;
    this.setState({
      mensajeUL: estadoBootstrapModal.respuestaServiceStatus,
      estadoMensajeUL: estadoBootstrapModal.estadoMostrarServiceStatusUL
    })
    console.log("Estado guardado en AdminSigfox \n", this.state)
}

  ///Elimina datos seleccionados en configuración
    cancelar = () => {
      console.log("Estado al entrar a cancelar", this.state)
      this.state.lista = [];
      this.state.tipoRest = "";
      this.state.tipoDispSelecc = "";
      this.state.showComponentDL = false;
      this.state.resultPromises = [{}];
      this.state.showResultDL = false;
      this.setState({
      tipoRest: "",
      mensaje1D_UL: [{}],
      lista: [],
      tipo: "",
      showComponentDL: false,
      resultPromises: [{}],
      showResultDL: false,
    })
    console.log("Estado al salir de cancelar", this.state)
    }

    ////Elimina los datos del rest de un dispositivo en UPLINK
    cancelar1D = () => {
      console.log("Estado al entrar a cancelar1D", this.state)
      this.state.showComponentUL = false;
      this.state.showSubCompUL = false;
      this.state.tipoRest = "";
      this.setState({
      tipoRest: "",
      mensaje1D_UL: [{}],
      showComponentUL: false,
      showSubCompUL: false
    })
    console.log("Estado al salir de cancelar1D", this.state)
    }

    ///Hace aparecer modal de configuración
    configurar = () => {
      console.log("Estado al entrar a configurar", this.state)
      this.state.showComponentUL = false;
      this.setState({
      showComponentUL: false,
      show: true,
      })
      console.log("Estado al salir de configurar", this.state)
  };

    // Configuración de UPLINK para buscar info de un dispositivo

    configurar1D = (a) => {
      console.log("Estado al entrar a configurar 1D", this.state)
      Api.adquirirUnGPSTracker(a.currentTarget.value)
      .then(
      result=>{
        // result = (JSON.stringify(result));
        console.log(result)
        this.state.mensaje1D_UL = [result];
        this.setState({
          mensaje1D_UL: [result]
        })
      }, error=>{
        console.log(error);
        this.state.mensaje1D_UL = [{}];
        this.setState({
          mensaje1D_UL: [{}]
        })
      }
    );
    console.log("Estado al salir de configurar 1D", this.state)
    }

    /////Muestra información del resultado de 1D
    showResult1D = () => {
      console.log("Estado al entrar a showResult1D", this.state)
      this.state.showSubCompUL = true;
      this.state.showComponentDL = false;
      this.setState({
        showSubCompUL: true,
        showComponentDL: false
      })
      console.log("Estado al salir de showResult1D", this.state)
    }

    ////if de resultado de 1D
    mostrarSi = () => {
      if(this.state.mensaje1D_UL[0]._id !== undefined){
        return (
          <>
          <pre>
            {JSON.stringify(this.state.mensaje1D_UL[0], null, 2) }
          </pre>
          </>
        );
      } else {
        return (
          <>
           <div>No existe registro del dispositivo Ingresado</div>
          </>
        );
      }
    }

    ////Guardar información del modal de config
    guardarModal = async() => {
      console.log("Estado al entrar a guardarModal", this.state)
      this.state.show = false;
      this.state.showSubCompDL = false;
      this.state.showResultDL = true;
      this.setState({
      showSubCompDL: false,
      show: false,
      showResultDL: true,
      });
      if (this.state.tipoCerca === "Circular"){
        console.log(this.state.tipoCerca, this.state.datosCircular)
      }else if (this.state.tipoCerca === "Cuadrada") {
        console.log(this.state.tipoCerca, this.state.datosCuadrada)
      } else if (this.state.tipoCerca === "Triangular"){
        console.log(this.state.tipoCerca, this.state.datosTriangular)
      }

      ////////////Hacer el consumo para configuracion de dispositivos
      if (this.state.tipoDispSelecc === "gpsTracker") {
      Promise.all(
        await this.state.lista.map(async elemento => {
          return await Api.updDispoGPS(this.state, elemento)
        })
      ).then(results => {
        console.log("Resultado OK promise all config", results)
        this.state.resultPromises = results;
          this.setState({
            resultPromises: results,
          })
      }, error => {
        console.log("Resultado error promise all config", error)
        this.state.resultPromises = error;
          this.setState({
            resultPromises: error,
          })
      })
      }
      console.log("Estado al salir de guardarModal", this.state)
    }

    ////Cierra modal
    cancelmodal = () => {
      console.log("Estado al entrar a cancelmodal", this.state)
      /////Colocar vacío al cancelar
      this.state.datosCircular = [{radio: "", lat: "", lon:""}];
      this.state.datosCuadrada = [{v1: [{lat: "", lon: ""}], v2: [{lat: "", lon: ""}], v3: [{lat: "", lon: ""}], v4:[{lat: "", lon: ""}]}];
      this.state.datosTriangular = [{v1: [{lat: "", lon: ""}], v2: [{lat: "", lon: ""}], v3: [{lat: "", lon: ""}]}];
      this.state.resultPromises = [{}];
      this.state.showResultDL = false;
      ////Colocar en off
      this.state.show = false;
      this.state.showSubCompDL = false;
      ///////Setear cambios
      this.setState({
        show: false,
        showSubCompDL: false,
        datosCircular: [{radio: "", lat: "", lon:""}],
        datosCuadrada: [{v1: [{lat: "", lon: ""}], v2: [{lat: "", lon: ""}], v3: [{lat: "", lon: ""}], v4:[{lat: "", lon: ""}]}],
        datosTriangular: [{v1: [{lat: "", lon: ""}], v2: [{lat: "", lon: ""}], v3: [{lat: "", lon: ""}]}],
        resultPromises: [{}],
        showResultDL: false,
      });
      console.log("Estado al salir de cancelmodal", this.state)
    }

    ///////Obtiene valor de cerca
    selecCerca = (cerca) => {
      console.log("Estado al entrar a selecCerca", this.state)
      this.setState({
      tipoCerca: cerca,
      showSubCompDL: true
      })
      console.log("Estado al salir de selecCerca", this.state)
    }

    /////Guarda ID del dispositivo a ser creado
    obtenerNuevoID = (a) =>{
      console.log("Estado al entrar a obtenerNuevoID", this.state)
      this.state.nuevoID = a.currentTarget.value;
      this.setState({
        nuevoID: a.currentTarget.value
      })
      console.log("Estado al salir de obtenerNuevoID", this.state)
    }

    //////función para FORM de creación de dispositivo en UL
    crearNuevoDisp = () => {
      console.log("Estado al entrar a crearNuevoDisp", this.state)
      if (this.state.nuevoID !== ""){
          Api.crearNuevoDispo(this.state.nuevoID)
          .then(
          result=>{
            console.log(result)
            this.state.showSubCompUL = true;
            this.state.mensajeResult = "Dispositivo creado con éxito"
            this.setState({
              showSubCompUL: true,
              mensajeResult: "Dispositivo creado con éxito"
            })
          }, error=>{
            console.log(error);
            this.state.showSubCompUL = true;
            this.state.mensajeResult = "Error al crear dispositivo, inténtelo de nuevo"
            this.setState({
              showSubCompUL: true,
              mensajeResult: "Error al crear dispositivo, inténtelo de nuevo"
            })
          }
          );
      } else {
        console.log("No ingresó ID")
      }
      console.log("Estado al salir de crearNuevoDisp", this.state)
    }

    //////función para cancelar la creación de dispositivo en UL
    cancelNuevoDisp = () => {
      console.log("Estado al entrar a cancelNuevoDisp", this.state)
      this.state.nuevoID = "";
      this.state.showComponentUL = false;
      this.state.mensajeResult = "";
      this.state.showSubCompUL = false;
      this.setState({
        nuevoID: "",
        showComponentUL: false,
        mensajeResult: "",
        showSubCompUL: false,
      })
      console.log("Estado al salir de cancelNuevoDisp", this.state)
    }

    /////////Hace el rest para borrar
    borrarCerca = async() =>{
      console.log("Estado al entrar a borrarCerca", this.state)
      this.state.showNG = false;
      this.state.showResultDL = true;
      this.setState({
      showNG: false,
      showResultDL: true,
      });
      if (this.state.tipoDispSelecc === "gpsTracker" && this.state.seleccBorrarGeo === true) {
        Promise.all(
          await this.state.lista.map(async elemento => {
            return await Api.updDispoGPS(this.state, elemento)
          })
        ).then(results => {
          console.log("Resultado OK promise all borrar geo", results)
          this.state.resultPromises = results;
          this.setState({
            resultPromises: results,
          })
        }, error => {
          console.log("Resultado error promise all borrar geo", error)
          this.state.resultPromises = error;
          this.setState({
            resultPromises: error,
          })
        })
      }
      console.log("Estado al salir de borrarCerca", this.state)
    }

    ////Funcion para cancelar el borrar la configuracion de geocercas
    cancelarBorrar = () => {
      console.log("Estado al entrar a cancelarBorrar", this.state)
      ////Colocar en off
      this.state.showNG = false;
      this.state.seleccBorrarGeo = false;
      this.state.resultPromises = [{}];
      this.state.showResultDL = false;
      ///////Setear cambios
      this.setState({
        showNG: false,
        seleccBorrarGeo: false,
        resultPromises: [{}],
        showResultDL: false,
       });
      console.log("Estado al salir de cancelarBorrar", this.state)
    }

    ///////Al cerrar el modl de borrar geocerca
    cancelmodalGeo = () =>{
      console.log("Estado al entrar a cancelmodalGeo", this.state)
      ////Colocar en off
      this.state.showNG = false;
      this.state.resultPromises = [{}];
      this.state.showResultDL = false;
      ///////Setear cambios
      this.setState({
        showNG: false,
        resultPromises: [{}],
        showResultDL: false,
      });
      console.log("Estado al salir de cancelmodalGeo", this.state)
    }

    ////////////Esconder resultados de configurar/borrar cerca
    hideResult = () => {
      console.log("Estado al entrar a hideResult", this.state)
        this.state.showResultDL = false;
        this.state.resultPromises = [{}];
        this.setState({
          showResultDL: false,
          resultPromises: [{}],
        })
      console.log("Estado al salir de hideResult", this.state)
    }

  render (){
      return (
      <>
        <div>
          <section className="w3-center w3-panel">
          <BootstrapModal className="w3-teal w3-amber" parentCallback = {this.cbFnBootstrapModal.bind(this)}></BootstrapModal>
          </section>
        </div>

        {/* {/////////////////////////////////// BOTÓN DOWNLINK (INICIO) ///////////////////////////////////////////////} */}
          <>
        {/* {LISTA DE DISPOSITIVOS OBTENIDOS} */}
        <div>
          lista de dispositivos
          {this.state.showComponentDL && this.state.lista.length>0 && <ShowList list={this.state.lista}/>}
        </div>

        {this.state.showComponentDL && this.state.lista.length>0 && <div>
        {/* <Button className="boton" variant="danger" onClick={this.configurar.bind(this)}>
          Configurar
        </Button> */}
          <ModalDowlinks  dispositivos={this.state.lista}></ModalDowlinks>

        <Button className="boton" variant="secondary" onClick={this.cancelar.bind(this)}>
          Cancelar
        </Button>
        </div>}

        {/* {MODAL DE CONFIGURACIÓN} */}
        <>

        {/* <Modal
          show={this.state.show}
          onHide={this.cancelmodal}
          // backdrop="static"
          keyboard={false}
          className="w3-modal-content{background-color: transparent} w3-animate-zoom" style={{maxwidth:600}}>
          <Modal.Header closeButton className="w3-rigth">
          <h5>Downlinks</h5>
          </Modal.Header>
          <Modal.Body>
            
          </Modal.Body>
          <Modal.Footer>
            <Button className="boton" variant="danger" onClick={this.guardarModal.bind(this)}>
              Enviar
            </Button>
            <Button className="boton" variant="secondary" onClick={this.cancelmodal.bind(this)}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal> */}
        </>
         {/* {MODAL DE CONFIGURACIÓN FIN} */}

         </>
        {/* {///////////////////////////////////// BOTÓN DOWNLINK (FIN) /////////////////////////////////////////////} */}

        {/* {/////////////////////////////////// BOTÓN UPLINK (INICIO) ///////////////////////////////////////////////} */}
      <>
        
        {/* {Rest de un solo dispositivo (INICIO)} */}
        <>
        {this.state.tipoRest === "unDispo" && this.state.showComponentUL === true && <div>
          <div>
            <div>
              <h5>REVISAR INFORMACIÓN DE UN DISPOSITIVO</h5>
              <br></br>
              <h6>Ingrese el ID del dispositivo {this.state.tipoDispSelecc} que desea obtener información</h6>
            </div>
          <form>
            <label for="ID"> ID:</label>
            <input type="text" id="ID" name="ID1" onChange={this.configurar1D.bind(this)}/>
            <br/>
            <br/>
          </form>
          </div>
        <Button className="boton" variant="danger" onClick={this.showResult1D.bind(this)}>
          Ok
        </Button>
        <Button className="boton" variant="secondary" onClick={this.cancelar1D.bind(this)}>
          Cancelar
        </Button>  
        </div>}

        {this.state.showSubCompUL === true && this.state.tipoRest === "unDispo" && <div class="alert alert-secondary span2" role="alert">
          <div>
            <h5>Información del dispositivo Ingresado</h5>
          </div>
          <div>
            {this.mostrarSi()}
            </div>
          </div>
          }
          </>
          {/* {Rest de un solo dispositivo (FIN)} */}

         {/* {Rest de creación de dispositivo INICIO}  */}
         <>
          {this.state.tipoRest === "crearDispoUL" && this.state.showComponentUL === true && <div>
            <div>
              <h5>Creación de un nuevo dispositivo {this.state.tipoDispSelecc}</h5>
            </div>
          <form >
            <label for="nuevoID"> Ingrese el nuevo ID:</label>
            <input type="text" id="nuevoID" name="nuevoID1" onChange={this.obtenerNuevoID.bind(this)}/>
            <br/><br/>
            <Button className="boton" variant="danger" onClick={this.crearNuevoDisp.bind(this)}>
              Registrar
            </Button>
            <Button className="boton" variant="secondary" onClick={this.cancelNuevoDisp.bind(this)}>
              Cerrar
            </Button>
          </form>
          </div>}
          {this.state.showSubCompUL && this.state.tipoRest === "crearDispoUL" && <div div class="alert alert-secondary" role="alert">
              {this.state.mensajeResult}
            </div>}
         </>
         {/* {Rest de creación de dispositivo FIN}  */}

         </>
        {/* {/////////////////////////////////// BOTÓN UPLINK (FIN) ///////////////////////////////////////////////} */}

      </>
      
      );
    }
}
export default AdminBeta;