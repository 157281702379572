import React, { Component } from "react";
import "../../styles.css";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import pinAzul from '../../assets/maps/marker-blue.svg';
import pinRojo from '../../assets/maps/marker-red.svg';
import pinVerde from '../../assets/maps/marker-green.svg';
import pinAmari from '../../assets/maps/marker-yellow.svg';
import { Card, Row, Col } from "react-bootstrap";
import Api from '../../services/api-service.js'
import Auth from '../../services/auth-service.js';

const YOUR_GOOGLE_API_KEY_GOES_HERE = "AIzaSyBYNDUEf0g8OQPJfiEW7ybizYPiMN_7XOU";

const style = {
  width: '100%',
  height: '100%'
}

export class General extends Component {

  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  };

  constructor(props) {
    super(props);

    this.state = {
      seleccion: "",
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      params: [{ organizacion: "", estado: "" }],
      // posicionesSemagro: [{}],
      posicionesAll: [{}],
      posicionesFiltradas: [{}],

      rol: Auth.getRol(),
      organizaciones: Auth.getOrganizaciones(),

    };
  }
  configDispoUbic = (event) => {
    this.state.seleccion = event.currentTarget.value;

    this.adquirirDatos();
    // this.state.params.organizacion = event.currentTarget.value;
    // this.state.params.estado = "all";

    // console.log(this.state.params);

    // this.setState({
    //   seleccion: event.currentTarget.value,
    //   params: [{organizacion: event.currentTarget.value, estado: "all"}],
    // })
    // //console.log("parametros al rest \n", this.state.params)
    // Api.obtenerDispoGeneral(this.state.params).then(
    //   result =>{
    //     console.log('obtenerDispoGeneral', result)
    //     this.separar(result)
    //   }
    // )
  }

  adquirirDatos() {
    this.state.params.organizacion = this.state.seleccion;
    this.state.params.estado = "all";

    console.log(this.state.params);

    //console.log("parametros al rest \n", this.state.params)
    Api.obtenerDispoGeneral(this.state.params).then(
      result => {
        console.log('obtenerDispoGeneral', result)
        this.separar(result)
      }
    )


  }



  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  separar = async (respuesta) => {
    console.log("Respuesta de fetch", respuesta)
    console.log("this.state.seleccion", this.state.seleccion)

    let auxFiltros = await respuesta.map(item => {
      if (this.state.seleccion === 'all') {
        return item;
      }
      if (this.state.seleccion !== item.organizacion) {
        item = null;
      }

      return item;
    }
    ).filter(item => item !== null)

    // auxFiltros = await auxFiltros.filter(item=> item!==null);

    console.log('auxFiltros', auxFiltros)

    this.setState({ posicionesFiltradas: auxFiltros })


  }

  render() {
    return (
      <>
        <div>
          <h1 className="texto-titulo titulo-card ">Ubicación fincas</h1>
        </div>
        <div>
          <label>
            Seleccione el tipo de geocerca:
            <select value={this.state.selected} onChange={this.configDispoUbic}>
              {/* <option value="" selected disabled hidden>Selecciona una opción</option> */}
              <option value="all">Todos</option>
              {this.state.organizaciones.map((item, index) => {
                return (
                  <option value={item.organizacion}>{item.organizacion}</option>
                )
              })}
            </select>
          </label>
        </div>
        <Card >
          <Card.Header>
            <Card.Title as="h4">Mapa de ubicaciones</Card.Title>
          </Card.Header>
          <div className="body-card-ubicaciones-fincas">

            <Map google={this.props.google}
              initialCenter={{
                lat: -0.163202,
                lng: -78.481166
              }}
              style={style}
              zoom={7}
            >

              {this.state.posicionesFiltradas.map((item) => {
                if (item.posicion) {
                  console.log("Items", item)
                  if (item.alertaDesconexion === false)
                    return <Marker
                      key={item._id}
                      // title={item.name}
                      name={item.nombre}
                      onClick={this.onMarkerClick}
                      position={{ lat: item.posicion.coordinates[0], lng: item.posicion.coordinates[1] }}
                      icon={{
                        url: pinAzul,
                        scaledSize: new this.props.google.maps.Size(50, 50)
                      }}
                    />
                  else
                    return <Marker
                      key={item._id}
                      // title={item.name}
                      name={item.nombre ? item.nombre : 'Sin Nombre'}
                      onClick={this.onMarkerClick}
                      position={{ lat: item.posicion.coordinates[0], lng: item.posicion.coordinates[1] }}
                      icon={{
                        url: pinRojo,
                        scaledSize: new this.props.google.maps.Size(50, 50)
                      }}
                    />
                }

              }
              )
              }

              {/* {this.state.seleccion == "semagro" && this.state.posicionesSemagro.map((item) =>  {
                    console.log("Items", item)
                    if(item.alertaDesconexion == false) 
                      return <Marker
                        key={item._id}
                        // title={item.name}
                        name={item.nombre}
                        onClick={this.onMarkerClick}
                        position={{ lat: item.posicion.coordinates[0], lng: item.posicion.coordinates[1] }}
                        icon={{
                        url: pinAzul,
                        scaledSize: new this.props.google.maps.Size(50,50)
                        }}
                      />
                      if(item.estado == true) 
                      return <Marker
                        key={item._id}
                        // title={item.name}
                        name={item.nombre}
                        onClick={this.onMarkerClick}
                        position={{ lat: item.posicion.coordinates[0], lng: item.posicion.coordinates[1] }}
                        icon={{
                        url: pinRojo,
                        scaledSize: new this.props.google.maps.Size(50,50)
                        }}
                      />
                    } */}
              {/* )
                  } */}

              <InfoWindow
                marker={this.state.activeMarker}
                visible={this.state.showingInfoWindow}>
                <div>
                  <>{this.state.selectedPlace.name}</>
                </div>
              </InfoWindow>

            </Map>

          </div>
        </Card>
      </>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: (YOUR_GOOGLE_API_KEY_GOES_HERE)
})(General)

