import React from "react";
import ReactApexChart from "react-apexcharts";

import "./graficos.css";




class LineasArea extends React.Component {

  componentDidUpdate(prevProps) {
    // console.log ('actualizado area');
    // console.log (this.props.seriesData);

    
  }


  constructor (props) {
    super(props);
    // this.actualizarDatos = this.actualizarDatos.bind(this);

    this.state = {
          
      // seriesData: 
      // this.props.seriesData, 
      // [{
      //   name: 'series1',
      //   data: [31, 40, 28, 51, 42, 109, 100]
      // }, { 
      //   name: 'series2',
      //   data: [11, 32, 45, 32, 34, 52, 41]
      // }],
      // options: {
      //   chart: {
      //     height: 350,
      //     type: 'area'
      //   },
      //   dataLabels: {
      //     enabled: false
      //   },
      //   stroke: {
      //     curve: 'smooth'
      //   },
      //   yaxis: {
      //     // tickAmount: 5,
      //     min: 16,
      //     max: 80
      //   },
      //   xaxis: {
      //     type: 'datetime',
      //     min: new Date('30 Jan 2022').getTime(),
      //     tickAmount: 6,
      //   },
      //   // xaxis: {
      //   //   tickAmount: 10,

      //   // //   type: 'datetime',
      //   // //   categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
      //   // },
      //   tooltip: {
      //     x: {
      //       format: 'dd/MM/yy HH:mm'
      //     },
      //   },
      // },
    
    
    };

    // this.setState({ seriesData: this.props.seriesData})


    // this.state.series = 
  }





render() {
  return (
    <>
       <div id="chart">
        <ReactApexChart options={this.props.options} series={this.props.seriesData} type="area" height={350} />
        </div>
    </>
  );
}
}

export default LineasArea ;





