import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import Api from "./../../services/api-service";
import { useHistory } from "react-router-dom";
import {
  BarraBusqueda,
  BotonCrear,
  BotonFiltro,
  Categorias,
  ContenedorCategorias,
  ContenedorTabla,
  ContenedorTitulo,
  Main,
  NavbarTabla,
  Th,
  Titulo,
  Tr,
  TablaDispo,
  TrC,
  CoFiltroIn,
  Divfiltro,
  SelectFiltro,
  IconoLupa,
  ContenedorDis,
  Nombredispositivo,
  DescripcionDispo,
  Organizacion,
  Tipo,
  ContenedorFecha,
  Estado,
  Tiempo,
  Fecha,
  BotonMostrar,
} from "../../components/Table/Components";
import InputBusqueda from "./../../components/Table/inputBusqueda";

const TablaDispositivos = () => {
  let history = useHistory();
  const [results, setResults] = useState([]);
  const [text, setText] = useState("");

  useEffect(() => {
    console.log("entro al use effect");
    Api.getDeviceByPartialId(text).then(
      (result) => {
        setResults(result);
      },
      (error) => {
        console.log("Obtencion erronea");
      }
    );
  }, [text]);

  return (
    <>
      <Main>
        <ContenedorTitulo>
          <Titulo>Dispositivos alcantarillas</Titulo>
        </ContenedorTitulo>

        <ContenedorTabla>
          <NavbarTabla>
            <BarraBusqueda>
              <IconoLupa>
                <SearchIcon />
              </IconoLupa>
              <InputBusqueda setText={setText} text={text} />
            </BarraBusqueda>
            <BotonCrear
              onClick={() => {
                var url = "/admin/MapaAlacantarilla";
                history.push(url);
              }}
            >
              Crear Nuevo
            </BotonCrear>
            <BotonMostrar
              onClick={() => {
                var url = "/admin/viewDevices";
                history.push(url);
              }}
            >
              Mostrar todo
            </BotonMostrar>
          </NavbarTabla>
          <TablaDispo>
            <Tr>
              <Th>Dispositivo</Th>
              <Th>Organizacion</Th>
              <Th>Coordenadas</Th>
              <Th>Fecha creacion</Th>
              <Th>UTC</Th>
              <Th>Acciones</Th>
            </Tr>

            {results.length > 0 ? (
              results.map((device, i) => (
                <TrC>
                  <td>
                    <ContenedorDis>
                      <Nombredispositivo>{device.deviceID}</Nombredispositivo>
                      <DescripcionDispo>{device.tipo}</DescripcionDispo>
                    </ContenedorDis>
                  </td>

                  <td>
                    <Organizacion>{device.organizacion}</Organizacion>
                  </td>
                  <td>
                    <Tipo>Lat: {device.coordenadas.lat}</Tipo>
                    <Tipo>Lng: {device.coordenadas.lng}</Tipo>
                  </td>
                  <td>
                    <ContenedorFecha>
                      <Fecha>{device.fechaRegistro}</Fecha>
                    </ContenedorFecha>
                  </td>
                  <td>
                    <Tiempo>{device.utc}</Tiempo>
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        var url = "/admin/dataDevice?id=" + device.deviceID;
                        history.push(url);
                      }}
                    >
                      Mostrar datos
                    </button>
                  </td>
                </TrC>
              ))
            ) : (
              <div>No hay resultados</div>
            )}
          </TablaDispo>
        </ContenedorTabla>
      </Main>
    </>
  );
};

export default TablaDispositivos;
