import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import "./graficos.css";

function randomDate(start, end) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}


class LineasArea extends React.Component {

  componentDidUpdate(prevProps) {

  }


  constructor(props) {
    super(props);

    this.state = {
    };
  }



  render() {
    console.log("----------------------------------------------- xaxis", this.props.options.xaxis)
    console.log("----------------------------------------------- series", this.props.series)
    return (
      <>
        <div id="chart">
          <ReactApexChart options={this.props.options} series={this.props.series} type="area" height={350} />
        </div>
      </>
    );
  }
}

export default LineasArea;





