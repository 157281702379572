import React, { Component } from "react";
import MapaGoogle from './MapaGoogle.js'
import {
  Card,
  Row,
  Col,
} from "react-bootstrap";
import './CercaCircular.css'

class CircularMapa extends Component {

  constructor(props) {
    super(props);

    this.state = {
      radio: "",
      lat: "",
      lon: "",
    };
  }
  mapaGCbk(evento){
    console.log("Estado recibido de MapaGoogle", evento)
    if(evento.contador !== 0){
    this.state.radio = evento.radio;
    this.state.lat = evento.markers[0].position.lat;
    this.state.lon = evento.markers[0].position.lng;
    this.setState({
      radio: evento.radio,
      lat: evento.markers[0].position.lat,
      lon: evento.markers[0].position.lng
    })
    console.log("Estado de Circular mapa despues de upd", this.state)
    this.props.parentCallback(this.state)
  }
  }


  render() {
    return (
      <>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <div className="body-card">
            <MapaGoogle parentCallback={this.mapaGCbk.bind(this)}/>
            </div>
          </Card>
        </Col>
      </Row>
        
      </>
    );
  }
}
export default CircularMapa;
