import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';

import './estilosFormularios.css';

import Api from '../../../services/api-service.js'



const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#elemento');

export default function EdicionDispositivosHumitemp(props) {
  let device = props.item.deviceID;
  // let nombre = props.item.nombre;
  let grupo = props.item.grupo;
  // let rol = props.rol;

  console.log(props.organizaciones)

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [nombre, setNombre] = React.useState(props.item.nombre);
  const [posSVGx, setPosSVGx] = React.useState(props.item?.posicionSVG?.posx);
  const [posSVGy, setPosSVGy] = React.useState(props.item?.posicionSVG?.posy);
  const [rol, setRol] = React.useState(props.rol);

  const [organizaciones, setOrganizaciones] = React.useState([]);



  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
    setOrganizaciones((props.organizaciones)? props.organizaciones : [])
  }

  function closeModal() {
    console.log('cerrando')
    setIsOpen(false);
  }



  //// ACTUALIZACION DE NOMBRES Y PARAMETROS
  function changeName(event){
    console.log(event.target.value)
    setNombre(event.target.value);
  }

  function changeSVGx(event){
    console.log(event.target.value)
    setPosSVGx(event.target.value);
  }

  function changeSVGy(event){
    console.log(event.target.value)
    setPosSVGy(event.target.value);
  }





  function handleChangeGrupo(event){
    console.log(event.target.value);
    grupo = event.target.value;
  }

  function submitForm(){
    let actualizacion ={ 
      deviceID: device,
      nombre: (nombre == '')? null: nombre ,
      grupo:  (grupo=='null')? null: grupo ,
      posicionSVG: (posSVGx === '' || posSVGy === '')? null: {posx: Number(posSVGx), posy: Number(posSVGy)}
    }

    console.log(actualizacion)
    Api.edicionDispositivoHumitemp(actualizacion).then(
      result=>{
        console.log(result);
        // this.setState({devicesTable: result})
        // this.setState({tablaFiltrada: result})
        closeModal();
        props.eventFinishedOK();
      }, error=>{
        console.log('error en la actualización');
        alert('error en la actualización ')
      }
    )
  }
  
  return (
    <div>
      <button onClick={openModal}>Edición</button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
                >
        <div>
          <h1 >Edición Dispositivo</h1>
          {/* <button onClick={closeModal}>close</button> */}
          <h3>{device}</h3>
          <div className=''>
            <div className='formulario'>
             <label>Nombre: </label>
             <input type="text" id="lname" name="lname" value={nombre} onChange={changeName}/>
            </div>
            <div class="selectores">
                <label for="grupo">Grupo:</label>
                <select id="grupo" onChange={handleChangeGrupo}>
                  {/* <option value="" disabled  hidden>seleccionar</option> */}
                  <option selected value="null">No Grupo</option>
                  {organizaciones.map(( item, index )=>{
                    return (<option value={item['grupo']}>{item.grupo}</option>)
                    })}
                  {/* <option value="CD1">CD1</option> */}
                  {/* <option value="constecoin">Constecoin</option> */}
                </select>
              </div>
              {(rol=='hhh') &&<div>
                <h3>Alertas</h3>
                <div className='formulario'>
                <label>Valor Min: </label>
                <input type="text" id="vmin" name="vmin" />
                </div>
                <div className='formulario'>
                <label>Valor Max: </label>
                <input type="text" id="vmax" name="vmax" />
                </div>
                </div>}

                {(rol=='administrador general') &&<div>
                <h3>Posicionamiento SVG</h3>
                <div className='formulario'>
                <label>Valor X: </label>
                <input type="number" id="svgx" name="svgx" value={posSVGx} onChange={changeSVGx}/>
                </div>
                <div className='formulario'>
                <label>Valor Y: </label>
                <input type="number" id="svgy" name="svgy" value={posSVGy} onChange={changeSVGy}/>
                </div>
                </div>}

                <div className='div-botones row'>
                  <div className='col-6 button-edicion'>
                    <button className="buttonGeneral" onClick={()=>submitForm()}>Aceptar</button>
                  </div>
                  <div className='col-6 button-edicion'>
                    <button className="buttonGeneral button-secondary" onClick={()=>closeModal()}>Cancelar</button>
                  </div>
                </div>
          </div>

        </div>
      </Modal>
    </div>
  );
}