import React from "react";
import {Card,Container,Row,Col} from "react-bootstrap";
import "./CacaoTempSens.css"
import Lineas from './Cacao/LineasCacao.js'
import Api from '../services/api-service.js'

class CacaoTempSens extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      lineasIDs: [],
      datos2: [],
      mostrar: false,
      datos3: [],
      mostrar2: false,
      validaciones: [{num: 1},{num: 2},{num: 3},{num: 4},{num: 5}],
      dataSalida: []
    };    
  }

  componentDidMount(){
    this.inicio2()
  }

  async inicio2(){
    
    var cajonesIDS = Api.getIDsBeta()
    var verifNoNull = await Promise.all(cajonesIDS.map(async datos=>{
      var respuestaDeGET = await this.inicioDatos2(datos.ID)
      if(respuestaDeGET !== null){
        datos.CTL.map((datos1, index)=>{
          respuestaDeGET[0]["lineaT"+(index+1)] = datos1.linea;
          respuestaDeGET[0]["numT"+(index+1)] = datos1.num;
          respuestaDeGET[0]["cajonT"+(index+1)] = datos1.cajon;
        })
        return respuestaDeGET[0]
      } else {
        return respuestaDeGET
      }
    })
    )
    verifNoNull.map(datos=>{
      if(datos!==null){
        this.state.mostrar2 = true;
        this.setState({
          mostrar2: true
        })
      }
    })
    if(this.state.mostrar2==true){
      this.state.datos3 = verifNoNull;
      this.setState({
        datos3: verifNoNull,
      })
    }

    this.ordenarDatos(this.state.datos3)
    
  }

  inicioDatos2 (id){
    return new Promise((resolve,reject)=>{
      Api.obtenerDispCacao2(id)
      .then(
      result=>{
        resolve (result)
      }, error=>{
        resolve (null)
      }
    );
    })
  }

  ordenarDatos(data){
    var dataSalida = []
    var objIn = new Object
    data.map((datos1,index)=>{
      this.state.validaciones.map((datos2, index2)=>{
        if(datos1["lineaT"+(datos2.num)]==1){
          var objeto = this.generacionObjeto(datos1,datos2)
          dataSalida.push(objeto)
        } else if(datos1["lineaT"+(datos2.num)]==2){
          var objeto = this.generacionObjeto(datos1,datos2)
          dataSalida.push(objeto)
        } else if(datos1["lineaT"+(datos2.num)]==3){
          var objeto = this.generacionObjeto(datos1,datos2)
          dataSalida.push(objeto)
        } else if(datos1["lineaT"+(datos2.num)]==4){
          var objeto = this.generacionObjeto(datos1,datos2)
          dataSalida.push(objeto)
        } else if(datos1["lineaT"+(datos2.num)]==5){
          var objeto = this.generacionObjeto(datos1,datos2)
          dataSalida.push(objeto)
        } else if(datos1["lineaT"+(datos2.num)]==6){
          var objeto = this.generacionObjeto(datos1,datos2)
          dataSalida.push(objeto)
        } else if(datos1["lineaT"+(datos2.num)]==7){
          var objeto = this.generacionObjeto(datos1,datos2)
          dataSalida.push(objeto)
        } else if(datos1["lineaT"+(datos2.num)]==8){
          var objeto = this.generacionObjeto(datos1,datos2)
          dataSalida.push(objeto)
        }
      })
    })
    this.state.dataSalida = dataSalida;
    this.setState({
      dataSalida: dataSalida
    })
  }

  generacionObjeto(datos1, datos2){
    var objeto = {
      deviceID: datos1["deviceID"],
      T: datos1["T"+(datos2.num)],
      linea: datos1["lineaT"+(datos2.num)],
      cajon: datos1["cajonT"+(datos2.num)],
      num: datos1["numT"+(datos2.num)],
      fechaCompleta: datos1["fechaCompleta"],
      fechaEcuador: datos1["fechaEcuador"],
      organizacion: datos1["organizacion"]
    }
    return objeto
  }

  render() {
    return (
        <Container>
        <Row>
          <section className="w3-center w3-panel">
          </section>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Sensores de temperatura</Card.Title>
                  <p className="card-category">
                    Fecha y hora de transmisión de datos
                  </p>
              </Card.Header>
            </Card>
          </Col>
        </Row>
        <Row lg={1}>
          <Col >
            {this.state.mostrar2 && <Lineas info={this.state}></Lineas>}
            {this.state.mostrar2 == false && <>
            <br></br>
            <h3>Cargando datos...</h3>
            </>}
          </Col>
          
        </Row>
        </Container>
    );
  }}

  export default CacaoTempSens;






