import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import LoginLayout from "./layouts/Login.js"
import AdminLayout from "./layouts/Admin.js";

import "bootstrap/dist/css/bootstrap.min.css";

import App from './App.js'


ReactDOM.render(
  <App/>
  // <BrowserRouter>
  //   <Switch>
  //     <Route path="/login" render={(props) => <LoginLayout {...props} />} />
  //     <Route path="/admin" render={(props) => (auth === true? <AdminLayout {...props} /> : <Redirect from="/" to="/login/ingreso" />)} />
  //     {/* <Redirect from="/" to="/admin/home" /> */}
      
  //   </Switch>
  // </BrowserRouter>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
//registerServiceWorker();

