import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { render } from "react-dom";

import BodegaComponent from "../components/bodega/Bodega";
import TransporteComponent from "../components/Transporte/Transporte";
import "./CadenaFrio.css";
import Api from "../services/api-service.js";

class CadenaFrio extends React.Component {
  intervalID = 0;

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      devicesTable: [],
      tablaHistorialProducto: [],
      showBodega: false,
      showTransporte: false,
      botonesName: [
        "Bodega 1",
        "Transporte 1",
        "Bodega 2",
        "Transporte 2",
        "Bodega 3",
      ],
      botonesType: ["bodega", "transporte", "bodega", "transporte", "bodega"],
      botonesState: [false, false, false, false, false],
      bodega1: null,
      ble1: null,
      establecimiento: null,
      posicion: null,
      dispositivoSeleccionado: null,
      dispositivoSeleccionadoObject: null,
    };
  }
  intervalID = setInterval(() => {
    this.refreshConectedDevices();
    this.refreshDataDevice(this.state.dispositivoSeleccionado);
    console.log("actualización");
  }, 5000);

  refreshDataDevice = (device) => {
    Api.adquisicionDatosProducto(device, 50).then((result) => {
      console.log("adquisicionDatosProducto", result);
      if (!result) {
        return;
      }

      // let datos = result.map(item=>{
      //   return item.temperatura;
      // })

      console.log(result);

      this.setState({ tablaHistorialProducto: result });
    });
  };

  refreshConectedDevices = () => {
    Api.adquisicionConectedDevices("S1", this.state.establecimiento).then(
      async (result) => {
        console.log("adquisicionConectedDevices BLE 1", result);

        this.setState({ ble1: result[0] });

        // let auxiliar1 = await result.filter(item=>
        //   {
        //     let auxVar = false;
        //     if(item.gateway === 'AC233FA921C4'){
        //       auxVar = true;
        //     }
        //     return auxVar;
        //   })
        // console.log('auxiliar1',auxiliar1)

        // if(auxiliar1.length !== 0){
        //   console.log('detectado BLE 1')
        //   // this.setState({ble1: item.ble1})
        //   // deteccion ok del gateway 1
        //   // this.setState({ styleGateway1: {...this.state.styleGateway1, gateway: {...styleFigura, fill: "#0000FF", opacity: 1}}});
        // } else{
        //   console.log('NOO detectado BLE 1')
        //   // deteccion ERROR del gateway 1
        //   // this.setState({ styleGateway1: {...this.state.styleGateway1, gateway: {...styleFigura, fill: "#ff0000", opacity: 1}}});
        // }

        this.setState({ devicesTable: result });

        //// si el dispositivo es seleccionado muestra los indicadores

        if (this.state.dispositivoSeleccionado !== null) {
          let dispositivo = result.filter(
            (item) => item.mac === this.state.dispositivoSeleccionado
          );

          console.log(
            "this.state.dispositivoSeleccionado",
            this.state.dispositivoSeleccionadoObject,
            this.state.posicion,
            dispositivo
          );

          if (dispositivo.length !== 0) {
            this.setState({ posicion: dispositivo[0].ubicacion });
          } else {
            this.setState({ posicion: null });
          }
          // this.setState({dispositivoSeleccionado: evento.mac})
        }
      },
      (error) => {}
    );
  };

  buttonClick(tipo) {
    let estadoBotonesAux = this.state.botonesState;
    this.state.botonesState.map((item, index) => {
      console.log(tipo, index);
      if (index === tipo) {
        estadoBotonesAux[index] = true;
        // console.log(this.state.botonesName[index])
        this.setState({ establecimiento: this.state.botonesName[index] });
        // this.state.bodega = this.state.botonesName[index];
      } else {
        estadoBotonesAux[index] = false;
      }
      this.setState({ botonesState: estadoBotonesAux });
    });
    console.log(estadoBotonesAux, this.state.establecimiento);

    // console.log(this.state.botonesState)

    // if (tipo==='2'||tipo==='4'){
    //   this.setState({showTransporte: true, showBodega: false})
    // }

    // if (tipo==='1'||tipo==='3'||tipo==='5'){
    //   this.setState({showTransporte: false, showBodega: true})
    // }

    // if (tipo === '1'){
    //   this.setState({bodega: 'bodega 1'})
    // }
    // else if (tipo === '2'){
    //   this.setState({bodega: 'transporte 1'})
    // }
    // else {
    //   this.setState({bodega: null})
    // }
  }

  clickFila(evento) {
    console.log(evento);
    if (evento === null) {
      this.setState({ posicion: null });
      this.setState({ dispositivoSeleccionado: null });
      this.setState({ dispositivoSeleccionadoObject: null });
    } else {
      this.setState({ posicion: evento.ubicacion });
      this.setState({ dispositivoSeleccionado: evento.mac });
      this.setState({ dispositivoSeleccionadoObject: evento });
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    return (
      <>
        <Container fluid>
          <div className="buttonsElements-cadenaFrio">
            <ul>
              {this.state.botonesName.map((item, index) => {
                return (
                  <li>
                    <a
                      className={
                        this.state.botonesState[index]
                          ? "estado-boton-activo"
                          : null
                      }
                      onClick={() => this.buttonClick(index)}
                    >
                      {item}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          {this.state.botonesType.map((item, index) => {
            return (
              <>
                {this.state.botonesState[index] && item === "bodega" && (
                  <Row>
                    <Col md="6">
                      <Card className="strpied-tabled-with-hover">
                        <Card.Header>
                          <Card.Title as="h4">
                            Bodega {this.state.dispositivoSeleccionado}
                          </Card.Title>
                        </Card.Header>
                        <div className="body-card">
                          <BodegaComponent
                            ble1={this.state.ble1}
                            bodega={this.state.establecimiento}
                            posicion={this.state.posicion}
                          ></BodegaComponent>
                        </div>
                      </Card>
                    </Col>
                    <Col md="6">
                      <Card className="strpied-tabled-with-hover">
                        <Card.Header>
                          <Card.Title as="h4">Inventario Bodega</Card.Title>
                        </Card.Header>
                        <Card.Body className="table-full-width table-responsive px-0">
                          <Table className="table-hover table-striped">
                            <thead>
                              <tr
                                onClick={() => {
                                  this.clickFila(null);
                                }}
                              >
                                <th className="border-0">Zona</th>
                                <th className="border-0">Tipo</th>
                                <th className="border-0">Distancia</th>
                                <th className="border-0">MAC</th>
                                <th className="border-0">Temperatura</th>
                              </tr>
                            </thead>
                            <tbody className="fila-tabla">
                              {this.state.devicesTable.map((item, index) => {
                                console.log("here", item);
                                return (
                                  <tr
                                    key={index}
                                    onClick={() => {
                                      this.clickFila(item);
                                    }}
                                  >
                                    <td>{item.ubicacion}</td>
                                    <td>{item.tipo}</td>
                                    <td>{item.distancia.toFixed(4)}</td>
                                    <td>{item.mac}</td>
                                    <td>{item.temperatura.toFixed(2)}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md="12">
                      <Card className="strpied-tabled-with-hover">
                        <Card.Header>
                          <Card.Title as="h4">Inventario Bodega</Card.Title>
                        </Card.Header>
                        <Card.Body className="table-full-width table-responsive px-0">
                          <Table className="table-hover table-striped">
                            <thead>
                              <tr
                                onClick={() => {
                                  this.clickFila(null);
                                }}
                              >
                                <th className="border-0">Zona</th>
                                <th className="border-0">Distancia</th>
                                <th className="border-0">MAC</th>
                                <th className="border-0">Temperatura</th>
                              </tr>
                            </thead>
                            <tbody className="fila-tabla">
                              {this.state.tablaHistorialProducto.map(
                                (item, index) => {
                                  console.log("here", item);
                                  return (
                                    <tr
                                      key={index}
                                      onClick={() => {
                                        this.clickFila(item);
                                      }}
                                    >
                                      <td>{item.ubicacion}</td>
                                      <td>{item.distancia.toFixed(4)}</td>
                                      <td>{item.mac}</td>
                                      <td>{item.temperatura}</td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                )}

                {this.state.botonesState[index] && item === "transporte" && (
                  <Row>
                    <Col md="6">
                      <Card className="strpied-tabled-with-hover">
                        <Card.Header>
                          <Card.Title as="h4">Transporte</Card.Title>
                        </Card.Header>
                        <div className="body-card">
                          <TransporteComponent
                            ble1={this.state.ble1}
                          ></TransporteComponent>
                        </div>
                      </Card>
                    </Col>
                    <Col md="6">
                      <Card className="strpied-tabled-with-hover">
                        <Card.Header>
                          <Card.Title as="h4">Inventario Transporte</Card.Title>
                          {/* <p className="card-category">
                                
                              </p> */}
                        </Card.Header>
                        <Card.Body className="table-full-width table-responsive px-0">
                          <Table className="table-hover table-striped">
                            <thead>
                              <tr>
                                {/* <th className="border-0">ID</th> */}
                                <th className="border-0">Área</th>
                                <th className="border-0">Producto</th>
                                <th className="border-0">Codigo Prod</th>
                                <th className="border-0">ID</th>
                                {/* <th className="border-0">Fecha Último Dato</th>
                                    <th className="border-0">Hora Último Dato</th>
                                    <th className="border-0">Tiempo Último Dato</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.devicesTable.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item.ubicacion}</td>
                                    <td>{item.distancia}</td>
                                    <td>{item.mac}</td>
                                    <td>{item.temperatura}</td>
                                    {/* <td>{item.horaUltimoDato}</td> */}
                                    {/* <td>{item.tiempoUltimoDato}</td> */}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                )}
              </>
            );
          })}
        </Container>
      </>
    );
  }
}

export default CadenaFrio;
