import React from "react";

import DateTimePicker from '@mui/lab/DateTimePicker';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Button, Card, Container, Row, Col} from "react-bootstrap";

import "./IoTCacao.css"
import LineasArea from  "./Cacao/Lineas-area.js";
import Api from '../services/api-service.js'
import CuadroTemp from "./Cacao/CuadroTemp.js";

class IoTCacao extends React.Component {

  constructor (props) {
    super(props);

    this.selectorFun = this.selectorFun.bind(this);
    this.handleChangeFechaFinal = this.handleChangeFechaFinal.bind(this);
    this.handleChangeFechaInicial = this.handleChangeFechaInicial.bind(this);

    this.state = {
      idApi: '',
      dataApi:[],
      datos1: [{
        id: "000",
        linea: 0,
        T1: 0,
        T2: 0,
        T3: 0,
        num1: 0,
        num2: 0,
        num3: 0
      }],
      datos2: [
      {
          _id: "6261c2649a178124cf3d2136",
          linea: 1,
          deviceID: "4417931d32a0",
          organizacion: "gpf",
          fechaCompleta: "2022-04-21T15:45:24.476Z",
          timestamp: 1650555924476,
          tipo: 0,
          T1: 0,
          T2: 0,
          T3: 0
      }
      ],
      selectores: [
        {value:"1", text: 'Línea 1'},
        {value:"2", text: 'Línea 2'},
        {value:"3", text: 'Línea 3'},
        {value:"4", text: 'Línea 4'},
        {value:"5", text: 'Línea 5'},
        {value:"6", text: 'Línea 6'},
        {value:"7", text: 'Línea 7'},
        {value:"8", text: 'Línea 8'},
      ],
      lineaSelec: 0,
      series: [{}],
      seleccionado: false,
      optionsTemperatura: {
        chart: {
          height: 350,
          type: 'area'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        yaxis: {
          tickAmount: 10,
          min: -80,
          max: 80,
          decimalsInFloat: 2,
        },
        xaxis: {
          type: 'datetime',
          // min: fechaInicial.getTime(),
          min: new Date(),
          tickAmount: 6,
        },
        // xaxis: {
        //   tickAmount: 10,

        //   type: 'datetime',
        //   // categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
        // },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
      },
      infoVentana: "",
      queryEnvio: false,

      fechaInicial: new Date(),
      fechaFinal: new Date(),
      lineasIDS: [],

      mostrar2: false,
      datos3: [],
      validaciones: [{num: 1},{num: 2},{num: 3},{num: 4},{num: 5}],
      dataSalida: [],
      termometro1: [],
      termometro2: [],
      termometro3: []
    };    
    
  }

  selectorFun = (event) => {
    this.state.lineaSelec = event.target.value;
    this.setState({
        lineaSelec: event.target.value
      })
    this.state.dataSalida.map((datos, index)=>{
      if(Number(this.state.lineaSelec) == datos.linea && datos.num == 1){
        this.state.termometro1 = datos
        this.setState({
          termometro1: datos
        })
        // console.log("termo1", this.state.termometro1)
      } else if (Number(this.state.lineaSelec) == datos.linea && datos.num == 2){
        this.state.termometro2 = datos
        this.setState({
          termometro2: datos
        })
        // console.log("termo2", this.state.termometro2)
      } else if (Number(this.state.lineaSelec) == datos.linea && datos.num == 3){
        this.state.termometro3 = datos
        this.setState({
          termometro3: datos
        })
        // console.log("termo3", this.state.termometro3)
      }
      this.state.seleccionado = true;
      this.setState({
        seleccionado: true
      })
    })
    // this.organizDatos()
    this.resetFechasArea()
    this.datosTermometrosOnClick()
    this.datosHistoricosOnClick()
  }

  handleChangeFechaInicial = (event) =>{
    this.setState({fechaInicial: new Date(event)})
  }

  handleChangeFechaFinal = (event) =>{
    this.setState({fechaFinal: new Date(event)})
  }

  handleClick = (event) =>{
    //     var fechaActual = new Date();
    //     var numberOfMlSeconds = fechaActual.getTime();
    //     var addMlSeconds = 5 * 60 * 60000;
    //     var fechaActualEC = new Date(numberOfMlSeconds - addMlSeconds);
    //     var addMlSeconds2 = 10 * 60 * 60000;
    //     var fechaInicial = new Date(numberOfMlSeconds - addMlSeconds2);
    //     console.log("1 Fecha final", fechaActualEC)
    //     console.log("1 Fecha inicial",fechaInicial)
    this.updateOptionsTemperatura('xaxis',  {
      type: 'datetime',
      min: this.state.fechaInicial.getTime(),
      max: this.state.fechaFinal.getTime(),
      tickAmount: 6,
    })
    this.datosHistoricosOnClick()
    
  }

  async datosHistoricosOnClick (){
    /////////////HISTORICO DATA ////////
    var dataApi = await Promise.all(this.state.dataSalida.map(async datos=>{
      if(datos.linea==this.state.lineaSelec){
        this.state.idApi = datos.deviceID
        this.setState({
          idApi: datos.deviceID
        })
        // console.log("deviceID", this.state.idApi)
        var resultGET = await this.getHistorico(this.state.idApi, 'baba', this.state.fechaInicial, this.state.fechaFinal)
        if(resultGET!== null){
            return {
              'historico': resultGET,
            }
        } else {
          return null
        }
        
      }
    })
    )
    // console.log("dataApi", dataApi)
    this.state.dataApi = []
    this.setState({
      dataApi: []
    })
    if(dataApi !== null){
      dataApi.map(datos=>{
        if(datos !== undefined){
          this.state.dataApi.push(datos.historico);
        }
      })
    }
    
    this.state.validaciones.map((dataMap, index)=>{
      this.state.datos3.map((dataMap2, index2)=>{
        if(this.state.termometro1.cajon == dataMap2["cajonT"+dataMap.num]){
          // console.log("1 es T"+ dataMap.num)
          this.state.termometro1.numVar = dataMap.num
        } else if(this.state.termometro2.cajon == dataMap2["cajonT"+dataMap.num]){
          // console.log("2 es T"+ dataMap.num)
          this.state.termometro2.numVar = dataMap.num
        } else if(this.state.termometro3.cajon == dataMap2["cajonT"+dataMap.num]){
          // console.log("3 es T"+ dataMap.num)
          this.state.termometro3.numVar = dataMap.num
        }
      })
    })

    var datosTemp = new Array();
    var datosTemp1 = new Array();
    var datosTemp2 = new Array();

    // console.log("dataApi", this.state.dataApi)
        if(this.state.dataApi.length==3){
          datosTemp = this.state.dataApi[0].map(dato=>{
            return[dato.timestamp, dato["T"+this.state.termometro1.numVar]]
          })
          datosTemp1 = this.state.dataApi[1].map(dato=>{
            // console.log("T"+this.state.termometro2.numVar, dato["T"+this.state.termometro2.numVar])
            return[dato.timestamp, dato["T"+this.state.termometro2.numVar]]
          })
          datosTemp2 = this.state.dataApi[2].map(dato=>{
            return[dato.timestamp, dato["T"+this.state.termometro3.numVar]]
          })
        this.state.series = {
            series1: [{
              name: "Temperatura",
              data: datosTemp
            }],
            series2: [{
              name: "Temperatura",
              data: datosTemp1
            }],
            series3: [{
              name: "Temperatura",
              data: datosTemp2
            }]
        };
        this.setState({
          series: {
            series1: [{
              name: "Temperatura",
              data: datosTemp
            }],
            series2: [{
              name: "Temperatura",
              data: datosTemp1
            }],
            series3: [{
              name: "Temperatura",
              data: datosTemp2
            }]
          }
        })
        }

    /////////////HISTORICO DATA ////////
    return('done')
  }

  async datosTermometrosOnClick(){
    var cajonesIDS = Api.getIDsBeta()
    var verifNoNull = await Promise.all(cajonesIDS.map(async datos=>{
      var respuestaDeGET = await this.inicioDatos2(datos.ID)
      if(respuestaDeGET !== null){
        datos.CTL.map((datos1, index)=>{
          respuestaDeGET[0]["lineaT"+(index+1)] = datos1.linea;
          respuestaDeGET[0]["numT"+(index+1)] = datos1.num;
          respuestaDeGET[0]["cajonT"+(index+1)] = datos1.cajon;
        })
        return respuestaDeGET[0]
      } else {
        return respuestaDeGET
      }
    })
    )
    // console.log("verifNoNull", verifNoNull)
    verifNoNull.map(datos=>{
      if(datos!==null){
        this.state.mostrar2 = true;
        this.setState({
          mostrar2: true
        })
      }
    })
    if(this.state.mostrar2==true){
      this.state.datos3 = verifNoNull;
      this.setState({
        datos3: verifNoNull,
      })
    }
    this.ordenarDatos(this.state.datos3)
  }

  async resetFechasArea(){
    var fechaActual = new Date();
    var fechaInicial = fechaActual;
    ////OJO DEJAR VALOR POR DEFAULT EN CANTIDAD DE DÍAS ATRÁS
    fechaInicial.setTime(fechaInicial.getTime() - 1000 * 60* 60* 24* 4)
    this.state.fechaInicial = fechaInicial;
    this.state.fechaFinal = new Date();
    this.updateOptionsTemperatura('xaxis',  {
      type: 'datetime',
      min: this.state.fechaInicial.getTime(),
      max: this.state.fechaFinal.getTime(),
      tickAmount: 6,
    })
  }

  updateOptionsTemperatura(parameter, value){
    let auxOptionsTemperatura = {...this.state.optionsTemperatura};
    auxOptionsTemperatura[parameter] = value;
    this.state.optionsTemperatura = auxOptionsTemperatura;
    this.setState({optionsTemperatura: auxOptionsTemperatura})
  }

  async organizDatos(){
    var fechaActual = new Date();
    var fechaInicial = fechaActual;
    ////OJO DEJAR VALOR POR DEFAULT EN CANTIDAD DE DÍAS ATRÁS
    fechaInicial.setTime(fechaInicial.getTime() - 1000 * 60* 60* 24* 4)
    this.state.fechaInicial = fechaInicial;
    this.state.optionsTemperatura.xaxis = {
      type: 'datetime',
      min: fechaInicial.getTime(),
      tickAmount: 6,
    }
    this.setState(prevState=>({
      fechaInicial: fechaInicial,
      optionsTemperatura:{
        ...prevState.optionsTemperatura,
        xaxis: {
          type: 'datetime',
          min: fechaInicial.getTime(),
          tickAmount: 6,
        }
      }
    }))

    var cajonesIDS = Api.getIDsBeta()
    var verifNoNull = await Promise.all(cajonesIDS.map(async datos=>{
      var respuestaDeGET = await this.inicioDatos2(datos.ID)
      if(respuestaDeGET !== null){
        datos.CTL.map((datos1, index)=>{
          respuestaDeGET[0]["lineaT"+(index+1)] = datos1.linea;
          respuestaDeGET[0]["numT"+(index+1)] = datos1.num;
          respuestaDeGET[0]["cajonT"+(index+1)] = datos1.cajon;
        })
        return respuestaDeGET[0]
      } else {
        return respuestaDeGET
      }
    })
    )
    // console.log("verifNoNull", verifNoNull)
    verifNoNull.map(datos=>{
      if(datos!==null){
        this.state.mostrar2 = true;
        this.setState({
          mostrar2: true
        })
      }
    })
    if(this.state.mostrar2==true){
      this.state.datos3 = verifNoNull;
      this.setState({
        datos3: verifNoNull,
      })
    }
    this.ordenarDatos(this.state.datos3)

    /////////////HISTORICO DATA ////////
    var dataApi = await Promise.all(this.state.dataSalida.map(async datos=>{
      if(datos.linea==this.state.lineaSelec){
        this.state.idApi = datos.deviceID
        this.setState({
          idApi: datos.deviceID
        })
        // console.log("deviceID", this.state.idApi)
        var resultGET = await this.getHistorico(this.state.idApi, 'baba', this.state.fechaInicial, this.state.fechaFinal)
        if(resultGET!== null){
            return {
              'historico': resultGET,
            }
        } else {
          return null
        }
        
      }
    })
    )
    // console.log("dataApi", dataApi)
    this.state.dataApi = []
    this.setState({
      dataApi: []
    })
    if(dataApi !== null){
      dataApi.map(datos=>{
        if(datos !== undefined){
          this.state.dataApi.push(datos.historico);
        }
      })
    }
    
    this.state.validaciones.map((dataMap, index)=>{
      this.state.datos3.map((dataMap2, index2)=>{
        if(this.state.termometro1.cajon == dataMap2["cajonT"+dataMap.num]){
          // console.log("1 es T"+ dataMap.num)
          this.state.termometro1.numVar = dataMap.num
        } else if(this.state.termometro2.cajon == dataMap2["cajonT"+dataMap.num]){
          // console.log("2 es T"+ dataMap.num)
          this.state.termometro2.numVar = dataMap.num
        } else if(this.state.termometro3.cajon == dataMap2["cajonT"+dataMap.num]){
          // console.log("3 es T"+ dataMap.num)
          this.state.termometro3.numVar = dataMap.num
        }
      })
    })

    var datosTemp = new Array();
    var datosTemp1 = new Array();
    var datosTemp2 = new Array();

    // console.log("dataApi", this.state.dataApi)
        if(this.state.dataApi.length==3){
          datosTemp = this.state.dataApi[0].map(dato=>{
            return[dato.timestamp, dato["T"+this.state.termometro1.numVar]]
          })
          datosTemp1 = this.state.dataApi[1].map(dato=>{
            // console.log("T"+this.state.termometro2.numVar, dato["T"+this.state.termometro2.numVar])
            return[dato.timestamp, dato["T"+this.state.termometro2.numVar]]
          })
          datosTemp2 = this.state.dataApi[2].map(dato=>{
            return[dato.timestamp, dato["T"+this.state.termometro3.numVar]]
          })
        this.state.series = {
            series1: [{
              name: "Temperatura",
              data: datosTemp
            }],
            series2: [{
              name: "Temperatura",
              data: datosTemp1
            }],
            series3: [{
              name: "Temperatura",
              data: datosTemp2
            }]
        };
        this.setState({
          series: {
            series1: [{
              name: "Temperatura",
              data: datosTemp
            }],
            series2: [{
              name: "Temperatura",
              data: datosTemp1
            }],
            series3: [{
              name: "Temperatura",
              data: datosTemp2
            }]
          }
        })
        }

    /////////////HISTORICO DATA ////////

    if(this.state.queryEnvio == true){
      this.state.dataSalida.map((datos, index)=>{
        if(Number(this.state.lineaSelec) == datos.linea && datos.num == 1){
          this.state.termometro1 = datos
          this.setState({
            termometro1: datos
          })
          // console.log("termo1", this.state.termometro1)
        } else if (Number(this.state.lineaSelec) == datos.linea && datos.num == 2){
          this.state.termometro2 = datos
          this.setState({
            termometro2: datos
          })
          // console.log("termo2", this.state.termometro2)
        } else if (Number(this.state.lineaSelec) == datos.linea && datos.num == 3){
          this.state.termometro3 = datos
          this.setState({
            termometro3: datos
          })
          // console.log("termo3", this.state.termometro3)
        }
      })
      this.state.seleccionado = true;
      this.setState({
        seleccionado: true
      })
    }

  }

  inicioDatos2 (id){
    return new Promise((resolve,reject)=>{
      Api.obtenerDispCacao2(id)
      .then(
      result=>{
        resolve (result)
      }, error=>{
        resolve (null)
      }
    );
    })
  }

  ordenarDatos(data){
    var dataSalida = []
    var objIn = new Object
    data.map((datos1,index)=>{
      this.state.validaciones.map((datos2, index2)=>{
        if(datos1["lineaT"+(datos2.num)]==1){
          var objeto = this.generacionObjeto(datos1,datos2)
          dataSalida.push(objeto)
        } else if(datos1["lineaT"+(datos2.num)]==2){
          var objeto = this.generacionObjeto(datos1,datos2)
          dataSalida.push(objeto)
        } else if(datos1["lineaT"+(datos2.num)]==3){
          var objeto = this.generacionObjeto(datos1,datos2)
          dataSalida.push(objeto)
        } else if(datos1["lineaT"+(datos2.num)]==4){
          var objeto = this.generacionObjeto(datos1,datos2)
          dataSalida.push(objeto)
        } else if(datos1["lineaT"+(datos2.num)]==5){
          var objeto = this.generacionObjeto(datos1,datos2)
          dataSalida.push(objeto)
        } else if(datos1["lineaT"+(datos2.num)]==6){
          var objeto = this.generacionObjeto(datos1,datos2)
          dataSalida.push(objeto)
        } else if(datos1["lineaT"+(datos2.num)]==7){
          var objeto = this.generacionObjeto(datos1,datos2)
          dataSalida.push(objeto)
        } else if(datos1["lineaT"+(datos2.num)]==8){
          var objeto = this.generacionObjeto(datos1,datos2)
          dataSalida.push(objeto)
        }
      })
    })
    this.state.dataSalida = dataSalida;
    this.setState({
      dataSalida: dataSalida
    })
    // console.log("dataSalida", this.state.dataSalida)
  }

  generacionObjeto(datos1, datos2){
    var objeto = {
      deviceID: datos1["deviceID"],
      T: datos1["T"+(datos2.num)],
      linea: datos1["lineaT"+(datos2.num)],
      cajon: datos1["cajonT"+(datos2.num)],
      num: datos1["numT"+(datos2.num)],
      fechaCompleta: datos1["fechaCompleta"],
      fechaEcuador: datos1["fechaEcuador"],
      organizacion: datos1["organizacion"]
    }
    return objeto
  }

  getHistorico (id, tipoLote, inicio, fin){
    return new Promise((resolve,reject)=>{
      console.log("inicio", inicio, "fin", fin)
      Api.obtenerHistoricoCacao(id, tipoLote, inicio, fin)
      .then(
      result=>{
        // console.log("result en IoT cacao", result)
        resolve (result)
      }, error=>{
        resolve (null)
      }
    );
    })
  }

  checkQuery = (a) =>{
    if( this.state.queryEnvio == false){
      var b = a.split('&');
      var d
      b.map(c=>{
        if(c.substring(0, 6) == "linea="){
          d = c.substring(6, 7)
          this.state.lineaSelec = d;
          // this.state.seleccionado = true;
          // console.log("Cambia vision", this.state.seleccionado)
          this.state.queryEnvio = true;
          this.setState({
            lineaSelec: d,
            // seleccionado: true,
            queryEnvio: true
          })
        }
      })
    }
    this.organizDatos()
  }

  componentDidMount(){
    this.state.infoVentana = window.location.search;
    // console.log("Info ventana", this.state.infoVentana)
    this.checkQuery(this.state.infoVentana)
    this.organizDatos()
  }

  render() {
    return (
        <Container>
        <Row>
          <section className="w3-center w3-panel">
          </section>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Sensores de temperatura</Card.Title>
                  <p className="card-category">
                    Selección de temperatura e históricos por línea
                  </p>
              </Card.Header>

            </Card>
          </Col>
        </Row>
        <Row lg={5}>
          <Col >
          <div class="selectores">
            <label for="grupo">Líneas:</label>
              <select id="grupo" onChange={this.selectorFun.bind(this)}>
                <option selected hidden value="null">No Seleccionado</option>
                {this.state.selectores.map(item=>{
                return <option value={item.value}>{item.text}</option>
                })
                }
              </select>
          </div>
          </Col>
        </Row>
        <Row >
        <div className="col-lg-3">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="Fecha Inicial"
              value={this.state.fechaInicial}
              onChange={this.handleChangeFechaInicial.bind(this)}
            />
          </LocalizationProvider>
        </div>

        <div className="col-lg-3">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label="Fecha Final"
                value={this.state.fechaFinal}
                onChange={this.handleChangeFechaFinal.bind(this)}
              />
            </LocalizationProvider>
        </div>
        <Col md="2">
          <Button className="buttonGeneral" onClick={this.handleClick}>Buscar en Rango</Button>
        </Col>
      </Row>
        <Row lg={3}>
          {this.state.seleccionado && this.state.dataApi.length==3 &&
          <Col>
            <br></br>
            <h3>
            <CuadroTemp fila={1} linea={this.state.lineaSelec} cajon={this.state.termometro1.cajon} temperatura={this.state.termometro1.T}/>
            </h3>
            <br></br>
            <LineasArea series = {this.state.series.series1} options={this.state.optionsTemperatura}/>
          </Col>
          }
          {this.state.seleccionado && this.state.dataApi.length==3 &&
          <Col>
            <br></br>
            <h3>
            <CuadroTemp fila={2} linea={this.state.lineaSelec} cajon={this.state.termometro2.cajon} temperatura={this.state.termometro2.T}/>
            </h3>
            <br></br>
            <LineasArea series = {this.state.series.series2} options={this.state.optionsTemperatura}/>
          </Col>
          }
          {this.state.seleccionado && this.state.dataApi.length==3 &&
          <Col>
            <br></br>
            <h3>
            <CuadroTemp fila={3} linea={this.state.lineaSelec} cajon={this.state.termometro3.cajon} temperatura={this.state.termometro3.T}/>
            </h3>
            <br></br>
            <LineasArea series = {this.state.series.series3} options={this.state.optionsTemperatura}/>
          </Col>
          }
        </Row>
        </Container>
    );
  }}

  export default IoTCacao;