import React, { useEffect, useState } from "react";
import Cacao from "./Data Center/index";
import GPF from "./GPF/index";
import Aproca from "./Aproca/index";
import Constecoin from "./Constecoin/Home";
import Elicrom from "./Elicrom/index";
import EPCONST from "./epconst_gad_sto/index"
import ConsejoSeguridad from "./Ecu911/index"
import Api from "../../services/api-service.js";

const ViewOne = (props) => {
  if (props.name != undefined) {
    switch (props.name) {
      case "data center":
        return <Cacao />;
      case "gpf":
        return <GPF />;
      case "aproca":
        return <Aproca />;
      case "Elicrom":
        return <Elicrom />;
      case "epconst_gad_sto":
        return <EPCONST />;
      case "ecu911_sto":
        return <ConsejoSeguridad />;
      default:
        return <Constecoin />;
    }
  } else {
    return <>Cargando...</>;
  }
};

export default ViewOne;
