import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card } from "react-bootstrap";
import bat from "../graficos/imagenes/bateria.png";
class guage2 extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
      
        series: [50],
        options: {

          chart: {
            height: 350,
            type: 'radialBar',
            offsetY: -10
          },
          plotOptions: {
            radialBar: {
              hollow: {
                margin: 15,
                size: '70%',
                image: bat,
                imageWidth: 125,
                imageHeight: 125,
                imageClipped: false
              },
              startAngle: -135,
              endAngle: 135,
              dataLabels: {
                name: {
                  fontSize: '25px',
                  color: undefined,
                  offsetY: 120
                },
                value: {
                  offsetY: 76,
                  fontSize: '22px',
                  color: undefined,
                  formatter: function (val) {
                    return val + "%";
                  }
                }
              }
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                shadeIntensity: 0.15,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 65, 91]
            },
          },
          stroke: {
            dashArray: 4
          },
          labels: ['Bateria'],
        },
      
      
      };
    }

  

    render() {
      return (
        
<>
<Card>
  <div id="chart">
<ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" height={350} />
</div>
</Card>
</>
);
     }
    }
    export default guage2;
              