import React from "react";
import abierto from "./abierto.png";
import cerrado from "./cerrado.png";
import ButtonS from "../../../../components/Button";
import {
  BarraBusqueda,
  BotonCrear,
  ContenedorTabla,
  ContenedorTitulo,
  Main,
  BotonMostrar,
  NavbarTabla,
  Th,
  Button,
  Titulo,
  Tr,
  TablaDispo,
  TrC,
  IconoLupa,
  ContenedorDis,
  Nombredispositivo,
  DescripcionDispo,
  Organizacion,
  Tipo,
  ContenedorFecha,
  Tiempo,
  Fecha,
  BotonDatos,
  ParaBoton,
  ParaMapa,
  BotonDevice,
  ContenedorButton,
} from "../../DataCenterElements";

const Puertas = () => {
  var valor = 0;
  return (
    <>
      <Main>
        <ContenedorTitulo>
          <Titulo>Control de acceso</Titulo>
        </ContenedorTitulo>
        {valor == 1 ? (
          <>
            <img src={abierto} />
            <p>Abierto</p>
          </>
        ) : (
          <>
            <img src={cerrado} />
            <p>Cerrado</p>
          </>
        )}

        <ContenedorButton>
          <ButtonS
            url="/admin/creacionGabinete"
            message="Regresar"
            style={BotonDevice}
          >
            Regresar
          </ButtonS>
        </ContenedorButton>
      </Main>
    </>
  );
};

export default Puertas;
