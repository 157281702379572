import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import Input from "../../../components/Entradas/Input";
import { useHistory } from "react-router-dom";
import API from "../../../services/api-service";
import Select from "../../../components/Entradas/Select";
import IngresarMarcador from "../../../components/Map/Ingresar Marcador/index";
import ButtonS from "../../../components/Button";

import {
  BarraBusqueda,
  BotonCrear,
  ContenedorTabla,
  ContenedorTitulo,
  Main,
  BotonMostrar,
  NavbarTabla,
  Th,
  Button,
  Titulo,
  Tr,
  TablaDispo,
  TrC,
  IconoLupa,
  ContenedorDis,
  Nombredispositivo,
  DescripcionDispo,
  Organizacion,
  Tipo,
  ContenedorFecha,
  Tiempo,
  Fecha,
  BotonDatos,
  ParaBoton,
  ParaMapa,
  BotonDevice,
  ContenedorButton,
} from "../AlcantarillaElements";
import Mapa from "../../../components/Alcantarillas/IngresarMarcador/index";

const TipoDispositivo = [
  {
    value: "Celular",
    label: "Celular",
  },
  {
    value: "LoRaWAN",
    label: "LoRaWAN",
  },
  {
    value: "WIFI",
    label: "WIFI",
  },
];

const ZonasHorarias = [
  {
    value: "Etc/GMT+12",
    label: "(GMT-12:00) International Date Line West",
  },
  {
    value: "Pacific/Midway",
    label: "(GMT-11:00) Midway Island, Samoa",
  },
  {
    value: "Pacific/Honolulu",
    label: "GMT-10:00) Hawaii",
  },
  {
    value: "US/Alaska",
    label: "GMT-09:00) Alaska",
  },
  {
    value: "America/Los_Angeles",
    label: "(GMT-08:00) Pacific Time (US & Canada)",
  },
  {
    value: "America/Tijuana",
    label: "(GMT-08:00) Tijuana, Baja Californi",
  },
  {
    value: "US/Arizona",
    label: "(GMT-07:00) Arizona",
  },
  {
    value: "America/Chihuahua",
    label: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
  },
  {
    value: "US/Mountain",
    label: "(GMT-07:00) Mountain Time (US & Canada)",
  },
  {
    value: "America/Managua",
    label: "GMT-06:00) Central America",
  },
  {
    value: "US/Central",
    label: "(GMT-06:00) Central Time (US & Canada)",
  },
  {
    value: "America/Mexico_City",
    label: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
  },
  {
    value: "Canada/Saskatchewan",
    label: "(GMT-06:00) Saskatchewan",
  },
  {
    value: "America/Bogota",
    label: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
  },
  {
    value: "Canada/Atlantic",
    label: "(GMT-04:00) Atlantic Time (Canada)",
  },
  {
    value: "America/Caracas",
    label: "(GMT-04:00) Caracas, La Paz",
  },
  {
    value: "America/Manaus",
    label: "(GMT-04:00) Manaus",
  },
  {
    value: "America/Santiago",
    label: "(GMT-04:00) Santiago",
  },
  {
    value: "Canada/Newfoundland",
    label: "(GMT-03:30) Newfoundland",
  },
  {
    value: "America/Sao_Paulo",
    label: "(GMT-03:00) Brasilia",
  },
  {
    value: "America/Argentina/Buenos_Aires",
    label: "(GMT-03:00) Buenos Aires, Georgetown",
  },
  {
    value: "America/Godthab",
    label: "(GMT-03:00) Greenland",
  },
  {
    value: "America/Montevideo",
    label: "GMT-03:00) Montevideo",
  },
  {
    value: "America/Noronha",
    label: "(GMT-02:00) Mid-Atlantic",
  },
  {
    value: "Atlantic/Cape_Verde",
    label: "(GMT-01:00) Cape Verde Is.",
  },
  {
    value: "Atlantic/Azores",
    label: "(GMT-01:00) Azores",
  },
  {
    value: "Africa/Casablanca",
    label: "(GMT+00:00) Casablanca, Monrovia, Reykjavik",
  },
  {
    value: "Etc/Greenwich",
    label:
      "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
  },
  {
    value: "Europe/Amsterdam",
    label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
  },
  {
    value: "Europe/Belgrade",
    label: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
  },
  {
    value: "Europe/Brussels",
    label: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
  },
  {
    value: "Europe/Sarajevo",
    label: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
  },
  {
    value: "Africa/Lagos",
    label: "(GMT+01:00) West Central Africa",
  },
  {
    value: "Asia/Amman",
    label: "(GMT+02:00) Amman",
  },
  {
    value: "Europe/Athens",
    label: "(GMT+02:00) Athens, Bucharest, Istanbul",
  },
  {
    value: "Asia/Beirut",
    label: "(GMT+02:00) Beirut",
  },
  {
    value: "Africa/Cairo",
    label: "(GMT+02:00) Cairo",
  },
  {
    value: "Africa/Harare",
    label: "(GMT+02:00) Harare, Pretoria",
  },
  {
    value: "Europe/Helsinki",
    label: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
  },
  {
    value: "Asia/Jerusalem",
    label: "(GMT+02:00) Jerusalem",
  },
  {
    value: "Europe/Minsk",
    label: "(GMT+02:00) Minsk",
  },
  {
    value: "Africa/Windhoek",
    label: "(GMT+02:00) Windhoek",
  },
  {
    value: "Asia/Kuwait",
    label: "(GMT+03:00) Kuwait, Riyadh, Baghdad",
  },
  {
    value: "Europe/Moscow",
    label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
  },
  {
    value: "Africa/Nairobi",
    label: "(GMT+03:00) Nairobi",
  },
  {
    value: "Asia/Tbilisi",
    label: "(GMT+03:00) Tbilisi",
  },
  {
    value: "Asia/Tehran",
    label: "(GMT+03:30) Tehran",
  },
  {
    value: "Asia/Muscat",
    label: "(GMT+04:00) Abu Dhabi, Muscat",
  },
];

const Index = () => {
  const [device, setDevice] = useState(null);
  const [obtencionCoor, setobtencionCoor] = useState(false);
  const [typeDevice, setTypeDevice] = useState("Celular");
  const [utc, setUtc] = useState("America/Bogota");
  const [zoom, setZoom] = useState(22);

  const [position, setPosition] = useState({
    lat: null,
    lng: null,
  });

  var history = useHistory();

  function ObtenerCoordendas() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log("Coordenadas Obtenidas");
        console.log("Latitud " + position.coords.latitude);
        console.log("Longitud " + position.coords.longitude);

        setPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setobtencionCoor(true);
      },
      (error) => {
        setZoom(8);
        swal({
          icon: "warning",
          title: "Oops...",
          text: "No activaste la localizacion o tu dispositivo no tiene GPS, Ingresalo Manualmente",
        });
      }
    );
  }

  useEffect(() => {
    ObtenerCoordendas();
  }, [obtencionCoor]);

  function sendData() {
    if (device == null) {
      swal({
        icon: "error",
        title: "Oops...",
        text: "Ingresa los datos del dispositivo",
      });
    } else if (position.lat == null && position.lng == null) {
      swal({
        icon: "error",
        title: "Oops...",
        text: "Debes escoger la ubicacion del dispostivo",
      });
    } else {
      API.saveDevice(device, position.lat, position.lng, typeDevice, utc);
      swal({
        icon: "success",
        title: "Dispositivo Guardado",
      });
      var url = "/admin/viewAllDeviceAlcantarilla";
      history.push(url);
    }
  }

  return (
    <>
      <Main>
        <ContenedorTitulo>
          <Titulo>Crear nuevo Dispositivo</Titulo>
        </ContenedorTitulo>
        <Input
          message="Ingrese el ID dispositivo"
          value={device}
          setFunction={setDevice}
          id="outlined-disabled"
        />
        <Select
          message="Ingrese la conectividad"
          value={typeDevice}
          setFunction={setTypeDevice}
          data={TipoDispositivo}
          id="outlined-disabled"
        />
        <Select
          message="Franja Horaria"
          value={utc}
          setFunction={setUtc}
          data={ZonasHorarias}
          id="outlined-disabled"
        />

        {obtencionCoor ? (
          <IngresarMarcador
            position={position}
            setFunction={setPosition}
            zoom={zoom}
          />
        ) : null}

        <ContenedorButton>
          <ButtonS
            url="/admin/viewAllDeviceAlcantarilla"
            message="Regresar"
            style={BotonDevice}
          >
            Regresar
          </ButtonS>
          <BotonDevice
            onClick={() => {
              sendData();
            }}
          >
            Guardar
          </BotonDevice>
        </ContenedorButton>
      </Main>
    </>
  );
};

export default Index;
