import React, {useEffect, useState} from 'react'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import { BarraBusqueda, BotonCrear, BotonFiltro, Categorias, ContenedorCategorias, ContenedorTabla, ContenedorTitulo, Main, NavbarTabla, Th, Titulo, Tr, TablaDispo, TrC, CoFiltroIn, Divfiltro, SelectFiltro, IconoLupa, InputBusqueda, ContenedorDis, Nombredispositivo, DescripcionDispo, Organizacion, Tipo, ContenedorFecha, Estado, Tiempo, Fecha } from './DispositivosElementos'
import {Modal,TextField, Button} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap'
import './style.css'
import Dato from './data.json'
import ContenidoTabla from './ContenidoTabla';

const useStyles=makeStyles((theme)=>({
  modal:{
    position: 'absolute',
    width: 200,
    backgroundColor: 'white',
    boder: '2px solid #000',
    // boxShadow: theme.shadows[5],
    top: '50%',
    left: '40%',
  }
}))

const useData = () =>{
  const [data, setData] = useState([]);
  useEffect(()=>{
    fetch("./data.json")
    .then(response => response.json())
    .then(datos => {
      setData(datos)
    })
  }, [])
  console.log(data);
  return data;
}




const TablaDispositivos = () => {

const DatosJ = useData();
const styles=useStyles();

const [modal,setModal]=useState(false);

const abrirCerrarModal=()=>{
  setModal(!modal);
}

const [ dropdown, setDropdown]=useState(false);

const abrirCerrarDrop=()=>{
  setDropdown(!dropdown);
}




  return (
    <>
      <Main>
        <ContenedorTitulo>
          <Titulo>
            Titulo
          </Titulo>
        </ContenedorTitulo>
        <ContenedorCategorias>
          <Categorias>
            Todos
          </Categorias>
          <Categorias>
            Categoria 1
          </Categorias>
          <Categorias>
          Categoria 2
          </Categorias>
          <Categorias>
          Categoria 3
          </Categorias>
        </ContenedorCategorias>
        <ContenedorTabla>
          <NavbarTabla>
            <BotonFiltro><FilterListIcon />Filtros</BotonFiltro>
            <BarraBusqueda><IconoLupa><SearchIcon/></IconoLupa><InputBusqueda placeholder='Busqueda...'></InputBusqueda></BarraBusqueda>
            <BotonCrear>Crear Nuevo</BotonCrear>
          </NavbarTabla>
<ContenidoTabla/>
  
        </ContenedorTabla>
      </Main>
    </>
  )
}

export default TablaDispositivos

