import React from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { Main, ForMap, Texto1, Texto2, Mapas } from "./ElementsActualizar";

const YOUR_GOOGLE_API_KEY_GOES_HERE = "AIzaSyBYNDUEf0g8OQPJfiEW7ybizYPiMN_7XOU";

const Actualizar = (props) => {
  return (
    <>
      <Main>
        <Mapas>
          <ForMap>
            <Map
              google={window.google}
              initialCenter={{
                lat: props.lat,
                lng: props.lng,
              }}
              zoom={22}
              onClick={(x, y, LatLng, ev) => {
                props.function({
                  lat: LatLng.latLng.lat(),
                  lng: LatLng.latLng.lng(),
                });
              }}
            >
              {props.coordendas.lat ? (
                <Marker
                  position={{
                    lat: props.coordendas.lat,
                    lng: props.coordendas.lng,
                  }}
                  draggable={true}
                  name={"Current location"}
                />
              ) : null}
            </Map>
          </ForMap>
        </Mapas>
      </Main>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: YOUR_GOOGLE_API_KEY_GOES_HERE,
})(Actualizar);
