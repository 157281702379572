import React, { useState, useEffect } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import swal from "sweetalert";
import Input from "../../../../components/Entradas/Input";
import { useHistory } from "react-router-dom";
import API from "../../../../services/api-service";
import Select from "../../../../components/Entradas/Select";
import IngresarMarcador from "../../../../components/Map/Ingresar Marcador/index";
import ButtonS from "../../../../components/Button";
import {
  BarraBusqueda,
  BotonCrear,
  ContenedorTabla,
  ContenedorTitulo,
  Main,
  BotonMostrar,
  NavbarTabla,
  Th,
  Button,
  Titulo,
  Tr,
  TablaDispo,
  TrC,
  IconoLupa,
  ContenedorDis,
  Nombredispositivo,
  DescripcionDispo,
  Organizacion,
  Tipo,
  ContenedorFecha,
  Tiempo,
  Fecha,
  BotonDatos,
  ParaBoton,
  ParaMapa,
  BotonDevice,
  ContenedorButton,
} from "../../DataCenterElements";

const Index = () => {
  const [device, setDevice] = useState(null);
  const [obtencionCoor, setobtencionCoor] = useState(false);
  const [typeDevice, setTypeDevice] = useState("Celular");
  const [utc, setUtc] = useState("America/Bogota");
  const [zoom, setZoom] = useState(22);

  const [data, setData] = useState("");
  const [saveApi, setSaveApi] = useState([]);

  const [position, setPosition] = useState({
    lat: null,
    lng: null,
  });

  var history = useHistory();

  var Data = [];

  function onSelectRow(row, isSelected, e) {
    if (isSelected) {
      Data.push(row["deviceID"]);
    }
    if (!isSelected) {
      Data.pop(row["deviceID"]);
    }
    console.log("Es la data ");
    console.log(Data);
  }
  function onSelectAll(isSelect, rows, e) {
    if (isSelect) {
      console.log("Selecciono todo");
      data.map((index) => {
        Data.push(index.deviceID);
      });
    }
    if (!isSelect) {
      console.log("Deselecciono");
      data.map((index) => {
        Data.pop(index.deviceID);
      });
    }
    console.log("Es la data ");
    console.log(Data);
  }

  const selectRowProp = {
    mode: "checkbox",
    clickToSelect: true,
    unselectable: [2],
    selected: [1],
    onSelect: onSelectRow,
    onSelectAll: onSelectAll,
    bgColor: "#A1F168",
  };

  useEffect(() => {
    API.ObtenerDispositivosDataCenter().then(
      (result) => {
        setData(result);
      },
      (error) => {
        console.log("Obtencion erronea");
      }
    );
  }, []);

  function sendData() {
    if (device == null) {
      swal({
        icon: "error",
        title: "Oops...",
        text: "Ingresa los datos del dispositivo",
      });
    } else {
      API.saveGabinetes(device, Data);
      swal({
        icon: "success",
        title: "Dispositivo Guardado",
      });
      var url = "/admin/creacionGabinete";
      history.push(url);
    }
  }

  return (
    <>
      <Main>
        <ContenedorTitulo>
          <Titulo>Crear Nuevo Gabinete</Titulo>
        </ContenedorTitulo>
        <Input
          message="Ingrese el ID dispositivo"
          value={device}
          setFunction={setDevice}
          id="outlined-disabled"
        />
        {/* <Select
          message="Ingrese la conectividad"
          value={typeDevice}
          setFunction={setTypeDevice}
          data={TipoDispositivo}
          id="outlined-disabled"
        />
        <Select
          message="Franja Horaria"
          value={utc}
          setFunction={setUtc}
          data={ZonasHorarias}
          id="outlined-disabled"
        /> */}

        {data.length > 0 ? (
          <BootstrapTable data={data} selectRow={selectRowProp}>
            <TableHeaderColumn isKey dataField="deviceID">
              ID
            </TableHeaderColumn>
            <TableHeaderColumn dataField="organizacion">Name</TableHeaderColumn>
            <TableHeaderColumn dataField="tipo">Value</TableHeaderColumn>
          </BootstrapTable>
        ) : null}

        <ContenedorButton>
          <ButtonS
            url="/admin/creacionGabinete"
            message="Regresar"
            style={BotonDevice}
          >
            Regresar
          </ButtonS>
          <BotonDevice
            onClick={() => {
              sendData();
            }}
          >
            Guardar
          </BotonDevice>
        </ContenedorButton>
      </Main>
    </>
  );
};

export default Index;
