import React from "react";
// import logo from './aiot_icon.png';
import './App.css';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import LoginLayout from "./layouts/Login.js"
import AdminLayout from "./layouts/Admin.js";
import GeneralLayout from "./layouts/General.js"

import "bootstrap/dist/css/bootstrap.min.css";

import Auth from './services/auth-service.js'

class App extends React.Component {

  auth = Auth.getJWT('asdfasdfasdf');
  // return 
  constructor(props) {
    super(props);
    // No llames this.setState() aquí!
    this.state = { auth: false, counter: 0 };
    // this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount(){
    
  }

  handleCallbackLogin = (childData) =>{
    // this.setState({auth: childData})
    this.setState({auth: childData})
    this.auth = childData;
    console.log("Entro", this.auth);
    // console.log(this.props, this.props.history);

  }

  render(){
    return (
      <BrowserRouter>
      <Switch>
        <Route path="/q"   render={(props) => <GeneralLayout />} />
        <Route path="/login"   render={(props) => <LoginLayout {...props} onLoginCallback={this.handleCallbackLogin}/>} />
        <Route path="/admin" render={(props) => (Auth.getJWT() !== null? <AdminLayout {...props} /> : <Redirect from="/" to="/login/ingreso" />)} />
        <Redirect from="/" to="/admin/home" />
        
      </Switch>
    </BrowserRouter>
    )
    }
    // );  
}

export default App;
