import React, { Component } from "react";
import './ListaDispositivosGeneral.css';
import { Button } from 'react-bootstrap'
import Api from '../../services/api-service.js'

class UplinkGPSTracker extends Component {

  constructor(props) {
    super(props);

    this.state = {
      respuestaServiceStatus: "", //Guarda la respuesta "Service ok"
      estadoMostrarServiceStatus: false, //Estado para mostrar componente de alerta con respuesta de ServiceStatus
      mostrarComponenteUL: false, //Estado para mostrar componente de UL
      tipoRest: "" //Tipo de rest pedido
    };
  }

  ////Función que obtiene el estado del servicio
  estadoServicio1 = () => {
    Api.estadoServicio()
    .then(
      result=>{
        console.log(result);
        this.state.estadoMostrarServiceStatus = true;
        this.state.respuestaServiceStatus = result;
        this.setState({
          respuestaServiceStatus: result,
          estadoMensaje: true,
        })
      }, error=>{
        console.log(error);
        this.state.estadoMostrarServiceStatus = true;
        this.state.respuestaServiceStatus = error;
        this.setState({
          respuestaServiceStatus: error,
          estadoMensaje: true,
        })
      }
    );
  }
  
  //////Función que envía el tipo de rest seleccionado: unDIspoInfo
  seleccionRest = (event) =>{
    this.state.tipoRest = event.currentTarget.value;
    this.state.mostrarComponenteUL = true;
    this.setState({
      tipoRest: event.currentTarget.value,
      mostrarComponenteUL: true,
    })
    console.log("Estado enviado desde UplinkGPSTracker a BootstrapModal", this.state)
    this.props.parentCallback(this.state);
  }

  render() {
    return (
      <div>
        <h6>Seleccione el tipo de UPLINK</h6>
        {/* {////////////////// boton de mostrar estado} */}
        <>
        <div>
          <Button className="btn btn-primary buttonGeneral"  onClick={this.estadoServicio1}> Revisar estado del servicio</Button>
        </div>
        <br></br>
        { this.state.estadoMostrarServiceStatus &&
        <div class="alert alert-secondary" role="alert">
          Estado: {this.state.respuestaServiceStatus}
        </div>
        }
        </>
        {/* {////////////////// boton revisar un dispositivo} */}
        <div>
        <Button className="btn btn-primary buttonGeneral" value="unDispo"  onClick={this.seleccionRest}> Revisar Información de un Dispositivo</Button>
        </div>
        <br></br>
        <div>
        <Button className="btn btn-primary buttonGeneral" value="crearDispoUL"  onClick={this.seleccionRest}> Crear un Dispositivo</Button>
        </div>
      </div>
    );
  }
}
export default UplinkGPSTracker;
