import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { Mapaa } from "./RegistroComponentes";
const YOUR_GOOGLE_API_KEY_GOES_HERE = "AIzaSyBYNDUEf0g8OQPJfiEW7ybizYPiMN_7XOU";
const style = {
  width: "95%",
  height: "70%",
};

function Mapa(props) {
  return (
    <>
      <Map
        google={window.google}
        initialCenter={{
          lat: props?.data.latitude,
          lng: props?.data.longitude,
        }}
        style={style}
        zoom={18}
      >
        <Marker
          key={1}
          // title={item.name}
          name={"Sin Nombre"}
          position={{ lat: props?.data.latitude, lng: props?.data.longitude }}
        />
      </Map>
    </>
  );
}

export default GoogleApiWrapper({
  apiKey: YOUR_GOOGLE_API_KEY_GOES_HERE,
})(Mapa);
