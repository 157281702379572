import React, { useState, useEffect } from 'react'
import { Card, Table, Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Api from "../../services/api-service"



const Dispositivos = () => {
    const [data, setData] = useState("")
    const query = new URLSearchParams(useLocation().search);

    useEffect(() => {
        const organizacion = query.get("organizacion");
        const tipo = query.get("tipo");
        let Data = {
            organizacion: organizacion,
            tipo: tipo
        }
        Api.obtenerDIspoGPF(Data).then(
            (result) => {
                console.log(result)
                setData(result);
            },
            (error) => {
                console.log("Obtencion erronea");
            }
        );
    }, []);
    return (
        <>
            <Container fluid>
                <Row>


                    <Col md="12">
                        <Card className="strpied-tabled-with-hover">
                            <Card.Header>
                                <Card.Title as="h4">Devices</Card.Title>
                                <p className="card-category">
                                    Tabla de dispositivos conectados
                                </p>
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                <Table
                                    className="table-hover table-striped"
                                    id="tabla-obtenida"
                                >
                                    <thead>
                                        <tr>
                                            <th className="border-0">Device ID</th>
                                            <th className="border-0">Organización</th>
                                            <th className="border-0">NOMBRE</th>
                                            <th className="border-0">GRUPO</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.length > 0 ? (data.map((index, i) => {
                                                return (
                                                    <tr >
                                                        <td>{index.deviceID}</td>
                                                        <td>{index.organizacion}</td>
                                                        <td>{index.nombre}</td>
                                                        <td>CLA</td>
                                                    </tr>
                                                )
                                            })) : null
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Dispositivos