import React, { Component } from "react";
import MapaComp from '../components/General/MapaComp.js'
import Api from '../services/api-service.js'
import {
  Card,
  Table,
  Row,
  Col,
} from "react-bootstrap";

import './General.css';
import Top from "../components/Top-admin/Top.js";


class General extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      lat: -0.261564,
      lon: -78.521580,
      leido: false,
      alarma: "",
      temp: "",
      fecha: "",
      hora: "",
      nombre: "",
      id: ""
    };
  }
  
    componentDidMount(){
      if(this.state.id !== ""){
        Api.lastReloj(this.state.id).then(
          result =>{
            console.log("Último dato de correo en base", result)
            this.obtenerCoord(result)
          }
        )
      }
    }

    obtenerCoord(res){
      if(res.length !== 0){
      var coord = res[0].coordenadas.split(",")
      this.state.lat = coord[0].replace("http://maps.google.com/?q=", "")
      this.state.lon = coord[1]
      this.state.leido = true
      this.state.alarma = res[0].tipoAlarma
      this.state.temp = res[0].temperatura
      this.state.fecha = res[0].fecha
      this.state.hora = res[0].hora
      this.state.nombre = res[0].name
      this.setState({
        lat: coord[0].replace("http://maps.google.com/?q=", ""),
        lon: coord[1],
        leido: true,
        alarma: res[0].tipoAlarma,
        temp: res[0].temperatura,
        fecha: res[0].fecha,
        hora: res[0].hora,
        nombre: res[0].name
      })
      console.log("lat", this.state.lat, "lon", this.state.lon)
    }
    }

  render(){
    var x1 = window.location.search;
    this.state.id = x1.replace("?u=", "");
    console.log("id obtenido",this.state.id)
  return (
    <> 
    <Top></Top>
      <div className="login-wrapper">
        <div class="login-panel">
        <Row>
          <Col md="20">
            <Card className="strpied-tabled-with-hover">

              <Card.Header>
                <Card.Title as="h4">Información</Card.Title>
              </Card.Header>

              <Card.Body className="table-full-width table-responsive px-0">
                      <Table className="table-hover table-striped">
                        <thead>
                          <tr>
                            <th className="border-0">Nombre</th>
                            <th className="border-0">Alarma</th>
                            <th className="border-0">Temperatura</th>
                            <th className="border-0">Fecha</th>
                            <th className="border-0">Hora</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{this.state.nombre}</td>
                            <td>{this.state.alarma}</td>
                            <td>{this.state.temp}</td>
                            <td>{this.state.fecha}</td>
                            <td>{this.state.hora}</td>
                          </tr>
                        </tbody>
                      </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
            <Col md="20">
              <Card className="strpied-tabled-with-hover">

              <Card.Header>
                <Card.Title as="h4">Ubicación del R12T</Card.Title>
              </Card.Header>

              <div className="body-card">
                {this.state.leido && <MapaComp latitud={this.state.lat} longitud={this.state.lon}></MapaComp>}
              </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
}

export default General;
