import ReactDOM from 'react-dom';
import Modal from 'react-modal';

import Api from '../../../services/api-service.js'

import React, { useState, useEffect } from 'react';

import './estilosFormularios.css';



const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement');

export default function EditarEmpresas(props) {



  const [modalIsOpen, setIsOpen] = React.useState(false);
  // const [organizacion, setOrganizacion] = React.useState(props.organizacion);
  const [nombre, setNombre] = React.useState(props.item.nombre);
  const [email, setEmail] = React.useState(props.item.email);
  const [direccion, setDireccion] = React.useState(props.item.direccion);
  const [estado, setEstado ] = React.useState(props.item.estado);
  const [cantidadUsuarios, setCantidadUsuarios ] = React.useState(props.item.cantidadUsuarios);

  const [form, setForm ] = React.useState({organizacion: props.item.organizacion});

  
  useEffect(() => {

  setNombre(props.item.nombre)
  setEmail(props.item.email)
  setDireccion(props.item.direccion)
  setCantidadUsuarios(props.item.cantidadUsuarios)
  setEstado(props.item.estado)

  },[props]);
  

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    console.log('closeModal')
   setIsOpen(false);
   reset();
  }

  function reset(){
    setNombre(props.item.nombre)
    setEmail(props.item.email)
    setDireccion(props.item.direccion)
    setCantidadUsuarios(props.item.cantidadUsuarios)
    setEstado(props.item.estado)
  }
  
  function afterOpenModal() {
    console.log('afterOpenModal')
  console.log(props)

  }

  function handleChangeForm(event){
    console.log(event.target.name)



    form[event.target.name] = (event.target.value === 'null')? null: event.target.value ;

    if(event.target.name === 'nombre'){setNombre(event.target.value)}
    if(event.target.name === 'email'){setEmail(event.target.value)}
    if(event.target.name === 'direccion'){setDireccion(event.target.value)}
    if(event.target.name === 'cantidadUsuarios'){setCantidadUsuarios(event.target.value)}
    if(event.target.name === 'estado'){setEstado(event.target.value)}

    console.log(form)


  }

  function submitForm(){
    console.log('envio info', form);
    Api.actualizacionParametrosEmpresa(form).then(
      result =>{
        console.log(result)

        // if(!result.mensaje){
        //   alert(result.error)
        // } else {
          // alert(result.mensaje)
          props.resultCallback(result.mensaje);
          closeModal();
        // }

      }, error=>{
        alert(error)
        console.log(error.error)

      }
    )
  }

  return (


    <div>
      <button onClick={openModal}>Editar</button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
      >
        <div>
          <h1 >Editar Empresa</h1>
          <div className=''>
          <div className='formulario'>
             <label>Nombre: </label>
             <input type="text" id="nombre" name="nombre" value={nombre} onChange={handleChangeForm}/>
            </div>
            <div className='formulario'>
             <label>Email: </label>
             <input type="text" id="email" name="email" value={email} onChange={handleChangeForm}/>
            </div>
            <div className='formulario'>
             <label>Cantidad Usuarios: </label>
             <input type="number" id="cantidadUsuarios" name="cantidadUsuarios" value={cantidadUsuarios} onChange={handleChangeForm}/>
            </div>
            <div className='formulario'>
             <label>Dirección: </label>
             <input type="text" id="direccion" name="direccion" value={direccion} onChange={handleChangeForm}/>
            </div>

            
            <div className='div-botones row'>
              <div className='col-6 button-edicion'>
                <button className="buttonGeneral" onClick={()=>submitForm()}>Actualizar</button>
              </div>
              <div className='col-6 button-edicion'>
                <button className="buttonGeneral button-secondary" onClick={()=>closeModal()} >Cancelar</button>
              </div>
            </div>
          </div>

        </div>
      </Modal>
    </div>
  );
}