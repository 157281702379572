import React from "react";

import './AdministracionEmpresas.css';
import Api from '../../services/api-service.js'
import Auth from '../../services/auth-service.js';
import ReactToExcel from 'react-html-table-to-excel';
import { CSVLink } from 'react-csv'

import Ventana from '../../components/betatemp/betaform'
import Beta from '../../components/betatemp/ConsumoBeta'
import { BotonModal, BootstrapModa } from '../../components/betatemp/beta_editar';
import { makeStyles } from '@mui/styles';
// react-bootstrap components


import AgregarEmpresas from './formularios/AgregarEmpresas.js'
import EditarEmpresas from './formularios/EditarEmpresas.js'
import ConfiguracionEmpresas from './formularios/ConfiguracionEmpresas.js'


// import { BotonModal,BootstrapModa } from '../components/betatemp/beta_editar';

import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { render } from "react-dom";





class AdministracionCuentas extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      selectedTipo: null,
      selectedOrganizacion: 'all',
      listTable: [],
      tablaFiltrada: [],
      showBetatemp: false,
      showSigfox: false,
      selectedItem: null,
      showEdit: false,
      isModalOpen: false,
      children: null,
      ModalSetup: null,
      rol: Auth.getRol(),
      organizaciones: Auth.getOrganizaciones()
    };
    // console.log('Auth.getRol()',Auth.getRol())


    this.setState({ rol: Auth.getRol() });
    this.actualizarTablaEmpresas();

  }


  componentWillMount() {
    console.log(this.state.organizaciones);

    let auxFilter = this.state.organizaciones.filter(item => item.organizacion === 'gpf');

    if (auxFilter.length >= 1) {
      console.log(auxFilter[0].rol);
      this.state.rol = auxFilter[0].rol;
      this.setState({ rol: auxFilter[0].rol });
    }
  }

  actualizarTablaEmpresas() {

    Api.obtenerTablaEmpresas({ organizacion: this.state.selectedOrganizacion, estado: "all" }).then(
      result => {
        console.log(result);
        this.setState({ listTable: result })
        this.setState({ tablaFiltrada: result })
      }, error => {
        console.log('error en la adquisicion de datos', error);
      }
    )
  }

  handleModalOpen = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen })
  }
  dismissable = () => {
    console.log('salir')
    this.setState({
      isModalOpen: false
    })
  }

  handleShow = (item) => {
    this.setState({ selectedItem: item })

    if (item) {
      this.setState({ showEdit: true })
    } else {
      this.setState({ showEdit: false })
    }
  }


  resultModalAgregarEmpresa = (value) => {
    console.log(value);
    this.actualizarTablaEmpresas();

  }
  resultModalEditarEmpresa = (value) => {
    console.log(value);
    this.actualizarTablaEmpresas();

  }



  render() {
    const { isModalOpen } = this.state;
    let children;
    if (isModalOpen) {
      children = (
        <div className="estiloModal">hola</div>
      );
    }
    return (
      <>
        <Container fluid>


          <Row>

            <section className="w3-center w3-panel">
              {/* <Ventana className="w3-container w3-modal-content w3-teal"/>  */}
            </section>

            <Col md="2">
              {(this.state.rol == 'administrador' || this.state.rol == 'administrador general' || true)
                && <AgregarEmpresas organizaciones={this.state.organizaciones} resultCallback={this.resultModalAgregarEmpresa.bind(this)}></AgregarEmpresas>}
            </Col>
            {/* <Col md="2">
              <div class="selectores">
                <label for="tipo">Tipo Dispositivo:</label>
                <select id="tipo" onChange={this.handleChangeTipo}>
                  <option value="" disabled selected hidden>seleccionar</option>
                  <option value="wifi">Wifi</option>
                  <option value="lora">Lora</option>
                  <option value="sigfox">Sigfox</option>
                  <option value="celular">Celular</option>
                </select>
              </div> 
            </Col> */}


            {/* <Col md="2">
              <div>
              <ReactToExcel
              className="btn btn-primary buttonGeneral"
              table="tabla-obtenida"
              filename="iotTablaConste"
              sheet="sheet 1"
              buttonText="Exportar como xls"
              />
              </div> 
            </Col>

            <Col md="2">
              <div>
                <CSVLink data={this.state.devicesTable} filename={"iotTablaConste.csv"}>
                  <Button className="buttonGeneral"> Exportar como csv</Button>
                </CSVLink>
              </div> 
            </Col> */}


            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">Empresas</Card.Title>
                  <p className="card-category">
                    Empresas de IoT
                  </p>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table className="table-hover table-striped" id="tabla-obtenida">
                    <thead>
                      <tr>
                        {/* <th className="border-0">ID</th> */}
                        <th className="border-0">Nombre</th>
                        <th className="border-0">Organización</th>
                        <th className="border-0">Cantidad Usuarios</th>
                        <th className="border-0">Estado</th>
                        {/* <th className="border-0">SIGFOX</th>
                        <th className="border-0">MAC</th> */}
                        <th className="border-0">Edición</th>
                        <th className="border-0">Configuración</th>
                        {/* <th className="border-0">Tiempo Último Dato</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.tablaFiltrada.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.nombre}</td>
                            <td>{item.organizacion}</td>
                            <td>{item.cantidadUsuarios}</td>
                            <td>{item.estado}</td>
                            <td><EditarEmpresas item={item} resultCallback={this.resultModalEditarEmpresa.bind(this)}></EditarEmpresas></td>
                            <td><ConfiguracionEmpresas item={item} resultCallback={this.resultModalEditarEmpresa.bind(this)}></ConfiguracionEmpresas></td>
                          </tr>
                        );
                      })}

                    </tbody>
                  </Table>

                </Card.Body>
              </Card>
            </Col>

          </Row>
        </Container>
        {/* <ModalSetup
             visible={isModalOpen}
             dismiss={this.dissmissable}
             children={children} 
          /> */}

        {/* {this.state.isModalOpen && <ModalEdicion />} */}

        {/* <ModalEdicion 
          visible={isModalOpen}
          dismiss={this.dissmissable}
          children={children} 
      /> */}


        {/* <BootstrapModa 
            setShow ={this.state.showEdit}
            handleClose={()=>this.handleShow(null)}
            item={this.state.selectedItem}
            // id={item._id}
            // nombre={this.state.selectedItem.nombre}
            // organization={item.organization}
            // descripcion={item.descripcion}
            // codigoDeBarras={item.codigoDeBarras}
        
            
          > 
          test 
          <h1>holaaa</h1>
          </BootstrapModa> */}
      </>
    );
  }
}




export default AdministracionCuentas;





