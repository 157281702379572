

import React, { useState } from 'react'; 
import ReactExport from "react-export-excel"; 
import {
  Button,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"

const ExcelFile = ReactExport.ExcelFile; 
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet; 
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Download = ({ head, data }) => {

  const [listHead, setHead] = useState(head);
  const [listSelectNewTable, setListSelectNewTable] = useState(data);

  console.log("Esta es la data")
  console.log(head, data)
    return (
      <ExcelFile className="buttonGeneral"
      element={<Button className="btn btn-primary buttonGeneral" >Exportar Excel</Button>}>
      
        <ExcelSheet data={data} name="Hoja test">
          {
            head.map(d =>
              <ExcelColumn label={d.Header} value={d.accessor} />)
          }
        </ExcelSheet>
      </ExcelFile>
    ); }

export default Download;