import React, { useEffect, useState } from "react";
import { LogIn } from "react-feather";
import { useLocation } from "react-router-dom";
import Auth from "../../services/auth-service"
import API from "../../services/api-service"
import Button from "../../components/Button/index"
import CuentaUsuario from "../../views/Usuario/CuentaUsuario.js";
import {
    BarraBusqueda,
    BotonCrear,
    ContenedorTabla,
    ContenedorTitulo,
    Main,
    NavbarTabla,
    Th,
    Titulo,
    Tr,
    TablaDispo,
    TrC,
    IconoLupa,
    ContenedorDis,
    Nombredispositivo,
    DescripcionDispo,
    Organizacion,
    Tipo,
    ContenedorFecha,
    Tiempo,
    Fecha,
    BotonMostrar,
    BotonDatos,
  } from "./UsuariosElements";

const Cuentas = () => {
    const [results, setResults] = useState([]);
    const [organiza, setOrgani] = useState();
    const [postRol, setRol] = useState();
    const query = new URLSearchParams(useLocation().search);
    const obteOrganizacion = query.get("organizacion");



    useEffect(()=>{


        setOrgani(obteOrganizacion)

        

        let rol = Auth.getRol()
        setRol(rol)


        // API.obtenerUsuarios(rol,obteOrganizacion).then((response)=>{
        API.obtenerUsuarios(rol,obteOrganizacion).then((response)=>{
            console.log(response)
            setResults(response)
        },(error)=>{
            console.log(error);
        }) 

    },[])

  return (
    <>

{postRol=="visualizador" || postRol=="usuario" ? <CuentaUsuario/> : <Main>
        <ContenedorTitulo>
          <Titulo>Usuarios</Titulo>
        </ContenedorTitulo>

        <ContenedorTabla>
          <NavbarTabla>
            {/* <BarraBusqueda>
              <IconoLupa>
                <SearchIcon />
              </IconoLupa>
              <InputBusqueda setText={setText} text={text} />
            </BarraBusqueda> */}
            <Button
              url={`/admin/createUser?organizacion=${obteOrganizacion}`}
              message="Crear Nuevo"
              style={BotonCrear}
            />
          </NavbarTabla>
          <TablaDispo>
            <Tr>
              <Th>Usuario</Th>
              <Th>Nombre</Th>
              <Th>Organizacion</Th>
              <Th>Rol</Th>
              <Th>Email</Th>
              <Th>Estado</Th>
              <Th>Accion</Th>
            </Tr>
            {results.length > 0 ? (
              results.map((device, i) => (
                <TrC>
                  <td>

     
                      <DescripcionDispo>{device.usuario}</DescripcionDispo>

                  </td>
                  <td>

                      <Nombredispositivo>{device.nombre}</Nombredispositivo>

                  </td>

                  <td>
                    <Organizacion>{organiza}</Organizacion>
                  </td>
                  <td>
                    <Organizacion>{device.rol}</Organizacion>
                  </td>
                  <td>
                    <Organizacion>{device.email}</Organizacion>
                  </td>
                  <td>
                    <Organizacion>{device.estado}</Organizacion>
                  </td>
                  
                  <td>
                    <Button
                      url={`/admin/editUserOne?count=${device._id}&organizacion=${organiza}`}
                      message="Editar"
                      style={BotonDatos}
                    />
                  </td>
                </TrC>
              ))
            ) : (
              <div>No hay resultados</div>
            )}
          </TablaDispo>
        </ContenedorTabla>
      </Main>}

      
    </>
  )
}

export default Cuentas