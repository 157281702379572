import React from "react";
import sidebarImage from "../assets/img/login.jpg";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Top from "../components/Top/Top.js";
import LoginComponent from "../components/login/login.js";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "./Login.css";

function showNotification(titulo, mensaje, tipo) {
  store.addNotification({
    title: titulo,
    message: mensaje,
    type: tipo,
    insert: "top",
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: false,
    },
  });
}

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { counter: 0 };
  }  

  onHandlerClick = () => {    
    this.props.onLoginCallback("data ok");
    return "ok";
  };

  onHandlerLoginComponent = (datos) => {
    this.props.onLoginCallback(datos);    

    if (datos === null) {      
      showNotification("Error", "credenciales incorrectas", "danger");
    } else {
      showNotification("Bienvenido", "Acceso garantizado", "success");

      setTimeout(() => {
        this.props.history.push("/admin/home");
      }, 3000);
    }
  };

  render() {
    return (
      <>
        <Top />
        <ReactNotification />
        <div className="login-wrapper">
          <div class="login-panel">
            <Card>
              <CardMedia component="img" height="140" image={sidebarImage} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Iniciar sesión
                </Typography>
                <LoginComponent
                  onLoginComponentCallback={this.onHandlerLoginComponent}
                />
              </CardContent>
              <CardActions></CardActions>
            </Card>
          </div>
        </div>
      </>
    );
  }
}

export default Login;
export { showNotification };
