// import Auth from './auth-service.js';
import { object } from "prop-types";
import { getJWT } from "./auth-service.js";

const URL_BASE_BLE = "https://iot.constecoin.com/";
const URL_BASE = "https://aiot.constecoin.com/";

/////////////////////////////////////////////////////AUTNTICACION DE CUENTAS ///////////////////////////////////
function login(body) {
  console.log(body);
  let url = URL_BASE + "api/autenticacion/cuentas/autenticacion";
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (respuesta) => {
          resolve(respuesta);
        },
        (error) => reject(error)
      );
  });
}

/////////////////////////////////////////////////////AUTNTICACION DE CUENTAS ///////////////////////////////////
function registro(body) {
  console.log(body);
  let url = URL_BASE + "api/autenticacion/cuentas/registro";
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (respuesta) => {
          resolve(respuesta);
        },
        (error) => reject(error)
      );
  });
}

function actualizacionParametrosUsuario(body) {
  console.log(body);
  let url = URL_BASE + "api/autenticacion/cuentas/setParameters";
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (respuesta) => {
          resolve(respuesta);
        },
        (error) => reject(error)
      );
  });
}

function actualizacionDatosUsuario(body) {
  console.log(body);
  let url = URL_BASE + "api/autenticacion/cuentas/updateParameters";
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    body: JSON.stringify(body),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (respuesta) => {
          resolve(respuesta);
        },
        (error) => reject(error)
      );
  });
}
/////////////////////////////////////////////////////AUTNTICACION DE CUENTAS ///////////////////////////////////
function registroEmpresa(body) {
  console.log(body);
  let url = URL_BASE + "api/autenticacion/empresas/registro";
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (respuesta) => {
          resolve(respuesta);
        },
        (error) => reject(error)
      );
  });
}

function actualizacionParametrosEmpresa(body) {
  console.log(body);
  let url = URL_BASE + "api/autenticacion/empresas/setParameters";
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (respuesta) => {
          resolve(respuesta);
        },
        (error) => reject(error)
      );
  });
}

function actualizacionConfiguracionEmpresa(body) {
  console.log(body);
  let url = URL_BASE + "api/autenticacion/empresas/setConfigurations";
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (respuesta) => {
          resolve(respuesta);
        },
        (error) => reject(error)
      );
  });
}

function adquirirConfiguracionesEmpresa(element) {
  // console.log(body)
  var url =
    URL_BASE +
    "api/autenticacion/empresas/getEmpresa?organizacion=" +
    element.organizacion;

  // let url= "https://iot.constecoin.com/api/autenticacion/empresas/getEmpresa";
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    // body: JSON.stringify(body)
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (respuesta) => {
          resolve(respuesta);
        },
        (error) => reject(error)
      );
  });
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

function obtenerUsuario() {
  let url = URL_BASE + "/api/autenticacion/cuentas/getUsuario";

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    // body: JSON.stringify(body)
  };
  console.log("X", getJWT());

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => resolve(result),
        (error) => reject(error)
      );
  });
}

///////////////////////////////////////////////////ADQUIERE LOS DISPOSITIVOS REGISTRADOS ///////////////////////////////////
function adquirirDevices() {
  console.log("X");

  let url =
    URL_BASE + "api/administracion-dispositivos/dispositivos/listaDispositivos";
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    // body: JSON.stringify(body)
  };
  console.log("X", getJWT());

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => resolve(result),
        (error) => reject(error)
      );
  });
}

///////////////////////////////////////////////////ADQUIERE LOS DISPOSITIVOS CONECTADOS ///////////////////////////////////
function adquirirDevicesConected() {
  console.log("Xxx");

  let url =
    URL_BASE +
    "api/modulos-cacao/getStatusDevices?" +
    "estado=all&organizacion=all";
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    // body: JSON.stringify(body)
  };
  console.log("X", getJWT());

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => resolve(result),
        (error) => reject(error)
      );
  });
}

///////////////////////////////////////////////////ADQUIERE LAS ALERTAS DE LOS DEVICES ///////////////////////////////////
function adquirirAlertasDevices(elemento) {
  console.log("X");

  // let url=  URL_BASE + "api/modulos-cacao/getAlertDevices?"+"estado="+estado+"&organizacion="+ organizacion;
  let url =
    URL_BASE +
    "api/gpf/humitemp/dashboard/getAlert?" +
    "estado=" +
    elemento.estado +
    "&organizacion=" +
    elemento.organizacion;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    // body: JSON.stringify(body)
  };
  // console.log("X", Auth.getJWT())

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => resolve(result),
        (error) => reject(error)
      );
  });
}

function setStateAlert(elemento) {
  // console.log("X")

  // let url=  URL_BASE + "api/modulos-cacao/getAlertDevices?"+"estado="+estado+"&organizacion="+ organizacion;
  let url = URL_BASE + "api/gpf/humitemp/dashboard/setStateAlert";
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    body: JSON.stringify(elemento),
  };
  // console.log("X", Auth.getJWT())

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => resolve(result),
        (error) => reject(error)
      );
  });
}

////////////////////////////////////////REVISA EL ESTADO DEL SERVICIO DE GPSTRACKER ////////////////////////////
function estadoServicio() {
  let url = "https://iot.constecoin.com/api/sigfox/sigfoxWeb/status";
  const requestOptions = {
    method: "GET",
    // headers: { 'Content-Type': 'application/json' },
    // body: JSON.stringify(body)
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res)
      .then(
        (result) => resolve(result.text()),
        (error) => reject(error)
      );
  });
}
/////////////////////////////////////////////// AQUISICION DE DISPOSITIVOS CONECTADOS BLE //////////////////////
function adquisicionConectedDevices(tipo, bodega) {
  return new Promise((resolve, reject) => {
    fetch(
      URL_BASE_BLE +
      "api/cadenaFrio/administracion-ble/ble/getActiveDevices?numeroDatos=10&tipo=" +
      tipo +
      "&bodega=" +
      bodega
    )
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
  });
}

//////////////////////////////////Obtiene lista de dispositivos GPST TRACKER Usado para administración en GPSTrackerList.js
function adquirirDevicesGPSTracker() {
  let url = "http://test.egm-robotics.com/api/sigfox/sigfoxWeb/getListDevices";
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    // body: JSON.stringify(body)
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

//////////////////////////////////Obtiene lista de dispositivos Beta Temp Usado para administración en BetaTempList.js
function adquirirDevicesBeta() {
  let url =
    URL_BASE + "api/administracion-dispositivos/dispositivos/listaDispositivos";
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    // body: JSON.stringify(body)
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => {
        if (res.status === 200) {
          console.log("SUCCESSS");
          return res.json();
        } else if (res.status === 500) {
          console.log("SOMETHING WENT WRONG");
          // this.setState({ requestFailed: true })
          return reject(res.json());
        } else {
          console.log("ERROR DESCONOCIDO");
          return reject(res.json());
        }
      })
      .then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.log(error);
          return reject(error);
        }
      );
  });
}

///////////Adquirir información de un dispositivo GPS Tracker usado en AdminSigfox
function adquirirUnGPSTracker(_id) {
  let url = URL_BASE + "api/sigfox/sigfoxWeb/getDevice?device=";
  var url1 = url + _id;
  console.log("url1", url1);
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    // body: JSON.stringify(body)
  };

  return new Promise((resolve, reject) => {
    fetch(url1, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

///////////Crear un nuevo Dispositivo GPSTracker usado en AdminSigfox
function crearNuevoDispo(_id) {
  let url = URL_BASE + "api/sigfox/sigfoxWeb/newDevice";
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    body: { device: _id },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

///////////Actualizar parametros de dispositivos GPSTracker
function updDispoGPS(datos, elemento) {
  var requestOptions;

  let url = URL_BASE + "api/sigfox/sigfoxWeb/setParametersDevice";
  if (datos.tipoCerca === "Circular" && datos.seleccBorrarGeo === false) {
    console.log("Entro a configurar en API");
    requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getJWT(),
      },
      body: JSON.stringify({
        device: elemento,
        tipo: "CIRCULAR",
        latitud1: datos.datosCircular.lat,
        longitud1: datos.datosCircular.lon,
        radio: datos.datosCircular.radio,
      }),
    };
  }
  if (datos.seleccBorrarGeo === true) {
    console.log("Entro a borrar en API");
    requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getJWT(),
      },
      body: JSON.stringify({ device: elemento, tipo: "NO GEOCERCA" }),
    };
  }
  console.log(requestOptions);
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

///////////Obtener último dato para posición de reloj
function lastReloj(_id) {
  console.log("id de funcion", _id);
  let url = URL_BASE + "api/relojes/dataUser?usuario=";
  var url1 = url + _id;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    // body: JSON.stringify(body)
  };

  return new Promise((resolve, reject) => {
    fetch(url1, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

///////////Obtener los datos libres de los BLE para hacer el Match
function getFreeBle() {
  // console.log("id de funcion",_id)
  let url =
    URL_BASE_BLE +
    "api/cadenaFrio/administracion-ble/ble/getDevicesFree?numeroDatos=5&device=ble";
  // var url1 = url + _id;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    // body: JSON.stringify(body)
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

///////////Obtener los datos libres de los PRODUCTOS para hacer el Match
function getFreeProducts() {
  // console.log("id de funcion",_id)
  let url =
    URL_BASE_BLE +
    "api/cadenaFrio/administracion-ble/ble/getDevicesFree?numeroDatos=5&device=productos";
  // var url1 = url + _id;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    // body: JSON.stringify(body)
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function getBleMatches(numero) {
  // console.log("id de funcion",_id)
  let url =
    URL_BASE_BLE +
    "api/cadenaFrio/administracion-ble/ble/getMatchedDevices?numeroDatos=" +
    numero;
  // var url1 = url + _id;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    // body: JSON.stringify(body)
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function matchProductsBle(body) {
  // console.log("id de funcion",_id)
  let url =
    URL_BASE_BLE + "api/cadenaFrio/administracion-ble/ble/matchDeviceProduct";
  // var url1 = url + _id;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    body: JSON.stringify(body),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function releaseMatch(body) {
  // console.log("id de funcion",_id)
  let url =
    URL_BASE_BLE + "api/cadenaFrio/administracion-ble//ble/releaseMatch";
  // var url1 = url + _id;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    body: JSON.stringify(body),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function adquisicionDatosProducto(producto, numeroDatos) {
  var url =
    URL_BASE_BLE +
    "api/cadenaFrio/dashboard/getDataDevice?producto=" +
    producto +
    "&&numeroDatos=" +
    Number(numeroDatos);
  return new Promise((resolve, reject) => {
    fetch(url)
      // fetch("http://172.16.2.87/api/modulos-cacao/getDevices")
      .then((res) => res.json())
      .then(
        (result) => {
          // elements = result;
          // elements = [...elements];
          // console.log('elements',result);
          // console.log(result);
          resolve(result);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
  });
}

function adquisicionAvailableProcess() {
  var url = URL_BASE_BLE + "api/cadenaFrio/dashboard/getAvailableProcess";
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
  });
}

///////////Obtener dispositivos y ubicaciones
function obtenerDispoGeneral(elemento) {
  // let url= URL_BASE + 'api/administracion-dispositivos/dispositivos/listaDispositivos';
  let url =
    URL_BASE +
    "api/administracion-dispositivos/dispositivos/getStatusDevices?organizacion=" +
    elemento.organizacion +
    "&estado=" +
    elemento.estado;
  console.log("Link", url);
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

///////////Obtener configuracion de dashboard humitemp &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& en desarrollo
function obtenerConfiguracionDashHumitemp(elemento) {
  let url = URL_BASE + "api/gpf/humitemp/dashboard/getConfigDashboard?";
  url += "&organizacion=" + elemento.organizacion;

  // + +'&estado='+elemento.estado + '&fechaInicial';
  console.log("Link 111111111111111111", url);
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => {
        if (res.status === 200) {
          console.log("SUCCESSS");
          return res.json();
        } else if (res.status === 500) {
          console.log("SOMETHING WENT WRONG");
          // this.setState({ requestFailed: true })
          return reject(res.json());
        } else {
          console.log("ERROR DESCONOCIDO");
          return reject(res.json());
        }
      })
      .then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.log(error);
          return reject(error);
        }
      );
  });
}

///////////Obtener datos dispositivos humitemp &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& en desarrollo
function obtenerDatosDispoHumitemp(elemento) {
  let url = URL_BASE + "api/gpf/humitemp/dashboard/getDataParsed?";
  url += "deviceID=" + elemento.dispositivos;
  url += "&organizacion=" + elemento.organizacion;
  url += "&estado=" + elemento.estado;
  url += "&intervalo=" + elemento.intervalo;

  if (elemento.fechaInicial && elemento.fechaFinal) {
    url += "&fechaInicio=" + new Date(elemento.fechaInicial).toISOString();
    url += "&fechaFinal=" + new Date(elemento.fechaFinal).toISOString();
  }

  console.log("Link 3333333333", url);

  // + +'&estado='+elemento.estado + '&fechaInicial';
  // console.log("Link", url)
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => {
        if (res.status === 200) {
          console.log("SUCCESSS");
          return res.json();
        } else if (res.status === 500) {
          console.log("SOMETHING WENT WRONG");
          // this.setState({ requestFailed: true })
          return reject(res.json());
        } else {
          console.log("ERROR DESCONOCIDO");
          return reject(res.json());
        }
      })
      .then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.log(error);
          return reject(error);
        }
      );
  });
}
function obtenerDatosS1(elemento) {
  let url = URL_BASE + "api/gabinetesGet/dashboard/getDataParsed?";
  url += "deviceID=" + elemento.dispositivos;
  url += "&organizacion=" + elemento.organizacion;
  url += "&estado=" + elemento.estado;

  if (elemento.fechaInicial && elemento.fechaFinal) {
    url += "&fechaInicio=" + new Date(elemento.fechaInicial).toISOString();
    url += "&fechaFinal=" + new Date(elemento.fechaFinal).toISOString();
  }

  console.log("Link 3333333333", url);

  // + +'&estado='+elemento.estado + '&fechaInicial';
  // console.log("Link", url)
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => {
        if (res.status === 200) {
          console.log("SUCCESSS");
          return res.json();
        } else if (res.status === 500) {
          console.log("SOMETHING WENT WRONG");
          // this.setState({ requestFailed: true })
          return reject(res.json());
        } else {
          console.log("ERROR DESCONOCIDO");
          return reject(res.json());
        }
      })
      .then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.log(error);
          return reject(error);
        }
      );
  });
}

///////////Obtener dispositivos humitemp &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& en desarrollo
function obtenerDispositivosHumitemp(elemento) {
  let url =
    URL_BASE +
    "api/gpf/humitemp/dashboard/getListDevices?" +
    "organizacion=" +
    elemento.organizacion +
    "&estado=" +
    elemento.estado;
  console.log("Link 222222222222222222222", url);
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}
function obtenerDispositivosS1(elemento) {
  let url =
    URL_BASE +
    "api/gabinetesGet/dashboard/getListDevices?" +
    "organizacion=" +
    elemento.organizacion +
    "&estado=" +
    elemento.estado;
  console.log("Link 222222222222222222222", url);
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}
///////////Obtener posicion dispositivos humitemp &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& en desarrollo
function obtenerPosDispositivosHumitemp(elemento) {
  let url =
    URL_BASE +
    "api/gpf/humitemp/dashboard/getPosDevices?" +
    "organizacion=" +
    elemento.organizacion +
    "&estado=" +
    elemento.estado;
  console.log("Link", url);
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}
function obtenerPosDispositivosS1(elemento) {
  let url =
    URL_BASE +
    "api/gabinetesGet/dashboard/getPosDevices?" +
    "organizacion=" +
    elemento.organizacion +
    "&estado=" +
    elemento.estado;
  console.log("Link", url);
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

///////////Obtener grupos humitemp &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& en desarrollo
function obtenerGruposHumitemp(elemento) {
  let url =
    URL_BASE +
    "api/gpf/humitemp/dashboard/getListGroups?" +
    "organizacion=" +
    elemento.organizacion +
    "&estado=" +
    elemento.estado;
  // console.log("Link", url)
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

///////////Obtener dispositivos humitemp &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& en desarrollo
function adquirirIncidentDevicesBeta(elemento) {
  let url =
    URL_BASE +
    "api/gpf/humitemp/dashboard/getIncidentDevices?" +
    "organizacion=" +
    elemento.organizacion +
    "&deviceID=" +
    elemento.deviceID +
    "&estado=" +
    elemento.estado;
  // console.log("Link", url)
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

///////////Obtener dispositivos humitemp &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& en desarrollo
function adquirirIncidentDevices(elemento) {
  let url =
    URL_BASE +
    "api/dashboard-general/alertas/getIncidents?" +
    "organizacion=" +
    elemento.organizacion +
    "&deviceID=" +
    elemento.deviceID +
    "&estado=" +
    elemento.estado;
  // console.log("Link", url)
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

///////////Obtener dispositivos humitemp &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& en desarrollo
function adquirirIncidentDevicesHumitemp(elemento) {
  let url =
    URL_BASE +
    "api/gpf/humitemp/dashboard/getIncidentDevices?" +
    "organizacion=" +
    elemento.organizacion +
    "&deviceID=" +
    elemento.deviceID +
    "&estado=" +
    elemento.estado;
  // console.log("Link", url)
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

///////////Edición dispositivos humitemp &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& en desarrollo
function edicionDispositivoHumitemp(elemento) {
  let url = URL_BASE + "api/gpf/humitemp/dashboard/setParametersDevice";
  // console.log("Link", url)
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    body: JSON.stringify(elemento),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

///////////Obtener Procesosp &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& en desarrollo
function obtenerProcesos() {
  let url = "https://aiot.constecoin.com/api/cacao/procesos/getAllProcesos";
  // console.log("Link", url)
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

///////////CREACION procesos &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& en desarrollo
function creacionProcesos(elemento) {
  let url = "https://aiot.constecoin.com/api/cacao/procesos/saveProcesosCacao";
  // console.log("Link", url)
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    body: JSON.stringify(elemento),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

///////////CREACION grupos humitemp &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& en desarrollo
function creacionGrupoHumitemp(elemento) {
  let url = URL_BASE + "api/gpf/humitemp/dashboard/createGroup";
  // console.log("Link", url)
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    body: JSON.stringify(elemento),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

///////////Edición grupos humitemp &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& en desarrollo
function edicionGrupoHumitemp(elemento) {
  let url = URL_BASE + "api/gpf/humitemp/dashboard/setParametersGroup";
  // console.log("Link", url)
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    body: JSON.stringify(elemento),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

///////////Obtener datos dispositivos humitemp &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& en desarrollo
function setDownlinkBetatemp(elemento) {
  let url = URL_BASE + "api/betatemp/wifi/setDownlink";
  // console.log("Link", url)
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    body: JSON.stringify(elemento),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => {
        if (res.status === 200) {
          console.log("SUCCESSS");
          return res.json();
        } else if (res.status === 500) {
          console.log("SOMETHING WENT WRONG", res);
          // this.setState({ requestFailed: true })
          return reject(res.json());
        } else {
          console.log("ERROR DESCONOCIDO");
          return reject(res.json());
        }
      })
      .then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.log(error);
          return reject(error);
        }
      );
  });
}

function obtenerIdDispositivos() {
  console.log("Entro para consumir los ides: ");
  let url = URL_BASE + "/api/lgtloraGet/getAllDisp";
  console.log("Link", url);
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

///////////Obtener dispositivos humitemp &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& en desarrollo
function obtenerTablaEmpresas(elemento) {
  let url =
    URL_BASE +
    "api/autenticacion/empresas/getEmpresas?" +
    "organizacion=" +
    elemento.organizacion +
    "&estado=" +
    elemento.estado;
  // console.log("Link", url)
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function obtenerDatosDispositivos(elemento) {
  console.log("El elemento llegando aqui de cantidad es: " + elemento.cantidad);
  let url =
    URL_BASE +
    "/api/lgtloraGet/getOneDisp?deviceID=" +
    elemento.deviceID +
    "&cantidad=" +
    elemento.cantidad;
  console.log("Link", url);
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function obtenerListaEmpresas(elemento) {
  let url =
    URL_BASE +
    "api/autenticacion/empresas/getListEmpresas?" +
    "organizacion=" +
    elemento.organizacion +
    "&estado=" +
    elemento.estado;
  // console.log("Link", url)
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => {
        console.log("requestOptions", res);
        return res.json();
      })
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

///////////Obtener dispositivos humitemp &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& en desarrollo
function obtenerListaUsuarios(elemento) {
  let url =
    URL_BASE +
    "/api/autenticacion/cuentas/getUsuarios?" +
    "organizacion=" +
    elemento.organizacion +
    "&estado=" +
    elemento.estado;
  // console.log("Link", url)
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function getIDsBeta() {
  var lineaIDs = [
    {
      IDp: "e868e7d69bc5",
      ID: "bcddc224da4",
      modulo: 1,
      CTL: [
        {
          linea: 4,
          num: 1,
          cajon: 10,
          variable: "T1",
        },
        {
          linea: 4,
          num: 2,
          cajon: 11,
          variable: "T2",
        },
        {
          linea: 4,
          num: 3,
          cajon: 12,
          variable: "T3",
        },
        {
          linea: 3,
          num: 1,
          cajon: 7,
          variable: "T4",
        },
        {
          linea: 3,
          num: 2,
          cajon: 8,
          variable: "T5",
        },
      ],
    },
    {
      IDp: "e868e7d71bce",
      ID: "e868e7d71fcf",
      modulo: 2,
      CTL: [
        {
          linea: 3,
          num: 3,
          cajon: 9,
          variable: "T1",
        },
        {
          linea: 2,
          num: 1,
          cajon: 4,
          variable: "T2",
        },
        {
          linea: 2,
          num: 2,
          cajon: 5,
          variable: "T3",
        },
        {
          linea: 2,
          num: 3,
          cajon: 6,
          variable: "T4",
        },
        {
          linea: 1,
          num: 1,
          cajon: 1,
          variable: "T5",
        },
      ],
    },
    {
      IDp: "e868e7d3f04c",
      ID: "d8bfc0f2bffc",
      modulo: 3,
      CTL: [
        {
          linea: 1,
          num: 2,
          cajon: 2,
          variable: "T1",
        },
        {
          linea: 1,
          num: 3,
          cajon: 3,
          variable: "T2",
        },
        {
          linea: 5,
          num: 1,
          cajon: 13,
          variable: "T3",
        },
        {
          linea: 5,
          num: 2,
          cajon: 14,
          variable: "T4",
        },
        {
          linea: 5,
          num: 3,
          cajon: 15,
          variable: "T5",
        },
      ],
    },
    {
      IDp: "e868e7d698d6",
      ID: "98f4abd98840",
      modulo: 4,
      CTL: [
        {
          linea: 6,
          num: 1,
          cajon: 16,
          variable: "T1",
        },
        {
          linea: 6,
          num: 2,
          cajon: 17,
          variable: "T2",
        },
        {
          linea: 6,
          num: 3,
          cajon: 18,
          variable: "T3",
        },
        {
          linea: 7,
          num: 1,
          cajon: 19,
          variable: "T4",
        },
        {
          linea: 7,
          num: 2,
          cajon: 20,
          variable: "T5",
        },
      ],
    },
    {
      IDp: "e868e7d698e3",
      ID: "bcddc22416ac",
      modulo: 5,
      CTL: [
        {
          linea: 7,
          num: 3,
          cajon: 21,
          variable: "T1",
        },
        {
          linea: 8,
          num: 1,
          cajon: 22,
          variable: "T2",
        },
        {
          linea: 8,
          num: 2,
          cajon: 23,
          variable: "T3",
        },
        {
          linea: 8,
          num: 3,
          cajon: 24,
          variable: "T4",
        },
      ],
    },
  ];
  return lineaIDs;
}

function obtenerDispCacao(id) {
  // console.log("ID EN UN DISP", id)
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };
  let url2 =
    "https://aiot.constecoin.com/api/cacaoData/dashboard/getOneDisp?deviceID=" +
    id;
  let url =
    URL_BASE_BLE +
    "api/modulos-cacao/dashboard/dashboard/getOneDisp?deviceID=" +
    id;
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function obtenerDispCacao2(id) {
  // console.log("ID EN UN DISP", id)
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };
  let url =
    "https://aiot.constecoin.com/api/cacaoData/dashboard/getOneDisp?deviceID=" +
    id +
    "&cantidad=1";
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function obtenerDataGabinete(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };
  let url =
    "https://aiot.constecoin.com/api/gabinetesGet/getDataBLE?mac=" +
    id +
    "&cantidad=360";
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function obtenerDataGabineteTemp(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };
  let url =
    "https://aiot.constecoin.com/api/gabinetesGet/getDataBLE?mac=" +
    id +
    "&cantidad=1";
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function obtenerDataGabineteClick(id, inicio, fin) {
  if (typeof inicio !== "number" && typeof fin !== "number") {
    console.log("inicio", JSON.stringify(inicio), "fin", JSON.stringify(fin));
    var inicioLink = Date.parse(JSON.stringify(inicio).substring(1, 23));
    var finLink = Date.parse(JSON.stringify(fin).substring(1, 23));
    var MlSeconds = 5 * 60 * 60000;
    inicioLink = inicioLink - MlSeconds;
    finLink = finLink - MlSeconds;
  } else {
    // console.log("SEGUNDO IF")
    var inicioLink = inicio;
    var finLink = fin;
  }
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  console.log(
    "fechaInicial api",
    typeof inicioLink,
    inicioLink,
    "fechaFinal api",
    typeof finLink,
    finLink
  );

  let url =
    "https://aiot.constecoin.com/api/gabinetesGet/getDataBLE2?mac=" +
    id +
    "&inicio=" +
    inicioLink +
    "&fin=" +
    finLink;
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

///////////////PARA REST DE HISTORICO/ ///////////
function obtenerHistoricoCacao(id, tipoLote, inicio, fin) {
  // console.log("ID EN HISTORICO", id)
  if (typeof inicio !== "number" && typeof fin !== "number") {
    var inicioLink = Date.parse(JSON.stringify(inicio).substring(1, 23));
    var finLink = Date.parse(JSON.stringify(fin).substring(1, 23));
  } else {
    var inicioLink = inicio;
    var finLink = fin;
  }
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };
  let url =
    "https://aiot.constecoin.com/api/cacaoData/dashboard/getDispbyDate?deviceID=" +
    id +
    "&tipoLote=" +
    tipoLote +
    "&inicio=" +
    inicioLink +
    "&fin=" +
    finLink;
  // console.log("url", url)
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}
//////////////////////////////

////////////////GET ALARMAS Y MENSAJES ALCANTARILLAS
function alcantarillasInfoAM(nombre) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };
  let url =
    "https://aiot.constecoin.com/api/alcantarillasGet/getMensajeAlcantarilla";
  let url2 =
    "https://aiot.constecoin.com/api/alcantarillasGet/getAlarmaAlcantarilla";
  var urlSend;
  if (nombre == "mensaje") {
    urlSend = url;
  } else if (nombre == "alarma") {
    urlSend = url2;
  }
  return new Promise((resolve, reject) => {
    fetch(urlSend, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function sendWhats() {
  var request = require("request");
  var options = {
    method: "POST",
    url: "https://graph.facebook.com/v13.0/100892332641253/messages",
    headers: {
      Authorization:
        "Bearer EAAFzMnZBvzDkBADTSzMMutFhhHDRTKD1mwot6rM8pN1viDRTJs2GqLXSSfWA7Hah8HKwP3iL0JfKvxgviJQ6Ay7ewmZBzFy14hM2qKErJg87ZBAbVbQyCZCW0gSupqK4K2b7Cfe9JtVZCO3yU4hAzZCsKL2LaZAd6RwHSofybyRxNx9GyndoD5XRWfoo6Uk0RC54nJ7FfRyKwZDZD",
      "Content-Type": "application/json",
    },
    body: '{ "messaging_product": "whatsapp", "to": "593961266100", "type": "template", "template": { "name": "hello_world", "language": { "code": "en_US" } } }',
  };

  request(options, function (error, response) {
    if (error) throw new Error(error);
    console.log(response.body);
  });
}

//////DE CONSUMO BLE.js //////////////////////////////////////////////
function peticionGet(url) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}
//////////////////////////////////////////////

function obtenerDatosScanner() {
  console.log("Entro a obtener los datos Scanner: ");
  let url = URL_BASE + "/api/lgtloraGet/getAllDisp";
  console.log("Link", url);
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

///////////////////////////////////Alcantarilla/////////////////////////////
function saveDevice(id, lat, leng, type, _utc) {
  let url = URL_BASE + "api/alcantarillas/create";
  var b = {
    deviceID: id,
    coordenadas: { lat: lat, lng: leng },
    tipo: type,
    utc: _utc,
    organizacion: "ALCANTARILLAS",
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    body: JSON.stringify(b),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}
function saveWt32Device(id, lat, leng, type, _utc, organizacion, nombre) {
  let url = URL_BASE + "api/wt32/dashboard/create";
  var b = {
    deviceID: id,
    nombre: nombre,
    coordenadas: { lat: lat, lng: leng },
    tipo: type,
    utc: _utc,
    organizacion: organizacion,
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    body: JSON.stringify(b),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}
function consultaHoras(consulta) {
  let url = URL_BASE + "api/gpf/humitemp/dashboard/getReport";
  var b = {
    devices: consulta.arregg,
    horas: consulta.horass,
    fecha: consulta.fecha,
    tipoDevice: consulta.tipo
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    body: JSON.stringify(b),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("ESTO DEVUELVE");
          console.log(result);
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function saveGabinetes(id, data) {
  let url = "https://aiot.constecoin.com/api/datacenter/createGabinete";
  var b = {
    gabineteID: id,
    organizacion: "Data Center",
    dispositivos: data,
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    body: JSON.stringify(b),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function getAllDevices() {
  let url = URL_BASE + "api/alcantarillas/getAllDisp";
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions).then(
      (result) => {
        console.log(result);
        resolve(result);
      },
      (error) => reject(error)
    );
  });
}

function updateDevice(id, lat, leng) {
  let url = URL_BASE + "api/alcantarillas/editDevice";
  var b = { deviceID: id, coordenadas: { lat: lat, lng: leng } };
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    body: JSON.stringify(b),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}
function updateDeviceWT32(id, organizacion, lat, leng, nombre) {
  let url = "https://aiot.constecoin.com/api/wt32/dashboard/editDevice";
  var b = {
    deviceID: id,
    coordenadas: { lat: lat, lng: leng },
    organizacion: organizacion,
    nombre: nombre,
  };
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    body: JSON.stringify(b),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function getDeviceById(id) {
  let url = URL_BASE + "api/alcantarillas/getOneDevice?deviceID=" + id;
  console.log(url);
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}
function getDeviceWt32ById(id, Cmostrar, pagina) {

  // let url = "http://localhost:20020/getOneDevice"
  let url = URL_BASE + "api/wt32/dashboard/getOneDevice";
  var b = {
    mac: id,
    mostrar: Cmostrar,
    pagina: pagina
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    body: JSON.stringify(b),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });

}

function getDeviceByPartialId(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };
  let url =
    "https://aiot.constecoin.com/api/alcantarillas/getFindOne?deviceID=" + id;
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}
function getDeviceWt32Id(id, organizacion, tipo) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };
  let url =
    "https://aiot.constecoin.com/api/wt32/dashboard/getFindOne?deviceID=" +
    id +
    "&organizacion=" +
    organizacion + "&tipo=" + tipo;
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function getGabineteByPartialId(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };
  let url =
    "https://aiot.constecoin.com/api/datacenter/getFindOne?gabineteID=" + id;
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function enviardatosWHatsapp(gabinete, temperatura, humedad, acceso, energia) {
  console.log("Entrnado a enviar");
  let url = "https://aiot.constecoin.com/api/notificationWhatsapp/sendWhatsapp";
  if (acceso == 1) {
    acceso = "Abierto";
  } else {
    acceso = "Cerrado";
  }
  if (energia == 1) {
    energia = "Encendido";
  } else {
    energia = "Apagado";
  }

  var infor = [gabinete, temperatura, humedad, acceso, energia];
  var b = {
    template: "notificacion_datecenter",
    data: infor,
    phones: [
      {
        number: "593983498106",
      },
      {
        number: "593990355059",
      },
    ],
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    body: JSON.stringify(b),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function getTemplateData(company) {
  let url = "http://localhost:55645/getTemplate";
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    body: JSON.stringify({ company }),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function saveImages(images) {
  let url = "http://localhost:55645/saveImage";
  console.log(images);
  const requestOptions = {
    method: "POST",
    body: images,
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function obtenerTodosGateway() {
  console.log("Entro para consumir los ides: ");
  let url = "https://aiot.constecoin.com/api/gateway/getAllGateway";
  console.log("Link", url);
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function ObtenerDatosWategay(elemento) {
  console.log(
    "El elemento llegando aqui de cantidad es: " + elemento.IdGateway
  );
  let url =
    "https://aiot.constecoin.com/api/gateway/getOneGateway?IdGateway=" +
    elemento.IdGateway;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function ObtenerDispositivosDataCenter() {
  let url = "https://aiot.constecoin.com/api/datacenter/getAll";
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}
function ObtenerDispositivosHumi(tipo) {
  let url = "https://aiot.constecoin.com/api/gpf/humitemp/dashboard/getDevice?tipo=" + tipo;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}
function EliminarWT32(device) {
  let url = "https://aiot.constecoin.com/api/wt32/dashboard/deleteOne";
  var b = { deviceID: device };
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
    body: JSON.stringify(b),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}
function obtenerUsuarios(rol, organizacion) {
  let url = `https://aiot.constecoin.com/api/autenticacion/cuentas/getUser?organizacion=${organizacion}&rol=${rol}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}
function obtenerUsuariosIndividual(user) {
  let url = `https://aiot.constecoin.com/api/autenticacion/cuentas/getDataUser?user=${user}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function actualizarCuentaUsuario(
  id,
  usuario,
  nombre,
  estado,
  rol,
  email,
  password,
  validacionNombre
) {
  var b = {
    id: id,
    validacionNombre: validacionNombre,
    usuario: usuario,
    nombre: nombre,
    estado: estado,
    rol: rol,
    email: email,
    password: password,
  };

  let url = "https://aiot.constecoin.com/api/autenticacion/cuentas/editUser";
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(b),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (respuesta) => {
          resolve(respuesta);
        },
        (error) => reject(error)
      );
  });
}
function eliminarUsuario(id) {
  var b = {
    id: id,
  };

  let url = "https://aiot.constecoin.com/api/autenticacion/cuentas/deleteUser";
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(b),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (respuesta) => {
          resolve(respuesta);
        },
        (error) => reject(error)
      );
  });
}
function crearUsuario(postUsuario, postPassword, postEmail, postHabilitaciones, postOrganizaciones, postRol, postNombre, postEstado) {
  var b = {
    usuario: postUsuario,
    password: postPassword,
    email: postEmail,
    habilitaciones: postHabilitaciones,
    organizaciones: postOrganizaciones,
    rol: postRol,
    nombre: postNombre,
    estado: postEstado
  };

  console.log("888888888888888888888888888888888888888888")
  console.log(b)

  let url = "https://aiot.constecoin.com/api/autenticacion/cuentas/createuser";
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(b),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (respuesta) => {
          resolve(respuesta);
        },
        (error) => reject(error)
      );
  });
}

function obtenerTESTING(postRol, kobteOrganizacion) {
  var b = {
    catalogo: postRol,
    organizacion: kobteOrganizacion
  };

  console.log("888888888888888888888888888888888888888888")
  console.log(b)

  let url = "https://aiot.constecoin.com/api/alertas/obtenerValores";
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(b),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (respuesta) => {
          resolve(respuesta);
        },
        (error) => reject(error)
      );
  });
}


function crearAlerta(b) {


  console.log("888888888888888888888888888888888888888888")
  console.log(b)

  let url = "https://aiot.constecoin.com/api/alertas/crearAlerta";
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(b),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (respuesta) => {
          resolve(respuesta);
        },
        (error) => reject(error)
      );
  });
}

function getAlertas(id, organizacion) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };
  let url =
    "https://aiot.constecoin.com/api/alertas/getAlertas?deviceID=" +
    id +
    "&organizacion=" +
    organizacion;
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}

function getAlertOne(id, organizacion, estado, tipo) {
  let url = URL_BASE + "api/alertas/getAlertasOne?id=" + id + "&organizacion=" + organizacion + "&state=" + estado + "&type=" + tipo;
  console.log(url);
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}


function obtenerDIspoGPF(Data) {
  let url = `https://aiot.constecoin.com/api/gabinetesGet/getDevices?organizacion=${Data.organizacion}&tipo=${Data.tipo}`;
  console.log(url);
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJWT(),
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => reject(error)
      );
  });
}


function tablaTemperaturasGPF(Data) {
  let url = "https://aiot.constecoin.com/api/gabinetesGet/getInDates";

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(Data),
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (respuesta) => {
          resolve(respuesta);
        },
        (error) => reject(error)
      );
  });
}



export default {
  login,
  adquirirDevices,
  ObtenerDispositivosDataCenter,
  adquirirDevicesConected,
  ObtenerDatosWategay,
  estadoServicio,
  adquisicionConectedDevices,
  adquirirDevicesGPSTracker,
  adquirirDevicesBeta,
  adquirirIncidentDevicesBeta,
  adquirirIncidentDevicesHumitemp,
  adquirirUnGPSTracker,
  crearNuevoDispo,
  updDispoGPS,
  lastReloj,
  obtenerDispoGeneral,
  creacionProcesos,
  obtenerProcesos,
  creacionGrupoHumitemp,
  edicionGrupoHumitemp,

  obtenerGruposHumitemp,

  ////////////////////////////////////
  getFreeBle,
  getFreeProducts,
  matchProductsBle,
  getBleMatches,
  enviardatosWHatsapp,
  releaseMatch,

  adquisicionDatosProducto,
  adquisicionAvailableProcess,
  adquirirAlertasDevices,
  setStateAlert,

  actualizacionDatosUsuario,

  obtenerDatosDispoHumitemp,
  obtenerDispositivosHumitemp,
  adquirirIncidentDevices,
  edicionDispositivoHumitemp,
  obtenerPosDispositivosHumitemp,

  setDownlinkBetatemp,
  saveGabinetes,

  registro,
  obtenerListaEmpresas,
  obtenerTablaEmpresas,
  obtenerListaUsuarios,

  registroEmpresa,
  actualizacionParametrosEmpresa,

  actualizacionConfiguracionEmpresa,
  actualizacionParametrosUsuario,
  obtenerUsuario,

  adquirirConfiguracionesEmpresa,

  obtenerConfiguracionDashHumitemp,

  getIDsBeta,
  peticionGet,
  obtenerDispCacao,
  obtenerDispCacao2,
  obtenerHistoricoCacao,
  obtenerTodosGateway,
  getGabineteByPartialId,
  alcantarillasInfoAM,
  sendWhats,
  obtenerDataGabinete,
  obtenerDataGabineteTemp,
  obtenerDataGabineteClick,
  obtenerDatosDispositivos,
  obtenerIdDispositivos,
  obtenerDatosScanner,

  saveDevice,
  getAllDevices,
  updateDevice,
  getDeviceById,
  getDeviceByPartialId,

  obtenerDatosS1,
  obtenerDispositivosS1,

  getTemplateData,
  saveImages,
  obtenerPosDispositivosS1,
  getDeviceWt32Id,
  getDeviceWt32ById,

  getTemplateData,
  saveImages,
  saveWt32Device,
  updateDeviceWT32,
  consultaHoras,
  ObtenerDispositivosHumi,
  EliminarWT32,
  obtenerUsuarios,
  obtenerUsuariosIndividual,
  actualizarCuentaUsuario,
  eliminarUsuario,
  crearUsuario,
  obtenerTESTING,
  crearAlerta,
  getAlertas,
  getAlertOne,
  obtenerDIspoGPF,
  tablaTemperaturasGPF
};
