import React from "react";
import ReactApexChart from "react-apexcharts";

import tempe from "../graficos/imagenes/indicadortem.png";
import { Card } from "react-bootstrap";

class radialbartemp extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
      
        series: this.props.seriesData,
        options: {
          chart: {
            height: 100,
            type: 'radialBar',
          },
          plotOptions: {
            background: "#e7e7e7",
            radialBar: {
             
              hollow: {
                margin: 105,
                size: '70%',
                image: tempe,
                imageWidth: 45,
                imageHeight: 45,
                imageClipped: false
              },
              dataLabels: {
                name: {
                  show: false,
                  color: '#333',
                  offsetY: -25,
                },
                value: {
                  show: true,
                  color: '#333',
                  offsetY: 45,
                  fontSize: '22px',
                  formatter: function (val) {
                    return val
                  }
                }
              }
            }
          },
         
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark', //light
              gradientToColors: [ '#e33636'],
              shadeIntensity: 1,
             // type: 'vertical',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100]
            },
          },
          
          stroke: {
            lineCap: 'round'
          },
          labels: ['Temperatura'],
        },
      
      
      };

      this.setState({series : this.props.seriesData})
    }

  

    render() {
      return (
    <>    
<Card>
  <div id="chart" >
    <ReactApexChart options={this.state.options} series={this.props.seriesData} type="radialBar" height={220}/>
    </div></Card>
  </>
  );
  }
}
export default radialbartemp;