import React, { useEffect, useState } from "react";
import Api from "./../../services/api-service";
import SearchIcon from "@material-ui/icons/Search";
import InputBusqueda from "./../../components/Table/inputBusqueda";
import Button from "../../components/Button/index";
import Auth from "../../services/auth-service.js";
import { useLocation } from "react-router-dom";
import {
  BarraBusqueda,
  BotonCrear,
  ContenedorTabla,
  ContenedorTitulo,
  Main,
  NavbarTabla,
  Th,
  Titulo,
  Tr,
  TablaDispo,
  TrC,
  IconoLupa,
  ContenedorDis,
  Nombredispositivo,
  DescripcionDispo,
  Organizacion,
  Tipo,
  ContenedorFecha,
  Tiempo,
  Fecha,
  BotonMostrar,
  BotonDatos,
} from "./AlertasElements";

const Index = () => {
  const [results, setResults] = useState([]);
  const [text, setText] = useState("");
  const [postRol, setRol] = useState("administrador");
  const query = new URLSearchParams(useLocation().search);
  const obteOrganizacion = query.get("organizacion");


  useEffect(() => {


    Api.getAlertas(text, obteOrganizacion).then(
      (result) => {
        console.log("first")
        console.log("first")
        console.log("first")
        console.log("first")
        result.map((index) =>
          console.log(index._id)
        )
        console.log("first")
        console.log("first")
        console.log("first")
        console.log("first")
        setResults(result);
      },
      (error) => {
        console.log("Obtencion erronea");
      }
    );
  }, [text]);

  return (
    <>
      <Main>
        <ContenedorTitulo>
          <Titulo>Alertas</Titulo>
        </ContenedorTitulo>

        <ContenedorTabla>
          <NavbarTabla>
            <BarraBusqueda>
              <IconoLupa>
                <SearchIcon />
              </IconoLupa>
              <InputBusqueda setText={setText} text={text} />
            </BarraBusqueda>
            {postRol == "usuario" || postRol == "visualizador" ? null : (
              <Button
                url={`/admin/createAlert?organizacion=${obteOrganizacion}`}
                message="Crear Nuevo"
                style={BotonCrear}
              />
            )}
          </NavbarTabla>
          <TablaDispo>
            <Tr>
              <Th>Nombre</Th>
              <Th>Estado</Th>
              <Th>Acciones</Th>
            </Tr>
            {results.length > 0 ? (
              results.map((device, i) => (
                <TrC>
                  <td>
                    <Nombredispositivo>{device.nombre}</Nombredispositivo>
                  </td>

                  <td>
                    <Organizacion>{device.organizacion}</Organizacion>
                  </td>
                  <td>
                    <Button
                      url={`/admin/viewAlert?id=${device._id}&organizacion=${obteOrganizacion}&view=one`}
                      message="Mostrar Datos"
                      style={BotonDatos}
                    />
                  </td>
                </TrC>
              ))
            ) : (
              <div>No hay resultados</div>
            )}
          </TablaDispo>
        </ContenedorTabla>
      </Main>
    </>
  );
};

export default Index;
