import React, { Component } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";

import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// import AdminNavbar from "components/Navbars/AdminNavbar";
// import Footer from "components/Footer/Footer";
import Sidebar from "../components/Sidebar/Sidebar.js";
import Top from "../components/Top-admin/Top.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import SidebarMenu from "./sidebarMenu/SidebarMenu";

import './Admin.css';

import routes from "../routes";
import { menu, menuUser } from "../menu";

import sidebarImage from "../assets/img/sidebar-2.jpg";


import Auth from '../services/auth-service.js'
import { MenuBookSharp } from "@material-ui/icons";


// notificaciones 
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';


function showNotification() {

  console.log("ok")
  // store.addNotification({
  //   title: "Wonderful!",
  //   message: "teodosii@react-notifications-component",
  //   type: "success",
  //   insert: "top",
  //   container: "bottom-right",
  //   animationIn: ["animate__animated", "animate__fadeIn"],
  //   animationOut: ["animate__animated", "animate__fadeOut"],
  //   dismiss: {
  //     duration: 5000,
  //     onScreen: true
  //   }
  // });
}

// export showNotification; 


function Admin() {
  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const location = useLocation();
  const mainPanel = React.useRef(null);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {

        // <Route path="/admin" render={(props) => (Auth.getJWT() !== null? <AdminLayout {...props} /> : <Redirect from="/" to="/login/ingreso" />)} />
        // console.log('Auth.getJWT()', Auth.getJWT())
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => (Auth.getJWT() !== null ? <prop.component {...props} /> : <Redirect from="/" to="/login/ingreso" />)}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);
  return (
    <>
      <Top />
      <div className="wrapper">
        <ReactNotification />
        <SidebarMenu menu={menu} menuUser={menuUser} />
        <div className="main-panel" ref={mainPanel}>
          <div className="content">
            <Switch>{getRoutes(routes)}</Switch>
          </div>
        </div>
      </div>
    </>
  );
}

export default Admin;
export { showNotification };
