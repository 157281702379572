import React, { Component } from "react";
import './ListaDispositivosGeneral.css';

class ShowList2 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      encabezado: ["Lista de dispositivos:"],
    };
  }

  renderTableHeader = () => {
    return Object(
    this.state.encabezado).map(attr =>
    <th key={attr}> {attr.toUpperCase()} </th>
    )
  }

  renderTableRows = () => {
    return this.props.list.map(user => {
      return (
        <tr key={user.id}>
          <td className="Tabla">{user}</td>
        </tr>
      )
    })
  }

  render() {
    return (
      <div class="alert alert-secondary span2" role="alert">
        <table>
          <thead>
            <tr>
              {this.renderTableHeader()}
            </tr>
          </thead>
          <tbody>
            {this.renderTableRows()}
          </tbody>
        </table>
      </div>
    );
  }
}
export default ShowList2;
