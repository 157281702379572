import React,{Component} from "react";
import { Redirect } from "react-router-dom";
import DateTimePicker from '@mui/lab/DateTimePicker';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';


import {
 
  Container,
  Row,
  Col,
  Button
} from "react-bootstrap";

import "./Dashboard.css";

// import Lineas from "./graficos/Lineas.js";
//import LineasZomable from  "./graficos/Lineas-zomable.js";
import LineasArea from  "./graficos/Lineas-area.js";
 
import Api from '../../services/api-service.js'
import Auth from '../../services/auth-service.js'


import Bateria from "./graficos/guageBarritas.js"
import RadioHum from "./graficos/indicadorHumedad"
import RadioTem from "./graficos/indicadorTemperaura"

// notificaciones 
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';

import * as qs from 'qs'



function showNotification(titulo, mensaje, tipo ){

  store.addNotification({
    title: titulo,
    message: mensaje,
    type: tipo,
    insert: "top",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: false
    }
  });

}

class DashboardMonitorizacion extends React.Component {

  intervalID = 0;

  

  constructor (props) {
    super(props);
    // this.updateCharts = this.updateCharts.bind(this);

    let fechaActual = new Date();
    let fechaInicial = fechaActual;
    fechaInicial.setTime(fechaInicial.getTime() - 1000 * 60* 60*24* 1)

    let x1 = this.props.location.search;
    console.log('this.props.location.search',this.props.location.search)
    x1 = x1.replace("?organizacion=", "");

    var query_string = this.QueryStringToJSON(this.props.location.search);
    console.log('query_string',query_string);

    this.state = {

      fechaReporte: new Date(),

      fechaInicial: fechaInicial,
      fechaFinal: new Date(),


      selectedDevice: '',
      selectedOrganizacion: x1,
      organizaciones: [],

      dispositivoSeleccionado : '505663077429696',
      devicesTable :  [],

      dataTemperatura: [0],
      dataHumedad: [0],
      dataBateria: [0],

      limitesHumedad : [10,110],
      limitesTemperatura : [0,80],

      dataLineas:
        [{
          name: 'temperatura',
          data: [0,0,0,0]
        }],

        dataHumedadLineas:
        [{
          name: 'humedad',
          data: [0,0,0,0]
        }],
                
      // datos: [1,2,50,30],
      open:  false,

      ///////////////////// temperatura  ////////////////////
      optionsTemperatura: {
        chart: {
          height: 350,
          type: 'area'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        yaxis: {
          // tickAmount: 5,
          min: 0,
          max: 80
        },
        xaxis: {
          type: 'datetime',
          min: fechaInicial.getTime(),
          tickAmount: 6,
        },
        // xaxis: {
        //   tickAmount: 10,

        // //   type: 'datetime',
        // //   categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
        // },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
      },


      ////////////////// humedad ////////////////////
      optionsHumedad: {
        chart: {
          height: 350,
          type: 'area'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        yaxis: {
          // tickAmount: 5,
          min: 10,
          max: 110
        },
        xaxis: {
          type: 'datetime',
          min: fechaInicial.getTime(),
          tickAmount: 6,
        },
        // xaxis: {
        //   tickAmount: 10,

        // //   type: 'datetime',
        // //   categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
        // },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
      },


    };   


    


  }

  componentWillMount(){
    
    let x1 = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    console.log(x1);
    if(x1.organizacion){
      this.state.selectedOrganizacion = x1.organizacion;
    }

    Api.obtenerConfiguracionDashHumitemp({organizacion: this.state.selectedOrganizacion}).then(
      result=> {
        console.log('obtenerConfiguracionDashHumitemp',result)
        if(result[0].dashboards.limitesHumedad){
          // this.setState({limitesHumedad: result[0].dashboards.limitesHumedad})
          this.updateOptionsHumedad('yaxis', {min: result[0].dashboards.limitesHumedad[0], max: result[0].dashboards.limitesHumedad[1]} )
        }
        if(result[0].dashboards.limitesTemperatura){
          this.updateOptionsTemperatura('yaxis', {min: result[0].dashboards.limitesTemperatura[0], max: result[0].dashboards.limitesTemperatura[1]} )
        }
      }, error =>{
        console.log(error);
      }
    )





        // adquision de un solo dato 
        Api.obtenerDispositivosHumitemp({organizacion: this.state.selectedOrganizacion, estado: "all"}).then(
          result=>{
            console.log(result);
            this.setState({devicesTable: result})
    
            if(!x1.device){
              this.state.selectedDevice = result[0].deviceID;
              
            } else {
              this.setState({ selectedDevice: x1.device });
              this.selectedDevice = x1.device;
            }

            this.actualizacionDatos();
        
            this.intervalID = setInterval(()=>{
              this.actualizacionDatos();
            }, 60000);

          }, error=>{
            console.log('error en la adquisicion de datos');
          }
        )
    

  }



  actualizacionDatos(){
    Api.obtenerDatosDispoHumitemp({dispositivos: this.state.selectedDevice, organizacion: this.state.selectedOrganizacion ,
       estado: "all", fechaInicial: this.state.fechaInicial, fechaFinal: this.state.fechaFinal}).then(
      async result=>{
        console.log('obtenerDatosDispoHumitemp', this.state.selectedDevice, result);

        if(result.length===0){
          return;
        }

        this.mostrarNotificacion(this.state.selectedDevice);


        this.setState({tablaDatos: result})

        let fechaAux =  new Date(result[0].fechaCompleta.toString());
        fechaAux = new Date(fechaAux.toISOString().slice(0, fechaAux.toISOString().indexOf('.')));

        // fechaAux.setTime(fechaAux.getTimezoneOffset()+ fechaAux.getTime()) 

        // actualizacion de hora
        console.log('fechaReporte', (new Date()).toISOString(), new Date(result[0].fechaCompleta.toString()).toISOString(), result[0].fechaCompleta.toString())

        this.setState({fechaReporte: fechaAux})
        // this.setState({fechaReporte: new Date(result[result.length-1].fechaCompleta)})


        
        // this.setState({tablaFiltrada: result})

        let datosTambiente = await result.map(item=>{
          // console.log(item)
          item.voltajePorcentual = this.calculoPorcentualBateria(item.Vbat);
          // return [item.fechaCompleta, item.Tambiente];
          return [item.timestamp, item.Tambiente];
        })
        let datosHambiente = await result.map(item=>{
          // item.voltajePorcentual = this.calculoPorcentualBateria(item.Vbat);
          return  [item.timestamp, item.Hambiente];
        })



        // let datosTexterna1 = result.map(item=>{
        //   return item.Texterna1;
        // })
        // let datosTexterna2 = result.map(item=>{
        //   return item.Texterna2;
        // })

        // console.log(datosTambiente)

        this.setState({dataLineas:
          [
            {
            name: 'Temperatura Ambiente',
            data: datosTambiente
            },
        ]
        })
        this.setState({dataHumedadLineas:
          [
            {
            name: 'Humedad relativa',
            data: datosHambiente
            },
        ]
        })
        
        console.log( 'result[0].Tambiente', datosTambiente)

        this.setState({dataTemperatura:
          [
            result[0].Tambiente, 
          ]
        })
        
        this.setState({dataHumedad:
          [
            result[0].Hambiente, 
          ]
        })
        
        this.setState({dataBateria:
          [
            result[0].voltajePorcentual
          ]
        })

      }, error=>{
        console.log('error en la adquisicion de datos');
      }
    )

    this.actualizarOrganizaciones();

   
  }

  async actualizarOrganizaciones(){
    let auxOrganizaciones = await Auth.getOrganizaciones();

    if(!auxOrganizaciones){
      this.setState({organizaciones: []})
      console.log('auxOrganizaciones',auxOrganizaciones)
    } else {

      this.setState({organizaciones: auxOrganizaciones})
      console.log('auxOrganizaciones',this.state.organizaciones)
    }
  }



  handleChangeProcess = (evento)=>{


    let auxValue = evento.target.value
    //console.log('cambio de proceso', auxValue);


    this.setState({dispositivoSeleccionado: auxValue})


  }

  handleChangeOrganizacion = (event) => {
    this.state.selectedOrganizacion = event.target.value;
    // this.actualizarDataDevices();
  }
  

  handleChangeDevice = (event) => {
    this.setState({ selectedDevice: event.target.value });
    this.state.selectedDevice = event.target.value;
    this.actualizacionDatos();


    // console.log(event.target.value)
    // this.filtroTabla(this.state.tablaDatos, event.target.value);
  }; 

  handleChangeFechaInicial = (event) =>{
    console.log(event)
    this.setState({fechaInicial: new Date(event)})
    // this.state.fechaInicial = event.target.value;
    // this.actualizarDataDevices();
  }
  handleChangeFechaFinal = (event) =>{
    console.log(event)
    this.setState({fechaFinal: new Date(event)})

    // this.state.fechaInicial = event.target.value;
    // this.actualizarDataDevices();
  }

  handleClick = (event) =>{
    console.log(event)
    // this.setState({fechaFinal: new Date(event)})

    // this.state.fechaInicial = event.target.value;

    // let auxOptionsTemperatura = this.state.optionsTemperatura;
    // auxOptionsTemperatura.xaxis.min = this.state.fechaInicial.getTime();



    // let auxOptionsTemperatura = {
    //   chart: {
    //     height: 350,
    //     type: 'area'
    //   },
    //   dataLabels: {
    //     enabled: false
    //   },
    //   stroke: {
    //     curve: 'smooth'
    //   },
    //   yaxis: {
    //     // tickAmount: 5,
    //     min: this.state.limitesTemperatura[0],
    //     max: this.state.limitesTemperatura[1]
    //   },
    //   xaxis: {
    //     type: 'datetime',
    //     min: this.state.fechaInicial.getTime(),
    //     max: this.state.fechaFinal.getTime(),
    //     tickAmount: 6,
    //   },
    //   // xaxis: {
    //   //   tickAmount: 10,

    //   // //   type: 'datetime',
    //   // //   categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
    //   // },
    //   tooltip: {
    //     x: {
    //       format: 'dd/MM/yy HH:mm'
    //     },
    //   },
    // }

    this.updateOptionsTemperatura('xaxis',  {
      type: 'datetime',
      min: this.state.fechaInicial.getTime(),
      max: this.state.fechaFinal.getTime(),
      tickAmount: 6,
    })

    
    this.updateOptionsHumedad('xaxis',  {
      type: 'datetime',
      min: this.state.fechaInicial.getTime(),
      max: this.state.fechaFinal.getTime(),
      tickAmount: 6,
    })
    // this.state.optionsTemperatura = auxOptionsTemperatura;
    // this.setState({optionsTemperatura: auxOptionsTemperatura})
    // this.setState({optionsHumedad: auxOptionsTemperatura})

    // console.log(this.state.optionsTemperatura)
    this.actualizacionDatos();
  }


  updateOptionsTemperatura(parameter, value){
    let auxOptionsTemperatura = {...this.state.optionsTemperatura};
    auxOptionsTemperatura[parameter] = value;
    this.state.optionsTemperatura = auxOptionsTemperatura;

    this.setState({optionsTemperatura: auxOptionsTemperatura})
    // console.log('updateOptionsTemperatura', 'renderizacion ok', auxOptionsTemperatura);
  }

  updateOptionsHumedad(parameter, value){
    let auxOptionsumedad = {...this.state.optionsHumedad};
    auxOptionsumedad[parameter] = value;
    this.state.optionsHumedad = auxOptionsumedad;

    this.setState({optionsHumedad: auxOptionsumedad})
    // console.log('updateOptionsTemperatura', 'renderizacion ok', auxOptionsTemperatura);
  }





  
  filtroTabla(tabla, dispositivo){
  
    const result = tabla.filter(item => {
      // console.log(item);
      let auxDispositivo = false;


      //////////////////////////////////////////////   TABLA  ///////////////////////////////
      if(dispositivo === 'all'){
        auxDispositivo = true;
      }
      if(dispositivo === null){
        auxDispositivo = true;
      }
      else {
        if(item.deviceID === dispositivo){
          auxDispositivo = true;
        }
      }
      return (auxDispositivo );
    });

    this.setState({tablaFiltrada: result})

  }






  calculoPorcentualBateria(voltajeX100){

    let voltaje = voltajeX100 / 100;

    let Vmax = 3.32;
    let Vmin = 2.45;

    let Pmin = 10;
    let Pmax = 100;


    if(voltaje >= Vmax){
      voltaje = Vmax;
    }

    if(voltaje <= 0){
      voltaje = 0;
    }


    let Vporcentaje = ((Pmax - Pmin) / (Vmax - Vmin))*(voltaje-Vmin) + Pmin;

    return Vporcentaje.toFixed(2); 
  }
  

      
  mostrarNotificacion(device){
    showNotification('Selección: '+device, 'Datos actualizados', 'success');
    console.log('textos');

  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  QueryStringToJSON(location) {            
      var pairs = location.slice(1).split('&');
      
      var result = {};
      pairs.forEach(function(pair) {
          pair = pair.split('=');
          result[pair[0]] = decodeURIComponent(pair[1] || '');
      });

      return JSON.parse(JSON.stringify(result));
  }


  render() {

    return (
      <>
        <Container fluid className="margin">
        <Row >
        <div className="col-lg-3">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="Fecha Inicial"
              value={this.state.fechaInicial}
              onChange={this.handleChangeFechaInicial}
            />
          </LocalizationProvider>
        </div>

        <div className="col-lg-3">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label="Fecha Final"
                value={this.state.fechaFinal}
                onChange={this.handleChangeFechaFinal}
              />
            </LocalizationProvider>
        </div>
        <Col md="2">
          <Button className="buttonGeneral" onClick={this.handleClick}>Buscar en Rango</Button>
        </Col>
      </Row>
          <Row>
          
            <div className="app row">
              <div className="col-md-3">
              </div>
              <h1 className="texto-titulo titulo-card titulo-pagina col-md-6">Dashboard</h1>
              <div className="col-md-3">
              </div>
              <Row>
              {/* <Col md="2">
                <div class="selectores">
                  <label for="organizaciones">Organizaciones:</label>
                  <select id="organizaciones" onChange={this.handleChangeOrganizacion}>
                    <option selected value="all">Todos</option>
                    {this.state.organizaciones.map(( item, index ) => {
                      console.log('this.state.organizaciones', item)
                              return (
                                <option value={item.organizacion}>{item.organizacion}</option>
                              )
                            })}
                  </select>
                </div> 
              </Col> */}
              
              <Col md="2">
                <div className="selectores">
                  <label for="dispositivos">Dispositivos:</label>
                  <select id="dispositivos" value={this.state.selectedDevice} onChange={this.handleChangeDevice}>
                  {/* <option selected value="all">Todos</option> */}
                    {this.state.devicesTable.map(( item, index ) => {
                        return (
                          <option key={index} value={item.deviceID}>{(item?.nombre)? item?.nombre: item?.deviceID}</option>
                        )
                      })} 
                  </select>
                </div> 
              </Col>
              <Col md="6">
              </Col>
              <Col className=" titulo-pagina horaReporte" md="2">
                Reporte: {this.state.fechaReporte.toLocaleTimeString()}
              </Col>
              <div className="col-lg-4">
                <h3>Temperatura</h3>
                <RadioTem seriesData = {this.state.dataTemperatura}/>
              </div>
              <div className="col-lg-4">
                <h3>Humedad</h3>
                <RadioHum seriesData = {this.state.dataHumedad}/>
              </div>
              <div className="col-lg-4">
                <h3>Bateria</h3>
                <Bateria seriesData = {this.state.dataBateria}/>
              </div>
              <Col md="12">
                <h2 className=" titulo-pagina">Gráficas</h2>
              </Col>
              <Col md="6">
                <h3>Temperatura</h3>
                <LineasArea seriesData = {this.state.dataLineas} options={this.state.optionsTemperatura}/>
              </Col>
              <Col md="6">
              <h3>Humedad</h3>
                <LineasArea seriesData = {this.state.dataHumedadLineas} options={this.state.optionsHumedad}/>
              </Col>
              {/* <Col md="6">
                <LineasArea seriesData = {this.state.seriesData}/>
              </Col> */}
          

              </Row>

            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default DashboardMonitorizacion ;





