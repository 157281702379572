import React from "react";
import logo from "../../../assets/img/epconst/epconst.png";

import {
  Main,
  ContenedorTitulo,
  ContenedorEncabezado,
  Imagen,
  BotonesDerecha,
  BotonesIzquierda,
  ContendorBotones,
  Tabla,
  Td,
  Th,
  ParaSelect,
  LabelSelect,
  Tr,
  Divfiltro,
  CoFiltroIn,
  SelectFiltro,
  Trc,
  BotonModal,
  TituloModal,
  Mapaa,
  CoFiltroInD,
  DivfiltroD,
  SelectFiltroD,
  ContenedorImagenes,
  Imagen1,
} from "./ElementsAproca";

const EPCONST = () => {
  return (
    <>
      <Main>
        <ContenedorImagenes>
          <Imagen1 src={logo} alt="MDN" />
        </ContenedorImagenes>
        <ContenedorEncabezado>
          {/* <ContenedorTitulo>MONITOREO DEL DATA CENTER</ContenedorTitulo> */}
        </ContenedorEncabezado>
        {/* <ContenedorImagenes>
          <Imagen src={header} />
        </ContenedorImagenes> */}
      </Main>
    </>
  );
};

export default EPCONST;
