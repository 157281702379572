import React from "react";
import { InputBusqueda } from "./ElementosInput";

const index = ({ setText, text }) => {
  return (
    <>
      <InputBusqueda
        placeholder="Busqueda..."
        type="text"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
    </>
  );
};

export default index;
