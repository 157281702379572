import React, { Component } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper, Circle } from 'google-maps-react';

import './estiloform.css'


class EdicionMapa extends Component {
  constructor(props) {

    let marcadores = [];
    let initialCenter = {};
    let zoomMap = 1;
    if (props.datos && props.datos.posicion && props.datos.posicion.coordinates) {
      marcadores = [{
        title: "", name: "", position: {
          lat: props.datos.posicion.coordinates[0],
          lng: props.datos.posicion.coordinates[1]
        }
      }];
      initialCenter = marcadores[0];
      zoomMap = 15;
    } else {
      initialCenter = {
        position: {
          lat: -1.0825217,
          lng: -77.9670043
        }
      }
      zoomMap = 7;
    }

    super(props);
    this.state = {
      contador: 0,
      markers: marcadores,
      initialCenter: initialCenter,
      zoom: zoomMap,
      limite: 1,
      showCircle: false,
      change: false,
      radio: ""
    };
    this.onClick = this.onClick.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);

    // console.log('posicion obtenida', props.datos)

    // this.setState({
    //   markers: marcadores
    // })
    // console.log('markers', this.state.marcadores)

    // }
  }

  onClick(t, map, coord) {
    if (this.state.contador < this.state.limite) {
      const { latLng } = coord;
      const lat = latLng.lat();
      const lng = latLng.lng();
      // this.state.contador= this.state.contador +1;

      // this.setState({change: true});

      if (this.state.markers.length >= 0) {
        this.setState(previousState => {
          return {
            markers: [
              // ...previousState.markers,
              {
                title: "",
                name: "",
                position: { lat, lng }
              }
            ]
          };
        });
      } else {
        this.setState({
          markers: [
            {
              title: "",
              name: "",
              position: { lat, lng }
            }
          ]
        })
      }
      console.log(this.state.markers)
      this.props.changeCallback(this.state.markers[0])

    }
    this.setState({
      showCircle: true
    })
    // console.log("marcadores", this.state.markers)
    // this.props.parentCallback(this.state)

  }

  elimina(array, elem) {
    var var2 = array.filter(function (e) {
      return e.position.lat !== elem.position.lat &&
        e.position.lng !== elem.position.lng
    });
    return var2
  }

  onMarkerClick(event) {
    var var1 = this.elimina(this.state.markers, { title: "", name: "", position: { lat: event.position.lat, lng: event.position.lng } })
    this.setState({
      markers: var1,
      // showCircle: false
    })
    if (this.state.contador !== 0) {
      this.setState({
        contador: this.state.contador - 1
      })
    }
    // console.log("Nuevos marcadores", this.state.markers,"\n contador", this.state.contador)
    // this.props.parentCallback(this.state)  
  }

  radioFunction = (a) => {
    this.state.radio = a.currentTarget.value;
    this.setState({
      radio: a.currentTarget.value
    })
    console.log("Radio", this.state.radio)
    this.props.parentCallback(this.state)
  }

  render() {
    return (
      <Map
        google={this.props.google}
        initialCenter={this.state.initialCenter.position}
        //   style={{ position: 'relative', width: "100%"}}
        className={"map"}
        zoom={this.state.zoom}
        onClick={this.onClick}
      >
        {this.state.markers.length > 0 && this.state.markers.map((marker, index) => {
          console.log(this.state.markers);
          return (<Marker
            key={index}
            title={marker.title}
            name={marker.name}
            position={marker.position}
            onClick={this.onMarkerClick}
          />
          )
        }
        )}


      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: ("AIzaSyBYNDUEf0g8OQPJfiEW7ybizYPiMN_7XOU")
})(EdicionMapa);