import React, { Component } from "react";
import './ListaDispositivosGeneral.css';

class CercaCircular extends Component {

  constructor(props) {
    super(props);
    this.radioFunction = this.radioFunction.bind(this);
    this.latFunction = this.latFunction.bind(this);
    this.lonFunction = this.lonFunction.bind(this);

    this.state = {
      radio: "",
      lat: "",
      lon: "",
    };
  }

  radioFunction (a) {
    this.state.radio = a.currentTarget.value;
    this.setState({
      radio: a.currentTarget.value
    })
    console.log("Radio", this.state.radio)
    this.props.parentCallback(this.state)
  }

  latFunction (a) {
    this.state.lat = a.currentTarget.value;
    this.setState({
      lat: a.currentTarget.value
    })
    console.log("lat", this.state.lat)
    this.props.parentCallback(this.state)
  }

  lonFunction (a) {
    this.state.lon = a.currentTarget.value;
    this.setState({
      lon: a.currentTarget.value
    })
    console.log("lon", this.state.lon)
    this.props.parentCallback(this.state)
  }

  render() {
    return (
      <div>
        <div>
          <br/>
          <h6>Configuración de cerca circular</h6>
        </div>
        <div>
          <form>
            <label for="radio"> Radio:</label>
            <input type="number" min="0" id="radio" name="radio1" onChange={this.radioFunction.bind(this)}/>
            <br/>
            <label for="latCentro"> Latitud Punto central:</label>
            <input type="number" id="latCentro" name="latCentro1" onChange={this.latFunction.bind(this)}/>
            <br/>
            <label for="lonCentro"> Longitud Punto central:</label>
            <input type="number" id="lonCentro" name="lonCentro1" onChange={this.lonFunction.bind(this)}/>
            <br/>
          </form>
        </div>
        <div>
        </div>
      </div>
    );
  }
}
export default CercaCircular;
