import React from "react";
import 'w3-css/w3.css';
import Consumo from "../Administracion/Consumo"
 
// react-bootstrap components
import {
  // Badge,
  // Button,
  // Card,
  // Navbar,
  // Nav,
  // Table,
  Container,
  Row,
  // Col,
  // Modal, 
} from "react-bootstrap";
//import { render } from "react-dom";





class Registro extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      open:  false,
      devicesTable: [],
      users:[],
      isLoading: false,
      isError: false
    };

   

  }



  
render() {
  return (
    <>
      <Container fluid className="w3-panel ">
        <Row>
         
         <Consumo/>
        </Row>
      </Container>
    </>
  );
}
}

export default Registro;



