import React from "react";

import { Container, Row, Col } from "react-bootstrap";

import "./Dashboard.css";

import Api from "../../services/api-service.js";

import SvgUbicacionComponent from "./svgVectores/SvgUbicacion.js";

class MapaUbicacionSensores extends React.Component {
  intervalID = 0;

  item = {};

  constructor(props) {
    super(props);
    // this.updateCharts = this.updateCharts.bind(this);

    let x1 = this.props.location.search;
    console.log("this.props.location.search", this.props.location.search);
    x1 = x1.replace("?organizacion=", "");

    this.state = {
      selectedOranizacion: x1,
      dispositivoSeleccionado: "505663077429696",
      listaProcesos: [],

      seriesData: [1, 2, 3, 4, 5],
      datos: [1, 2, 50, 30],
      open: false,
      devicesTable: [],

      mapaUbicacionesDispositivos: null,
    };

    this.actualizarTablaDispositivos();
    this.adquirirConfiguracionesEmpresa(this.state.selectedOranizacion);

    this.intervalID = setInterval(() => {
      this.actualizarTablaDispositivos();
    }, 5000);
  }

  adquirirConfiguracionesEmpresa(empresa) {
    this.setState({ datos: empresa });
    Api.adquirirConfiguracionesEmpresa({
      organizacion: empresa,
      estado: "all",
    }).then(
      (result) => {
        console.log("RESULTADOOOO");
        console.log(result);
        this.setState({
          mapaUbicacionesDispositivos: result.mapaUbicacionesDispositivos,
        });
        // this.setState({tablaFiltrada: result})
      },
      (error) => {
        console.log("error en la adquisicion de datos", error);
      }
    );
  }

  actualizarTablaDispositivos() {
    Api.obtenerPosDispositivosHumitemp({
      organizacion: this.state.selectedOranizacion,
      estado: "all",
    }).then(
      (result) => {
        console.log(result);
        this.setState({ devicesTable: result });
        // this.setState({tablaFiltrada: result})
      },
      (error) => {
        console.log("error en la adquisicion de datos", error);
      }
    );
  }

  // mapaUbicacionesDispositivos

  // updateCharts() {
  //   const max = 90;
  //   const min = 30;
  //   const newMixedSeries = [];
  //   const newBarSeries = [];

  //   this.state.seriesMixedChart.forEach((s) => {
  //     const data = s.data.map(() => {
  //       return Math.floor(Math.random() * (max - min + 1)) + min;
  //     });
  //     newMixedSeries.push({ data: data, type: s.type });
  //   });

  //   this.state.seriesBar.forEach((s) => {
  //     const data = s.data.map(() => {
  //       return Math.floor(Math.random() * (180 - min + 1)) + min;
  //     });
  //     newBarSeries.push({ data, name: s.name });
  //   });

  //   this.setState({
  //     seriesMixedChart: newMixedSeries,
  //     seriesBar: newBarSeries,
  //     seriesRadial: [Math.floor(Math.random() * (90 - 50 + 1)) + 50]
  //   });
  // }

  handleChangeProcess = (evento) => {
    let auxValue = evento.target.value;
    //console.log('cambio de proceso', auxValue);

    this.setState({ dispositivoSeleccionado: auxValue });
  };

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    return (
      <>
        <Container fluid>
          <Row>
            <div className="app">
              <h1 className="texto-titulo titulo-card titulo-pagina">
                Sensores
              </h1>
              {/* {this.state.selectedOranizacion} */}
              {/* {this.state.datos} */}
              <Row>
                <Col md="12">
                  <SvgUbicacionComponent
                    style={{
                      backgroundImage: `url(${this.state.mapaUbicacionesDispositivos})`,
                    }}
                    className="svgComponent"
                    devicesTable={this.state.devicesTable}
                  ></SvgUbicacionComponent>
                  <div>
                    {/* <img src={gpfImg} className="imagenGPF"/> */}

                    {/* <BodegaComponent  className="svgComponent"></BodegaComponent> */}
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default MapaUbicacionSensores;
