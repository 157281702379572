import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';

import './estilosFormularios.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement');

export default function AgregarDispositivosHumitemp() {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  function handleChangeOrganizacion(){

  }

  return (
    <div>
      <button onClick={openModal}>Agregar Dispositivo</button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div>
          <h1 >Nuevo Dispositivo</h1>
          {/* <button onClick={closeModal}>close</button> */}
          {/* <h2>I am a modal</h2> */}
          <div className=''>
          <div className='formulario'>
             <label>Identificador: </label>
             <input type="text" id="lname" name="lname"/>
            </div>
            <div className='formulario'>
             <label>Nombre: </label>
             <input type="text" id="lname" name="lname"/>
            </div>
            <div class="selectores">
                <label for="grupo">Organización:</label>
                <select id="grupo" onChange={handleChangeOrganizacion}>
                  {/* <option value="" disabled  hidden>seleccionar</option> */}
                  <option selected value="null">No Organizacion</option>
                  <option value="constecoin">Constecoin</option>
                  <option value="gpf">GPF</option>
                  {/* <option value="constecoin">Constecoin</option> */}
                </select>
              </div>
            {/* <h3>Alertas</h3>
            <div className='formulario'>
             <label>Valor Min: </label>
             <input type="text" id="vmin" name="vmin"/>
            </div>
            <div className='formulario'>
             <label>Valor Max: </label>
             <input type="text" id="vmax" name="vmax"/>
            </div> */}
            <div className='div-botones row'>
              <div className='col-6 button-edicion'>
                <button className="buttonGeneral">Crear</button>
              </div>
              <div className='col-6 button-edicion'>
                <button className="buttonGeneral button-secondary" onClick={()=>closeModal()} >Cancelar</button>
              </div>
            </div>
          </div>

        </div>
      </Modal>
    </div>
  );
}