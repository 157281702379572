import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import Api from '../../../services/api-service.js'


import './estilosFormularios.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement');

export default function AgregarGruposHumitemp(props) {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [grupo, setGrupo] = React.useState(null);
  const [organizacion, setOrganizacion] = React.useState(props.organizacion);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  function handleChangeOrganizacion(){

  }
  function handleChangeForm(event){
    console.log(event.target.name)



    // form[event.target.name] = (event.target.value === 'null')? null: event.target.value ;

    if(event.target.name === 'grupo'){setGrupo(event.target.value)}
    // if(event.target.name === 'email'){setEmail(event.target.value)}
    // if(event.target.name === 'direccion'){setDireccion(event.target.value)}
    // if(event.target.name === 'cantidadUsuarios'){setCantidadUsuarios(event.target.value)}
    // if(event.target.name === 'estado'){setEstado(event.target.value)}

    // console.log(form)


  }

  function createGroup(){
    let grupoDoc = {
      grupo: grupo, 
      organizacion: organizacion
    }

    console.log(grupoDoc)

    Api.creacionGrupoHumitemp(grupoDoc).then(
      result=>{
        console.log(result);
        // this.setState({devicesTable: result})
        // this.setState({tablaFiltrada: result})
        closeModal();
        props.eventFinishedOK();
      }, error=>{
        console.log('error en la creacion');
        alert('error en la actualización ')
      }
    )
  }

  return (
    <div>
      <button onClick={openModal}>Agregar Grupo</button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div>
          <h1 >Nuevo Grupo</h1>
          {/* <button onClick={closeModal}>close</button> */}
          {/* <h2>I am a modal</h2> */}
          <div className=''>
          {/* <div className='formulario'>
             <label>Identificador: </label>
             <input type="text" id="lname" name="lname"/>
            </div> */}
            <div className='formulario'>
             <label>Nombre: </label>
             <input type="text" id="grupo" name="grupo" onChange={handleChangeForm}/>
            </div>
            {/* <div class="selectores">
                <label for="grupo">Organización:</label>
                <select id="grupo" onChange={handleChangeOrganizacion}>
                  <option selected value="null">No Organizacion</option>
                  <option value="constecoin">Constecoin</option>
                  <option value="gpf">GPF</option>
                </select>
              </div> */}
            {/* <h3>Alertas</h3>
            <div className='formulario'>
             <label>Valor Min: </label>
             <input type="text" id="vmin" name="vmin"/>
            </div>
            <div className='formulario'>
             <label>Valor Max: </label>
             <input type="text" id="vmax" name="vmax"/>
            </div> */}
            <div className='div-botones row'>
              <div className='col-6 button-edicion'>
                <button className="buttonGeneral" onClick={()=>createGroup()}>Crear</button>
              </div>
              <div className='col-6 button-edicion'>
                <button className="buttonGeneral button-secondary" onClick={()=>closeModal()} >Cancelar</button>
              </div>
            </div>
          </div>

        </div>
      </Modal>
    </div>
  );
}