import styled from 'styled-components'

// Estilos para el contenedor general
export const Main = styled.div`
  margin: 25px 30px 25px 30px;
  border: PowderBlue 2px solid;
`
export const Formulario = styled.div`
  background: #f7f7f7;
  padding-bottom: 30px;
`
// Estilo para el titulo del formulario
export const Titulo = styled.h1`
  font-family: serif;
  left:0;
  right:0;
  text-align: center;
  background: white;
  padding: 10px 0 10px 0;
`
// Para los input
export const ParaNombre = styled.div`
  margin-left: 30px;
`
// Inputs
export const Form = styled.div`
  margin-top: 15px;
  height:50px
`
// Laravel de los inputs
export const LavelTitu = styled.label`
  width: 25%;
  font-size: 18px;
  font-weight: 700;
`
// Estilo de la caja de inout
export const CasillaIn = styled.input`
  height: 50px;
  width: 25%;
`
// Estilo de la caja de select
export const ForDivv = styled.select`
  width: 25%;
  display: inline-block;
  height: 50px;
  padding: 3px 10px;
  border: 1px solid;
  border-radius: 10px;
`
// Estilo para titulo de tipo H2
export const H2 = styled.h2`
  text-align: center;
  margin-top: 30px;
`
// Contenedor seleccion multiple
export const ContenedorSelect = styled.div`
  padding-top: 30px;
  text-align:center;
`
// Cuadro de cada seleccionador
export const ParaMarcado = styled.div`
  display: inline-block;
  text-align: center;
`
// Estilo cuadro 
export const Casilla = styled.input`
  display: block;
  height: 50px;
  width: 222px; 
`

// Laravel para cuadro de seleccionador multiple
export const Lavel = styled.label`
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  font-family: serif;
`
// ----------Fechas y horas-------

// ->Contenedor
export const ContenedorHorarios = styled.div`
  text-align: center;
  margin: 30px 0;
`
// Contendor infividual
export const Horarios = styled.div`
  display:inline-block;
  margin: 10px 35px; 
`
// Fecha
export const Fecha = styled.input`
  border-radius: 3px;
  border: 1.5px solid;
  margin: 0.5em 3em;
  text-align:center;
  height: 50px;
`
// Hora
export const Hora = styled.input`
  border-radius: 3px;
  border: 1.5px solid;
  margin: 0.5em 3em;
  text-align:center;
  height: 50px;
`
// ---------------Botones-----------

// ->Contenedor
export const ContenedorBotones = styled.div`
  text-align: center;
  margin-top: 60px;
`
// Botones
export const Botones = styled.div`
  display: inline-block;
`

// Interno de Botones
export const BotonInterno = styled.button`
  ${'' /* background: #60db50; */}
  background: ${({ lightBg }) => (lightBg ? '#60db50' : 'red')};;
  border-radius: 3px;
  border: 1.5px solid;
  margin: 0.5em 3em;
  width: 200px;
  text-align: center;
  color:white;
  height:55px;
`
