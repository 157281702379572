import React, {useState, useEffect} from 'react'
import Prevista from './Prevista'
import Api from '../../services/api-service'
import { Centrar, Main } from './ProcesosElementos';
import Filtrador from './Filtrador';



function Procesos() {

    const [dato, setDatos]=useState();
    const [loading, setLoading]=useState(true);

    useEffect(()=>{
        Api.obtenerProcesos()
        .then(result=>{
        console.log("result", result)
        setDatos(result)
        setLoading(false);
    }, error=>{
        console.log('Obtencion erronea');
        // window.location.replace('');
      
    })
    }, []);

  return (
    <Main>
    {
        loading ? (
            <Centrar>
            <div className='spinner-border text-primary' role='status'><span className='visually-hidden'></span></div>
            </Centrar>


        ) : <Filtrador data={dato}/>
    }

    </Main>
  )
} 

export default Procesos