import styled from "styled-components";

export const Mapas = styled.div`
  height: 300px;
  @media screen and (max-width: 768) {
    font-size: 24px;
  }
  @media screen and (max-widtg: 480px) {
    font-size: 18px;
  }
`;

export const ForMap = styled.div`
  justify-content: center;
  align-content: center;
  position: absolute;
  width: 70%;
  height: 300px;
  @media screen and (max-width: 768px) {
    width: 70%;
  }
  @media screen and (max-widtg: 480px) {
    width: 50%;
  }
`;
