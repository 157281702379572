import React from "react";
import { Redirect, Link } from "react-router-dom";
import Auth from "../../services/auth-service.js";
import { FaList } from "react-icons/fa";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import {
  humitemp,
  betatemp,
  cadenaFrio,
  cacaoBeta,
  gabinetes,
  LGT92,
  Alcantarilla,
  DataCenter,
  Calibracion,
  Wt32,
  Administracion,
  synlab
} from "../../menu";
import "./SidebarMenu.scss";

export class SidebarMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: this.props.menu,
      menuUser: this.props.menuUser,
      collapsed: false,
      rol: Auth.getRol(),

    };
  }

  getLink(props) {
    return Auth.getJWT() !== null ? (
      <props.component {...props} />
    ) : (
      <Redirect from="/" to="/login/ingreso" />
    );
  }

  getHabilitation(value) {
    if (Auth.getHabilitations()) {
      return Auth.getHabilitations().indexOf(value) !== -1;
    } else {
      return false;
    }
  }

  setStatusCollapsed(value) { }

  sidebarClick = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  getSubmenu = (element, name, oranizacion, tipo) => {

    console.log("Aqui esta el tipo ")
    console.log(tipo)

    if (!element) {
      return;
    }

    let item;
    if (element.modulo === "humitemp") {
      item = humitemp;
    }
    if (element.modulo === "betatemp") {
      item = betatemp;
    }
    if (element.modulo === "cadena frio") {
      item = cadenaFrio;
    }
    if (element.modulo === "cacao beta") {
      item = cacaoBeta;
    }
    if (element.modulo === "gabinetes") {
      item = gabinetes;
    }
    if (element.modulo === "LGT92") {
      item = LGT92;
    }
    if (element.modulo === "Alcantarilla") {
      item = Alcantarilla;
    }
    if (element.modulo === "DataCenter") {
      item = DataCenter;
    }
    if (element.modulo === "Calibracion") {
      item = Calibracion;
    }
    if (element.modulo === "Wt32") {
      item = Wt32;
    }
    if (element.modulo === "Administracion") {
      item = Administracion;
    }
    if (element.modulo === "synlab") {
      item = synlab;
    }




    return (
      <SubMenu
        key={name}
        title={name}
        icon={<FaList />}
        className="submenuStyle"
      >
        {item.children.map((element) => {
          return (
            // <MenuItem key={element.name} className="styleMenuItem">{element.name}<Link to={element.layout + element.path}/></MenuItem>
            <MenuItem key={element.name} className="styleMenuItem">
              {element.name}
              <Link
                to={{
                  pathname: `${element.layout}${element.path}`,
                  search: `?organizacion=${oranizacion}&tipo=${tipo}`,
                }}
              />
            </MenuItem>
          );
        })}
      </SubMenu>
    );
  };

  render() {
    return (
      <div id="sidebar-menu" className="estilo-sidebar">
        <ProSidebar
          className="sidebar-style"
          collapsed={this.state.collapsed}
          onToggle={this.setStatusCollapsed}
        >
          <img
            className="logo-img logo-aiot"
            src={require("../../assets/img/aiot_logo.png").default}
            onClick={this.sidebarClick}
            alt="..."
          />
          <Menu iconShape="square">
            {this.state.menu.map((item) => {
              //carga el modulo de menu
              if (item.state !== undefined && item.type !== "model") {
                if (item.name !== "General") {
                  if (this.state.rol !== "administrador general") {
                    return;
                  }
                }

                return (
                  <SubMenu
                    key={item.name}
                    title={item.name}
                    icon={item.icon}
                    className="submenuStyle"
                  >
                    {item.children !== undefined &&
                      item.children.map((element) => {
                        return (
                          <MenuItem
                            key={element.name}
                            className="styleMenuItem"
                          >
                            {element.name}
                            <Link
                              to={{
                                pathname: `${element.layout}${element.path}`,
                                search: `?organizacion=${item.name}`,
                              }}
                            />
                          </MenuItem>
                        );
                      })}
                  </SubMenu>
                );
              }
            })}


            {this.state.menuUser.map((item) => {
              if (this.state.rol === "administrador") {
                let organizacion
                //carga el modulo de menu
                if (Auth.getOrganizaciones().length == 1) {
                  let orga = Auth.getOrganizaciones()
                  organizacion = orga[0].organizacion
                  return (
                    <SubMenu
                      title={item.name}
                      icon={item.icon}
                      className="submenuStyle"
                    >
                      {item.children !== undefined &&
                        item.children.map((element) => {
                          return (
                            <MenuItem
                              key={element.name}
                              className="styleMenuItem"
                            >
                              {element.name}
                              <Link
                                to={{
                                  pathname: `${element.layout}${element.path}`,
                                  search: `?organizacion=${organizacion}`,
                                }}
                              />
                            </MenuItem>
                          );
                        })}
                    </SubMenu>
                  );
                }
              }
            })}


            {Auth.getOrganizaciones().map((item) => {
              //carga organizaciones
              { console.log(item) }
              if (!item) {
                return;
              }
              if (item.configuracion.length > 1) {
                return (
                  <SubMenu
                    key={item.organizacion}
                    title={item.nombre}
                    icon={<FaList />}
                    className="submenuStyle"
                  >
                    {item.configuracion !== undefined &&
                      item.configuracion.map((element) => {

                        return this.getSubmenu(
                          element,
                          element.nombre,
                          item.organizacion,
                          element.catalogo
                        );
                      })}
                  </SubMenu>
                );
              } else {
                { console.log("ENTRNADOOOOOOOO TIPOOOOo") }
                return this.getSubmenu(
                  item.configuracion[0],
                  item.nombre,
                  item.organizacion,
                  item.configuracion[0].catalogo
                );
              }
            })}
          </Menu>
        </ProSidebar>
      </div>
    );
  }
}

export default SidebarMenu;
