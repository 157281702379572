import styled from "styled-components";

export const Main = styled.div`
  margin: 25px 30px 25px 30px;
  border: White 2px solid;
  height: 100%;
`;

export const ContenedorEncabezado = styled.div`
  padding-bottom: 50px;
  ${"" /* border: 1px solid #238442; */}
`;

export const ContenedorImagenes = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const ContenedorTitulo = styled.h1`
  font-family: roman;
  font-size: xx-large;
  color: #002b6d;
  text-align: center;
`;

export const ContendorBotones = styled.div`
  margin: 25px 30px 25px 30px;
`;

export const BotonesIzquierda = styled.div`
  background: rgb(17 67 150);
  color: white;
  display: inline-block;
  padding: 5px 5px;
`;

export const BotonesDerecha = styled.button`
  background: rgb(17 67 150);
  color: white;
  display: inline-block;
  padding: 5px 5px;
  float: right;
`;
// Contenido de CSS para la Tabla

export const Tabla = styled.table`
  width: 100%;
`;

export const Tr = styled.tr`
  left: 0.03%;
  right: 0.06%;
  top: 19.77%;
  bottom: 75.08%;
  background: #efefef;
  height: 45px;
`;

export const Th = styled.th`
  left: 25.01%;
  right: 61.36%;
  top: 21.49%;
  bottom: 76.46%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #4f4f4f;
  text-align: center;
  padding: 15px;
`;

export const Td = styled.td`
  // width: 25%;
  // text-align: left;
  // vertical-align: top;
`;

export const ParaSelect = styled.div`
  background: rgb(17 67 150);
`;

export const LabelSelect = styled.label`
  color: white;
`;

export const CoFiltroIn = styled.div`
  height: 45px;
  width: 100%;
  background: #efefef;
  text-align: right;
`;

export const Divfiltro = styled.div`
  display: inline-block;
  /* Filas por página : 10 */
  margin: 10px 30px 0 30px;

  /* Table/Footer */

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.05em;

  /* text */
`;

export const SelectFiltro = styled.select`
  border: none;
  background: #efefef;
`;

// Para el contenido de la Tabla

export const Trc = styled.tr`
  text-align: center;
  ${"" /* margin: 15px 0 15px 0; */}
  ${"" /* border: solid; */}
border-width: 1px 0;
  border-color: #dadada;
  ${"" /* height: 30px; */}
  ${"" /* background: red; */}
`;

export const BotonModal = styled.button`
  background: transparent;
  border: 0;
  display: inline-block;
  padding: 0px 5px;
  float: right;
`;

export const TituloModal = styled.h2`
  padding: 15px 100px;
`;

export const Mapaa = styled.div`
  height: 230px;
`;

export const CoFiltroInD = styled.div`
  ${
    "" /* height: 45px;
  width: 100%;
  background: #efefef;
  text-align: left; */
  }
  border: none;
`;

export const Imagen = styled.img``;
export const Imagen1 = styled.img`
  width: 80%;
`;

export const DivfiltroD = styled.div`
  ${
    "" /* display: inline-block;
  margin: 10px 30px 0 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em; */
  }

  border: none;
  &:focus {
    outline: none;
  }
`;

export const SelectFiltroD = styled.select`
  border: none;
  &:focus {
    outline: none;
  }
  padding-left: 10px;
`;
