import React from "react";
import Auth from "../services/auth-service.js";
import GetTemplate from "./LandingPage/index.js";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      devicesTable: [],
      organizations: Auth.getOrganizaciones(),
    };
  }

  render() {
    let organization;
    if (this.state.organizations.length > 1) {
      organization = "Constecoin";
    } else {
      organization = this.state.organizations[0].organizacion;
    }
    return <GetTemplate name={organization} />;
  }
}

export default Home;
