import Button from "@restart/ui/esm/Button";
import React, { Component } from "react";
import '../../views/Administracion/AdminSigfox.css';
import './w3.css';
import './ListaDispositivosGeneral.css'

class BotonesConfig extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ingresoBoton: "",
      showComp: false,
    };
  }

  tipoIngreso = (event) =>{
    console.log("Inicio de tipo de ingreso en BotonesConfig", this.state)
    this.state.ingresoBoton = event.currentTarget.value;
    this.state.showComp = true;
    this.setState({
      ingresoBoton: event.currentTarget.value,
      showComp: true,
    })
    console.log("Estado enviado desde BotonesConfig a MapaOrManual", this.state)
    this.props.parentCallback(this.state);
  }

  render() {
    return (
      <>
        <Button class="btn btn-primary buttonGeneral" value="manual" onClick={this.tipoIngreso.bind(this)}>Configuración manual</Button>
        <Button class="btn btn-primary buttonGeneral" value="mapa" onClick={this.tipoIngreso.bind(this)}>Configuración por mapa</Button>
      </>
    );
  }
}
export default BotonesConfig;
