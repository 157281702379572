import styled from 'styled-components'


export const Main = styled.div`
  margin: 25px 30px 25px 30px;
  border: PowderBlue 2px solid;
`
export const Buscador = styled.input`
  margin: 25px 30px 25px 30px;
  border: PowderBlue 2px solid;
`

// Estilos para el contenedor general
export const Centrar = styled.div`
    ${'' /* background-color: red;
    float: center; */}
`



// Para lectura de datos

export const Tabla = styled.table`
width: 100%;
border-collapse: collapse;
`

export const TrC = styled.tr`
text-align: center;
${'' /* margin: 15px 0 15px 0; */}
${'' /* border: solid; */}
border-width: 1px 0;
border-color: #dadada;
${'' /* height: 30px; */}
${'' /* background: red; */}

`

export const ColumnasPrincipales = styled.th`
left: 25.01%;
right: 61.36%;
top: 21.49%;
bottom: 76.46%;
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 130%;
color: #4F4F4F;
text-align: center;
padding: 15px;
`
export const Columnas = styled.td`
`
export const Filas = styled.tr`
${'' /* position: absolute; */}
left: 0.03%;
right: 0.06%;
top: 19.77%;
bottom: 75.08%;
background: #EFEFEF;
`
export const BarraBusqueda = styled.div`
display: inline-block;
margin-left: 42px;
margin-right: 320px;
height: 50px;
width: 392px;
background: #EFEFEF;
padding: 1% 0;
align-content: center;
justify-content: flex-start;
align-items: center;
margin-bottom: 10px;
border-radius: 10px;
`


export const IconoLupa = styled.div`
display: inline-block;
margin: 0 10px;
`

export const InputBusqueda = styled.input`
display: inline-block;
width: 320px;
border: none;
background-color: transparent;
&:focus {
        outline: none;
    }
${'' /* border-radius: 1px; */}

`

export const Titulo = styled.h1`
  font-family: serif;
  left:0;
  right:0;
  text-align: center;
  background: white;
  padding: 10px 0 10px 0;
`

export const Par = styled.div`
color: #114396;

`

export const Inpar = styled.div`
color: #000;
`