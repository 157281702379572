import React from 'react'
import { Button,Modal } from 'react-bootstrap'
import './w3.css';
import '../../views/Administracion/AdminSigfox.css';
import './ListaDispositivosGeneral.css'
import './BootstrapModal.css'

import GPSTrackerList from "./GPSTrackerList.js"
import UplinkGPSTracker from './UplinkGPSTracker.js';
import { Component } from 'react';

class BootstrapModal extends Component {

    constructor(props){
        super(props)
        this.mostrarModalDL = this.mostrarModalDL.bind(this);
        this.cerrarModalDL = this.cerrarModalDL.bind(this);
        this.cerrarModalUL = this.cerrarModalUL.bind(this);
        this.mostrarModalUL = this.mostrarModalUL.bind(this);
        this.callbackFunction = this.callbackFunction.bind(this);
        this.sendList = this.sendList.bind(this);
        this.estadoComponenteDL = this.estadoComponenteDL.bind(this);
        this.estadoComponenteUL = this.estadoComponenteUL.bind(this);
        this.uplinkFunctionGPSTracker = this.uplinkFunctionGPSTracker.bind(this);
        this.state = {
          /////variables que muestran modals de uplink y downlink
          showModalDL: false, //Show Modal DL
          showModalUL: false, //Show Modal UL
          ///// variables que toman los valores de los selectores
          tipoDispSelecDL: "", ///Selector de Downlink
          tipoDispSelecUL: "", //Selector de Uplink
          ////// lista obtenida del BetaTempList y GPSTrackerList
          lista: [],
          //////COntrola componentes de AdminSigfox
          mostrarComponenteDL: false,
          mostrarComponenteUL: false,
          //////Tipo de rest que se quiere hacer desde Modal de uplink
          tipoRest: "",
          //////Mensaje de estado de servicio de Uplink GPSTRacker
          respuestaServiceStatus: "",
          estadoMostrarServiceStatusUL: false,
        }
      }

        ///////Funciones para botones DL
        cerrarModalDL () {
          console.log("Estado IN cerrarModalDL", this.state)
          this.state.showModalDL = false;
          this.state.tipoDispSelecDL = "";
          this.setState({
          showModalDL: false,
          tipoDispSelecDL: "",
          })
          console.log("Estado OUT cerrarModalDL en BootstrapModal.js", this.state)
        };

        mostrarModalDL () {
          console.log("Estado IN mostrarModalDL en BootstrapModal.js", this.state)
          this.state.showModalDL = true;
          this.state.mostrarComponenteDL= true;
          this.state.mostrarComponenteUL= false;
          this.setState({
          showModalDL: true,
          mostrarComponenteDL: true,
          mostrarComponenteUL: false
          })
          console.log("Estado OUT mostrarModalDL en BootstrapModal.js", this.state)
        };
    
        ///////Funciones para botones UL  
        cerrarModalUL () {
          console.log("Estado IN cerrarModalUL en BootstrapModal.js", this.state)
          this.state.showModalUL = false;
          this.state.tipoDispSelecUL = "";
          this.setState({
          showModalUL: false,
          tipoDispSelecUL: "",
          })
          console.log("Estado OUT cerrarModalUL en BootstrapModal.js", this.state)
        };
        mostrarModalUL () {
          console.log("Estado IN mostrarModalUL en BootstrapModal.js", this.state)
          this.state.showModalUL= true;
          this.state.mostrarComponenteDL= false;
          this.state.mostrarComponenteUL= true;
          this.setState({
          showModalUL: true,
          mostrarComponenteDL: false,
          mostrarComponenteUL: true
          })
          console.log("Estado OUT mostrarModalUL en BootstrapModal.js", this.state)
        };

        ////////////// Callback de obtención de listas
        callbackFunction (listaObtenida) {
          console.log("Estado IN callbackFunction en BootstrapModal.js", this.state)
          this.state.lista = listaObtenida
          this.state.mostrarComponenteDL= true
          this.state.mostrarComponenteUL= false
          this.setState({
            lista: listaObtenida,
            mostrarComponenteDL: true,
            mostrarComponenteUL: false,
          })
          console.log("Estado OUT callbackFunction en BootstrapModal.js", this.state)
      }

        ////// Enviar lista
        sendList () {
          console.log("Estado enviado desde BootstrapModal a AdminSigfox", this.state)
          this.props.parentCallback(this.state)
        }

        ////// Controla el componente de DL AdminSigfox
        estadoComponenteDL() {
          console.log("Estado IN estadoComponenteDL en BootstrapModal.js", this.state)
          this.state.mostrarComponenteDL = true;
        this.setState({
          mostrarComponenteDL: true,
        });
        console.log("Estado nuevo Modal \n", this.state.mostrarComponenteDL)
        console.log("Estado OUT estadoComponenteDL en BootstrapModal.js", this.state)
        this.sendList()
        this.cerrarModalDL()
      }

      ////// Controla el componente de UL AdminSigfox
      estadoComponenteUL () {
        console.log("Estado IN estadoComponenteUL en BootstrapModal.js", this.state)
        this.state.mostrarComponenteUL = true;
        this.setState({
          mostrarComponenteUL: true,
        });
        console.log("Estado nuevo Modal \n", this.state.mostrarComponenteUL)
        console.log("Estado OUT estadoComponenteUL en BootstrapModal.js", this.state)
        this.sendList()
        this.cerrarModalUL()
      }

      ///////Función de callback de UplinkGPSTracker.js recibe todo el state
      uplinkFunctionGPSTracker (estadoUplinkGPSTracker) {
        console.log("Recibido de UplinkGPSTracker en BootstrapModal\n", estadoUplinkGPSTracker)

        this.state.mostrarComponenteUL= estadoUplinkGPSTracker.mostrarComponenteUL;
        this.state.mostrarComponenteDL= false;
        this.state.tipoRest= estadoUplinkGPSTracker.tipoRest;
        this.state.respuestaServiceStatus= estadoUplinkGPSTracker.respuestaServiceStatus;
        this.state.estadoMostrarServiceStatusUL= estadoUplinkGPSTracker.estadoMostrarServiceStatus;

        this.setState({
          mostrarComponenteUL: estadoUplinkGPSTracker.mostrarComponenteUL,
          mostrarComponenteDL: false,
          tipoRest: estadoUplinkGPSTracker.tipoRest,
          respuestaServiceStatus: estadoUplinkGPSTracker.respuestaServiceStatus,
          estadoMostrarServiceStatusUL: estadoUplinkGPSTracker.estadoMostrarServiceStatus,
        })
        this.sendList()
        this.cerrarModalUL()
    }
  render (){
    return (
      <>
        <div className="bordeDiv row" >
          <div className="inicioIzq col-12 col-md-3 col-lg-5">
            Administración de configuraciones para Uplink y Downlink de dispositivos GPS Tracker
          </div>
          <div className="col-12 col-md-9 col-lg-7">
            <button  className="btn btn-primary buttonGeneral" onClick={this.mostrarModalDL}>
              Downlinks
            </button>
            <button  className="btn btn-primary  buttonGeneral" onClick={this.mostrarModalUL}>
              Uplink
            </button>
          </div>
        </div>
      {/* {Modal botón Downlink} */}
        <Modal
          show={this.state.showModalDL}
          onHide={this.cerrarModalDL}
          // backdrop="static"
          keyboard={false}
          className=""
          style={{maxwidth:800}}>
          <Modal.Header closeButton className="">
          <h5>Configuraciones de Downlik de GPS TRacker</h5>
          </Modal.Header>

          <Modal.Body>
            <div>
              <div>Seleccione los dipositivos a configurar</div>
              <GPSTrackerList parentCallback = {this.callbackFunction}/>
            </div>
          </Modal.Body>

          <Modal.Footer>
              <button className="btn btn-primary buttonGeneral" onClick={this.estadoComponenteDL}>
                Ok
              </button>
            <button className="btn btn-primary buttonGeneral button-secondary" onClick={this.cerrarModalDL}>
              Cancelar
            </button>
          </Modal.Footer>
        </Modal>


      {/* {Modal botón uplink} */}
        <Modal
          show={this.state.showModalUL}
          onHide={this.cerrarModalUL}
          // backdrop="static"
          keyboard={false}
          className="w3-modal-content{background-color: transparent} w3-animate-zoom " style={{maxwidth:600}}>
          <Modal.Header closeButton className="w3-rigth">
          <h5>Configuraciones de Uplink de GPS Tracker</h5>
          </Modal.Header>

          <Modal.Body>
            <div>
              <UplinkGPSTracker parentCallback = {this.uplinkFunctionGPSTracker}/>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button className="btn btn-primary buttonGeneral">
              Ok
            </button>
            <button className="btn btn-primary buttonGeneral button-secondary" onClick={this.cerrarModalUL}>
              Cancelar
            </button>
          </Modal.Footer>
        </Modal>
      </>        
    );
  }
}
export default BootstrapModal;