import React from "react";
import "./CuadroTemp.css"
import Thermometer from 'react-thermometer-component'


class CuadroTemp extends React.Component {

  constructor (props) {
    super(props);
    // console.log("fila:", this.props.fila, "Linea:", this.props.linea)
    this.state = {
    };
  }

  render() {
    // console.log("props", this.props.info)
    return (
      <>
        <p className="texto">
          ID: {this.props.info[0].mac}
        </p>
        <div className="">
        <Thermometer
          theme="light"
          value={this.props.info[0].temperature}
          max="80"
          steps=""
          format="°C"
          size="normal"
          height="150"
        />
        </div>
      </>
    );
  }
}

  export default CuadroTemp;