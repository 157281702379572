import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card } from "react-bootstrap";
import bat from "../graficos/imagenes/bateria.png";
class guage2 extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
      
        series: this.props.seriesData,
        options: {

          chart: {
            height: 200,
            type: 'radialBar',
            offsetY: -10
          },
          plotOptions: {
            radialBar: {
              hollow: {
                margin: 15,
                size: '70%',
                image: bat,
                imageWidth: 40,
                imageHeight: 40,
                imageClipped: false
              },
              startAngle: -135,
              endAngle: 135,
              dataLabels: {
                name: {
                  show: false,
                  fontSize: '25px',
                  color: "#333",
                  offsetY: -20 
                },
                value: {
                  offsetY: 45,
                  fontSize: '22px',
                  color: undefined,
                  formatter: function (val) {
                    return val + "%";
                  }
                }
              }
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                shadeIntensity: 0.15,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 65, 91]
            },
          },
          stroke: {
            dashArray: 4
          },
          labels: ['Bateria', 'bat'],
        },
      
      
      };

      this.setState({series : this.props.seriesData})

    }

  

    render() {
      return (
        <>
          <Card>
            <div id="chart">
              <ReactApexChart options={this.state.options} series={this.props.seriesData} type="radialBar"  height={250} />
            </div>
          </Card>
        </>
      );
    }
}
export default guage2;
              