function saveJWT(token) {
    localStorage.setItem("token", token);
  }
  
  function getJWT() {
    let token = localStorage.getItem("token");
  
    if (token === "undefined" || token === "null") {
      token = null;
    }
    return token;
  }
  
  function saveRol(variable) {
    localStorage.setItem("rol", JSON.stringify(variable));
  }

  function saveAlert(variable) {
    localStorage.setItem("alerta", JSON.stringify(variable));
  }

  function getAlert() {
    let variable = localStorage.getItem("alerta");
  
    if (variable === "undefined" || variable === "null") {
      variable = null;
      return variable;
    }
    return JSON.parse(variable);
  }
  
  function getRol() {
    let variable = localStorage.getItem("rol");
  
    if (variable === "undefined" || variable === "null") {
      variable = null;
      return variable;
    }
    return JSON.parse(variable);
  }
  
  function saveHabilitations(habilitaciones) {
    localStorage.setItem("habilitaciones", JSON.stringify(habilitaciones));
  }
  
  function getHabilitations() {
    let habilitaciones = localStorage.getItem("habilitaciones");
  
    if (habilitaciones === undefined || habilitaciones === "null") {
      habilitaciones = null;
    }
    return habilitaciones;
  }
  function getHabilitations2() {
    let habilitaciones = localStorage.getItem("habilitaciones");
  
    if (habilitaciones === undefined || habilitaciones === "null") {
      habilitaciones = null;
    }
    return JSON.parse(habilitaciones);
  }
  
  function saveOrganizaciones(aux) {
    localStorage.setItem("organizaciones", JSON.stringify(aux));
  }
  function saveVariables(aux) {
    localStorage.setItem("variables",JSON.stringify(aux));
  }
  function getVariables() {
    let aux = localStorage.getItem("variables");
    if (aux === undefined || aux === "null") {
      aux = null;
    }
    return JSON.parse(aux);
  }
  
  function getOrganizaciones() {
    let aux = localStorage.getItem("organizaciones");
  
    if (aux === undefined || aux === "null") {
      aux = null;
    }
    return JSON.parse(aux);
  }
  
  module.exports = {
    saveJWT,
    getJWT,
    saveRol,
    getRol,
    saveHabilitations,
    getHabilitations,
    saveOrganizaciones,
    getOrganizaciones,
    getHabilitations2,
    saveVariables,
    getVariables,
    saveAlert,
    getAlert,
  };
  