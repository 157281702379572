import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Input2 from "../../../components/Entradas/Input2/index2";
import Button from '@mui/material/Button';
import iconWhatsapp from "../../../assets/img/whatsapp.png"
import iconEmail from "../../../assets/img/icono-email.png"
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import iconDelete from "../../../assets/img/icono-delete.png"
import Select from "../../../components/Entradas/Select";
import Swal from "sweetalert2";
import Auth from "../../../services/auth-service.js";
import { useHistory } from "react-router-dom";
import Api from "../../../services/api-service";
import { useLocation } from "react-router-dom";
import {
    ContenedorTitulo,
    Main,
    Titulo,
    BotonCrear,
    BotonSiguiente,
    ContenedorComuW,
    Icono_ContenedorComuW,
    Numero_ContenedorComuW,
    Icono2_ContenedorComuW,
    Botton2_Contenedor,
    Botton2,
    BotonRetroceder,
    NavbarTabla,
    Testing,
    Verificacion
} from "../AlertasElements";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #E8E6E2',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

let Tipo = [
    {
        value: "whatsapp",
        label: "Mensaje de Whatsapp",
    },
    {
        value: "email",
        label: "Correo Electronico",
    },
];
let codigoNumero = [
    {
        value: "593",
        label: "Ecuador(+593)",
    },
];



const MedioAlerta = () => {

    const [documento, setDocumento] = useState({});

    const [generalNumero, setGeneralNumero] = useState([]);
    const [generalEmail, setGeneralEmail] = useState([]);

    const [open, setOpen] = useState(false);
    const [veri, setVeri] = useState(false);
    const [veriE, setVeriE] = useState(false);
    const [botones, setBotones] = useState(false);
    const [mail, setMail] = useState("");
    const [mensajeVe, setMensajeVe] = useState("");
    const [mensajeVeE, setMensajeVeE] = useState("");
    const [selecTipo, setSelectTipo] = useState("");
    const [prefijoNumero, setPrefijoNumero] = useState("");
    const [numero, setNumero] = useState("");
    const [numbers, setNumbers] = useState([]);
    const [postEmail, setPostEmail] = useState([]);
    const query = new URLSearchParams(useLocation().search);
    const obteOrganizacion = query.get("organizacion");

    let history = useHistory();

    function guardarAlerta() {
        documento.phones = generalNumero;
        documento.mails = generalEmail;
        Api.crearAlerta(documento).then((respues) => {
            if (respues.status == "Ok") {
                Swal.fire(`${respues.message}`, '', 'success')
                Swal.fire("Guardado exitosamente", "", "success");
                history.push(`/admin/alertas?organizacion=${obteOrganizacion}`);
            } else {
                Swal.fire(`${respues.message}`, '', 'error')
            }
        }, (error) => {

        })

    }

    function validacionBotones() {
        if (generalNumero.length > 0 || generalEmail.length > 0) {
            setBotones(true)
        } else {
            setBotones(false)
        }
    }

    function returnPage() {

        history.push(`/admin/messageAlert?organizacion=${obteOrganizacion}`);
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose2 = () => {
        setOpen(false);
        setSelectTipo("")
        setPrefijoNumero("")
        setNumero("")
        setNumbers([])
        setPostEmail([])
        setVeri(false)
        setVeriE(false)
    };
    const handleClose = () => {
        let arregloN = []
        let arregloMail = []


        setOpen(false);
        numbers.map((index) => {
            let arregloNumero = {}
            arregloNumero.number = index.number
            arregloN.push(arregloNumero)
        })
        // //console.log.log(arregloN)
        generalNumero.map((index) => {
            let arregloNumero = {}
            arregloNumero.number = index.number
            arregloN.push(arregloNumero)
        })

        postEmail.map((index) => {
            let arregloNumero = {}
            arregloNumero = index
            arregloMail.push(arregloNumero)
        })

        generalEmail.map((index) => {
            let arregloNumero = {}
            arregloNumero = index
            arregloMail.push(arregloNumero)
        })

        // //console.log.log(arregloN)

        setGeneralNumero(arregloN)
        setGeneralEmail(arregloMail)
        setNumbers([])
        setPostEmail([])
    };

    function eliminarPosicion(i) {
        ////console.log.log("Este es el numero")
        ////console.log.log(i)
        delete (numbers[i])
        const resultado = numbers.filter(numbers => numbers != 'undefined');
        setNumbers(resultado)
        ////console.log.log(resultado);
    }

    function whatsappGeneral(i) {
        // //console.log.log("Este es el numero")
        // //console.log.log(i)
        delete (generalNumero[i])
        const resultado = generalNumero.filter(numbers => numbers != 'undefined');
        setGeneralNumero(resultado)
        ////console.log.log(resultado);
    }

    function emailGeneral(i) {
        ////console.log.log("Este es el numero")
        // //console.log.log(i)
        delete (generalEmail[i])
        const resultado = generalEmail.filter(numbers => numbers != 'undefined');
        setGeneralEmail(resultado)

        ////console.log.log(resultado);
    }

    function eliminarPosicionEmail(i) {
        ////console.log.log("Este es el numero")
        ////console.log.log(i)
        delete (postEmail[i])
        const resultado = postEmail.filter(numbers => numbers != 'undefined');
        setPostEmail(resultado)
        ////console.log.log(resultado);
    }

    function guardarData() {
        switch (prefijoNumero) {
            case '593':
                if (numero != null && numero != "" && prefijoNumero != null && prefijoNumero != "") {
                    if (numero.length == 10) {
                        //console.log.log("Entrnadooooo")
                        //console.log.log(numero.length)
                        if (numero.slice(0, 1) == 0) {
                            ////console.log.log("No tiene cero")
                            let ta = numero.slice(1)
                            ta = `${prefijoNumero}${ta}`
                            let data = {}
                            data.number = ta

                            numbers.push(data)
                            ////console.log.log(numbers)
                            setNumbers(numbers)
                            setPrefijoNumero("")
                            setNumero("")
                            setSelectTipo("")
                            setVeri(false)
                            // setAux(!aux)
                        }

                    } else if (numero.length == 9) {

                        setVeri(false)
                        setPrefijoNumero("")
                        setNumero("")
                        setSelectTipo("")


                    } else {
                        setVeri(true)
                        setMensajeVe("La cantidad de diguitos no es admitible")


                    }
                } else {
                    setVeri(true)
                    setMensajeVe("No puede estar vacio")



                }

                break;
            default:
            //console.log.log(`Sorry, we are out of .`);
        }

        // if (numero != null && numero != "" && prefijoNumero != null && prefijoNumero != "") {
        //     if (prefijoNumero == "593") {

        //     }

        //     if (numero.slice(0, 1) == 0) {
        //         ////console.log.log("No tiene cero")
        //         let ta = numero.slice(1)
        //         ta = `${prefijoNumero}${ta}`
        //         let data = {}
        //         data.number = ta

        //         numbers.push(data)
        //         ////console.log.log(numbers)
        //         setNumbers(numbers)
        //         setPrefijoNumero("")
        //         setNumero("")
        //         setSelectTipo("")
        //         // setAux(!aux)

        //     }
        // }
    }

    function guardarEmail() {

        let expresion = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/

        if (mail != null && mail != "") {
            if (expresion.test(mail)) {
                postEmail.push(mail)
                setPostEmail(postEmail)
                setMail("")
                setSelectTipo("")
                setVeriE(false)
            } else {
                setMensajeVeE("No es un correo valido")
                setVeriE(true)
            }

        } else {
            setMensajeVeE("El campo de correo no puede estar vacio")
            setVeriE(true)
        }

    }

    useEffect(() => {
        let prueba
        //console.log.log("TIENE DATAAAAAAAAAAAAAAAAAAAAAAA")
        //console.log.log(numbers)
        //console.log.log(postEmail)
        prueba = Auth.getAlert()
        setDocumento(prueba)
        //console.log.log("first")
        //console.log.log("first")
        //console.log.log("first")
        //console.log.log(prueba)
        //console.log.log("first")
        //console.log.log("first")
        //console.log.log("first")
        validacionBotones()
        //console.log.log(generalNumero)
        //console.log.log(generalEmail)
        ////console.log.log(numbers)
    }, [numbers, selecTipo]);

    ////console.log.log(numbers)
    return (
        <>
            <Main>
                <NavbarTabla>
                    <ContenedorTitulo>
                        <Titulo> Medios de Comunicacion </Titulo>
                    </ContenedorTitulo>
                    <BotonCrear onClick={handleOpen}>Crear</BotonCrear>
                </NavbarTabla>


                {open ? null : <>
                    {generalNumero.map((index, i) => {
                        return (<>
                            <ContenedorComuW>
                                <Icono_ContenedorComuW src={iconWhatsapp} alt="MDN" />
                                <Numero_ContenedorComuW>{index.number}</Numero_ContenedorComuW>
                                <Icono2_ContenedorComuW onClick={() => whatsappGeneral(i)}>
                                    <DeleteIcon />
                                </Icono2_ContenedorComuW>

                            </ContenedorComuW>
                        </>)
                    })}

                    {generalEmail.map((index, i) => {
                        return (<>
                            <ContenedorComuW>
                                <Icono_ContenedorComuW src={iconEmail} alt="MDN" />
                                <Numero_ContenedorComuW>{index}</Numero_ContenedorComuW>
                                <Icono2_ContenedorComuW onClick={() => emailGeneral(i)}>
                                    <DeleteIcon />
                                </Icono2_ContenedorComuW>

                            </ContenedorComuW>
                        </>)
                    })}
                </>}



                <Modal
                    hideBackdrop
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Box sx={{ ...style }}>

                        <h2 id="child-modal-title">Medios de comunicacion</h2>
                        <p id="child-modal-description">
                            Debe seleccionar por que medio desea que le llegue las notificaciones.
                        </p>

                        <Select
                            message="Medio"
                            value={selecTipo}
                            setFunction={setSelectTipo}
                            data={Tipo}
                            id="simple-disabled"
                        ></Select>

                        {selecTipo == "whatsapp" ? <>

                            <Select
                                message="Prefijo"
                                value={prefijoNumero}
                                setFunction={setPrefijoNumero}
                                data={codigoNumero}
                                id="simple-disabled"
                            ></Select>
                            <Input2
                                message="Numero"
                                value={numero}
                                setFunction={setNumero}
                                id="outlined-disabled"
                            />
                            {veri ? <Verificacion>*{mensajeVe}</Verificacion> : null}

                            <Botton2_Contenedor>
                                <Botton2 onClick={guardarData}>
                                    Agregar
                                </Botton2>
                            </Botton2_Contenedor>

                        </> : null}

                        {selecTipo == "email" ? <>

                            <Input2
                                message="Email"
                                value={mail}
                                setFunction={setMail}
                                id="outlined-disabled"
                            />
                            <Botton2_Contenedor>
                                {veriE ? <Verificacion>*{mensajeVeE}</Verificacion> : null}
                                <Botton2 onClick={guardarEmail}>
                                    Agregar
                                </Botton2>
                            </Botton2_Contenedor>

                        </> : null}

                        {numbers <= 0 ? null : <>

                            {numbers.map((index, i) => {
                                return (
                                    <>
                                        <ContenedorComuW>
                                            <Icono_ContenedorComuW src={iconWhatsapp} alt="MDN" />
                                            <Numero_ContenedorComuW>{index.number}</Numero_ContenedorComuW>
                                            <Icono2_ContenedorComuW onClick={() => eliminarPosicion(i)}>
                                                <DeleteIcon />
                                            </Icono2_ContenedorComuW>

                                        </ContenedorComuW>

                                    </>
                                )
                            })}
                        </>}

                        {postEmail <= 0 ? null : <>

                            {postEmail.map((index, i) => {
                                return (
                                    <>
                                        <ContenedorComuW>
                                            <Icono_ContenedorComuW src={iconEmail} alt="MDN" />
                                            <Numero_ContenedorComuW>{index}</Numero_ContenedorComuW>
                                            <Icono2_ContenedorComuW onClick={() => eliminarPosicionEmail(i)}>
                                                <DeleteIcon />
                                            </Icono2_ContenedorComuW>

                                        </ContenedorComuW>


                                    </>
                                )
                            })}
                        </>}

                        <Button onClick={handleClose2}>Cancelar</Button>
                        <Button onClick={handleClose}>Guardar</Button>
                    </Box>
                </Modal>



                <Testing>
                    <BotonRetroceder onClick={returnPage}>Atrás</BotonRetroceder>
                    <BotonSiguiente onClick={guardarAlerta}>Finalizar</BotonSiguiente>
                </Testing>



            </Main>
        </>
    )
}

export default MedioAlerta

