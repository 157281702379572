import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const Input = (props) => {
  return (
    <>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >

      {
        props.type=="password" ? <TextField
        type={props.type}
            id={props.id}
            label={props.message}
            value={props.value}
            variant="outlined"
            onChange={(e) => props.setFunction(e.target.value)}
          /> : null
      }
        {props.setFunction ? (
          <TextField
            id={props.id}
            label={props.message}
            value={props.value}
            variant="outlined"
            onChange={(e) => props.setFunction(e.target.value)}
          />
        ) : (
          <TextField
            defaultValue
            id={props.id}
            label={props.message}
            value={props.value}
            variant="outlined"
          />
        )}
      </Box>
    </>
  );
};

export default Input;
