import React, {useEffect,useState} from 'react';
import Ventana from './betaform'
import 'w3-css'
import '../betatemp/estiloform.css'
import { BotonModal,BootstrapModa } from './beta_editar';
import ReactToExcel from 'react-html-table-to-excel';
import {CSVLink} from 'react-csv'



import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
  } from "react-bootstrap";
  import { render } from "react-dom";
  
  
const Consumo =() => {
  const  errarse ='http://iot.constecoin.com/api/inv/inventario/borrarProducto'
  const  links ='https://iot.constecoin.com/api/administracion-dispositivos/dispositivos/listaDispositivos'

    
    const [datos, setDatos]= useState([])

    const [search,setSearch]=useState('')



    
    const [show, setShow] = useState(false);
    const [item, setItem] = useState([]);
    // const [tablaFiltrada, setItem] = useState([]);
    const handleClose =()=> setShow(false);
   const handleShow = (i)=> {
    setItem(i);

    setShow(true);
      
     
       }
   /*  function borrar (id){
      var eliminar ={_id:id}
      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(eliminar)}

        fetch(errarse, requestOptions)
        .then(response => console.log(response))
       
        
   
       
    } 
     */
    const peticionGet = async () => {
        const data =await fetch(links)
       const contenido = await data.json()
    
     setDatos(contenido)
  
    }
    useEffect(()=>{
        peticionGet()
       // console.log(datos)
        
    },)
  

    // handleChangeOrganizacion = (event) => {
    //   this.setState({ selectedOrganizacion: event.target.value });
    //   console.log(event.target.value)
    //   this.filtroTablaDevices(this.state.devicesTable, event.target.value);
    // };

    // filtroTablaDevices(tabla, organizacion){
  
    //   const result = tabla.filter(item => {
    //     console.log(item);
    //     let auxOrganizacion = false, auxTipo = true;
    //     if(organizacion === 'all'){
    //       auxOrganizacion = true;
    //     }
    //     if(organizacion === null){
    //       auxOrganizacion = true;
    //     }
    //     else {
    //       if(item.organization === organizacion){
    //         auxOrganizacion = true;
    //       }
    //     }
    //     // if(this.state.selectedTipo === null){
    //     //   auxTipo = true;
    //     // }
    //     // else {
    //     //   if(item.tipo === this.state.selectedTipo){
    //     //     auxTipo = true;
    //     //   }
    //     // }
  
    //     if(auxOrganizacion && auxTipo){
    //       console.log(true);
    //     }
  
    //     return (auxOrganizacion && auxTipo);
    //   });
  
    //   this.setState({tablaFiltrada: result})
  
    // }
  
    


    return(
        <>
        <section className="w3-center w3-panel">
          <Ventana className="w3-container w3-modal-content w3-teal"/> 
        </section>
        {/* <Col md="2">
              <div class="selectores">
                <label for="organizacion">Organización:</label>
                <select id="organizacion" onChange={this.handleChangeOrganizacion}>
                  <option value="" disabled  hidden>seleccionar</option>
                  <option selected value="all">Todos</option>
                  <option value="semagro">Semagro</option>
                  <option value="constecoin">Constecoin</option>
                </select>
              </div> 
            </Col> */}
        <Col md="2">
              <div>
              <ReactToExcel
              className="btn btn-danger button"
              table="tabla-obtenida"
              filename="iotTablaConste"
              sheet="sheet 1"
              buttonText="Exportar como xls"
              />
              </div> 
            </Col>

            <Col md="2">
              <div>
                <CSVLink data={datos} filename={"iotTablaConste.csv"}>
                  <Button className="button" variant="danger"> Exportar como csv</Button>
                </CSVLink>
              </div> 
            </Col>

<div className="w3-left">
</div>
    <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">Dispositivos</Card.Title>
                  <p className="card-category">
                    Dispositivos Beta Temp
                  </p>
                  <input className=" busqueda  "   type="text" placeholder="busqueda de dispositivo" onChange={(event) => {
            setSearch(event.target.value)
        }}></input>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table className="table-hover table-striped" id="tabla-obtenida">
                    <thead>
                      <tr>
                        <th className="border-0">Device ID</th>
                        {/* <th className="border-0">Nombre</th> */}
                        <th className="border-0">Organizacion</th>
                        <th className="border-0">Tipo</th>
                        <th className="border-0">Sigfox</th>
                        <th className="border-0">MAC</th>
                        <th className="border-0">Nombre</th>
                       
                      </tr>
                    </thead>
                    <tbody>
                      {datos.filter((value)=>{
        if (search==""){
        return value}
        else if (value.device_ID.toLowerCase().includes(search.toLowerCase())){
          return value
        }}).map(( item, index ) => {
                        return (
                          <tr key={index}>
                            <td>{item.device_ID}</td>
                            <td>{item.organization}</td>
                            <td>{item.tipo}</td>
                            <td>{item.idSigfox}</td>
                            <td>{item.idWifi}</td>
                            {/* <td>{item.tipoDispositivo} </td> */}
                            <td>{item.nombre} </td>
                            
                            <td>
                              <BotonModal className ="w3-button w3-blue w3-large" etiqueta ="Editar" onClick={()=>handleShow(item)} ></BotonModal> 
                             {/*  <Button onClick={()=>borrar(item._id)} className ="w3-deep-orange w3-button  w3-large"> Eliminar</Button> */}
                              </td>
                    
                          </tr>
                        );
                      })}

                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <BootstrapModa 
            setShow ={show}
            handleClose={handleClose}
            item={item}
            id={item._id}
            nombre={item.nombre}
            organization={item.organization}
            descripcion={item.descripcion}
            codigoDeBarras={item.codigoDeBarras}
        
            
          />


        </>
    )
} 
export default Consumo


/* .filter((value)=>{
  if (search==""){
  return value}
  else if (value.nombre.toLowerCase().includes(search.toLowerCase())){
    return value
  }}) */