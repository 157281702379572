import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import React from "react";

const YOUR_GOOGLE_API_KEY_GOES_HERE = "AIzaSyBYNDUEf0g8OQPJfiEW7ybizYPiMN_7XOU";

const Mapa = (props) => {
  return (
    <>
      <Map
        google={window.google}
        initialCenter={{
          lat: props.lat,
          lng: props.lng,
        }}
        style={props.styleMapa}
        zoom={11}
      >
        <Marker
          position={{
            lat: props.lat,
            lng: props.lng,
          }}
        />
      </Map>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: YOUR_GOOGLE_API_KEY_GOES_HERE,
})(Mapa);
