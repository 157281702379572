import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { render } from "react-dom";


class General extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      open:  false,
      devicesTable: []
    };    
  }

render() {
  return (
    <>
      <Container fluid>
        <Row>
          {/* <div className="col-4">
            <img src="/assets/img/almacenaje-01.png"/>
          </div> */}
        </Row>
      </Container>
    </>
  );
}
}

export default General;





