import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import FilterListIcon from "@material-ui/icons/FilterList";
import swal from "sweetalert";
import SearchIcon from "@material-ui/icons/Search";
import Api from "../../../../services/api-service";
import { useHistory } from "react-router-dom";
import Widthf from "../Gabitene/widg";
import ImagenAbierta from "./abierto.png";
import ImagenCerrada from "./cerrado.png";
import Prendido from "./prendido.png";
import Apagado from "./apagado.png";
import AccesoCOntro from "../Gabitene/controlacceso";
import CuadroTemp from "../../../Gabinete/CuadroTemp";
import Auth from "../../../../services/auth-service.js";
import {
  BarraBusqueda,
  BotonCrear,
  BotonFiltro,
  Categorias,
  ContenedorCategorias,
  ContenedorTabla,
  ContenedorTitulo,
  Main,
  NavbarTabla,
  Th,
  Titulo,
  Tr,
  TablaDispo,
  TrC,
  CoFiltroIn,
  Divfiltro,
  SelectFiltro,
  IconoLupa,
  ContenedorDis,
  Nombredispositivo,
  DescripcionDispo,
  Organizacion,
  Tipo,
  ContenedorFecha,
  Estado,
  Tiempo,
  Fecha,
  BotonMostrar,
  Widget,
  ContenedorWidget,
  ParaImagen,
  BotonVerDash,
} from "../../DataCenterElements";
import InputBusqueda from "../../../../components/Table/inputBusqueda";

const Creacion = () => {
  let history = useHistory();
  const [results, setResults] = useState([]);
  const [text, setText] = useState("");
  const [controlA, setControlA] = useState(0);
  const [encendido, setEncendido] = useState(1);
  const [auxRol, setAuxRol] = useState(false);

  useEffect(() => {
    let constarnte = Auth.getRol();
    if (constarnte == "visualizador") {
      setAuxRol(true);
    }
    console.log("entro al use effect");
    Api.getGabineteByPartialId(text).then(
      (result) => {
        setResults(result);
        console.log("Este es el resultado");
        console.log(result);
      },
      (error) => {
        console.log("Obtencion erronea");
      }
    );
  }, [text]);

  function datasend() {
    swal({
      icon: "success",
      title: "Enviado",
    });
    console.log("Entrnado ");
    Api.enviardatosWHatsapp(
      results[0]?.gabineteID,
      results[0]?.temperatura,
      results[0]?.humedad,
      results[0]?.estado1,
      results[0]?.estado2
    );
  }

  var Datoss = [
    {
      temperature: "32.72",
      mac: "Constecoin Gabinete",
    },
  ];

  return (
    <>
      <Main>
        <ContenedorTitulo>
          <Titulo>Data Center</Titulo>
        </ContenedorTitulo>

        <ContenedorTabla>
          <NavbarTabla>
            <BarraBusqueda>
              <IconoLupa>
                <SearchIcon />
              </IconoLupa>
              <InputBusqueda setText={setText} text={text} />
            </BarraBusqueda>
            <p>{auxRol}</p>
            {/* {if(constarnte=="visualizador"){
                
              }{}} */}
            {auxRol ? null : (
              <BotonCrear
                onClick={() => {
                  datasend();
                }}
              >
                Enviar Whatsapp
              </BotonCrear>
            )}

            <BotonCrear
              onClick={() => {
                var url = "/admin/crearGabinete";
                history.push(url);
              }}
            >
              Crear Nuevo
            </BotonCrear>
            {/* <BotonMostrar
              onClick={() => {
                var url = "/admin/viewDevices";
                history.push(url);
              }}
            >
              Mostrar todo
            </BotonMostrar> */}
          </NavbarTabla>
          <TablaDispo>
            <Tr>
              <Th>GabineteID</Th>
              <Th>Organizacion</Th>
              <Th>Numero Dispositivos</Th>
              <Th>Fecha Creacion</Th>
            </Tr>

            {results.length > 0 ? (
              results.map((device, i) => (
                <TrC>
                  <td>
                    <ContenedorDis>
                      <Nombredispositivo>{device.gabineteID}</Nombredispositivo>
                    </ContenedorDis>
                  </td>

                  <td>
                    <Organizacion>{device.organizacion}</Organizacion>
                  </td>

                  <td>
                    <ContenedorDis>
                      <Nombredispositivo>
                        {device.dispositivos.length}
                      </Nombredispositivo>
                    </ContenedorDis>
                  </td>
                  <td>
                    <ContenedorFecha>
                      <Fecha>{device.fechaRegistro}</Fecha>
                    </ContenedorFecha>
                  </td>
                </TrC>
              ))
            ) : (
              <div>No hay resultados</div>
            )}
          </TablaDispo>

          {results.length > 0
            ? results.map((index) => (
              <>
                <ContenedorWidget>
                  <Widget>
                    {index.estado1 == 1 ? (
                      <>
                        <ParaImagen src={ImagenAbierta} />
                        <p>Puerta Abierta</p>
                      </>
                    ) : (
                      <>
                        <ParaImagen src={ImagenCerrada} />
                        <p>Puerta Cerrada</p>
                      </>
                    )}

                    {/* <BotonVerDash
                onClick={() => {
                  var url = "/admin/controlAcceso";
                  history.push(url);
                }}
              >
                Control Acceso
              </BotonVerDash> */}
                  </Widget>
                  <Widget>
                    {index.estado2 == 1 ? (
                      <>
                        <ParaImagen src={Prendido} />
                        <p>Prendido</p>
                      </>
                    ) : (
                      <>
                        <ParaImagen src={Apagado} />
                        <p>Apagado</p>
                      </>
                    )}
                    {/* <BotonVerDash
                onClick={() => {
                  var url = "/admin/Gabinete?organizacion=gabinetes";
                  history.push(url);
                }}
              >
                Control Energia
              </BotonVerDash> */}
                  </Widget>
                  <Widget>
                    {/* <Widthf data={results} /> */}
                    <Widthf tem={index.temperatura} hum={index.humedad} />
                    {/* <Widthf tem={59} hum={10} /> */}
                    <BotonVerDash
                      onClick={() => {
                        var url = "/admin/Gabinete?organizacion=gabinetes";
                        history.push(url);
                      }}
                    >
                      Temperatura & Humedad
                    </BotonVerDash>
                  </Widget>
                </ContenedorWidget>
              </>
            ))
            : null}

          {/* <Widthf /> */}
          {/* <AccesoCOntro />
          <CuadroTemp info={Datoss} /> */}
        </ContenedorTabla>
      </Main>
    </>
  );
};

export default Creacion;
