import React from "react";

import './AdministracionCuentas.css';
import Api from '../../services/api-service.js'
import Auth from '../../services/auth-service.js';
import ReactToExcel from 'react-html-table-to-excel';
import {CSVLink} from 'react-csv'

import Ventana from '../../components/betatemp/betaform'
import Beta from '../../components/betatemp/ConsumoBeta'
import { BotonModal,BootstrapModa } from '../../components/betatemp/beta_editar';
import { makeStyles } from '@mui/styles';
// react-bootstrap components


// import { BotonModal,BootstrapModa } from '../components/betatemp/beta_editar';

import AgregarUsuarios from './formularios/AgregarUsuarios.js'
import EditarUsuarios from './formularios/EditarUsuarios.js'


import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { render } from "react-dom";





class AdministracionCuentas extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      open:  false,
      selectedTipo: null,
      selectedOrganizacion: 'all',
      devicesTable: [],
      tablaFiltrada: [],
      listaOrganizaciones: [],

      showBetatemp: false,
      showSigfox: false,
      selectedItem: null,
      showEdit: false,
      isModalOpen: false,
      children: null,
      ModalSetup: null,
      rol: Auth.getRol(),
      organizaciones: Auth.getOrganizaciones() 
    }; 
    this.buttonClick('sigfox');   
    // this.setState({showBetatemp: false, showSigfox: true})

    

  
    this.actualizarTablaCuentas();
    this.actualizarListaEmpresas();

  }

  componentWillMount(){
    console.log(this.state.organizaciones);

    let auxFilter = this.state.organizaciones.filter(item=> item.organizacion === 'gpf');

    if(auxFilter.length >= 1){
      console.log(auxFilter[0].rol);
      this.state.rol = auxFilter[0].rol;
      this.setState({rol: auxFilter[0].rol});
    }


    // this.setState({rol: Auth.getRol()});
  }

  actualizarTablaCuentas(){

    Api.obtenerListaUsuarios({organizacion: this.state.selectedOrganizacion, estado: "all"}).then(
      result=>{
        console.log(result);
        this.setState({devicesTable: result})
        this.setState({tablaFiltrada: result})
      }, error=>{
        console.log('error en la adquisicion de datos', error);
      }
    )
  }

  actualizacionTabla= ()=>{
    console.log('actualizacion ok')
    this.actualizarTablaDispositivos();
    // this.actualizarTablaDispositivos();
  }


  
  
  handleModalOpen =()=> {
    this.setState({isModalOpen: !this.state.isModalOpen})
  }
  dismissable =()=> {
    console.log('salir')
    this.setState({
    isModalOpen: false})
  }

  handleChangeTipo = (event) => {
    this.setState({ selectedTipo: event.target.value });
    console.log(event.target.value)
  };
  handleChangeOrganizacion = (event) => {
    this.setState({ selectedOrganizacion: event.target.value });
    console.log(event.target.value)
    this.filtroTablaDevices(this.state.devicesTable, event.target.value);
  };

  handleShow = (item)=> {
    this.setState({selectedItem : item})
    
    if(item){
      this.setState({showEdit : true})
    } else{
      this.setState({showEdit : false})
    }
  }

  buttonClick(tipo){

    if(tipo === 'sigfox'){
      this.setState({showBetatemp: false, showSigfox: true})
      
      console.log(Auth.getJWT());
    }
    if(tipo === 'lora'){
      console.log(Auth.getJWT());
    }
    if(tipo === 'adquirir'){
      console.log(Auth.getJWT());
      this.setState({showBetatemp: true, showSigfox: false})
    }
    if(tipo === 'cerrar'){
      Auth.saveJWT(null);
      this.props.history.push('/login/ingreso')
    } else {
      // }
    
      // Api.adquirirDevices().then(
      //   result=>{
      //     console.log(result);
      //     this.setState({devicesTable: result})
      //     // this.state.devicesTable = result;
      //     this.filtroTablaDevices(result, this.state.selectedOrganizacion);

      //   }, error=>{
      //     console.log('error en la adquisicion de datos');
      //   }
      // )
    }


  }

  showData = () => {
    console.log(this.state.devicesTable)
  }
  filtroTablaDevices(tabla, organizacion){
  
    const result = tabla.filter(item => {
      console.log(item);
      let auxOrganizacion = false, auxTipo = true;
      if(organizacion === 'all'){
        auxOrganizacion = true;
      }
      if(organizacion === null){
        auxOrganizacion = true;
      }
      else {
        if(item.organizacion === organizacion){
          auxOrganizacion = true;
        }
      }
      // if(this.state.selectedTipo === null){
      //   auxTipo = true;
      // }
      // else {
      //   if(item.tipo === this.state.selectedTipo){
      //     auxTipo = true;
      //   }
      // }

      if(auxOrganizacion && auxTipo){
        console.log(true);
      }

      return (auxOrganizacion && auxTipo);
    });

    this.setState({tablaFiltrada: result})

  }

  resultModalAgregarUsuarios = (value) => {
    console.log(value);
    this.actualizarTablaCuentas();
    

  }

   /////////////////////////////////////////////////////////////////  consumos ///////////////////////////////////
   actualizarListaEmpresas = () => {

    Api.obtenerListaEmpresas({}).then(
      result=>{
        console.log(result);
        this.setState({listaOrganizaciones: result});

      }, error=>{
        console.log('error en la adquisicion de datos', error);
      }
    )
  }
  


  resultModalEditarUsuarios = () =>{
    
    // this.actualizarTablaCuentas();


  }



  getOrganizacion = (organizaciones) => {
    // console.log(organizaciones)
    if(organizaciones.length === 1){
      return organizaciones[0].organizacion;
    } 
    else if(organizaciones.length > 1){
      return "multiples organizaciones";
    } else {
      return null;
    }
  }
  getRol = (organizaciones) => {
    // console.log(organizaciones)
    if(organizaciones.length === 1){
      return organizaciones[0].rol;
    } 
    else if(organizaciones.length > 1){
      return "Superadmin";
    } else {
      return null;
    }
  }


  
render() {
  const {isModalOpen} = this.state;
  let children;
  if(isModalOpen){
    children =(
      <div className="estiloModal">hola</div>
    );
  }
  return (
    <>
    <Container fluid>


      <Row>

      <section className="w3-center w3-panel">
          {/* <Ventana className="w3-container w3-modal-content w3-teal"/>  */}
        </section>

            <Col md="2">
              <div class="selectores">
                <label for="organizacion">Organización:</label>
                <select id="organizacion" onChange={this.handleChangeOrganizacion}>
                  {/* <option value="" disabled  hidden>seleccionar</option> */}
                  <option selected value="all">Todos</option>
                  
                  {this.state.listaOrganizaciones.map(( item, index ) => {
                      return (
                        <option value={item.organizacion}>{ item.nombre}</option>
                        )
                    })}
                  {/* <option value="constecoin">Constecoin</option> */}
                </select>
              </div> 
            </Col>
            <Col md="2">
            {(this.state.rol=='administrador' || this.state.rol=='administrador general' || true) 
              &&<AgregarUsuarios resultCallback={this.resultModalAgregarUsuarios.bind(this)} organizaciones={this.state.organizaciones}></AgregarUsuarios>}
            </Col>

            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">Devices</Card.Title>
                  <p className="card-category">
                    Tabla de dispositivos conectados
                  </p>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table className="table-hover table-striped" id="tabla-obtenida">
                    <thead>
                      <tr>
                        {/* <th className="border-0">ID</th> */}
                        <th className="border-0">Usuario</th>
                        <th className="border-0">Organización</th>
                        <th className="border-0">Rol</th>
                        <th className="border-0">Email</th>
                        <th className="border-0">Estado</th>
                        {/* <th className="border-0">SIGFOX</th>
                        <th className="border-0">MAC</th> */}
                        <th className="border-0">Edición</th>
                        <th className="border-0">Configuración</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.tablaFiltrada.map(( item, index ) => {
                        return (
                          <tr key={index}>
                            <td>{item.usuario}</td>
                            <td>{this.getOrganizacion(item.organizaciones)}</td>
                            <td>{this.getRol(item.organizaciones)}</td>
                            <td>{item.email}</td>
                            {/* <td>{item.grupo}</td> */}
                            <td>{item.estado}</td>
                            <td><EditarUsuarios item={item} resultCallback={this.resultModalEditarUsuarios.bind(this)}></EditarUsuarios></td>
                            {/* <td><ConfiguracionEmpresas item={item} resultCallback={this.resultModalEditarEmpresa.bind(this)}></ConfiguracionEmpresas></td> */}
                            <td></td>
                            {/* <td></td> */}
                          </tr>
                        );
                      })}

                    </tbody>
                  </Table>
              
                </Card.Body>
              </Card>
            </Col>

        </Row>
      </Container>
      {/* <ModalSetup
             visible={isModalOpen}
             dismiss={this.dissmissable}
             children={children} 
          /> */}

      {/* {this.state.isModalOpen && <ModalEdicion />} */}
      
      {/* <ModalEdicion 
          visible={isModalOpen}
          dismiss={this.dissmissable}
          children={children} 
      /> */}


      {/* <BootstrapModa 
            setShow ={this.state.showEdit}
            handleClose={()=>this.handleShow(null)}
            item={this.state.selectedItem}
            // id={item._id}
            // nombre={this.state.selectedItem.nombre}
            // organization={item.organization}
            // descripcion={item.descripcion}
            // codigoDeBarras={item.codigoDeBarras}
        
            
          > 
          test 
          <h1>holaaa</h1>
          </BootstrapModa> */}
    </>
  );
}
}




export default AdministracionCuentas;





