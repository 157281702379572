import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import Api from "../../services/api-service"
import { Button, Card, Row, Col } from "react-bootstrap";
import { CSVLink } from "react-csv";
import DateTimePicker from "@mui/lab/DateTimePicker";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Container } from "reactstrap";
import Download from "../../services/export-excel.js";
import TableContainer from "./tablaReact";


const Columnas = [
    {
        Header: "Dispositivo",
        accessor: "mac",
    },
    {
        Header: "Nombre",
        // accessor: "nombre",
        accessor: (values) => {
            // const nombre = values;
            if (values.mac == "AC233FA921BB") {
                return "bodegaGyq1"
            } else if (values.mac == "AC233FA921BC") {
                return "bodegaGyq2"
            }
        },
    },
    {
        Header: "T ambiente",
        accessor: "temperature",
    },
    {
        Header: "H Ambiente",
        accessor: "humidity",
    },
    {
        Header: "V bat (%)",
        accessor: "battery",
    },
    {
        Header: "Fecha",
        accessor: "fecha",
    },
]

const TablaTemperatura = () => {
    const [buscarFechas, setBuscarFechas] = useState(true)
    const [tipoDispositivo, setTipoDispositivo] = useState("all")
    const [fechaInicial, setFechaInicial] = useState(new Date().getTime() - 1000 * 60 * 60 * 24)
    const [fechaFinal, setFechaFinal] = useState(new Date())
    const [data, setData] = useState([])
    const [Intervalo, setIntervalo] = useState(10)
    const query = new URLSearchParams(useLocation().search);


    const buscarEntreFechas = () => {
        setBuscarFechas(!buscarFechas)
        console.log('Entroo')
    }

    const Testing = (e) => {
        setTipoDispositivo(e.target.value)
    }

    useEffect(() => {
        const organizacion = query.get("organizacion");
        let Data = {
            organizacion: organizacion,
            fechaInicial: fechaInicial,
            fechaFinal: fechaFinal,
            select: tipoDispositivo,
            intervalo: Intervalo
        }

        Api.tablaTemperaturasGPF(Data).then((respuesta) => {
            console.log("Esta es la respuesta")
            console.log(respuesta)
            setData(respuesta)
        }, (error) => {

        })
        // Api.obtenerDIspoGPF(Data).then(
        //     (result) => {
        //         console.log(result)
        //         setData(result);
        //     },
        //     (error) => {
        //         console.log("Obtencion erronea");
        //     }
        // );
    }, [tipoDispositivo, buscarFechas]);

    console.log(fechaInicial)
    return (
        <>
            <Container fluid className="w3-panel ">

                <Row>

                    <Col md="3">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                renderInput={(props) => <TextField {...props} />}
                                label="Fecha Inicial"
                                value={fechaInicial}
                                onChange={setFechaInicial}
                            />
                        </LocalizationProvider>
                    </Col>

                    <Col md="3">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                renderInput={(props) => <TextField {...props} />}
                                label="Fecha Final"
                                value={fechaFinal}
                                onChange={setFechaFinal}
                            />
                        </LocalizationProvider>
                    </Col>
                    <Col md="2">
                        <TextField id="outlined-basic" label="Intervalo" variant="outlined" value={Intervalo} onChange={(e) => setIntervalo(e.target.value)} />
                    </Col>
                    <Col md="2">
                        <Button className="buttonGeneral" onClick={buscarEntreFechas}>
                            Buscar en Rango
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col md="2">
                        <div class="selectores">
                            <label for="dispositivos">Dispositivos:</label>
                            <select id="dispositivos" onChange={(e) => Testing(e)}>
                                <option selected value="all">
                                    Todos
                                </option>
                                <option value="AC233FA921BB">
                                    bodegaGyq1
                                </option>
                                <option value="AC233FA921BC">
                                    bodegaGyq2
                                </option>
                            </select>
                        </div>
                    </Col>

                    <Row>
                        <Col md="2">
                            <Download
                                head={
                                    data.length === 0
                                        ? []
                                        : Columnas
                                }
                                data={data}
                            />
                        </Col>

                        <Col md="2">
                            <div>
                                <CSVLink
                                    data={data}
                                    filename={"BodegasGYE.csv"}
                                >
                                    <Button className="buttonGeneral">
                                        {" "}
                                        Exportar como csv
                                    </Button>
                                </CSVLink>
                            </div>
                        </Col>
                    </Row>

                    <Col md="12">
                        <Card className="strpied-tabled-with-hover">
                            <Card.Header>
                                <Card.Title as="h4">Temperaturas</Card.Title>
                                <p className="card-category">Tablas de temperaturas</p>
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                <TableContainer
                                    data={data}
                                    id="tabla-obtenida"
                                />

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>

    )
}

export default TablaTemperatura