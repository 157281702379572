import React, {useEffect,useState} from 'react';
import Ventana from './BLE_form'
import 'w3-css'
import './estiloform.css'
import { BotonModal,BootstrapModa } from './BLE_editar';

import "../../../src/assets/css/busqueda.css"
import "../../../src/assets/css/texto.css"

import {
    
    Card,
   
    Table,
   
    Col,
  } from "react-bootstrap";
 
  
const Consumo =() => {
  // const  errarse ='http://iot.constecoin.com/api/inv/inventario/borrarProducto'
  const  links ='https://iot.constecoin.com/api/cadenaFrio/administracion-ble/administracion/getDevices'

    
    const [datos, setDatos]= useState([])

    const [search,setSearch]=useState('')



    
    const [show, setShow] = useState(false);
    const [item, setItem] = useState([]);
    const handleClose =()=> setShow(false);
   const handleShow = (i)=> {
        setShow(true);
        setItem(i)
      
     
       }
   /*  function borrar (id){
      var eliminar ={_id:id}
      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(eliminar)}

        fetch(errarse, requestOptions)
        .then(response => console.log(response))
       
        
   
       
    } 
     */
    const peticionGet = async () => {
        const data =await fetch(links)
       const contenido = await data.json()
    
     setDatos(contenido)
  
    }
    useEffect(()=>{
        peticionGet()
        //console.log(datos)
        
    },)
  

    return(
        <>
          {/* <section className="w3-center w3-panel">
      
       </section> */}

<div className="w3-left">
</div>
<Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4" className="texto-titulo titulo-card">DISPOSITIVOS BLE </Card.Title>
                  <p className="subtitulo-card">
                    Tabla de registro de dispositivos BLE 
                  </p>
                  <input className=" busqueda  texto-busqueda"  type="text" placeholder="busqueda por mac " onChange={(event) => {
            setSearch(event.target.value)
        }}></input>
        <section> <Ventana className="w3-container w3-modal-content w3-teal registro"/> </section>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table className="table-hover table-striped">
                    <thead>
                      <tr className="cuadro-titulo">
                        {/* <th className="border-0">ID</th> */}
                        <th className="border-0 texto-titulo w-25">Mac</th>
                        <th className="border-0 texto-titulo w-25">Fecha de Registro</th>
                        <th className="border-0 texto-titulo w-10">tipo</th>
                        <th className="border-0 texto-titulo w-10">Zona</th>
                        <th className="border-0 texto-titulo w-15">Acciones</th>
                       
                      </tr>
                    </thead>
                    <tbody>
                      {datos.filter((value)=>{
        if (search==""){
        return value}
        else if (value.mac.toLowerCase().includes(search.toLowerCase())){
          return value
        }}).map(( item, index ) => {
                        return (
                          <tr key={index}>
                            {/* <td>{item._id}</td> */}
                           
                            <td className="text-tabla">{item.mac}</td>
                            <td className="text-tabla">{item.fechaRegistro}</td>
                            <td className="text-tabla">{item.tipo} </td>
                            <td className="text-tabla">{item.svgID}</td>
                          
                           
                            
                          
                            
                            
                            <td>
                              <BotonModal className ="buttonGeneral" etiqueta ="Editar" onClick={()=>handleShow(item)} ></BotonModal> 
                              </td>
                    
                          </tr>
                        );
                      })}

                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <BootstrapModa 
            setShow ={show}
            handleClose={handleClose}
            item={item}
           
        
            
          />


        </>
    )
} 
export default Consumo


/* .filter((value)=>{
  if (search==""){
  return value}
  else if (value.nombre.toLowerCase().includes(search.toLowerCase())){
    return value
  }}) */