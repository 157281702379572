import React, { useState, useEffect } from "react";
import Api from "../../services/api-service";
import {
  BotonesDerecha,
  BotonesIzquierda,
  ContendorBotones,
  ContenedorEncabezado,
  ContenedorTitulo,
  Main,
  Tabla,
  Td,
  Th,
  ParaSelect,
  LabelSelect,
  Tr,
  Divfiltro,
  CoFiltroIn,
  SelectFiltro,
  Trc,
  BotonModal,
  TituloModal,
  Mapaa,
  CoFiltroInD,
  DivfiltroD,
  SelectFiltroD,
} from "./RegistroComponentes";

const Index = () => {
  const [gatewayID, setGatewayID] = useState("null");
  const [listaGateway, setListaGateway] = useState([]);
  const [dato, setDatos] = useState();

  useEffect(() => {
    Api.obtenerTodosGateway().then(
      (result) => {
        console.log("DATOS ::::::: ");
        console.log(result);
        setListaGateway(result);
      },
      (error) => {
        console.log("Obtencion erronea");
      }
    );
  }, []);

  useEffect(() => {
    Api.ObtenerDatosWategay({
      IdGateway: gatewayID,
    }).then(
      (result) => {
        console.log(result);
        setDatos(result);
        // setLoading(false);
      },
      (error) => {
        console.log("Obtencion erronea");
      }
    );
  }, [gatewayID]);

  console.log("Esto son los datos ");
  console.log(listaGateway);

  return (
    <>
      <Main>
        <ContenedorEncabezado>
          <ContenedorTitulo>Registros</ContenedorTitulo>
          <ContendorBotones>
            <CoFiltroInD>
              <DivfiltroD>
                <BotonesIzquierda id="dispo">Dispositivo</BotonesIzquierda>

                <SelectFiltroD
                  id="dispo"
                  onChange={(event) => setGatewayID(event.target.value)}
                >
                  <option value="null" selected>
                    Selecciona un Dispositivo
                  </option>
                  {listaGateway?.map((item, i) => {
                    return (
                      <option value={item.IdGateway}>{item.IdGateway}</option>
                    );
                  })}
                </SelectFiltroD>
              </DivfiltroD>
            </CoFiltroInD>
          </ContendorBotones>
        </ContenedorEncabezado>
        <Tabla>
          <Tr>
            <Th>GATEWAY</Th>
            <Th>CSQ</Th>
            <Th>CREG</Th>
            <Th>Fecha reporte</Th>
          </Tr>
          {dato?.map((item, i) => {
            return (
              <Trc>
                <Td>{item.IdGateway}</Td>
                <Td>{item.csq}</Td>
                <Td>{item.creg}</Td>
                <Td>{item.fechaRegistro}</Td>
              </Trc>
            );
          })}
        </Tabla>
      </Main>
    </>
  );
};

export default Index;
