import React, {useState} from 'react'
import { Button,Modal } from 'react-bootstrap'
import 'w3-css/w3.css';
import Tx from '../input';
import './estiloform.css'


export default function BootstrapModa(props) {
    const [show, setShow] = useState(false);
    const [datos, setDatos] = useState({
      mac: "",
      tipo: "",
     
     
    });


    const expresiones = {
      //usuario: /^[a-zA-Z0-9\_\-]{4,16}$/, // Letras, numeros, guion y guion_bajo
      
      mac: /^[a-zA-Z0-9]{1,12}$/,
      tipo: /^[a-z]{1,40}$/, // Letras y espacios, pueden llevar acentos. /// grupo:
      
    }
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const  links ='https://iot.constecoin.com/api/cadenaFrio/administracion-ble/administracion/registroDevice'


    function enviarDatos (e) {
      var envio  ={
        deviceID:datos.mac,
        tipo:datos.tipo,
       
    }   
        e.preventDefault ();
        
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(envio)}
       
        console.log(JSON.stringify(envio)  )
        fetch(links, requestOptions)
        .then(response => console.log(response))
          setDatos({  mac: "",
          tipo: ""
        })
       setShow(false)
    }
    
    const ingreso= (event)=>{
      //console.log(event.target.value)
      setDatos({
        ...datos,[event.target.name]:event.target.value
      })
    }

    
    return (
        
        <>
         
  
  
          <Modal
        
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className=" w3-modal-content{background-color: transparent} w3-animate-zoom" style={{maxwidth:600}}>
            <Modal.Header closeButton className="w3-rigth">
            
            </Modal.Header>
            <Modal.Body>
             
             
      <div className="w3-row">  
        
        <div className="w3-rest  "   >
           <label>Registro de dispositivos  </label>
    
        <form onSubmit={enviarDatos }>
            <table className="w3-table">
           <tfoot>
        <tr>
          <td> 

          {/* deviceID: "",
      tipo: "",
      grupo: "",
      organizacion: "" */}
        
            <Tx
            
             etiqueta = "MAC"
             tipo = "text"
             name='mac'
             onChange={ingreso}
             value ={datos.mac}
             expresion ={expresiones.mac}
            />
          </td>
          <td>
          <Tx 
          etiqueta ="tipo"
          tipo = "text"
          name='tipo'
          onChange={ingreso}
          value ={datos.tipo}
             expresion ={expresiones.tipo}
             ayuda

            /> 
            <label>solo S1, E9 , o gateway</label>
          </td>
        </tr>
        
        </tfoot>
           </table>
           <Button  className="w3-button w3-green w3-large" variant="primary" type="submit">Crear </Button>
           </form>

        </div>
        </div>
  
            </Modal.Body>
            
          </Modal>

          <Button  className="buttonGeneral" variant="primary" onClick={handleShow}>
            Registro 
          </Button>
        </>
      );
    }