import React, { Component } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper, Circle } from 'google-maps-react';

class MapaGoogle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contador: 0,
      markers: new Object(),
      limite: 1,
      showCircle: false,
      radio: ""
    };
    this.onClick = this.onClick.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
  }

  onClick(t, map, coord) {
    if (this.state.contador < this.state.limite) {
      const { latLng } = coord;
      const lat = latLng.lat();
      const lng = latLng.lng();
      this.state.contador = this.state.contador + 1;

      if (this.state.markers.length >= 0) {
        this.setState(previousState => {
          return {
            markers: [
              ...previousState.markers,
              {
                title: "",
                name: "",
                position: { lat, lng }
              }
            ]
          };
        });
      } else {
        this.setState({
          markers: [
            {
              title: "",
              name: "",
              position: { lat, lng }
            }
          ]
        })
      }
    }
    this.setState({
      showCircle: true
    })
    // console.log("marcadores", this.state.markers)
    this.props.parentCallback(this.state)

  }

  elimina(array, elem) {
    var var2 = array.filter(function (e) {
      return e.position.lat !== elem.position.lat &&
        e.position.lng !== elem.position.lng
    });
    return var2
  }

  onMarkerClick(event) {
    var var1 = this.elimina(this.state.markers, { title: "", name: "", position: { lat: event.position.lat, lng: event.position.lng } })
    this.setState({
      markers: var1,
      showCircle: false
    })
    if (this.state.contador !== 0) {
      this.setState({
        contador: this.state.contador - 1
      })
    }
    // console.log("Nuevos marcadores", this.state.markers,"\n contador", this.state.contador)
    this.props.parentCallback(this.state)
  }

  radioFunction = (a) => {
    this.state.radio = a.currentTarget.value;
    this.setState({
      radio: a.currentTarget.value
    })
    console.log("Radio", this.state.radio)
    this.props.parentCallback(this.state)
  }

  render() {
    return (
      <div>
        {/* <h1 className="text-center">My Maps</h1> */}
        <label for="radio"> Radio:</label>
        <input type="number" min="0" id="radio" name="radio1" onChange={this.radioFunction.bind(this)} />
        <Map
          google={this.props.google}
          initialCenter={{
            lat: -0.163202,
            lng: -78.481166
          }}
          style={{ width: "100%", margin: "auto" }}
          className={"map"}
          zoom={17}
          onClick={this.onClick}
        >
          {this.state.markers.length >= 0 && this.state.markers.map((marker, index) => (
            <Marker
              key={index}
              title={marker.title}
              name={marker.name}
              position={marker.position}
              onClick={this.onMarkerClick}
            />
          ))}

          {this.state.showCircle && this.state.radio && <Circle
            radius={parseInt(this.state.radio)}
            center={this.state.markers[0].position}
            // onMouseover={() => console.log('mouseover')}
            // onClick={this.onCircleClick}
            // onMouseout={() => console.log('mouseout')}
            strokeColor='transparent'
            strokeOpacity={0}
            strokeWeight={5}
            fillColor='#FF0000'
            fillOpacity={0.2}
          />}

        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: ("AIzaSyBYNDUEf0g8OQPJfiEW7ybizYPiMN_7XOU")
})(MapaGoogle);