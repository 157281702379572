import React from "react";

import DateTimePicker from "@mui/lab/DateTimePicker";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Button, Card, Container, Row, Col } from "react-bootstrap";

import RadioHum from "./Gabinete/indicadorHumedad.js";
import LineasArea from "./Gabinete/Lineas-area.js";
import Api from "../services/api-service.js";
import CuadroTemp from "./Gabinete/CuadroTemp.js";

class Gabinete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      macIDS: [
        {
          mac: "AC233FA921B9",
        },
        {
          mac: "AC233FA921BB",
        },
        {
          mac: "AC233FA921BC",
        },
        {
          mac: "AC233FA921C4",
        },
        {
          mac: "AC233FA92027",
        },
      ],
      datosUnos: [],
      series: [{}],
      statistics: [],
      optionsTemperatura: {
        chart: {
          height: 350,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        yaxis: {
          tickAmount: 10,
          min: -150,
          max: 150,
          decimalsInFloat: 2,
        },
        xaxis: {
          type: "datetime",
          // min: fechaInicial.getTime(),
          min: new Date(),
          tickAmount: 6,
        },
        // xaxis: {
        //   tickAmount: 10,

        //   type: 'datetime',
        //   // categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
        // },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
      },
      mostrar: false,
      fechaInicial: new Date(),
      fechaFinal: new Date(),
    };
  }

  async setearFecha() {
    var fechaActual = new Date();
    var fechaInicial = fechaActual;
    ////OJO DEJAR VALOR POR DEFAULT EN CANTIDAD DE DÍAS ATRÁS (milis * seg * min * horas * días)
    fechaInicial.setTime(fechaInicial.getTime() - 1000 * 60 * 60 * 1 * 1);
    this.state.fechaInicial = fechaInicial;
    this.state.optionsTemperatura.xaxis = {
      type: "datetime",
      min: fechaInicial.getTime(),
      tickAmount: 6,
    };
    this.setState((prevState) => ({
      fechaInicial: fechaInicial,
      optionsTemperatura: {
        ...prevState.optionsTemperatura,
        xaxis: {
          type: "datetime",
          min: fechaInicial.getTime(),
          tickAmount: 6,
        },
      },
    }));
  }

  async getDataStatistics(dataSensores) {
    var tempMax = Math.max.apply(
      Math,
      dataSensores[0].map(function (o) {
        return o.temperature;
      })
    );
    var humMax = Math.max.apply(
      Math,
      dataSensores[0].map(function (o) {
        return o.humidity;
      })
    );
    var tempMax1 = Math.max.apply(
      Math,
      dataSensores[1].map(function (o) {
        return o.temperature;
      })
    );
    var humMax1 = Math.max.apply(
      Math,
      dataSensores[1].map(function (o) {
        return o.humidity;
      })
    );
    var tempMax2 = Math.max.apply(
      Math,
      dataSensores[2].map(function (o) {
        return o.temperature;
      })
    );
    var humMax2 = Math.max.apply(
      Math,
      dataSensores[2].map(function (o) {
        return o.humidity;
      })
    );
    var tempMax3 = Math.max.apply(
      Math,
      dataSensores[3].map(function (o) {
        return o.temperature;
      })
    );
    var humMax3 = Math.max.apply(
      Math,
      dataSensores[3].map(function (o) {
        return o.humidity;
      })
    );
    var tempMax4 = Math.max.apply(
      Math,
      dataSensores[4].map(function (o) {
        return o.temperature;
      })
    );
    var humMax4 = Math.max.apply(
      Math,
      dataSensores[4].map(function (o) {
        return o.humidity;
      })
    );

    var tempMin = Math.min.apply(
      Math,
      dataSensores[0].map(function (o) {
        return o.temperature;
      })
    );
    var humMin = Math.min.apply(
      Math,
      dataSensores[0].map(function (o) {
        return o.humidity;
      })
    );
    var tempMin1 = Math.min.apply(
      Math,
      dataSensores[1].map(function (o) {
        return o.temperature;
      })
    );
    var humMin1 = Math.min.apply(
      Math,
      dataSensores[1].map(function (o) {
        return o.humidity;
      })
    );
    var tempMin2 = Math.min.apply(
      Math,
      dataSensores[2].map(function (o) {
        return o.temperature;
      })
    );
    var humMin2 = Math.min.apply(
      Math,
      dataSensores[2].map(function (o) {
        return o.humidity;
      })
    );
    var tempMin3 = Math.min.apply(
      Math,
      dataSensores[3].map(function (o) {
        return o.temperature;
      })
    );
    var humMin3 = Math.min.apply(
      Math,
      dataSensores[3].map(function (o) {
        return o.humidity;
      })
    );
    var tempMin4 = Math.min.apply(
      Math,
      dataSensores[4].map(function (o) {
        return o.temperature;
      })
    );
    var humMin4 = Math.min.apply(
      Math,
      dataSensores[4].map(function (o) {
        return o.humidity;
      })
    );

    var tempAv =
      dataSensores[0].reduce((total, next) => total + next.temperature, 0) /
      dataSensores[0].length;
    var humAv =
      dataSensores[0].reduce((total, next) => total + next.humidity, 0) /
      dataSensores[0].length;
    var tempAv1 =
      dataSensores[1].reduce((total, next) => total + next.temperature, 0) /
      dataSensores[1].length;
    var humAv1 =
      dataSensores[1].reduce((total, next) => total + next.humidity, 0) /
      dataSensores[1].length;
    var tempAv2 =
      dataSensores[2].reduce((total, next) => total + next.temperature, 0) /
      dataSensores[2].length;
    var humAv2 =
      dataSensores[2].reduce((total, next) => total + next.humidity, 0) /
      dataSensores[2].length;
    var tempAv3 =
      dataSensores[3].reduce((total, next) => total + next.temperature, 0) /
      dataSensores[3].length;
    var humAv3 =
      dataSensores[3].reduce((total, next) => total + next.humidity, 0) /
      dataSensores[3].length;
    var tempAv4 =
      dataSensores[4].reduce((total, next) => total + next.temperature, 0) /
      dataSensores[4].length;
    var humAv4 =
      dataSensores[4].reduce((total, next) => total + next.humidity, 0) /
      dataSensores[4].length;

    var objStatistics = [
      {
        tempMax: tempMax,
        humMax: humMax,
        tempMax1: tempMax1,
        humMax1: humMax1,
        tempMax2: tempMax2,
        humMax2: humMax2,
        tempMax3: tempMax3,
        humMax3: humMax3,
        tempMax4: tempMax4,
        humMax4: humMax4,
      },
      {
        tempMin: tempMin,
        humMin: humMin,
        tempMin1: tempMin1,
        humMin1: humMin1,
        tempMin2: tempMin2,
        humMin2: humMin2,
        tempMin3: tempMin3,
        humMin3: humMin3,
        tempMin4: tempMin4,
        humMin4: humMin4,
      },
      {
        tempAv: parseFloat(tempAv.toFixed(2)),
        humAv: parseFloat(humAv.toFixed(2)),
        tempAv1: parseFloat(tempAv1.toFixed(2)),
        humAv1: parseFloat(humAv1.toFixed(2)),
        tempAv2: parseFloat(tempAv2.toFixed(2)),
        humAv2: parseFloat(humAv2.toFixed(2)),
        tempAv3: parseFloat(tempAv3.toFixed(2)),
        humAv3: parseFloat(humAv3.toFixed(2)),
        tempAv4: parseFloat(tempAv4.toFixed(2)),
        humAv4: parseFloat(humAv4.toFixed(2)),
      },
    ];
    // console.log("objStatistics", objStatistics)
    return objStatistics;
  }

  async dataInicial() {
    var dataUnos = await Promise.all(
      this.state.macIDS.map(async (datos) => {
        var respuestaDeGET = await this.getData2(datos.mac);
        return respuestaDeGET;
      })
    );
    // console.log("dataUnos", dataUnos)

    this.state.datosUnos = dataUnos;
    this.setState({
      datosUnos: dataUnos,
    });
    console.log("dataUnos", this.state.datosUnos);

    var dataSensores = await Promise.all(
      this.state.macIDS.map(async (datos) => {
        var respuestaDeGET = await this.getData(datos.mac);
        return respuestaDeGET;
      })
    );
    console.log("dataSensores", dataSensores);

    this.state.statistics = await this.getDataStatistics(dataSensores);
    console.log("objStatistics", this.state.statistics);

    var datosTemp = new Array();
    var datosTemp1 = new Array();
    var datosTemp2 = new Array();
    var datosTemp3 = new Array();
    var datosTemp4 = new Array();

    var datosHum = new Array();
    var datosHum1 = new Array();
    var datosHum2 = new Array();
    var datosHum3 = new Array();
    var datosHum4 = new Array();

    if (dataSensores.length == 5) {
      datosTemp = dataSensores[0].map((dato) => {
        return [
          new Date(dato.timestamp).getTime() - 5 * 60 * 60000,
          dato.temperature,
        ];
      });
      datosHum = dataSensores[0].map((dato) => {
        return [
          new Date(dato.timestamp).getTime() - 5 * 60 * 60000,
          dato.humidity,
        ];
      });
      datosTemp1 = dataSensores[1].map((dato) => {
        return [
          new Date(dato.timestamp).getTime() - 5 * 60 * 60000,
          dato.temperature,
        ];
      });
      datosHum1 = dataSensores[1].map((dato) => {
        return [
          new Date(dato.timestamp).getTime() - 5 * 60 * 60000,
          dato.humidity,
        ];
      });
      datosTemp2 = dataSensores[2].map((dato) => {
        return [
          new Date(dato.timestamp).getTime() - 5 * 60 * 60000,
          dato.temperature,
        ];
      });
      datosHum2 = dataSensores[2].map((dato) => {
        return [
          new Date(dato.timestamp).getTime() - 5 * 60 * 60000,
          dato.humidity,
        ];
      });
      datosTemp3 = dataSensores[3].map((dato) => {
        return [
          new Date(dato.timestamp).getTime() - 5 * 60 * 60000,
          dato.temperature,
        ];
      });
      datosHum3 = dataSensores[3].map((dato) => {
        return [
          new Date(dato.timestamp).getTime() - 5 * 60 * 60000,
          dato.humidity,
        ];
      });
      datosTemp4 = dataSensores[4].map((dato) => {
        return [
          new Date(dato.timestamp).getTime() - 5 * 60 * 60000,
          dato.temperature,
        ];
      });
      datosHum4 = dataSensores[4].map((dato) => {
        return [
          new Date(dato.timestamp).getTime() - 5 * 60 * 60000,
          dato.humidity,
        ];
      });
      this.state.series = {
        series1: [
          {
            name: "Temperatura",
            data: datosTemp,
          },
        ],
        series2: [
          {
            name: "Temperatura",
            data: datosTemp1,
          },
        ],
        series3: [
          {
            name: "Temperatura",
            data: datosTemp2,
          },
        ],
        series4: [
          {
            name: "Temperatura",
            data: datosTemp3,
          },
        ],
        series5: [
          {
            name: "Temperatura",
            data: datosTemp4,
          },
        ],
        series6: [
          {
            name: "Humedad",
            data: datosHum,
          },
        ],
        series7: [
          {
            name: "Humedad",
            data: datosHum1,
          },
        ],
        series8: [
          {
            name: "Humedad",
            data: datosHum2,
          },
        ],
        series9: [
          {
            name: "Humedad",
            data: datosHum3,
          },
        ],
        series10: [
          {
            name: "Humedad",
            data: datosHum4,
          },
        ],
      };
      this.setState({
        series: {
          series1: [
            {
              name: "Temperatura",
              data: datosTemp,
            },
          ],
          series2: [
            {
              name: "Temperatura",
              data: datosTemp1,
            },
          ],
          series3: [
            {
              name: "Temperatura",
              data: datosTemp2,
            },
          ],
          series4: [
            {
              name: "Temperatura",
              data: datosTemp3,
            },
          ],
          series5: [
            {
              name: "Temperatura",
              data: datosTemp4,
            },
          ],
          series6: [
            {
              name: "Humedad",
              data: datosHum,
            },
          ],
          series7: [
            {
              name: "Humedad",
              data: datosHum1,
            },
          ],
          series8: [
            {
              name: "Humedad",
              data: datosHum2,
            },
          ],
          series9: [
            {
              name: "Humedad",
              data: datosHum3,
            },
          ],
          series10: [
            {
              name: "Humedad",
              data: datosHum4,
            },
          ],
        },
      });
      console.log("series", this.state.series);
    }
  }

  handleChangeFechaInicial = (event) => {
    this.setState({ fechaInicial: new Date(event) });
  };

  handleChangeFechaFinal = (event) => {
    this.setState({ fechaFinal: new Date(event) });
  };

  handleClick = (event) => {
    // console.log("fechaInicial click", this.state.fechaInicial, "fechaFinal click", this.state.fechaFinal)
    this.datosHistoricosOnClick();
    this.updateOptionsTemperatura("xaxis", {
      type: "datetime",
      min: this.state.fechaInicial.getTime() - 5 * 60 * 60000,
      max: this.state.fechaFinal.getTime() - 5 * 60 * 60000,
      tickAmount: 6,
    });
    this.state.mostrar = true;
    this.setState({
      mostrar: true,
    });
  };

  updateOptionsTemperatura(parameter, value) {
    let auxOptionsTemperatura = { ...this.state.optionsTemperatura };
    auxOptionsTemperatura[parameter] = value;
    this.state.optionsTemperatura = auxOptionsTemperatura;
    this.setState({ optionsTemperatura: auxOptionsTemperatura });
  }

  async datosHistoricosOnClick() {
    var dataSensores = await Promise.all(
      this.state.macIDS.map(async (datos) => {
        var respuestaDeGET = await this.getDataClick(
          datos.mac,
          this.state.fechaInicial,
          this.state.fechaFinal
        );
        return respuestaDeGET;
      })
    );
    console.log("dataSensores", dataSensores);

    this.state.statistics = await this.getDataStatistics(dataSensores);
    console.log("objStatistics", this.state.statistics);

    var datosTemp = new Array();
    var datosTemp1 = new Array();
    var datosTemp2 = new Array();
    var datosTemp3 = new Array();
    var datosTemp4 = new Array();

    var datosHum = new Array();
    var datosHum1 = new Array();
    var datosHum2 = new Array();
    var datosHum3 = new Array();
    var datosHum4 = new Array();

    if (dataSensores.length == 5) {
      datosTemp = dataSensores[0].map((dato) => {
        return [
          new Date(dato.timestamp).getTime() - 5 * 60 * 60000,
          dato.temperature,
        ];
      });
      datosHum = dataSensores[0].map((dato) => {
        return [
          new Date(dato.timestamp).getTime() - 5 * 60 * 60000,
          dato.humidity,
        ];
      });
      datosTemp1 = dataSensores[1].map((dato) => {
        return [
          new Date(dato.timestamp).getTime() - 5 * 60 * 60000,
          dato.temperature,
        ];
      });
      datosHum1 = dataSensores[1].map((dato) => {
        return [
          new Date(dato.timestamp).getTime() - 5 * 60 * 60000,
          dato.humidity,
        ];
      });
      datosTemp2 = dataSensores[2].map((dato) => {
        return [
          new Date(dato.timestamp).getTime() - 5 * 60 * 60000,
          dato.temperature,
        ];
      });
      datosHum2 = dataSensores[2].map((dato) => {
        return [
          new Date(dato.timestamp).getTime() - 5 * 60 * 60000,
          dato.humidity,
        ];
      });
      datosTemp3 = dataSensores[3].map((dato) => {
        return [
          new Date(dato.timestamp).getTime() - 5 * 60 * 60000,
          dato.temperature,
        ];
      });
      datosHum3 = dataSensores[3].map((dato) => {
        return [
          new Date(dato.timestamp).getTime() - 5 * 60 * 60000,
          dato.humidity,
        ];
      });
      datosTemp4 = dataSensores[4].map((dato) => {
        return [
          new Date(dato.timestamp).getTime() - 5 * 60 * 60000,
          dato.temperature,
        ];
      });
      datosHum4 = dataSensores[4].map((dato) => {
        return [
          new Date(dato.timestamp).getTime() - 5 * 60 * 60000,
          dato.humidity,
        ];
      });
      this.state.series = {
        series1: [
          {
            name: "Temperatura",
            data: datosTemp,
          },
        ],
        series2: [
          {
            name: "Temperatura",
            data: datosTemp1,
          },
        ],
        series3: [
          {
            name: "Temperatura",
            data: datosTemp2,
          },
        ],
        series4: [
          {
            name: "Temperatura",
            data: datosTemp3,
          },
        ],
        series5: [
          {
            name: "Temperatura",
            data: datosTemp4,
          },
        ],
        series6: [
          {
            name: "Humedad",
            data: datosHum,
          },
        ],
        series7: [
          {
            name: "Humedad",
            data: datosHum1,
          },
        ],
        series8: [
          {
            name: "Humedad",
            data: datosHum2,
          },
        ],
        series9: [
          {
            name: "Humedad",
            data: datosHum3,
          },
        ],
        series10: [
          {
            name: "Humedad",
            data: datosHum4,
          },
        ],
      };
      this.setState({
        series: {
          series1: [
            {
              name: "Temperatura",
              data: datosTemp,
            },
          ],
          series2: [
            {
              name: "Temperatura",
              data: datosTemp1,
            },
          ],
          series3: [
            {
              name: "Temperatura",
              data: datosTemp2,
            },
          ],
          series4: [
            {
              name: "Temperatura",
              data: datosTemp3,
            },
          ],
          series5: [
            {
              name: "Temperatura",
              data: datosTemp4,
            },
          ],
          series6: [
            {
              name: "Humedad",
              data: datosHum,
            },
          ],
          series7: [
            {
              name: "Humedad",
              data: datosHum1,
            },
          ],
          series8: [
            {
              name: "Humedad",
              data: datosHum2,
            },
          ],
          series9: [
            {
              name: "Humedad",
              data: datosHum3,
            },
          ],
          series10: [
            {
              name: "Humedad",
              data: datosHum4,
            },
          ],
        },
      });
      console.log("series datosHistoricosOnClick", this.state.series);
    }
  }

  getData(id) {
    return new Promise((resolve, reject) => {
      Api.obtenerDataGabinete(id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  getData2(id) {
    return new Promise((resolve, reject) => {
      Api.obtenerDataGabineteTemp(id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  getDataClick(id, inicio, fin) {
    return new Promise((resolve, reject) => {
      Api.obtenerDataGabineteClick(id, inicio, fin).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  componentDidMount() {
    this.setearFecha();
    this.dataInicial();
  }

  render() {
    return (
      <Container>
        <Row>
          <section className="w3-center w3-panel"></section>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Monitorización</Card.Title>
                <p className="card-category">
                  Históricos de Temperatura y Humedad
                </p>
              </Card.Header>
            </Card>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Row>
            <h3>
              Ingrese fecha de inicio y fin para visualización de históricos
            </h3>
          </Row>
          <br></br>
          <br></br>
          <Row>
            <div className="col-lg-3">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Fecha Inicial"
                  value={this.state.fechaInicial}
                  onChange={this.handleChangeFechaInicial.bind(this)}
                />
              </LocalizationProvider>
            </div>

            <div className="col-lg-3">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Fecha Final"
                  value={this.state.fechaFinal}
                  onChange={this.handleChangeFechaFinal.bind(this)}
                />
              </LocalizationProvider>
            </div>
            <Col md="2">
              <Button className="buttonGeneral" onClick={this.handleClick}>
                Buscar en Rango
              </Button>
            </Col>
          </Row>
        </Row>
        {this.state.mostrar && this.state.series.series1 !== undefined && (
          <h2>Históricos temperatura</h2>
        )}
        <Row lg={3}>
          {this.state.mostrar && this.state.series.series1 !== undefined && (
            <Col>
              <br></br>
              <h3>
                <CuadroTemp info={this.state.datosUnos[0]} />
              </h3>
              <br></br>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Tipo</th>
                    <th scope="col">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Máximo</th>
                    <td>{this.state.statistics[0].tempMax}</td>
                  </tr>
                  <tr>
                    <th scope="row">Mínimo</th>
                    <td>{this.state.statistics[1].tempMin}</td>
                  </tr>
                  <tr>
                    <th scope="row">Promedio</th>
                    <td>{this.state.statistics[2].tempAv}</td>
                  </tr>
                </tbody>
              </table>
              <br></br>
              <LineasArea
                series={this.state.series.series1}
                options={this.state.optionsTemperatura}
              />
            </Col>
          )}
          {this.state.mostrar && this.state.series.series4 !== undefined && (
            <Col>
              <br></br>
              <h3>
                <CuadroTemp info={this.state.datosUnos[3]} />
              </h3>
              <br></br>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Tipo</th>
                    <th scope="col">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Máximo</th>
                    <td>{this.state.statistics[0].tempMax3}</td>
                  </tr>
                  <tr>
                    <th scope="row">Mínimo</th>
                    <td>{this.state.statistics[1].tempMin3}</td>
                  </tr>
                  <tr>
                    <th scope="row">Promedio</th>
                    <td>{this.state.statistics[2].tempAv3}</td>
                  </tr>
                </tbody>
              </table>
              <br></br>
              <LineasArea
                series={this.state.series.series4}
                options={this.state.optionsTemperatura}
              />
            </Col>
          )}
          {this.state.mostrar && this.state.series.series5 !== undefined && (
            <Col>
              <br></br>
              <h3>
                <CuadroTemp info={this.state.datosUnos[4]} />
              </h3>
              <br></br>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Tipo</th>
                    <th scope="col">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Máximo</th>
                    <td>{this.state.statistics[0].tempMax4}</td>
                  </tr>
                  <tr>
                    <th scope="row">Mínimo</th>
                    <td>{this.state.statistics[1].tempMin4}</td>
                  </tr>
                  <tr>
                    <th scope="row">Promedio</th>
                    <td>{this.state.statistics[2].tempAv4}</td>
                  </tr>
                </tbody>
              </table>
              <br></br>
              <LineasArea
                series={this.state.series.series5}
                options={this.state.optionsTemperatura}
              />
            </Col>
          )}
        </Row>

        {this.state.mostrar && this.state.series.series1 !== undefined && (
          <h2>Históricos humedad</h2>
        )}
        <Row lg={3}>
          {this.state.mostrar && this.state.series.series6 !== undefined && (
            <Col>
              <br></br>
              <h3>
                <RadioHum seriesData={this.state.datosUnos[0][0]}></RadioHum>
              </h3>
              <br></br>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Tipo</th>
                    <th scope="col">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Máximo</th>
                    <td>{this.state.statistics[0].humMax}</td>
                  </tr>
                  <tr>
                    <th scope="row">Mínimo</th>
                    <td>{this.state.statistics[1].humMin}</td>
                  </tr>
                  <tr>
                    <th scope="row">Promedio</th>
                    <td>{this.state.statistics[2].humAv}</td>
                  </tr>
                </tbody>
              </table>
              <br></br>
              <LineasArea
                series={this.state.series.series6}
                options={this.state.optionsTemperatura}
              />
            </Col>
          )}
          {this.state.mostrar && this.state.series.series9 !== undefined && (
            <Col>
              <br></br>
              <h3>
                <RadioHum seriesData={this.state.datosUnos[3][0]}></RadioHum>
              </h3>
              <br></br>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Tipo</th>
                    <th scope="col">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Máximo</th>
                    <td>{this.state.statistics[0].humMax3}</td>
                  </tr>
                  <tr>
                    <th scope="row">Mínimo</th>
                    <td>{this.state.statistics[1].humMin3}</td>
                  </tr>
                  <tr>
                    <th scope="row">Promedio</th>
                    <td>{this.state.statistics[2].humAv3}</td>
                  </tr>
                </tbody>
              </table>
              <br></br>
              <LineasArea
                series={this.state.series.series9}
                options={this.state.optionsTemperatura}
              />
            </Col>
          )}
          {this.state.mostrar && this.state.series.series10 !== undefined && (
            <Col>
              <br></br>
              <h3>
                <RadioHum seriesData={this.state.datosUnos[4][0]}></RadioHum>
              </h3>
              <br></br>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Tipo</th>
                    <th scope="col">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Máximo</th>
                    <td>{this.state.statistics[0].humMax4}</td>
                  </tr>
                  <tr>
                    <th scope="row">Mínimo</th>
                    <td>{this.state.statistics[1].humMin4}</td>
                  </tr>
                  <tr>
                    <th scope="row">Promedio</th>
                    <td>{this.state.statistics[2].humAv4}</td>
                  </tr>
                </tbody>
              </table>
              <br></br>
              <LineasArea
                series={this.state.series.series10}
                options={this.state.optionsTemperatura}
              />
            </Col>
          )}
        </Row>
      </Container>
    );
  }
}

export default Gabinete;
