import React, { useEffect, useState } from "react";
import ActualizarMapa from "../../../components/Map/Actualizar Marcador/index";
import swal from "sweetalert";
import Mapa from "../../../components/Mapa/CustomMarker";
import Select from "../../../components/Entradas/Select";
import Button from "../../../components/Button";
import Probando from "../Create/index";
import Veruno from "../../../components/Map/Ver Marcador/index";
import Auth from "../../../services/auth-service.js";
import MapaOne from "../../../components/Alcantarillas/VerUnaAlcantarilla/index";
import Modal from "react-modal";
import {
  BarraBusqueda,
  BotonCrear,
  BotonFiltro,
  Categorias,
  ContenedorCategorias,
  ContenedorTabla,
  ContenedorTitulo,
  Main,
  NavbarTabla,
  Th,
  Titulo,
  Tr,
  TablaDispo,
  TrC,
  CoFiltroIn,
  Divfiltro,
  SelectFiltro,
  IconoLupa,
  InputBusqueda,
  ContenedorDis,
  Nombredispositivo,
  DescripcionDispo,
  Organizacion,
  Tipo,
  ContenedorFecha,
  Estado,
  Tiempo,
  Fecha,
  BotonMostrar,
  BotonDevice,
  ContenedorButton,
} from "../AlcantarillaElements";
import Input from "../../../components/Entradas/Input";
import { useLocation } from "react-router-dom";
import Api from "../../../services/api-service.js";
import CustomMap from "../../../components/Alcantarillas/ActualizarMarcador/index";

const TipoAlera = [
  {
    value: "Reporte",
    label: "Reporte",
  },
  {
    value: "Alerta",
    label: "Alerta",
  },
];

// const customStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     transform: "translate(-50%, -50%)",
//   },
// };

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "800px",
    height: "620px",
    transform: "translate(-50%, -50%)",
  },
};

const ViewOne = () => {
  const [devices, setDevices] = useState([]);
  const [coordendas, setCoordendas] = useState({
    lat: null,
    lng: null,
  });
  const [report, setReport] = useState();
  const [tipo, setTipo] = useState("Reporte");
  const [viewData, setViewData] = useState(true);
  const [latitud, setLatitud] = useState(null);
  const [longitud, setLongitud] = useState(null);
  const query = new URLSearchParams(useLocation().search);
  const term = query.get("id");
  const [aux, setAux] = useState(Auth.getRol());

  const [modalIsOpen, setIsOpen] = useState(false);

  function sendData() {
    if (coordendas.lat == null && coordendas.lng == null) {
      swal({
        icon: "error",
        title: "Oops...",
        text: "Debes Seleccionar ubicacion",
      });
    } else {
      swal("Ok!", "Se actualizo!!", "success");
      Api.updateDevice(report?.deviceID, coordendas.lat, coordendas.lng);
      setIsOpen(false);
    }
  }

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  useEffect(() => {
    Api.getDeviceById(term).then(
      (response) => {
        console.log("Respuesta");
        console.log(response);
        setLatitud(response.coordenadas.lat);
        setLongitud(response.coordenadas.lng);
        setReport(response);
        if (tipo == "Alerta") {
          setViewData(false);
          setDevices(response.alertas);
        } else {
          setViewData(true);
          setDevices(response.data);
        }
      },
      (error) => {
        console.log("Obtencion erronea");
      }
    );
  }, [tipo, modalIsOpen]);

  console.log(coordendas.lat);
  console.log(coordendas.lng);

  return (
    <>
      <Main>
        <ContenedorTitulo>
          <Titulo>Datos</Titulo>
        </ContenedorTitulo>

        <Input
          message="ID del dispositivo"
          value={report?.deviceID}
          id="filled-required"
        />
        {/* 
        <ContenedorTitulo>
          <Titulo>Datos dispositivo</Titulo>
        </ContenedorTitulo> */}

        <Select
          message="Escoja el tipo de Informacion"
          value={tipo}
          setFunction={setTipo}
          data={TipoAlera}
          id="outlined-disabled"
        />

        <ContenedorTabla>
          <TablaDispo>
            {viewData ? (
              <Tr>
                <Th>Fecha</Th>
                <Th>Hora</Th>
                <Th>Tipo de Mensaje</Th>
                <Th>Nivel Bateria</Th>
                <Th>Nivel Señal</Th>
              </Tr>
            ) : (
              <Tr>
                <Th>Fecha</Th>
                <Th>Hora</Th>
                <Th>Tipo de Mensaje</Th>
              </Tr>
            )}

            {devices.map((data, i) =>
              viewData ? (
                <TrC>
                  <td>
                    <ContenedorFecha>
                      <Fecha>{data.t_stamp.slice(0, 8)}</Fecha>
                    </ContenedorFecha>
                  </td>
                  <td>
                    <ContenedorFecha>
                      <Fecha>{data.t_stamp.slice(9, 17)}</Fecha>
                    </ContenedorFecha>
                  </td>
                  <td>
                    <ContenedorDis>
                      <DescripcionDispo>
                        {data.sms_cont.slice(0, 6)}
                      </DescripcionDispo>
                    </ContenedorDis>
                  </td>
                  <td>
                    <Organizacion>
                      {data.sms_cont.slice(
                        9,
                        data.sms_cont.toString().indexOf("--")
                      )}
                    </Organizacion>
                  </td>
                  <td>
                    <Organizacion>
                      {data.sms_cont.slice(
                        data.sms_cont.toString().indexOf("--") + 5
                      )}
                    </Organizacion>
                  </td>
                </TrC>
              ) : (
                <TrC>
                  <td>
                    <ContenedorFecha>
                      <Fecha>{data.t_stamp.slice(0, 8)}</Fecha>
                    </ContenedorFecha>
                  </td>
                  <td>
                    <ContenedorFecha>
                      <Fecha>{data.t_stamp.slice(9, 17)}</Fecha>
                    </ContenedorFecha>
                  </td>
                  <td>
                    <ContenedorDis>
                      <DescripcionDispo>{data.sms_cont}</DescripcionDispo>
                    </ContenedorDis>
                  </td>
                </TrC>
              )
            )}
          </TablaDispo>
        </ContenedorTabla>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <ContenedorTitulo>
            <Titulo>Edicion del dispositivo</Titulo>
          </ContenedorTitulo>

          <Input
            message="ID del dispositivo"
            value={report?.deviceID}
            id="filled-required"
          />
          <p>*Selecciona la nueva ubicacion del dispositivo</p>
          {/* <button onClick={closeModal}>Cancelar</button> */}
          {latitud ? (
            <ActualizarMapa
              lat={latitud}
              lng={longitud}
              function={setCoordendas}
              coordendas={coordendas}
            />
          ) : null}
          <ContenedorButton>
            <BotonDevice
              onClick={() => {
                sendData();
              }}
            >
              Editar
            </BotonDevice>
            <BotonDevice onClick={() => setIsOpen(false)}>Cancelar</BotonDevice>
          </ContenedorButton>
        </Modal>
      </Main>

      {latitud ? <Veruno lat={latitud} lng={longitud} /> : null}

      <ContenedorButton>
        <Button
          url="/admin/viewAllDeviceAlcantarilla"
          message="Regresar"
          style={BotonDevice}
        >
          Regresar
        </Button>
        {aux == "administrador general" && (
          <BotonDevice
            onClick={() => {
              openModal();
            }}
          >
            Editar
          </BotonDevice>
        )}
      </ContenedorButton>
    </>
  );
};

export default ViewOne;
