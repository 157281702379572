import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';

import Api from '../../../services/api-service.js'


import './estilosFormularios.css';



const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement');

export default function AgregarEmpresas(props) {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [rol, setRol] = React.useState(null);
  const [organizaciones, setOrganizaciones] = React.useState(props.organizaciones);


  let form = {
    nombre: null,
    email: null,
    rol: null
  }

  // function 
  

  function openModal() {
    setIsOpen(true);

  }

  function afterOpenModal() {
    console.log('afterOpenModal')
  }

  function closeModal() {
    console.log('closeModal')
    setIsOpen(false);
  }

  function handleChangeForm(event){
    console.log(event.target.name)

    form[event.target.name] = (event.target.value === 'null')? null: event.target.value ;

    console.log(form);


  }

  function submitForm(){
    
    console.log('envio info', form);
    Api.registroEmpresa(form).then(
      result =>{
        console.log(result)

        if(!result.mensaje){
          alert(result.error)
        } else {
          alert(result.mensaje)
          props.resultCallback(result.mensaje);
          closeModal();
        }

      }, error=>{
        alert(error)
        console.log(error.error)

      }
    )
  }

  return (


    <div>
      <button onClick={openModal}>Agregar Empresa</button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
      >
        <div>
          <h1 >Nueva Empresa</h1>
          <div className=''>
          <div className='formulario'>
             <label>Nombre: </label>
             <input type="text" id="nombre" name="nombre" onChange={handleChangeForm}/>
            </div>
            <div className='formulario'>
             <label>Organización: </label>
             <input type="text" id="organizacion" name="organizacion" onChange={handleChangeForm}/>
            </div>
            <div className='formulario'>
             <label>Email: </label>
             <input type="text" id="email" name="email" onChange={handleChangeForm}/>
            </div>

            <div className='div-botones row'>
              <div className='col-6 button-edicion'>
                <button className="buttonGeneral" onClick={()=>submitForm()}>Crear</button>
              </div>
              <div className='col-6 button-edicion'>
                <button className="buttonGeneral button-secondary" onClick={()=>closeModal()} >Cancelar</button>
              </div>
            </div>
          </div>

        </div>
      </Modal>
    </div>
  );
}