import React from "react";
import "./Lineas.css"
import Api from '../../services/api-service.js'
import { Link } from "react-router-dom";
import CuadroTemp from "./CuadroTemp.js";



class Lineas extends React.Component {

  constructor (props) {
    super(props);

    this.inicio = this.inicio.bind(this); 

    this.state = {
      matches: [],
      infoVentana: "",
      matches2: [],
      titulos: [{num: '1'},
                {num: '2'},
                {num: '3'},
                {num: '4'},
                {num: '5'},
                {num: '6'},
                {num: '7'},
                {num: '8'}],
    };    
  }

  funLink = (a) => {
    var newLink = "IoTCacao"+this.state.infoVentana+"&linea="+a
    // console.log("Nuevo link", newLink)
    return newLink
  }
  
  inicio = () =>{
    this.state.matches2 = this.props.info.dataSalida
    // console.log("this.state.matches2", this.state.matches2)
    this.state.infoVentana = window.location.search;
    // console.log("Info ventana", this.state.infoVentana)
  }

  render() {
    this.inicio()
    return (
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr className="" key='filaTitulos'>
                {this.state.titulos.map(data=>(
                <td key={data.num} value={data.num} scope="col" className="texto">
                  <Link to={this.funLink(data.num)}>
                  <h3>Línea {data.num}</h3> 
                  </Link>
                </td>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="" key='fila1'>
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==1 && data.num==1 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==2 && data.num==1 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==3 && data.num==1 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==4 && data.num==1 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==5 && data.num==1 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==6 && data.num==1 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==7 && data.num==1 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==8 && data.num==1 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
              </tr>
              <tr className="" key='fila2'>
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==1 && data.num==2 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==2 && data.num==2 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==3 && data.num==2 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==4 && data.num==2 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==5 && data.num==2 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==6 && data.num==2 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==7 && data.num==2 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==8 && data.num==2 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
              </tr>
              <tr className="" key='fila3'>
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==1 && data.num==3 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==2 && data.num==3 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==3 && data.num==3 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==4 && data.num==3 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==5 && data.num==3 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==6 && data.num==3 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==7 && data.num==3 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
                {this.state.matches2.length == 24 && this.state.matches2.map((data, index)=>(
                  <>
                    {data.linea==8 && data.num==3 &&
                      <td key={data.cajon} value={data.T} scope="col" className="texto">
                        <div className="">
                          <CuadroTemp fila={data.num} linea={data.linea} cajon={data.cajon} temperatura={data.T}/>
                        </div>
                      </td>
                    }
                  </>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
    );
  }
}

  export default Lineas;