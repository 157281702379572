import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { render } from "react-dom";

import Api from "../../services/api-service.js";
import Auth from "../../services/auth-service.js";

import EditarUsuarios from "./formularios/EditarUsuarios.js";

class CuentaUsuario extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      devicesTable: [],
      usuario: {},
      parametrosUsuario: [
        { name: "Username", props: "usuario", show: false },
        { name: "Nombre", props: "nombre", show: true },
        { name: "Password", props: "password", show: true },
        { name: "Email", props: "email", show: true },
        { name: "Dirección", props: "direccion", show: true },
        { name: "Organización", props: "organizaciones", show: false },
        { name: "Rol", props: "rol", show: false },
      ],
    };

    this.actualizarCuenta();
  }

  actualizarCuenta() {
    Api.obtenerUsuario().then(
      (result) => {
        console.log(result);
        this.setState({ usuario: result });
      },
      (error) => {
        console.log("error en la adquisicion de datos", error);
      }
    );
  }

  resultModalEditarUsuarios = () => {
    this.actualizarCuenta();
  };

  getParameters(item) {
    console.log(item.props);
    if (item.props == "password") {
      return "*****";
    }
    if (item.props == "organizaciones") {
      if (this.state.usuario[item.props]) {
        if (this.state.usuario[item.props].length != 0) {
          return "multiples organizaciones";
        } else {
          return this.state.usuario[item.props][0]["organizacion"];
        }
      }
    }
    return this.state.usuario[item.props];
  }
  render() {
    return (
      <>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">Cuenta de usuario</Card.Title>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table
                    className="table-hover table-striped"
                    id="tabla-obtenida"
                  >
                    <tbody>
                      {this.state.parametrosUsuario.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>{this.getParameters(item)}</td>
                            <td>
                              {item.show && (
                                <EditarUsuarios
                                  item={this.state.usuario}
                                  tipoEditar={item.props}
                                  nombreEditar={item.name}
                                  resultCallback={this.resultModalEditarUsuarios.bind(
                                    this
                                  )}
                                ></EditarUsuarios>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default CuentaUsuario;
