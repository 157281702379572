import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import Api from "../../services/api-service";
import "w3-css/w3.css";
import "./estiloform.css";
import {
  Tabla,
  Tr,
  Th,
  Trc,
  Td,
  ProbandoMain,
  Cerrar,
  BotonesDerecha,
  BotonModal,
  TituloModal,
  Botones,
} from "./SubirElements";
import { width } from "@material-ui/system";

export default function BootstrapModa(props) {
  const [datos, setDatos] = useState({
    deviceID: "",
    tipo: "",
    grupo: "",
    organizacion: "",
  });

  const [show, setShow] = useState(false);

  const [seacrh, setSeacrh] = useState("");
  const [listaDatos, setListaDatos] = useState([]);
  const handleShow = () => setShow(true);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "800",
    },
  };

  const handleClose = () => setShow(false);

  function SaveData() {
    let deviceID = seacrh.slice(
      seacrh.indexOf("deviceID:") + 9,
      seacrh.indexOf(",tipo")
    );
    let tipo = seacrh.slice(
      seacrh.indexOf("tipo:") + 5,
      seacrh.indexOf(",grupo")
    );
    let grupo = seacrh.slice(
      seacrh.indexOf("grupo:") + 6,
      seacrh.indexOf(",organizacion")
    );
    let organizacion = seacrh.slice(
      seacrh.indexOf("organizacion:") + 13,
      seacrh.indexOf(",idSigfox")
    );
    let idSigfox = seacrh.slice(
      seacrh.indexOf("idSigfox:") + 9,
      seacrh.indexOf(",idWifi")
    );
    let idWifi = seacrh.slice(
      seacrh.indexOf("idWifi:") + 7,
      seacrh.indexOf(",nombre")
    );
    let nombre = seacrh.slice(seacrh.indexOf("nombre:") + 7);

    // console.log("El Devide es: " + deviceID);
    // console.log("El tipo es: " + tipo);
    // console.log("El Grupo es: " + grupo);
    // console.log("El organizacion es: " + organizacion);
    // console.log("El idSigfox es: " + idSigfox);
    // console.log("El idWifi es: " + idWifi);
    // console.log("El nombre es: " + nombre);

    var envio = {
      deviceID: deviceID,
      organizacion: organizacion,
      grupo: grupo,
      idWifi: idWifi,
      tipo: tipo,
      idSigfox: idSigfox,
      nombre: nombre,
    };

    // console.log(envio);
    listaDatos.push(envio);
  }

  const links =
    "https://aiot.constecoin.com/api/administracion-dispositivos/dispositivos/creaScaneer";

  function Consumo() {
    console.log("Entro a consumo");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(listaDatos),
    };

    console.log(JSON.stringify(listaDatos));
    fetch(links, requestOptions).then((response) => console.log(response));
    // setListaDatos([]);
    setShow(false);
  }

  function Testing(e) {
    if (e.key === "Enter") {
      console.log("Entro a testing");
      SaveData();
      setSeacrh("");
      console.log(listaDatos);
    }
  }

  function Cerrar() {
    setShow(false);
    // setListaDatos([]);
    // setListaDatos("");
  }

  return (
    <>
      <BotonesDerecha variant="primary" onClick={() => setShow(true)}>
        Registrar con Scanner
      </BotonesDerecha>

      <Modal
        show={show}
        size="xl"
        onHide={() => setShow(false)}
        backdrop="static"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Agregar dispositivos mediante filtro
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TituloModal>Agregar dispositivos mediante filtro</TituloModal>
          <input
            type="text"
            name="search"
            autoComplete="off"
            value={seacrh}
            onChange={(e) => setSeacrh(e.target.value)}
            onKeyDown={(e) => Testing(e)}
          />
          <Tabla id="example-custom-modal-styling-title">
            <tbody>
              <Tr>
                <Th>deviceID</Th>
                <Th>organizacion</Th>
                <Th>grupo</Th>
                <Th>idWifi</Th>
                <Th>tipo</Th>
                <Th>idSigfox</Th>
                <Th>nombre</Th>
              </Tr>
              {listaDatos?.map((item, i) => {
                return (
                  <Trc key={i}>
                    <Td>{item.deviceID}</Td>
                    <Td>{item.organizacion}</Td>
                    <Td>{item.grupo}</Td>
                    <Td>{item.idWifi}</Td>
                    <Td>{item.tipo}</Td>
                    <Td>{item.idSigfox}</Td>
                    <Td>{item.nombre}</Td>
                    <Td>
                      <Botones>Editar</Botones>
                    </Td>
                    <Td>
                      <Botones>Eliminar</Botones>
                    </Td>
                  </Trc>
                );
              })}
            </tbody>
          </Tabla>
          <Botones onClick={Cerrar}>Cerrar</Botones>
          <Botones onClick={Consumo}>Guardar</Botones>
        </Modal.Body>
      </Modal>
    </>
  );
}
