import Button from "@restart/ui/esm/Button";
import React, { Component } from "react";
import './CercaPoligonal.css'

class CercaPoligonal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      latitudes: [],
      longitudes: [],
      contador: 0
    };
  }

  addFun(){
    if (this.state.contador < 5) {
    this.setState({
      latitudes: [...this.state.latitudes, ""],
      longitudes: [...this.state.longitudes, ""],
      contador: this.state.contador + 1
    })
    this.props.parentCallback(this.state)
  }
  }

  handleChange1(e, index){
    this.state.latitudes[index] = e.target.value;
    this.setState({
      latitudes: this.state.latitudes
    })
    this.props.parentCallback(this.state)
  }

  handleChange2(e, index){
    this.state.longitudes[index] = e.target.value;
    this.setState({
      longitudes: this.state.longitudes
    })
    this.props.parentCallback(this.state)
  }

  elimFun(index){
    this.state.latitudes.splice(index,1)
    this.state.longitudes.splice(index,1)
    // console.log(this.state, "$$$$")
    this.setState({
      latitudes: this.state.latitudes,
      longitudes: this.state.longitudes,
      contador: this.state.contador - 1
    })
    this.props.parentCallback(this.state)
  }
  // submitFun(){
  //   console.log(this.state, "$$$$")
  // }

  render() {
    return (
      <>
        <div>
          <h5>
            Ingrese coordenadas de los vértices
          </h5>
          <div class="flex">
            
            <div  class="inputNumber">{
              this.state.latitudes.map((latitud, index)=>{
                return(
                <>
                <label>Latitud {index + 1}</label>
                <div key={index}>
                    <input onChange={(e)=>this.handleChange1(e, index)} value={latitud}></input>
                    {/* <Button onClick={() => this.elimFun(index)}>Quitar</Button> */}
                </div>
                </> 
                )
              })
            }</div>
            
            <div class="inputNumber second">{
              this.state.longitudes.map((longitud, index)=>{
                return(
                <>
                <label>Longitud {index + 1}</label>
                <div key={index}>
                    <input onChange={(e)=>this.handleChange2(e, index)} value={longitud}></input>
                    <Button className=" buttonGeneral button-secondary" onClick={() => this.elimFun(index)}>Quitar</Button>
                </div>
                </> 
                )
              })
            }</div>
          </div>
          <br></br>
          <Button class="btn btn-primary buttonGeneral" onClick={(e) => this.addFun(e)}>Añadir</Button>
          <br></br>
          {/* <Button class="btn btn-danger boton" onClick={(e) => this.submitFun(e)}>Enviar</Button> */}
        </div>
      </>
    );
  }
}
export default CercaPoligonal;