import React from "react";

import "./DispositivosIoT.css";
import Api from "../../services/api-service.js";
import Auth from "../../services/auth-service.js";
import ReactToExcel from "react-html-table-to-excel";
import { CSVLink } from "react-csv";

import Ventana from "../../components/betatemp/betaform";
import Beta from "../../components/betatemp/ConsumoBeta";
import {
  BotonModal,
  BootstrapModa,
} from "../../components/betatemp/beta_editar";
import { makeStyles } from "@mui/styles";
import Filtro from "../../views/SubirLector/index.js";
// react-bootstrap components

// import { BotonModal,BootstrapModa } from '../components/betatemp/beta_editar';

import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { render } from "react-dom";

function adquisicionConectedDevices() {
  return new Promise((resolve, reject) => {
    fetch(
      "http://iot-api.constecoin.com:8080/api/modulos-cacao/getAlertDevices"
    )
      // fetch("http://172.16.2.87/api/modulos-cacao/getDevices")
      .then((res) => res.json())
      .then(
        (result) => {
          // elements = result;
          // elements = [...elements];
          // console.log('elements',result);
          resolve(result);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
  });
}

class DispositivosIoT extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      selectedTipo: null,
      selectedOrganizacion: null,
      devicesTable: [],
      tablaFiltrada: [],
      showBetatemp: false,
      showSigfox: false,
      selectedItem: null,
      showEdit: false,
      rol: Auth.getRol(),
      organizaciones: Auth.getOrganizaciones(),
    };
    this.buttonClick("sigfox");
    // this.setState({showBetatemp: false, showSigfox: true})
    console.log(this.state.rol);
    console.log(this.state.organizaciones);
  }

  handleChangeTipo = (event) => {
    this.setState({ selectedTipo: event.target.value });
    console.log(event.target.value);
  };
  handleChangeOrganizacion = (event) => {
    this.setState({ selectedOrganizacion: event.target.value });
    console.log(event.target.value);
    this.filtroTablaDevices(this.state.devicesTable, event.target.value);
  };

  handleShow = (item) => {
    this.setState({ selectedItem: item });

    if (item) {
      this.setState({ showEdit: true });
    } else {
      this.setState({ showEdit: false });
    }
  };

  buttonClick(tipo) {
    if (tipo === "sigfox") {
      this.setState({ showBetatemp: false, showSigfox: true });

      console.log(Auth.getJWT());
    }
    if (tipo === "lora") {
      console.log(Auth.getJWT());
    }
    if (tipo === "adquirir") {
      console.log(Auth.getJWT());
      this.setState({ showBetatemp: true, showSigfox: false });
    }
    if (tipo === "cerrar") {
      Auth.saveJWT(null);
      this.props.history.push("/login/ingreso");
    } else {
      Api.adquirirDevices().then(
        (result) => {
          console.log(result);
          this.setState({ devicesTable: result });
          // this.state.devicesTable = result;
          this.filtroTablaDevices(result, this.state.selectedOrganizacion);
        },
        (error) => {
          console.log("error en la adquisicion de datos", error);
        }
      );
    }
  }

  showData = () => {
    console.log(this.state.devicesTable);
  };
  filtroTablaDevices(tabla, organizacion) {
    const result = tabla.filter((item) => {
      // console.log(item);
      let auxOrganizacion = false,
        auxTipo = true;
      if (organizacion === "all") {
        auxOrganizacion = true;
      }
      if (organizacion === null) {
        auxOrganizacion = true;
      } else {
        if (item.organizacion === organizacion) {
          auxOrganizacion = true;
        }
      }
      // if(this.state.selectedTipo === null){
      //   auxTipo = true;
      // }
      // else {
      //   if(item.tipo === this.state.selectedTipo){
      //     auxTipo = true;
      //   }
      // }

      if (auxOrganizacion && auxTipo) {
        // console.log(true);
      }

      return auxOrganizacion && auxTipo;
    });

    this.setState({ tablaFiltrada: result });
  }

  render() {
    return (
      <>
        <Container fluid>
          {/* <div class="buttonsElements">
            <ul>
              <li><a onClick={()=>this.buttonClick('sigfox')}>dispositivos</a></li>
              <li><a onClick={()=>this.buttonClick('lora')}>LoRa</a></li>
              <li><a onClick={()=>this.buttonClick('adquirir')}>Beta Temp</a></li>
              <li><a onClick={()=>this.buttonClick('cerrar')}>Item 4</a></li>
            </ul>
          </div> */}
          {/* {this.state.showSigfox && */}
          <Row>
            {this.state.rol == "administrador general" && (
              <section className="w3-center w3-panel">
                <Ventana className="w3-container w3-modal-content w3-teal" />
                <Filtro />
              </section>
            )}
            <Col md="2">
              <div class="selectores">
                <label for="organizacion">Organización:</label>
                <select
                  id="organizacion"
                  onChange={this.handleChangeOrganizacion}
                >
                  <option selected value="all">
                    Todos
                  </option>
                  {this.state.organizaciones.map((item, index) => {
                    // console.log('this.state.organizaciones', item)
                    return (
                      <option value={item.organizacion}>
                        {item.organizacion}
                      </option>
                    );
                  })}

                  {/* <option value="semagro">Semagro</option> */}
                  {/* <option value="constecoin">Constecoin</option> */}
                </select>
              </div>
            </Col>

            {/* <Col md="2">
              <div class="selectores">
                <label for="tipo">Tipo Dispositivo:</label>
                <select id="tipo" onChange={this.handleChangeTipo}>
                  <option value="" disabled selected hidden>seleccionar</option>
                  <option value="wifi">Wifi</option>
                  <option value="lora">Lora</option>
                  <option value="sigfox">Sigfox</option>
                  <option value="celular">Celular</option>
                </select>
              </div> 
            </Col> */}

            <Col md="2">
              <div>
                <ReactToExcel
                  className="btn btn-primary buttonGeneral"
                  table="tabla-obtenida"
                  filename="iotTablaConste"
                  sheet="sheet 1"
                  buttonText="Exportar como xls"
                />
              </div>
            </Col>

            <Col md="2">
              <div>
                <CSVLink
                  data={this.state.devicesTable}
                  filename={"iotTablaConste.csv"}
                >
                  <Button className="buttonGeneral"> Exportar como csv</Button>
                </CSVLink>
              </div>
            </Col>

            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">Devices</Card.Title>
                  {/* <p className="card-category">
                    Tabla de dispositivos conectados a CONSTECOIN
                  </p> */}
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table
                    className="table-hover table-striped"
                    id="tabla-obtenida"
                  >
                    <thead>
                      <tr>
                        {/* <th className="border-0">ID</th> */}
                        <th className="border-0">Device ID</th>
                        <th className="border-0">Organización</th>
                        <th className="border-0">TIPO</th>
                        <th className="border-0">SIGFOX</th>
                        <th className="border-0">MAC</th>

                        {this.state.rol == "administrador general" && (
                          <th className="border-0">Edición</th>
                        )}

                        {/* <th className="border-0">Tiempo Último Dato</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.tablaFiltrada.map((item, index) => {
                        return (
                          <tr key={index}>
                            {/* <td>{item._id}</td> */}
                            <td>{item.deviceID}</td>
                            <td>{item.organizacion}</td>
                            <td>{item.tipo}</td>
                            <td>{item.idSigfox}</td>
                            <td>{item.idWifi}</td>

                            {this.state.rol == "administrador general" && (
                              <td>
                                <BotonModal
                                  className="buttonGeneral"
                                  etiqueta="Editar"
                                  onClick={() => this.handleShow(item)}
                                ></BotonModal>
                              </td>
                            )}

                            {/* <td>{item.tiempoUltimoDato}</td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* }  */}

          {this.state.showBetatemp && (
            <Row>
              <Beta></Beta>
            </Row>
          )}
        </Container>
        <BootstrapModa
          setShow={this.state.showEdit}
          handleClose={() => this.handleShow(null)}
          item={this.state.selectedItem}
          // id={item._id}
          // nombre={this.state.selectedItem.nombre}
          // organizacion={item.organizacion}
          // descripcion={item.descripcion}
          // codigoDeBarras={item.codigoDeBarras}
        >
          test
          <h1>holaaa</h1>
        </BootstrapModa>
      </>
    );
  }
}

export default DispositivosIoT;
