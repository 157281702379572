import React, { Component } from "react";

import { Button } from "react-bootstrap";

import Api from "../../services/api-service.js";
import Auth from "../../services/auth-service.js";
import "../../styles.css";

class LoginComponent extends Component {
  state = {
    email: "",
    password: "",
  };

  handleChange = (e) => {
    this.setState({ [e.currentTarget.id]: e.currentTarget.value });
  };

  loginConsume = () => {
    let body = {
      usuario: this.state.email,
      password: this.state.password,
    };

    Api.login(body).then(
      (result) => {
        console.log(result);
        if (result.jwt === undefined) {
          this.props.onLoginComponentCallback(null);
        } else {
          Auth.saveJWT(result.jwt);
          Auth.saveRol(result.rol);
          Auth.saveHabilitations(result.habilitaciones);
          Auth.saveOrganizaciones(result.organizaciones);
          console.log("SON CREDENCIALES");
          console.log(result.organizaciones);
          this.props.onLoginComponentCallback(result.jwt);
        }
      },
      (error) => {
        this.props.onLoginComponentCallback(null);
      }
    );
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.loginConsume();
    }
  };

  render() {
    return (
      <div className="App">
        <form className="form">
          <label>Usuario:</label>
          <input
            className=" "
            id="email"
            type="text"
            placeholder="Usuario"
            onChange={this.handleChange}
          ></input>

          <label>Contraseña:</label>
          <input
            className=" "
            id="password"
            type="password"
            placeholder="Contraseña"
            onChange={this.handleChange}
            onKeyDown={this._handleKeyDown}
          ></input>

          <Button onClick={this.loginConsume} className="form__custom-button">
            Iniciar sesión
          </Button>
        </form>
      </div>
    );
  }
}

export default LoginComponent;
