import React, { useEffect, useState } from "react";
import Api from "./../../services/api-service";
import SearchIcon from "@material-ui/icons/Search";
import Auth from "../../services/auth-service.js";
import InputBusqueda from "./../../components/Table/inputBusqueda";
import Button from "../../components/Button/index";
import {
  BarraBusqueda,
  BotonCrear,
  ContenedorTabla,
  ContenedorTitulo,
  Main,
  NavbarTabla,
  Th,
  Titulo,
  Tr,
  TablaDispo,
  TrC,
  IconoLupa,
  ContenedorDis,
  Nombredispositivo,
  DescripcionDispo,
  Organizacion,
  Tipo,
  ContenedorFecha,
  Tiempo,
  Fecha,
  BotonMostrar,
  BotonDatos,
} from "./AlcantarillaElements";

const Index = () => {
  const [results, setResults] = useState([]);
  const [text, setText] = useState("");
  const [aux, setAux] = useState(Auth.getRol());

  useEffect(() => {
    Api.getDeviceByPartialId(text).then(
      (result) => {
        setResults(result);
      },
      (error) => {
        console.log("Obtencion erronea");
      }
    );
  }, [text]);

  return (
    <>
      <Main>
        <ContenedorTitulo>
          <Titulo>Dispositivos alcantarillas</Titulo>
        </ContenedorTitulo>

        <ContenedorTabla>
          <NavbarTabla>
            <BarraBusqueda>
              <IconoLupa>
                <SearchIcon />
              </IconoLupa>
              <InputBusqueda setText={setText} text={text} />
            </BarraBusqueda>
            {aux == "administrador general" && (
              <Button
                url="/admin/MapaAlacantarilla"
                message="Crear Nuevo"
                style={BotonCrear}
              />
            )}

            <Button
              url="/admin/viewDevices"
              message="Mostrar todo"
              style={BotonMostrar}
            />
          </NavbarTabla>
          <TablaDispo>
            <Tr>
              <Th>Dispositivo</Th>
              <Th>Organizacion</Th>
              <Th>Fecha creacion</Th>
              <Th>UTC</Th>
              <Th>Acciones</Th>
            </Tr>
            {results.length > 0 ? (
              results.map((device, i) => (
                <TrC>
                  <td>
                    <ContenedorDis>
                      <Nombredispositivo>{device.deviceID}</Nombredispositivo>
                      <DescripcionDispo>{device.tipo}</DescripcionDispo>
                    </ContenedorDis>
                  </td>

                  <td>
                    <Organizacion>{device.organizacion}</Organizacion>
                  </td>
                  <td>
                    <ContenedorFecha>
                      <Fecha>
                        {device.fechaRegistro
                          .toString()
                          .slice(
                            0,
                            device.fechaRegistro.toString().indexOf("T")
                          )}
                        {" / "}
                        {device.fechaRegistro
                          .toString()
                          .slice(
                            device.fechaRegistro.toString().indexOf("T") + 1,
                            16
                          )}
                      </Fecha>
                    </ContenedorFecha>
                  </td>
                  <td>
                    <Tiempo>{device.utc}</Tiempo>
                  </td>
                  <td>
                    <Button
                      url={"/admin/dataDevice?id=" + device.deviceID}
                      message="Mostrar Datos"
                      style={BotonDatos}
                    />
                  </td>
                </TrC>
              ))
            ) : (
              <div>No hay resultados</div>
            )}
          </TablaDispo>
        </ContenedorTabla>
      </Main>
    </>
  );
};

export default Index;
