import React from "react";
import ReactApexChart from "react-apexcharts";
import hume from "./indicadorhum.png";

import { Card } from "react-bootstrap";

class radialbartemp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      series: this.props.seriesData,
      options: {
        chart: {
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 105,
              size: '70%',
              image: hume,
              imageWidth: 40,
              imageHeight: 40,
              imageClipped: false
            },
            dataLabels: {
              name: {
                show: false,
                color: '#333',
                offsetY: -20,
              },
              value: {
                show: true,
                color: '#333',
                offsetY: 45,
                fontSize: '22px'
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            gradientToColors: ['#071478'],
            shadeIntensity: 1,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100]
          },
        },

        stroke: {
          lineCap: 'round'
        },
        labels: ['Humedad'],
      },


    };

    this.setState({ series: [this.props.seriesData] })

  }



  render() {
    return (
      <>
        <ReactApexChart options={this.state.options} series={[this.props.seriesData]} type="radialBar" height={220} />
      </>
    );
  }
}
export default radialbartemp;