import React, { useState } from "react";
import {
  Main,
  Formulario,
  Titulo,
  Form,
  H2,
  Fecha,
  Hora,
  ContenedorBotones,
  Botones,
  LavelTitu,
  ContenedorSelect,
  ParaMarcado,
  Casilla,
  ForDivv,
  BotonInterno,
  BotonnDos,
  ContenedorHorarios,
  Lavel,
  CasillaIn,
  Horarios,
  ParaNombre,
  ImagenDiv,
} from "./FormElements";
import { Button, Modal } from "react-bootstrap";
import Api from "../../../services/api-service.js";
import Auth from "../../../services/auth-service.js";

export default function FormularioLote() {
  var hoy = new Date();
  // console.log("hoy", hoy)
  var mes;
  if (hoy.getMonth() + 1 < 10) {
    mes = "0" + (hoy.getMonth() + 1);
  } else {
    mes = hoy.getMonth() + 1;
  }
  var dia;
  if (hoy.getDate() < 10) {
    dia = "0" + hoy.getMonth();
  } else {
    dia = hoy.getDate();
  }
  var fecha = hoy.getFullYear() + "-" + mes + "-" + dia;
  var hora1;
  if (hoy.getHours() < 10) {
    hora1 = "0" + hoy.getHours();
  } else {
    hora1 = hoy.getHours();
  }
  var min;
  if (hoy.getMinutes() < 10) {
    min = "0" + hoy.getMinutes();
  } else {
    min = hoy.getMinutes();
  }
  var sec;
  if (hoy.getSeconds() < 10) {
    sec = "0" + hoy.getSeconds();
  } else {
    sec = hoy.getSeconds();
  }
  var hora = "T" + hora1 + ":" + min + ":" + sec;
  var fechaYHora = fecha + "" + hora;

  // let lineas3 = [{nombre: 'C1', num: 1},
  //               {nombre: 'C2', num: 2},
  //               {nombre: 'C3', num: 3},
  //               {nombre: 'C4', num: 4},
  //               {nombre: 'C5', num: 5},
  //               {nombre: 'C6', num: 6},
  //               {nombre: 'C7', num: 7},
  //               {nombre: 'C8', num: 8},
  //               {nombre: 'C9', num: 9},
  //               {nombre: 'C10', num: 10},
  //               {nombre: 'C11', num: 11},
  //               {nombre: 'C12', num: 12},
  //               {nombre: 'C13', num: 13},
  //               {nombre: 'C14', num: 14},
  //               {nombre: 'C15', num: 15},
  //               {nombre: 'C16', num: 16},
  //               {nombre: 'C17', num: 17},
  //               {nombre: 'C18', num: 18},
  //               {nombre: 'C19', num: 19},
  //               {nombre: 'C20', num: 20},
  //               {nombre: 'C21', num: 21},
  //               {nombre: 'C22', num: 22},
  //               {nombre: 'C23', num: 23},
  //               {nombre: 'C24', num: 24},
  //             ];

  // let lineas2 = [{nombre: 'C22', num: 22},
  //               {nombre: 'C19', num: 19},
  //               {nombre: 'C16', num: 16},
  //               {nombre: 'C13', num: 13},
  //               {nombre: 'C23', num: 23},
  //               {nombre: 'C20', num: 20},
  //               {nombre: 'C17', num: 17},
  //               {nombre: 'C14', num: 14},
  //               {nombre: 'C24', num: 24},
  //               {nombre: 'C21', num: 21},
  //               {nombre: 'C18', num: 18},
  //               {nombre: 'C15', num: 15},

  //               {nombre: 'C1', num: 1},
  //               {nombre: 'C4', num: 4},
  //               {nombre: 'C7', num: 7},
  //               {nombre: 'C10', num: 10},
  //               {nombre: 'C2', num: 2},
  //               {nombre: 'C5', num: 5},
  //               {nombre: 'C8', num: 8},
  //               {nombre: 'C11', num: 11},
  //               {nombre: 'C3', num: 3},
  //               {nombre: 'C6', num: 6},
  //               {nombre: 'C9', num: 9},
  //               {nombre: 'C12', num: 12},
  //             ];

  let lineas1 = [
    { nombre: "C1", num: 1 },
    { nombre: "C4", num: 4 },
    { nombre: "C7", num: 7 },
    { nombre: "C10", num: 10 },
    { nombre: "C2", num: 2 },
    { nombre: "C5", num: 5 },
    { nombre: "C8", num: 8 },
    { nombre: "C11", num: 11 },
    { nombre: "C3", num: 3 },
    { nombre: "C6", num: 6 },
    { nombre: "C9", num: 9 },
    { nombre: "C12", num: 12 },
  ];

  let lineas = [
    { nombre: "C22", num: 22 },
    { nombre: "C19", num: 19 },
    { nombre: "C16", num: 16 },
    { nombre: "C13", num: 13 },
    { nombre: "C23", num: 23 },
    { nombre: "C20", num: 20 },
    { nombre: "C17", num: 17 },
    { nombre: "C14", num: 14 },
    { nombre: "C24", num: 24 },
    { nombre: "C21", num: 21 },
    { nombre: "C18", num: 18 },
    { nombre: "C15", num: 15 },
  ];

  const [checkedState, setCheckedState] = useState(
    new Array(lineas.length).fill(false)
  );

  const [checkedState1, setCheckedState1] = useState(
    new Array(lineas1.length).fill(false)
  );

  const [state, setState] = useState({
    nombreLote: "",
    tipolote: "",
    pesolote: "",
    cajon: "",
    fecha: "",
    hora: "",
    deviceID: "000",
  });

  const [aux, setAux] = useState(Auth.getRol());

  const [popShow, setPopShow] = useState(false);

  const [contPopUp, setContPopUp] = useState("");

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    if (name === "cajon") {
      if (value === state.cajon) {
        setState((prevState) => ({
          ...prevState,
          [name]: "",
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    // console.log("Forma",state)
  };

  function submitForm() {
    var vacio = Object.values(state);
    if (vacio.includes("")) {
      // console.log("Hay un elemento vacio")
      setContPopUp("error");
      setPopShow(true);
    } else {
      setContPopUp("ok");
      setPopShow(true);

      var cajonesIDS = Api.getIDsBeta();
      // console.log("lineas", lineasIDS)
      cajonesIDS.map((datos) => {
        // console.log("datos nuevos", datos)
        datos.CTL.map((datos1) => {
          if (datos1.cajon == state.cajon) {
            // console.log("id", datos.ID)
            state.deviceID = datos.ID;
            state.procesoNombre =
              state.deviceID +
              state.hora +
              state.fecha +
              fechaYHora +
              state.nombreLote;
            state.modulo = datos.modulo;
            state.cajon = datos1.cajon;
            state.linea = datos1.linea;
            state.lineaNum = datos1.num;
            state.lineaVariable = datos1.variable;
            state.fechaECingreso = fechaYHora;
            var fechafin = new Date(state.fecha + "T" + state.hora + ":00");
            fechafin.setHours(fechafin.getHours() + 96);
            state.fechaUTCfin = fechafin;
          }
        });
      });

      // console.log(fechaYHora);
      // console.log('envio info--->',state);
      setState({
        nombreLote: "",
        tipolote: "",
        pesolote: "",
        cajon: "",
        fecha: "",
        hora: "",
      });
      setCheckedState(new Array(lineas.length).fill(false));
      setCheckedState1(new Array(lineas1.length).fill(false));

      Api.creacionProcesos(state).then(
        (result) => {
          console.log(result);
        },
        (error) => {
          console.log("error en la creacion");
          alert("error en la actualización ");
        }
      );
    }
  }

  const handleOnChange = (position) => {
    // console.log("position", position)
    var updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    updatedCheckedState = checkedState.map((item, index) =>
      index !== position ? false : !item
    );
    setCheckedState1(new Array(lineas1.length).fill(false));
    setCheckedState(updatedCheckedState);
  };

  const handleOnChange1 = (position) => {
    // console.log("position", position)
    var updatedCheckedState1 = checkedState1.map((item, index) =>
      index === position ? !item : item
    );
    updatedCheckedState1 = checkedState1.map((item, index) =>
      index !== position ? false : !item
    );
    setCheckedState1(updatedCheckedState1);
    setCheckedState(new Array(lineas.length).fill(false));
  };

  const cerrarPopUp = () => {
    setPopShow(false);
  };

  const cancelForm = () => {
    setCheckedState(new Array(lineas.length).fill(false));
    setState({
      nombreLote: "",
      tipolote: "",
      pesolote: "",
      cajon: "",
      fecha: "",
      hora: "",
    });
  };

  return (
    <Main>
      <Modal show={popShow} onHide={cerrarPopUp}>
        <Modal.Header closeButton>
          {contPopUp === "error" && <Modal.Title>Error</Modal.Title>}
          {contPopUp === "ok" && <Modal.Title>Éxito</Modal.Title>}
        </Modal.Header>
        {contPopUp === "error" && (
          <Modal.Body>Llene todos los parámetros</Modal.Body>
        )}
        {contPopUp === "ok" && (
          <Modal.Body>El proceso se ha guardado con éxito</Modal.Body>
        )}
        <Modal.Footer>
          <Button variant="secondary" onClick={cerrarPopUp}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Formulario>
        <Titulo>ASIGNACIÓN DE LOTE</Titulo>
        <ParaNombre>
          <Form>
            <LavelTitu>NOMBRE DEL LOTE</LavelTitu>
            <CasillaIn
              type="text"
              id="nombreLote"
              name="nombreLote"
              value={state.nombreLote}
              onChange={handleChangeForm}
            />
          </Form>
          <br></br>
          <Form>
            <LavelTitu for="tipolote">TIPO DE LOTE</LavelTitu>
            <ForDivv
              id="tipolote"
              name="tipolote"
              value={state.tipolote}
              onChange={handleChangeForm}
            >
              <option value="baba">BABA</option>
              <option value="escurrido">ESCURRIDO</option>
              <option selected hidden value="">
                No Seleccionado
              </option>
            </ForDivv>
          </Form>
          <br></br>
          <Form>
            <LavelTitu>PESO DEL LOTE (Libras)</LavelTitu>
            <CasillaIn
              type="number"
              id="pesolote"
              name="pesolote"
              value={state.pesolote}
              onChange={handleChangeForm}
            />
          </Form>
          <br></br>
        </ParaNombre>
        <br />
        <hr size="2px" color="#c3c7c2" />
        <H2>CAJONES</H2>
        <ContenedorSelect
          id="Cajones"
          value={state.lineasfermetacion}
          onChange={handleChangeForm}
        >
          <>
            <h3>BLOQUE 2 (IZQUIERDA)</h3>
            {lineas.map(({ nombre, num }, index) => {
              return (
                <ParaMarcado>
                  <Casilla
                    type="checkbox"
                    id={`custom-checkbox-${index}`}
                    name="cajon"
                    value={num}
                    checked={checkedState[index]}
                    onChange={() => handleOnChange(index)}
                  />
                  <Lavel for="cajon">{nombre}</Lavel>
                </ParaMarcado>
              );
            })}
          </>
        </ContenedorSelect>
        <ContenedorSelect
          id="Cajones2"
          value={state.lineasfermetacion}
          onChange={handleChangeForm}
        >
          <>
            <h3>BLOQUE 3 (DERECHA)</h3>
            {lineas1.map(({ nombre, num }, index) => {
              return (
                <ParaMarcado>
                  <Casilla
                    type="checkbox"
                    id={`custom-checkbox-${index}`}
                    name="cajon"
                    value={num}
                    checked={checkedState1[index]}
                    onChange={() => handleOnChange1(index)}
                  />
                  <Lavel for="cajon">{nombre}</Lavel>
                </ParaMarcado>
              );
            })}
          </>
        </ContenedorSelect>
        <hr size="2px" color="#c3c7c2" />
        <ContenedorHorarios>
          <Horarios>
            <Lavel>FECHA INICIO</Lavel>
            <Fecha
              type="date"
              id="fecha"
              name="fecha"
              value={state.fecha}
              onChange={handleChangeForm}
            />
          </Horarios>
          <Horarios>
            <Lavel>HORA INICIO</Lavel>
            <Hora
              type="time"
              id="hora"
              name="hora"
              value={state.hora}
              onChange={handleChangeForm}
            />
          </Horarios>
        </ContenedorHorarios>
        <ContenedorBotones>
          <Botones>
            <BotonInterno lightBg={false} onClick={() => cancelForm()}>
              Cancelar
            </BotonInterno>
          </Botones>
          {aux == "administrador general" && (
            <Botones>
              <BotonInterno lightBg={true} onClick={() => submitForm()}>
                Enviar
              </BotonInterno>
            </Botones>
          )}
        </ContenedorBotones>
      </Formulario>
    </Main>
  );
}
