import React, { useState, useEffect } from "react";
import {
  BarraBusqueda,
  BotonCrear,
  BotonFiltro,
  Categorias,
  ContenedorCategorias,
  ContenedorTabla,
  ContenedorTitulo,
  Main,
  NavbarTabla,
  Th,
  Titulo,
  Tr,
  TablaDispo,
  TrC,
  CoFiltroIn,
  Divfiltro,
  SelectFiltro,
  IconoLupa,
  InputBusqueda,
  ContenedorDis,
  Nombredispositivo,
  DescripcionDispo,
  Organizacion,
  Tipo,
  ContenedorFecha,
  Estado,
  Tiempo,
  Fecha,
} from "./DispositivosElementos";
import Dato from "./data.json";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Loading } from "./Alerta";
import { ObtencionDatos } from "./OtencionDatos";

const ContenidoTabla = () => {
  const [modal, setModal] = useState(false);

  const abrirCerrarModal = () => {
    setModal(!modal);
  };

  const [currentPage, setCurrentPage] = useState(10);

  const [dropdown, setDropdown] = useState(false);

  const abrirCerrarDrop = () => {
    setDropdown(!dropdown);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 10);
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 10);
    }
  };

  return (
    <>
      <TablaDispo>
        <Tr>
          <Th>ID</Th>
          <Th>Dispositivo</Th>
          <Th>Orgtanizacion</Th>
          <Th>Tipo</Th>
          <Th>Fecha de ultimo dato</Th>
          <Th>Tiempo Ultimo Dato</Th>
          <Th></Th>
        </Tr>

        {Dato.map((item, i) => (
          <TrC>
            <td>
              <input type="checkbox" id="cbox2" value="second_checkbox" />
            </td>
            <td>
              <ContenedorDis>
                <Nombredispositivo>{item.nombre}</Nombredispositivo>
                <DescripcionDispo>{item.id}</DescripcionDispo>
              </ContenedorDis>
            </td>

            <td>
              <Organizacion>{item.organizacion}</Organizacion>
            </td>
            <td>
              <Tipo>{item.tipo}</Tipo>
            </td>
            <td>
              <ContenedorFecha>
                <Estado>{item.status}</Estado>
                <Fecha>{item.fechaUltimo}</Fecha>
              </ContenedorFecha>
            </td>
            <td>
              <Tiempo>{item.tiempoultimo}s</Tiempo>
            </td>
            <td>
              <Dropdown
                isOpen={dropdown}
                toggle={abrirCerrarDrop}
                direction="left"
              >
                <DropdownToggle className="testeo">
                  <MoreVertIcon className="botonci" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>Accio1</DropdownItem>
                  <DropdownItem>Accio2</DropdownItem>
                  <DropdownItem>Accio3</DropdownItem>
                  <DropdownItem>Accio4</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </td>
          </TrC>
        ))}
      </TablaDispo>
      <CoFiltroIn>
        <Divfiltro>
          <label>Filas por pagina</label>
          <SelectFiltro>
            <option value="value1">Value 1</option>
            <option value="value2" selected>
              Value 2
            </option>
            <option value="value3">Value 3</option>
          </SelectFiltro>
        </Divfiltro>
        <Divfiltro>251 of 596</Divfiltro>
        <Divfiltro>
          <ArrowBackIosIcon />
        </Divfiltro>
        <Divfiltro>
          <ArrowForwardIosIcon />
        </Divfiltro>
      </CoFiltroIn>
    </>
  );
};

export default ContenidoTabla;
