import React, { useState, useEffect } from "react";
import { Button, Modal, ModalTitle } from "react-bootstrap";
import swal from "sweetalert";
import "w3-css/w3.css";
import Tx from "../input";
import "./estiloform.css";
// import MapaGoogle from './MapaGoogle.js'
import {
  Map,
  InfoWindow,
  Marker,
  GoogleApiWrapper,
  Circle,
} from "google-maps-react";
import EdicionMapa from "./EdicionMapa";

import {
  BotonModalPos,
  ModalPosicionamiento,
} from "../modals/modalPosicionamiento";
import { makeStyles } from "@mui/styles";

export function BootstrapModa(props) {
  // console.log(props.item.deviceID);

  // const errarse =
  //   "http://aiot.constecoin.com/api/inv/inventario/borrarProducto";

  const errarse =
    "https://aiot.constecoin.com/api/administracion-dispositivos/dispositivos/borrarDispositivo";

  const expresiones = {
    //usuario: /^[a-zA-Z0-9\_\-]{4,16}$/, // Letras, numeros, guion y guion_bajo
    // nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
    // password: /^.{12}$/, // 4 a 12 digitos.
    //correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    // cantidad: /^\d{1,5}$/ // 7 a 14 numeros.
  };

  // console.log(props.item)

  var [datos, setDatos] = useState({
    //   deviceID: null,
    //   nombre:  null,
    // mapVisible:false    /
  });

  useEffect(() => {
    if (props.item) {
      setDatos(props.item);
    } else {
      setDatos({
        nombre: "",
      });
    }
    //onsole.log(datos)
  }, [props.item]);

  function enviarDatos(e) {
    var envio = {
      deviceID: datos.deviceID,
    };

    if (datos.nombre) envio.nombre = datos.nombre;
    if (datos.grupo) envio.grupo = datos.grupo;
    if (datos.idWifi) envio.idWifi = datos.idWifi;
    if (datos.tipo) envio.tipo = datos.tipo;
    if (datos.idSigfox) envio.idSigfox = datos.idSigfox;
    if (datos.chipID) envio.chipID = datos.chipID;
    if (datos.adicional) envio.adicional = datos.adicional;
    if (datos.posicion) envio.posicion = datos.posicion;

    // (!datos.nombre)? envio.nombre = datos.nombre: console.log('');

    console.log(envio);
    console.log(datos);

    e.preventDefault();
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(envio),
    };

    console.log(JSON.stringify(envio));
    fetch(links, requestOptions)
      .then((response) => response.json())
      .then((response) => console.log(response));
    props.handleClose();
  }

  const monstrarAlerta = (id) => {
    swal({
      title: "Eliminar",
      text: "Estas seguro de que deseas eliminar el archivo",
      icon: "warning",
      buttons: ["No", "Si"],
    }).then((respuesta) => {
      if (respuesta) {
        var eliminar = { deviceID: id };
        const requestOptions = {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(eliminar),
        };

        fetch(errarse, requestOptions).then((response) =>
          console.log(response)
        );
        swal({
          text: "El dispositivo se ha borrado con exito",
          icon: "success",
          timer: "2000",
        });
      }
    });
  };

  function borrar(id) {
    console.log("Este es el valor: " + id);
    var eliminar = { _id: id };
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(eliminar),
    };

    fetch(errarse, requestOptions).then((response) => console.log(response));
  }

  function handleShow(item) {
    // handleShow = (item)=> {
    // this.setState({selectedItem : item})
    // if(item){
    //   this.setState({showEdit : true})
    // } else{
    //   this.setState({showEdit : false})
    // }
    // }
  }

  const links =
    "https://aiot.constecoin.com/api/administracion-dispositivos/dispositivos/editarDispositivo";

  const ingreso = (event) => {
    console.log(event.target.name, event.target.value);

    setDatos((datos) => ({
      ...datos,
      [event.target.name]: event.target.value,
    }));
  };

  function showMap() {
    console.log("mapVisible");

    let parametro = "mapVisible";
    setDatos((datos) => ({
      ...datos,
      [parametro]: !datos[parametro],
    }));

    console.log(datos);
  }

  function changeMarker(event) {
    console.log(event);

    let parametro = "posicion";
    let auxPosicion = datos.posicion;
    if (!auxPosicion) {
      auxPosicion = {
        type: "Point",
        coordinates: [],
      };
    }
    auxPosicion.coordinates = [event.position.lat, event.position.lng];
    console.log("auxPosicion", datos, auxPosicion, event);
    setDatos((datos) => ({
      ...datos,
      [parametro]: auxPosicion,
    }));
  }

  return (
    <>
      <Modal
        show={props.setShow}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        className="w3-animate-zoom estiloModal2"
      >
        <Modal.Header closeButton className="w3-rigth">
          {/* w3-modal-content{background-color: transparent} w3-animate-zoom" */}
          {/* "w3-animate-zoom estiloModal */}
        </Modal.Header>
        <Modal.Body>
          <div className="w3-row">
            <div className="w3-rest  ">
              <h1 className="texto-titulo titulo-card titulo-pagina">
                Edición de dispositivo
              </h1>

              <form onSubmit={enviarDatos}>
                <div className="row">
                  <div className="col-sm-12">
                    <Tx
                      className="bloqueTexto"
                      etiqueta="Nombre"
                      tipo="text"
                      name="nombre"
                      onChange={ingreso}
                      value={!datos.nombre ? "" : datos.nombre}
                      expresion={expresiones.nombre}
                    />
                    {/* <Tx 
                etiqueta ="Organizacion"
                tipo = "text"
                name='organization'
                onChange={ingreso}
                value ={(!datos.organization)? '': datos.organization}
                expresion ={expresiones.organization}
                />  */}
                    {/* <Tx
                etiqueta ="chip ID"
                tipo = "text" 
                name='chipID'
                onChange={ingreso}
                value ={(!datos.chipID)? '': datos.chipID}
                expresion ={expresiones.chipID} />  */}
                    <Tx
                      etiqueta="Tipo"
                      tipo="text"
                      name="tipo"
                      onChange={ingreso}
                      value={!datos.tipo ? "" : datos.tipo}
                      expresion={expresiones.tipo}
                    />
                    <Tx
                      etiqueta="ID sigfox"
                      tipo="text"
                      name="idSigfox"
                      onChange={ingreso}
                      value={!datos.idSigfox ? "" : datos.idSigfox}
                      expresion={expresiones.idSigfox}
                    />
                    <Tx
                      etiqueta="MAC"
                      tipo="text"
                      name="idWifi"
                      onChange={ingreso}
                      value={!datos.idWifi ? "" : datos.idWifi}
                      expresion={expresiones.idWifi}
                    />

                    {/* <div> */}

                    {/* </div> */}
                  </div>
                  <div className="col-sm-12">
                    <Button
                      onClick={() => {
                        showMap();
                        console.log("click");
                      }}
                      className="buttonGeneral"
                    >
                      Mapa
                    </Button>
                    {datos.mapVisible && (
                      <EdicionMapa
                        datos={datos}
                        changeCallback={changeMarker.bind(this)}
                        className="estiloMapa"
                      />
                    )}

                    {/* {this.state.mapVisible && return(<EdicionMapa className="estiloMapa"/>) }      */}
                  </div>
                </div>

                <Button className="buttonGeneral" type="submit">
                  Actualizar
                </Button>
                <Button
                  className="buttonGeneral button-secondary"
                  variant="primary"
                  onClick={() => monstrarAlerta(props.item.deviceID)}

                  // onClick={() => borrar(props.item._id)}
                >
                  Eliminar{" "}
                </Button>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <ModalPosicionamiento
            setShow ={this.state.showEdit}
            handleClose={()=>handleShow(null)}
            // item={this.state.selectedItem}
            // id={item._id}
            // nombre={this.state.selectedItem.nombre}
            // organization={item.organization}
            // descripcion={item.descripcion}
            // codigoDeBarras={item.codigoDeBarras}
        
            
          /> */}
    </>
  );
}
export function BotonModal(props) {
  return (
    <Button className="buttonGeneral" onClick={props.onClick}>
      {props.etiqueta}
    </Button>
  );
}
