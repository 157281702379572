import React, {useEffect,useState} from 'react';
import Ventana from  '../formulario'
// import "../../src/ src/assets/css/busqueda.css"
// import "../src/assets/css/texto.css"

import { BotonModal,BootstrapModa } from './formulario_editar';


import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
  } from "react-bootstrap";

  
  
const Consumo =() => {
  const  errarse ='http://iot.constecoin.com/api/inv/inventario/borrarProducto'
  const  links ='https://iot.constecoin.com/api/cadena-frio/inventario/inventario/listaProductos'

    
    const [datos, setDatos]= useState([])

    const [search,setSearch]=useState('')



    
    const [show, setShow] = useState(false);
    const [item, setItem] = useState([]);
    const handleClose =()=> setShow(false);
   const handleShow = (i)=> {

        let item = i;


        if(item.variables)
          if(item.variables.temperatura)
            if(item.variables.temperatura.max)
              item.max = item.variables.temperatura.max
            else
            item.max = 0;
          else
          item.max = 0;
        else
        item.max = 0;


        if(item.variables)
          if(item.variables.temperatura)
            if(item.variables.temperatura.min)
              item.min = item.variables.temperatura.min
            else
            item.min = 0;
          else
          item.min = 0;
        else
        item.min = 0;

        if(item.variables)
          if(item.variables.temperatura)
            if(item.variables.temperatura.tiempoOut)
              item.tiempoOut = item.variables.temperatura.tiempoOut
            else
            item.tiempoOut = 0;
          else
          item.tiempoOut = 0;
        else
        item.tiempoOut = 0;
        

        // if(!item.variables?.temperatura?.min){
        //   item.min = null;
        // } else{
        //   item.min = item.variables.temperatura.min
        // }

        // if(!item.variables.temperatura.tiempoOut){
        //   item.tiempoOut = null;
        // } else{
        //   item.tiempoOut = item.variables.temperatura.tiempoOut
        // }

        console.log('.........................................', item)
        setItem(item)
      
        setShow(true);
     
       }
   /*  function borrar (id){
      var eliminar ={_id:id}
      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(eliminar)}

        fetch(errarse, requestOptions)
        .then(response => console.log(response))
       
        
   
       
    } 
     */
    const peticionGet = async () => {
        const data =await fetch(links)
       const contenido = await data.json()
    
     setDatos(contenido)
  
    }
    useEffect(()=>{
        peticionGet()
        
        
    },)
    

    
    

    return(
        <>
          <section className="w3-center w3-panel">
       <Ventana className=""/> 
       </section>

        <div className="w3-left">
        </div>
        <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4" className="texto-titulo titulo-card">PRODUCTOS</Card.Title>
                  <p className="subtitulo-card">
                    Tabla de registro de productos 
                  </p>
                  <input className=" busqueda  texto-busqueda"   type="text" placeholder="busqueda por nombre " onChange={(event) => {
            setSearch(event.target.value)
        }}></input>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table className="table-hover table-striped">
                    <thead>
                      <tr className="cuadro-titulo">
                        {/* <th className="border-0">ID</th> */}
                        <th className="border-0 texto-titulo">Nombre</th>
                        <th className="border-0 texto-titulo">Cantidad</th>
                        <th className="border-0 texto-titulo">Codigo de barras</th>
                        <th className="border-0 texto-titulo">Descripcion</th>
                        <th className="border-0 texto-titulo">Accion</th>
                       
                      </tr>
                    </thead>
                    <tbody className="">
                      {datos.filter((value)=>{
        if (search==""){
        return value}
        else if (value.nombre.toLowerCase().includes(search.toLowerCase())){
          return value
        }}).map(( item, index ) => {
                        return (
                          <tr   key={index}>
                            {/* <td>{item._id}</td> */}
                            <td className="text-tabla">{item.nombre}</td>
                            <td className="text-tabla">{item.cantidad}</td>
                            <td className="text-tabla">{item.codigoDeBarras}</td>
                            <td className="text-tabla">{item.descripcion}</td>
                            <td>
                              <BotonModal className ="buttonGeneral" etiqueta ="Editar" onClick={()=>handleShow(item)} ></BotonModal> 
                             {/*  <Button onClick={()=>borrar(item._id)} className ="w3-deep-orange w3-button  w3-large"> Eliminar</Button> */}
                              </td>
                    
                          </tr>
                        );
                      })}

                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <BootstrapModa 
            setShow ={show}
            handleClose={handleClose}
            item={item}
            id={item._id}
            nombre={item.nombre}
            cantidad={item.cantidad}
            descripcion={item.descripcion}
            codigoDeBarras={item.codigoDeBarras}
            
            
          />


        </>
    )
} 
export default Consumo