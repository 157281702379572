import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const SelectOperador = (props) => {

  function data(e) {

    let valor = false    
 console.log(e.target.value)
    props.data.map((index) => { 
      if (index.value == e.target.value) {
        if (index.tipo == "booleano") {
          valor=true
        }
      }
    })

    props.setFunction(e.target.value)
      props.setValor(valor)


  }



  return (
    <>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
         <TextField
            id="outlined-select-currency"
            select
            label={props.message}
            value={props.value}
            onChange={(e) =>  data(e)}
          >
            {props.data.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
      </Box>
    </>
  );
};

export default SelectOperador;
