import React from "react";

import "./StatusDispositivos.css";
import Api from "../../services/api-service.js";
import Auth from "../../services/auth-service.js";
import ReactToExcel from "react-html-table-to-excel";
import { CSVLink } from "react-csv";

import Beta from "../../components/betatemp/ConsumoBeta";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { render } from "react-dom";

function adquisicionConectedDevices() {
  return new Promise((resolve, reject) => {
    fetch(
      "http://iot-api.constecoin.com:8080/api/modulos-cacao/getAlertDevices"
    )
      // fetch("http://172.16.2.87/api/modulos-cacao/getDevices")
      .then((res) => res.json())
      .then(
        (result) => {
          // elements = result;
          // elements = [...elements];
          // console.log('elements',result);
          resolve(result);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
  });
}

class DispositivosIoT extends React.Component {
  constructor(props) {
    super(props);

    let x1 = this.props.location.search;
    console.log("this.props.location.search", this.props.location.search);
    x1 = x1.replace("?organizacion=", "");

    this.state = {
      open: false,
      selectedAlerta: null,
      selectedOrganizacion: x1,
      devicesTable: [],
      tablaFiltrada: [],
      rol: Auth.getRol(),
      organizaciones: Auth.getOrganizaciones(),

      tablasShow: {
        showTiempoReporte: false,
      },

      // showBetatemp: false,
      // showSigfox: false
    };

    this.buttonClick("reportes");
  }

  handleChangeAlerta = (event) => {
    this.setState({ selectedAlerta: event.target.value });
    console.log(event.target.value);
    this.filtroTablaAlertas(this.state.devicesTable, event.target.value);
  };
  handleChangeOrganizacion = (event) => {
    this.setState({ selectedOrganizacion: event.target.value });
    console.log(event.target.value);
    this.filtroTablaDevices(this.state.devicesTable, event.target.value);
  };

  buttonClick(tipo) {
    if (tipo === "reportes") {
      this.setState({ tablasShow: { showTiempoReporte: true } });

      console.log(Auth.getJWT());
    }
    // if(tipo === 'lora'){
    //   console.log(Auth.getJWT());
    // }
    // if(tipo === 'adquirir'){
    //   console.log(Auth.getJWT());
    //   this.setState({showBetatemp: true, showSigfox: false})
    // }
    // if(tipo === 'cerrar'){
    //   Auth.saveJWT(null);
    //   this.props.history.push('/login/ingreso')
    // } else {
    Api.adquirirDevicesConected().then(
      (result) => {
        console.log(result);
        this.setState({ devicesTable: result });
        // this.setState({tablaFiltrada: result})
        // this.state.devicesTable = result;
        this.filtroTablaDevices(result, this.state.selectedOrganizacion);
      },
      (error) => {
        console.log("error en la adquisicion de datos");
      }
    );
    // }
  }

  showData = () => {
    console.log(this.state.devicesTable);
  };

  filtroTablaAlertas(tabla, alerta) {
    this.filtroTabla(tabla, this.state.selectedOrganizacion, alerta);
  }

  filtroTablaDevices(tabla, organizacion) {
    this.filtroTabla(tabla, organizacion, this.state.selectedAlerta);
  }

  filtroTabla(tabla, organizacion, alerta) {
    const result = tabla.filter((item) => {
      // console.log(item);
      let auxOrganizacion = true,
        auxAlerta = false;

      //////////////////////////////////////////////   ORGANIZACION ///////////////////////////////
      if (organizacion === "all") {
        auxOrganizacion = true;
      }
      if (organizacion === null) {
        auxOrganizacion = true;
      } else {
        if (item.organization === organizacion) {
          auxOrganizacion = true;
        }
      }

      //////////////////////////////////////////////   ALERTAS  ///////////////////////////////

      if (alerta === "all") {
        auxAlerta = true;
      }
      if (alerta === null) {
        auxAlerta = true;
      } else {
        if (item.alertaDesconexion && alerta === "alerta") {
          auxAlerta = true;
        }
        if (!item.alertaDesconexion && alerta === "reportando") {
          auxAlerta = true;
        }
      }

      // if(auxOrganizacion && auxTipo){
      //   console.log(true);
      // }

      return auxOrganizacion && auxAlerta;
    });

    this.setState({ tablaFiltrada: result });
  }

  render() {
    return (
      <>
        <Container fluid>
          {/* <div class="buttonsElements">
            <ul>
              <li><a onClick={()=>this.buttonClick('reportes')}>Tiempos Reportes</a></li>
            </ul>
          </div> */}
          {/* {this.state.tablasShow.showTiempoReporte && */}
          <Row>
            {/* <Col md="2">
              <div class="selectores">
                <label for="organizacion">Organización:</label>
                <select id="organizacion" onChange={this.handleChangeOrganizacion}>
                  <option selected value="all">Todos</option>
                  {this.state.organizaciones.map(( item, index ) => {
                      return (
                        <option value={item.organizacion}>{item.organizacion}</option>
                      )
                    })}
                </select>
              </div> 
            </Col> */}

            <Col md="2">
              <div class="selectores">
                <label for="tipo">En Alerta:</label>
                <select id="tipo" onChange={this.handleChangeAlerta}>
                  {/* <option value="" disabled selected hidden>seleccionar</option> */}
                  <option selected value="all">
                    TODO
                  </option>
                  <option value="reportando">Reportando</option>
                  <option value="alerta">En alerta</option>
                  {/* <option value="sigfox">Sigfox</option>
                  <option value="celular">Celular</option> */}
                </select>
              </div>
            </Col>

            <Col md="2">
              <div>
                <ReactToExcel
                  className="btn btn-primary  buttonGeneral"
                  table="tabla-obtenida"
                  filename="iotTablaConste"
                  sheet="sheet 1"
                  buttonText="Exportar como xls"
                />
              </div>
            </Col>

            <Col md="2">
              <div>
                <CSVLink
                  data={this.state.devicesTable}
                  filename={"iotTablaConste.csv"}
                >
                  <Button className="buttonGeneral"> Exportar como csv</Button>
                </CSVLink>
              </div>
            </Col>

            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">Devices</Card.Title>
                  {/* <p className="card-category">
                    Tabla de dispositivos conectados a CONSTECOIN
                  </p> */}
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table
                    className="table-hover table-striped"
                    id="tabla-obtenida"
                  >
                    <thead>
                      <tr>
                        {/* <th className="border-0">ID</th> */}
                        <th className="border-0">Device ID</th>
                        <th className="border-0">Organización</th>
                        <th className="border-0">TIPO</th>
                        <th className="border-0">Fecha Último Dato</th>
                        <th className="border-0">Hora Último Dato</th>
                        <th className="border-0">Tiempo Último Dato</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.tablaFiltrada.map((item, index) => {
                        return (
                          <tr key={index}>
                            {/* <td>{item._id}</td> */}
                            <td>{item.deviceID}</td>
                            <td>{item.organizacion}</td>
                            <td>{item.tipo}</td>
                            <td>
                              {item.fechaUltimoDato
                                .toString()
                                .slice(
                                  0,
                                  item.fechaUltimoDato.toString().indexOf("T")
                                )}
                            </td>
                            <td>
                              {item.fechaUltimoDato
                                .toString()
                                .slice(
                                  item.fechaUltimoDato.toString().indexOf("T") +
                                    1,
                                  item.fechaUltimoDato.length - 5
                                )}
                            </td>
                            {/* <td>{item.horaUltimoDato}</td> */}
                            <td>{item.tiempoUltimoDato}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* } */}

          {this.state.showBetatemp && (
            <Row>
              <Beta></Beta>
            </Row>
          )}
        </Container>
      </>
    );
  }
}

export default DispositivosIoT;
