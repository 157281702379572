import React, { useEffect, useState } from "react";
import Api from "./../../services/api-service";

const LoadExcel = (props) => {
  function loadArchive() {
    let inputFile = document.getElementById("subir_archivo");
    const data = new FormData();

    data.append("archivo", inputFile.files[0]);
    console.log(data);

    fetch("http://localhost:55645/uploadExcel", {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        data.map((device) =>{
            Api.saveDevice(device.id,device.lat,device.lng,device.type,device.utc);
        })
      })
      .catch((error) => {
        console.error(error);
      });
  }
  return (
    <>      
      <h1>Subir archivo alcantarillas</h1>
      <a href="http://localhost:55645/getExcel"><button>Descargar formato</button></a>
      <br/><br/>
      <p>
        <label>Selecciona un nuevo archivo!</label>
      </p>
      
      <input type="file" id="subir_archivo" />
      <br/><br/>
      <button onClick={loadArchive}>Subir archivo</button>
      <div id="resultado"></div>
    </>
  );
};

export default LoadExcel;
