import React,{Component} from "react";

import {
 
  Container,
  Row,
  Col,
} from "react-bootstrap";

import "./Dashboard.css";

import Lineas from "./graficos/Lineas.js";
//import LineasZomable from  "./graficos/Lineas-zomable.js";
import LineasArea from  "./graficos/Lineas-area.js";

import Api from '../../services/api-service.js'




// function adquisicionDatos (){

//   var url = "https://iot.constecoin.com/api/cadenaFrio/dashboard/getDataDevice?producto=123456789&&numeroDatos=50";
//   return new Promise((resolve, reject) => {
//     fetch(url)
//     // fetch("http://172.16.2.87/api/modulos-cacao/getDevices")
//       .then(res => res.json())
//       .then(
//         (result) => {
//           // elements = result;
//           // elements = [...elements];
//           // console.log('elements',result);
//           // console.log(result);
//           resolve(result);
//         },
//         (error) => {
//           console.log(error);
//           reject(error);
//         }
//       )
//   })
// }


class Dashboard extends React.Component {


  

  constructor (props) {
    super(props);
    // this.updateCharts = this.updateCharts.bind(this);

    this.state = {
      dispositivoSeleccionado : '505663077429696',
      listaProcesos :  [],

      seriesData:[1,2,3,4,5],
      datos: [1,2,50,30],
      open:  false,
      devicesTable: [],
      optionsMixedChart: {
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            columnWidth: "50%"
          }
        },
        stroke: {
          width: [4, 0, 0]
        },
        xaxis: {
          categories: []
        },
        markers: {
          size: 6,
          strokeWidth: 3,
          fillOpacity: 0,
          strokeOpacity: 0,
          hover: {
            size: 8
          }
        },
        yaxis: {
          tickAmount: 5,
          min: 16,
          max: 40
        }
      },
      seriesMixedChart: [
        {
          name: "series-1",
          type: "line",
          data: [30, 40, 25, 50, 49, 21, 70, 51]
        },
        // {
        //   name: "series-2",
        //   type: "column",
        //   data: [23, 12, 54, 61, 32, 56, 81, 19]
        // },
        // {
        //   name: "series-3",
        //   type: "column",
        //   data: [62, 12, 45, 55, 76, 41, 23, 43]
        // }
      ],
      optionsRadial: {
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: "70%",
              background: "#fff",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24
              }
            },
            track: {
              background: "#fff",
              strokeWidth: "67%",
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35
              }
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -20,
                show: true,
                color: "#888",
                fontSize: "13px"
              },
              value: {
                formatter: function (val) {
                  return val;
                },
                color: "#111",
                fontSize: "30px",
                show: true
              }
            }
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#ABE5A1"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: "round"
        },
        labels: ["Percent"]
      },
      seriesRadial: [76],
      optionsBar: {
        chart: {
          stacked: true,
          stackType: "100%",
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        dataLabels: {
          dropShadow: {
            enabled: true
          }
        },
        stroke: {
          width: 0
        },
        xaxis: {
          categories: ["Fav Color"],
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        fill: {
          opacity: 1,
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            shadeIntensity: 0.35,
            gradientToColors: undefined,
            inverseColors: false,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [90, 0, 100]
          }
        },

        legend: {
          position: "bottom",
          horizontalAlign: "right"
        }
      },
      seriesBar: [
        {
          name: "blue",
          data: [32]
        },
        {
          name: "green",
          data: [41]
        },
        {
          name: "yellow",
          data: [12]
        },
        {
          name: "red",
          data: [65]
        }
      ]
    };   

    // adquision de un solo dato 
  
  
    /*   
    Api.adquisicionDatosProducto(this.state.dispositivoSeleccionado, 1).then(
      result=>{
        console.log('adquisicionDatosProducto',result)
      }    ) */

      
    Api.adquisicionDatosProducto(this.state.dispositivoSeleccionado, 25).then(
      result=>{
           // console.log('adquisicionDatosProducto',result)
            if(!result)
            {
              return;
            }

            let datos = result.map(item=>{
              return item.temperatura;
            })


            //console.log(datos)

            this.setState({seriesMixedChart: [
              {
                name: "series-1",
                type: "line",
                data: datos
                // data: datos
              },
              // {
              //   name: "series-2",
              //   type: "column",
              //   data: [23, 12, 54, 61, 32, 56, 81, 19]
              // },
              // {
              //   name: "series-3",
              //   type: "column",
              //   data: [62, 12, 45, 55, 76, 41, 23, 43]
              // }
            ]})

            this.setState({seriesMixedChart: [
              {
                name: "series-1",
                type: "line",
                data: datos
                // data: datos
              },
              // {
              //   name: "series-2",
              //   type: "column",
              //   data: [23, 12, 54, 61, 32, 56, 81, 19]
              // },
              // {
              //   name: "series-3",
              //   type: "column",
              //   data: [62, 12, 45, 55, 76, 41, 23, 43]
              // }
            ]})
        
            this.setState({datos: datos })
           
            this.setState({seriesData: [{
              name: 'series1',
              data: datos
            }]})
            

      }
    )


    Api.adquisicionAvailableProcess().then(
      result=> {
      //  console.log('adquisicionAvailableProcess',result)
        // this.listaProcesos= result;
        this.setState({listaProcesos: result})
      }, error=>{
        //console.log('error extrayendo procesos')

      }
    )



    setInterval(()=>{
      Api.adquisicionDatosProducto(this.state.dispositivoSeleccionado, 100).then(
        result=>{
          //console.log('adquisicionDatos',result)
          if(!result)
            {
              return;
            }

              let datos = result.map(item=>{
                return item.temperatura;
              })
  
  
             // console.log('datos',datos)
  
              this.setState({seriesMixedChart: [
                {
                  name: "series-1",
                  type: "line",
                  data: datos.slice(datos.length/2)
                  // data: datos
                },
                // {
                //   name: "series-2",
                //   type: "column",
                //   data: [23, 12, 54, 61, 32, 56, 81, 19]
                // },
                // {
                //   name: "series-3",
                //   type: "column",
                //   data: [62, 12, 45, 55, 76, 41, 23, 43]
                // }
              ]})
    
              this.setState({datos: datos })
              this.setState({seriesData: [{
                name: 'series1',
                data: datos
              }]})
  
          
              // name: 'series1',
              
  
  
        }
      )      
    }, 5000);


  }
  // updateCharts() {
  //   const max = 90;
  //   const min = 30;
  //   const newMixedSeries = [];
  //   const newBarSeries = [];

  //   this.state.seriesMixedChart.forEach((s) => {
  //     const data = s.data.map(() => {
  //       return Math.floor(Math.random() * (max - min + 1)) + min;
  //     });
  //     newMixedSeries.push({ data: data, type: s.type });
  //   });

  //   this.state.seriesBar.forEach((s) => {
  //     const data = s.data.map(() => {
  //       return Math.floor(Math.random() * (180 - min + 1)) + min;
  //     });
  //     newBarSeries.push({ data, name: s.name });
  //   });

  //   this.setState({
  //     seriesMixedChart: newMixedSeries,
  //     seriesBar: newBarSeries,
  //     seriesRadial: [Math.floor(Math.random() * (90 - 50 + 1)) + 50]
  //   });
  // }

  handleChangeProcess = (evento)=>{


    let auxValue = evento.target.value
    //console.log('cambio de proceso', auxValue);


    this.setState({dispositivoSeleccionado: auxValue})


  }



render() {
  return (
    <>
      <Container fluid>
        <Row>
        <div className="app">
          <h1 className="texto-titulo titulo-card titulo-pagina">Dashboard</h1>
        <Row>
          <Col md="12" className="row">
          <Col md="3">
              <div class="selectores">
                <label for="procesos">Proceso:</label>
                <select id="procesos" onChange={this.handleChangeProcess}>
                  {/* <option value="" disabled  hidden>seleccionar</option> */}
                  <option selected value="all">Todos</option>

                  {this.state.listaProcesos.map(( item, index ) => {
                        return (
                          <option value={item._id}>{item._id}</option>
                        );
                      })}
                  {/* <option value="constecoin">Constecoin</option> */}
                </select>
              </div> 
            </Col>

            {/* <Col md="2">
              <div class="selectores">
                <label for="organizacion">Organización:</label>
                <select id="organizacion" onChange={this.handleChangeOrganizacion}>
                  <option selected value="all">Todos</option>
                  <option value="semagro">Semagro</option>
                  <option value="constecoin">Constecoin</option>
                </select>
              </div> 
            </Col> */}


          </Col>
        <Col md="6">
          <Lineas seriesMixedChart = {this.state.seriesMixedChart}/>
        </Col>
        <Col md="6">
          <Lineas seriesMixedChart = {this.state.seriesMixedChart}/>
          </Col>
        <Col md="12">
          <LineasArea seriesData = {this.state.seriesData}/>
        </Col>
     

        {/* <div className="card col mixed-chart">
            <Chart
              options={this.state.optionsMixedChart}
              series={this.state.seriesMixedChart}
              type="line"
              width="500"
            />
          </div> */}

          {/* <div className="col radial-chart">
            <Chart
              options={this.state.optionsRadial}
              series={this.state.seriesRadial}
              type="radialBar"
              width="280"
            />
          </div> */}
        </Row>

        {/* <div className="row">
          <div className="col percentage-chart">
            <Chart
              options={this.state.optionsBar}
              height={140}
              series={this.state.seriesBar}
              type="bar"
              width={500}
            />
          </div>
 */}
          {/* <p className="col">
            <button onClick={this.updateCharts}>Update!</button>
          </p>
        </div> */}
      </div>
        </Row>
      </Container>
    </>
  );
}
}

export default Dashboard ;





