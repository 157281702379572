import React from 'react'
import { Card, Table, Container, Row, Col } from "react-bootstrap";


const Dispositivos = () => {

    return (
        <>
            <Container fluid>
                <Row>


                    <Col md="12">
                        <Card className="strpied-tabled-with-hover">
                            <Card.Header>
                                <Card.Title as="h4">Devices</Card.Title>
                                <p className="card-category">
                                    Tabla de dispositivos conectados
                                </p>
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                <Table
                                    className="table-hover table-striped"
                                    id="tabla-obtenida"
                                >
                                    <thead>
                                        <tr>
                                            <th className="border-0">Grupo</th>
                                            <th className="border-0">Organización</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr >
                                            <td>CLA</td>
                                            <td>gpf</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Dispositivos