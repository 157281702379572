import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import React, { useState } from "react";
import { Main, ForMap, Texto1, Texto2, Mapas } from "./elemets";
import API from "../../../services/api-service";
const YOUR_GOOGLE_API_KEY_GOES_HERE = "AIzaSyBYNDUEf0g8OQPJfiEW7ybizYPiMN_7XOU";
const styleMapa = {
  width: "80%",
  height: "80%",
};
var lati = 181;
var lngi = 181;

function sendData(e) {
  if (lati > 180) {
    alert("Seleccione la nueva ubicacion");
  } else {
    e.preventDefault();
    var id = document.getElementById("id");
    API.updateDevice(id.value, lati, lngi);
    window.location.reload();
  }
}

class Mapa extends React.Component {
  constructor(props) {
    super(props);
    this.state = { position: { lat: props.lat, lng: props.lng } };
  }

  render() {
    return (
      <>
        <Main>
          <Texto1>
            <p>Texto1</p>
          </Texto1>
          <Mapas>
            <ForMap>
              <Map
                google={window.google}
                initialCenter={{
                  lat: this.state.position.lat,
                  lng: this.state.position.lng,
                }}
                zoom={18}
                onClick={(x, y, LatLng, ev) => {
                  lati = LatLng.latLng.lat();
                  lngi = LatLng.latLng.lng();
                  this.setState(() => ({
                    position: { lat: lati, lng: lngi },
                  }));
                }}
              >
                <Marker
                  position={(this.state.position = { lat: lati, lng: lngi })}
                  ini
                />
              </Map>
            </ForMap>
          </Mapas>
          <Texto2>
            <p>Texto2</p>
          </Texto2>
        </Main>
      </>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: YOUR_GOOGLE_API_KEY_GOES_HERE,
})(Mapa);
