import React from "react";
import "./Dashboard.css";
import {
 
  Container,
  Row,
  Col,
} from "react-bootstrap";



import Lineas from "./graficos/Lineas.js";

import LineasArea from  "./graficos/Lineas-area.js";

import Api from '../../services/api-service.js'

//import Guage from "./graficos/guage.js"
import Bateria from "./graficos/guageBarritas.js"
import RadioHum from "./graficos/indicadorHumedad"
import RadioTem from "./graficos/indicadorTemperaura"





class Dashboard extends React.Component {


  

  constructor (props) {
    super(props);
    // this.updateCharts = this.updateCharts.bind(this);

    this.state = {
      dispositivoSeleccionado : '505663077429696',
      listaProcesos :  [],
      seriesData:[1,2,3,4,5],
      datos: [1,2,50,30],
      open:  false,
      devicesTable: [],
      seriesMixedChart:[],
     
      
    };   

    

      
    Api.adquisicionDatosProducto(this.state.dispositivoSeleccionado, 25).then(
      result=>{
          
            if(!result)
            {
              return;
            }

            let datos = result.map(item=>{
              return item.temperatura;
            })


            this.setState({seriesMixedChart: [
              {
                name: "series-1",
                type: "line",
                data: datos
            
              },
           
            ]})

            this.setState({seriesMixedChart: [
              {
                name: "series-1",
                type: "line",
                data: datos
               
              },
            
            ]})
        
            this.setState({datos: datos })
           
            this.setState({seriesData: [{
              name: 'series1',
              data: datos
            }]})
            

      }
    )


    Api.adquisicionAvailableProcess().then(
      result=> {
      
        this.setState({listaProcesos: result})
      }, error=>{
       

      }
    )



    setInterval(()=>{
      Api.adquisicionDatosProducto(this.state.dispositivoSeleccionado, 100).then(
        result=>{
         
          if(!result)
            {
              return;
            }

              let datos = result.map(item=>{
                return item.temperatura;
              })
  
  
          
  
              this.setState({seriesMixedChart: [
                {
                  name: "series-1",
                  type: "line",
                  data: datos.slice(datos.length/2)
                  // data: datos
                },
               
              ]})
    
              this.setState({datos: datos })
              this.setState({seriesData: [{
                name: 'series1',
                data: datos
              }]})
  
          
           
  
  
        }
      )      
    }, 5000);


  }
  
  handleChangeProcess = (evento)=>{


    let auxValue = evento.target.value
    


    this.setState({dispositivoSeleccionado: auxValue})


  }



render() {
  return (
    <>
      <Container fluid>
        <Row>
        <div className="app">
          <h1 className="texto-titulo titulo-card titulo-pagina">Dashboard GPF</h1>
        <Row>
          <Col md="12" className="row">
          <Col md="3">
              <div class="selectores">
                <label for="procesos">Proceso:</label>
                <select id="procesos" onChange={this.handleChangeProcess}>

                  <option selected value="all">Todos</option>

                  {this.state.listaProcesos.map(( item, index ) => {
                        return (
                          <option value={item._id}>{item._id}</option>
                        );
                      })}
                
                </select>
              </div> 
            </Col>
          </Col>
         {/*  indicadorres */}
         <Row xs="auto">
          <Col md="4">
          <RadioTem/>
        </Col>
        <Col md="4" >
          <RadioHum/>
        </Col>
        <Col md="4" >
          <Bateria/>
        </Col>
        </Row>

        <Col md="6">
          <Lineas seriesMixedChart = {this.state.seriesMixedChart}/>
        </Col>
        <Col md="6">
          <Lineas seriesMixedChart = {this.state.seriesMixedChart}/>
          </Col>
        <Col md="12">
          <LineasArea seriesData = {this.state.seriesData}/>
        </Col>
        
        
     
        </Row>
      </div>
        </Row>
      </Container>
    </>
  );
}
}

export default Dashboard ;





