import React, { Component } from "react";
import './ListaDispositivosGeneral.css';
import './BootstrapModal.css'
import Api from '../../services/api-service.js'

class BetaTempList extends Component {

  sendList = () => {
    this.props.parentCallback(this.state.selecc);
  }

  constructor(props) {
    super(props);

    this.state = {
      opciones: [],
      selecc: [],
      restDone: false,
    };
    this.onSeleccion = this.onSeleccion.bind(this);
  }

  componentDidMount () {
    Api.adquirirDevicesBeta().then(
      result =>{
        console.log(result)
        this.obtenerID(result)
      }
    )
  }
  /////Obtención de IDs del fetch
  obtenerID (respuesta) {
    for (var i=0; i< respuesta.length; i++) {
      var itemArray = {};
      itemArray.check = false;
      itemArray.id = respuesta[i].deviceID;
      this.state.opciones.push(itemArray);
    }
    this.state.restDone = true;
    this.setState({
      restDone: true,
    })
  }

  onSeleccion(e) {
    this.state.selecc = [];
    let { name } = e.target;
    let opciones = [...this.state.opciones];
    let indice = opciones.findIndex(el => el.id === name);
    opciones[indice].check = !opciones[indice].check;
    this.setState({
      optiones: [...opciones]
    });
    var selecciones = this.state.opciones.filter(el => el.check);
    for(var i =0; i<selecciones.length; i++){
      this.state.selecc[i] = selecciones[i].id;
    }
    console.log("Funcion de seleccion \n",this.state.selecc)
    this.sendList()
  }

  render() {
    return (
      <div>
        <div className="lista">
        <div className="bordeDivLista">ID's disponibles</div>
          {this.state.restDone && this.state.opciones.map(el => {
            return (
              <div key={el.id} className="dispositivos">
                <input
                  type="checkbox"
                  name={el.id}
                  value={el.check}
                  onChange={this.onSeleccion}
                  className="checkmark"
                />
                <label className="checkValues">{el.id}</label>
              </div>
            );
          })}
        </div>
        <div className="lista2">
        <div className="bordeDivLista2">Seleccionados</div>
        {this.state.restDone && this.state.selecc.map(el => {
            return (
              <div>
                {el}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
export default BetaTempList;
