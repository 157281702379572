// ** React Imports
import { Fragment } from 'react'
import React from "react";
// ** Custom Components
import Avatar from '../../@core/components/avatar'
import "./NotificationDropdown.css";
import { browserHistory } from 'react-router';
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";

// ** Third Party Components
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Bell, X, Check, AlertTriangle } from 'react-feather'

import Api from '../../services/api-service.js'


import {
  Button,
  Badge,
  Media,
  CustomInput,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'
import { render } from 'react-dom';
import Auth from '../../services/auth-service.js';


class NotificationDropdown extends React.Component {
  intervalID = 0;

  constructor (props) {
    super(props);

    this.state = {
      notificationsArray: [],
      notificationsBetatemp: [],
      lengthNotificationsBetatemp : 0,
      notificationsHumitemp: [],
      lengthNotificationsHumitemp : 0 ,
      
      rol: Auth.getRol(),
      organizaciones: Auth.getOrganizaciones() 
    };
    // let history = useHistory();

    this.adquirirAlertas(); 

    this.intervalID = setInterval(()=>{
      // console.log('consumir alertas')

      let aux = this.state.organizaciones.filter(item=> item.organizacion ===  'gpf')

      if(aux.length !== 0){
        this.adquirirAlertas(); 
      }
    }, 10000)
  }

  adquirirAlertas(){


    Api.adquirirIncidentDevices({organizacion: 'all', deviceID: 'all' , estado: false}).then(

      result=>{


        // console.log(result);
        // this.setState({devicesTable: result})
        // this.state.devicesTable = result;
        // this.filtroTablaAlertas(result, this.state.selectedOrganizacion);
        
        this.setState({notificationsBetatemp: result.betatemp})
        this.setState({lengthNotificationsBetatemp: result.lengthBetatemp})
        this.setState({notificationsHumitemp: result.humitemp})
        this.setState({lengthNotificationsHumitemp: result.lengthHumitemp})
        //  = [...result];


        

      }, error=>{
        // console.log('error en la adquisicion de datos', error);
      }
    )
  }

  // // ** Notification Array
  // var notificationsArray = [
  //   {
  //     img: require("../../assets/img/logo_constecoin.png").default,
  //     title: 'Módulo 3FDA no ha reportado desde 25/04/21 05:25',
  //     subtitle: 'Beta temp',

  //   //   (
  //   //     <Media tag='p' heading>
  //   //       <span className='font-weight-bolder'>Beta Temp</span>
  //   //     </Media>
  //   //   )
  //   },
  //   {
  //     img: require("../../assets/img/logo_constecoin.png").default,
  //     title: 'Módulo 3FDA no ha reportado desde 25/04/21 05:25',
  //     subtitle: 'Beta temp',
  //   //   title: (
  //   //     <Media tag='p' heading>
  //   //       <span className='font-weight-bolder'>New message</span>&nbsp;received
  //   //     </Media>
  //   //   )
  //   },
  //   {
  //     avatarContent: 'MD',
  //     color: 'light-danger',
  //     title: 'Base estación caida',
  //     subtitle: 'Beta temp',
  //   //   title: (
  //   //     <Media tag='p' heading>
  //   //       <span className='font-weight-bolder'>Revised Order 👋</span>&nbsp;checkout
  //   //     </Media>
  //   //   )
  //   },
  //   // {
  //   //   title: <h6 className='font-weight-bolder mr-auto mb-0'>System Notifications</h6>,
  //   //   switch: <CustomInput type='switch' id='primary' name='primary' inline defaultChecked />
  //   // },
  //   {
  //     avatarIcon: <X size={14} />,
  //     color: 'light-danger',
  //     title: 'Módulo lora desconectado',
  //     subtitle: 'Lora Base',
  //   //   title: (
  //   //     <Media tag='p' heading>
  //   //       <span className='font-weight-bolder'>Server down</span>&nbsp;registered
  //   //     </Media>
  //   //   )
  //   },
  //   // {
  //   //   avatarIcon: <Check size={14} />,
  //   //   color: 'light-success',
  //   //   subtitle: 'Last month sales report generated',
  //   //   title: (
  //   //     <Media tag='p' heading>
  //   //       <span className='font-weight-bolder'>Sales report</span>&nbsp;generated
  //   //     </Media>
  //   //   )
  //   // },
  //   // {
  //   //   avatarIcon: <AlertTriangle size={14} />,
  //   //   color: 'light-warning',
  //   //   subtitle: 'BLR Server using high memory',
  //   //   title: (
  //   //     <Media tag='p' heading>
  //   //       <span className='font-weight-bolder'>High memory</span>&nbsp;usage
  //   //     </Media>
  //   //   )
  //   // }
  // ]

  // const redirect = () => {
  //   console.log('redireccionamiento')
  //   history.push('/admin/home')
  //   // return <Redirect to={'/admin/home'} />
  //   }

  // ** Function to render Notifications
  /*eslint-disable */
  

  changeStateNotification (item){
    console.log('changeStateNotification',item)

    Api.setStateAlert({_id: item._id, estado: true}).then(
      result=>{
        console.log(result);
      }, error =>{
        console.log(error);

      }

    )

  }


  componentWillUnmount() {
    clearInterval(this.intervalID);
  }


  renderNotificationItems () {
    return (
      <PerfectScrollbar
        component='li'
        className='media-list scrollable-container'
        options={{
          wheelPropagation: false
        }}
      >
        {/* this.state.notificationsBetatemp.length !==0 && */}
        <h3>Betatemp</h3>
        { this.state.notificationsBetatemp.map((element, index) => {
          return (
            <div key={index}>
              <h4>{element._id}</h4>
              {element.elements.map((item, index)=>{
                return(
                  <a key={index} className='d-flex' onClick={(e) => { e.preventDefault()}}>
                  <Media
                    className={classnames('d-flex', {
                      'align-items-start': !item.switch,
                      'align-items-center': item.switch
                    })}
                  >
                    {!item.switch ? (
                      <Fragment>
                        {/* <Media left>
                          <Avatar
                            {...(item.img
                              ? { img: item.img, imgHeight: 32, imgWidth: 32 }
                              : item.avatarContent
                              ? {
                                  content: item.avatarContent,
                                  color: item.color
                                }
                              : item.avatarIcon
                              ? {
                                  icon: item.avatarIcon,
                                  color: item.color
                                }
                              : null)}
                          />
                        </Media> */}
                        <Media body className="media-body" onClick={(event)=>this.changeStateNotification(item)}>
                            <p className="titulo-notificaciones" onClick={ev=>{
                                // redirect();
                                // console.log('redireccionamiento')
                            }}>
                              {(item?.nombre)? item?.nombre: item?.deviceID}    
                            </p>
                            <div className='notificacion-sub'>
                              <a></a>
                              <small className='subtitulo-notificaciones'>{item.organizacion}</small>
                            </div>
                        </Media>
                      </Fragment>
                    ) : (
                      <Fragment>
                        {item.title}
                        {item.switch}
                      </Fragment>
                    )}
                  </Media>
                </a>
                )
              })}
            </div>
          )
        })}
        {/* ///////////////////////////////////////////////////////// humitemp ///////////////////////////// */}
        <h3>Humitemp</h3>
        { this.state.notificationsHumitemp.map((element, index) => {
          return (
            <div key={index}>
              <h4>{element._id}</h4>
              {element.elements.map((item, index)=>{
                return(
                  <a key={index} className='d-flex' onClick={(e) => { e.preventDefault()}}>
                  <Media
                    className={classnames('d-flex', {
                      'align-items-start': !item.switch,
                      'align-items-center': item.switch
                    })}
                  >
                    {!item.switch ? (
                      <Fragment>
                        {/* <Media left>
                          <Avatar
                            {...(item.img
                              ? { img: item.img, imgHeight: 32, imgWidth: 32 }
                              : item.avatarContent
                              ? {
                                  content: item.avatarContent,
                                  color: item.color
                                }
                              : item.avatarIcon
                              ? {
                                  icon: item.avatarIcon,
                                  color: item.color
                                }
                              : null)}
                          />
                        </Media> */}
                        <Media body className="media-body" onClick={(event)=>this.changeStateNotification(item)}>
                            <p className="titulo-notificaciones" onClick={ev=>{
                                // redirect();
                                // console.log('redireccionamiento')
                            }}>
                              {(item?.nombre)? item?.nombre: item?.deviceID}    
                            </p>
                            <div className='notificacion-sub'>
                              <a></a>
                              <small className='subtitulo-notificaciones'>{item.organizacion}</small>
                            </div>
                        </Media>
                      </Fragment>
                    ) : (
                      <Fragment>
                        {item.title}
                        {item.switch}
                      </Fragment>
                    )}
                  </Media>
                </a>
                )
              })}
            </div>
          )
        })}
      </PerfectScrollbar>
    )
  }
  /*eslint-enable */
  render() {
  return (
      // <></>
    <UncontrolledDropdown tag='li' className='notification-dropdown dropdown-notification nav-item mr-25 '>
    {/* <UncontrolledDropdown tag='li' className='notification-dropdown dropdown-notification nav-item mr-25 '> */}
      <DropdownToggle tag='a' className='nav-link' href='/' onClick={e => e.preventDefault()}>
        <Bell size={21} />
        <Badge pill color='danger' className='badge-up'>
          {this.state.lengthNotificationsBetatemp + this.state.lengthNotificationsHumitemp}
        </Badge>
      </DropdownToggle>
      <DropdownMenu tag='ul' right className='dropdown-menu-media dropdown-menu-items mt-0'>
        <li className='dropdown-menu-header'>
          <DropdownItem className='d-flex' tag='div' header>
            <h4 className='texto-titulo titulo-card mb-0 mr-auto'>Alertas</h4>
            <Badge tag='div' color='light-primary' pill>
                {this.state.notificationsArray.length-2} New
            </Badge>
          </DropdownItem>
        </li>
        {this.renderNotificationItems()}
        {/* <li className='dropdown-menu-footer'>
          <Button.Ripple color='primary' block>
            Read all notifications
          </Button.Ripple>
        </li> */}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}
}

export default NotificationDropdown
