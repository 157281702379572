import React, { useState } from "react";
import Prevista from "./Prevista";
import SearchIcon from '@material-ui/icons/Search';
import {
  Columnas,
  ColumnasPrincipales,
  Filas,
  Tabla,BarraBusqueda,IconoLupa,InputBusqueda, Titulo
} from "./ProcesosElementos";

function Filtrador(props) {
  const [fechain, setFechain] = useState();
  const [fechafi, setFechafi] = useState();

  const [word, setWord] = useState("");
  const [filter, setFilter] = useState(props.data);
  const handleChange = (e) => {
    let oldList = props?.data.map((person) => {
      return {
        nombreLote: person.nombreLote?.toLowerCase(),
        tipolote: person.tipolote,
        pesolote: person.pesolote,
        lineasfermetacion: person.lineasfermetacion,
        fecha: person.fecha,
      };
    });
    if (e !== "") {
      let newList = [];
      setWord(e);
      console.log("word",word)
      newList = oldList.filter((person) =>
        person.nombreLote?.includes(word?.toLowerCase())
      );
      setFilter(newList);
    } else {
      setFilter(props.data);
    }
  };

  const changeDateI = (e) => {};

  const changeDateF = (e) => {};

  var fechaInicio = new Date(fechain).getTime();
  var fechaFin = new Date(fechafi).getTime();

  var diff = fechaFin - fechaInicio;
  var dias = diff / (1000 * 60 * 60 * 24);

  // console.log(diff/(1000*60*60*24) );
  return (
    <div>
      {/* <input type="date" id='desde' onChange={e=> changeDateI(e.target.value)} />
        <input type="date" id='hasta' onChange={e=> changeDateF(e.target.value)} /> */}
        <Titulo>PROCESOS</Titulo>
        <BarraBusqueda><IconoLupa><SearchIcon/></IconoLupa><InputBusqueda onChange={(e) => handleChange(e.target.value)} placeholder='Búsqueda...'></InputBusqueda></BarraBusqueda>
      <Prevista data={filter} />
    </div>
  );
}

export default Filtrador;
