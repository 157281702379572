import React from "react";
// react-bootstrap components
import { Button, Card, Row, Col } from "react-bootstrap";
import TableContainer from "./tablas/TableContainer";

//import { render } from "react-dom";

import Api from "../../services/api-service.js";
import Auth from "../../services/auth-service.js";
import Download from "../../services/export-excel.js";

import { CSVLink } from "react-csv";

import DateTimePicker from "@mui/lab/DateTimePicker";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { Container } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";

class TablasDatosMonitorizacionTemperatura extends React.Component {
  // urlOrganizacion = '';

  constructor(props) {
    super(props);

    let fechaActual = new Date();
    let fechaInicial = fechaActual;
    fechaInicial.setTime(fechaInicial.getTime() - 1000 * 60 * 60 * 24);

    let x1 = this.props.location.search;
    console.log("this.props.location.search", this.props.location.search);
    x1 = x1.replace("?organizacion=", "");
    this.state = {
      //// selectores ////////////
      selectedDevice: "all",
      selectedOrganizacion: x1,
      devicesTable: [],
      organizaciones: [],
      intervalo: 10,

      ///// tabla de datos /////////////
      tablaDatos: [],
      tablaFiltrada: [],

      fechaInicial: fechaInicial,
      fechaFinal: new Date(),

      columns: [
        {
          Header: "Dispositivo",
          accessor: "nombre",
        },
        {
          Header: "T ambiente",
          accessor: "Tambiente",
        },
        {
          Header: "H Ambiente",
          accessor: "Hambiente",
        },
        {
          Header: "V bat (%)",
          accessor: "voltajePorcentual",
        },
        {
          Header: "Fecha",
          accessor: "fechaCompleta",
        },
      ],
      //  data: this.state.devicesTable, // previously was [listSelectNewTable]
      // Note we removed the extra wrapping nested array [] ˆ
    };

    console.log("id obtenido", this.state.selectedOrganizacion);

    this.setState({ selectedOrganizacion: x1 });

    //  <th className="border-0">Dispositivo</th>
    //  <th className="border-0">T Ambiente </th>
    //  <th className="border-0">H Ambiente </th>
    //  <th className="border-0">V bat (%)</th>
    //  <th className="border-0">Fecha</th>

    //   <td>{(item?.nombre)? item?.nombre: item?.deviceID} </td>
    //   <td>{item?.Tambiente}</td>
    //   <td>{item?.Hambiente}</td>
    //   <td>{item?.voltajePorcentual}  </td>
    //   <td>{item?.fechaCompleta.toString().slice(0, item?.fechaCompleta.toString().indexOf('T'))}  ||   {item?.fechaCompleta.toString().slice(item?.fechaCompleta.toString().indexOf('T')+1, item?.fechaCompleta.length - 5)}</td>

    // console.log('auxOrganizaciones',auxOrganizaciones)

    this.actualizarOrganizaciones();
    this.actualizarDataDevices();
    this.funcionProbando();
    this.actualizarTablaDispositivos();
    setInterval(() => {
      this.actualizarDataDevices();
    }, 60000);
  }

  componentWillMount() {
    console.log(this.state.selectedOrganizacion);

    // this.actualizarOrganizaciones();
    // this.actualizarDataDevices();

    // this.actualizarTablaDispositivos();
    // setInterval(()=>{
    //   this.actualizarTablaDispositivos();
    // }, 60000);
  }

  async actualizarOrganizaciones() {
    let auxOrganizaciones = await Auth.getOrganizaciones();

    if (!auxOrganizaciones) {
      this.setState({ organizaciones: [] });
      console.log("auxOrganizaciones", auxOrganizaciones);
    } else {
      this.setState({ organizaciones: auxOrganizaciones });
      console.log("auxOrganizaciones", this.state.organizaciones);
    }
  }

  actualizarDataDevices() {
    Api.obtenerDatosDispoHumitemp({
      dispositivos: this.state.selectedDevice,
      organizacion: this.state.selectedOrganizacion,
      estado: "all",
      fechaInicial: this.state.fechaInicial,
      fechaFinal: this.state.fechaFinal,
      intervalo: this.state.intervalo,
    }).then(
      (result) => {
        console.log(result);
        this.setState({ tablaDatos: result });

        let tablaAux = result.map((item) => {
          let auxNombre = this.state.devicesTable.filter(
            (element) => element.deviceID === item.deviceID
          )[0];
          let aux = auxNombre.nombre ? auxNombre.nombre : auxNombre.deviceID;
          // console.log(aux);
          item.nombre = aux;
          item.voltajePorcentual = this.calculoPorcentualBateria(
            item.Vbat
          ).toFixed(2);

          return item;
        });

        console.log(tablaAux);

        this.setState({
          tablaFiltrada: result.map((item) => {
            item.voltajePorcentual = this.calculoPorcentualBateria(
              item.Vbat
            ).toFixed(2);
            return item;
          }),
        });
      },
      (error) => {
        console.log("error en la adquisicion de datos");
      }
    );
  }

  actualizarTablaDispositivos() {
    Api.obtenerDispositivosHumitemp({
      organizacion: this.state.selectedOrganizacion,
      estado: "all",
    }).then(
      (result) => {
        console.log(result);
        this.setState({ devicesTable: result });
      },
      (error) => {
        console.log("error en la adquisicion de datos");
      }
    );
  }

  funcionProbando() {
    console.log("Entrnado a al funcion");
  }

  handleChangeOrganizacion = (event) => {
    this.state.selectedOrganizacion = event.target.value;
    this.actualizarDataDevices();
  };

  handleChangeDevice = (event) => {
    this.state.selectedDevice = event.target.value;
    this.actualizarDataDevices();
  };

  handleChangeFechaInicial = (event) => {
    console.log(event);
    this.setState({ fechaInicial: new Date(event) });
    // this.state.fechaInicial = event.target.value;
    // this.actualizarDataDevices();
  };
  handleChangeFechaFinal = (event) => {
    console.log(event);
    this.setState({ fechaFinal: new Date(event) });

    // this.state.fechaInicial = event.target.value;
    // this.actualizarDataDevices();
  };

  handleChangeIntervalo = (event) => {
    console.log(event);
    this.setState({ intervalo: event.target.value });

    // this.state.fechaInicial = event.target.value;
    // this.actualizarDataDevices();
  };

  handleClick = (event) => {
    console.log(event);
    // this.setState({fechaFinal: new Date(event)})

    // this.state.fechaInicial = event.target.value;
    this.actualizarDataDevices();
  };

  calculoPorcentualBateria(voltajeX100) {
    let voltaje = voltajeX100 / 100;

    let Vmax = 3.32;
    let Vmin = 2.45;

    let Pmin = 10;
    let Pmax = 100;

    if (voltaje >= Vmax) {
      voltaje = Vmax;
    }

    if (voltaje <= 0) {
      voltaje = 0;
    }

    let Vporcentaje = ((Pmax - Pmin) / (Vmax - Vmin)) * (voltaje - Vmin) + Pmin;

    return Vporcentaje;
  }

  render() {
    return (
      <>
        <Container fluid className="w3-panel ">
          <Row>
            <Col md="3">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Fecha Inicial"
                  value={this.state.fechaInicial}
                  onChange={this.handleChangeFechaInicial}
                />
              </LocalizationProvider>
            </Col>

            <Col md="3">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Fecha Final"
                  value={this.state.fechaFinal}
                  onChange={this.handleChangeFechaFinal}
                />
              </LocalizationProvider>
            </Col>
            <Col md="2">
              <TextField id="outlined-basic" label="Intervalo" variant="outlined" value={this.state.intervalo} onChange={this.handleChangeIntervalo} />
            </Col>
            <Col md="2">
              <Button className="buttonGeneral" onClick={this.handleClick}>
                Buscar en Rango
              </Button>
            </Col>
          </Row>
          <Row>
            {/* <Col md="2">
          <div class="selectores">
            <label for="organizaciones">Organizaciones:</label>
            <select id="organizaciones" onChange={this.handleChangeOrganizacion}>
              <option selected value="all">Todos</option>
              {this.state.organizaciones.map(( item, index ) => {
                console.log('this.state.organizaciones', item)
                        return (
                          <option value={item.organizacion}>{item.organizacion}</option>
                        )
                      })}
            </select>
          </div> 
        </Col> */}
            <Col md="2">
              <div class="selectores">
                <label for="dispositivos">Dispositivos:</label>
                <select id="dispositivos" onChange={this.handleChangeDevice}>
                  <option selected value="all">
                    Todos
                  </option>
                  {this.state.devicesTable.map((item, index) => {
                    return (
                      <option value={item.deviceID}>
                        {item?.nombre ? item?.nombre : item?.deviceID}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Col>

            <Row>
              <Col md="2">
                <Download
                  head={
                    this.state.tablaFiltrada.length === 0
                      ? []
                      : this.state.columns
                  }
                  data={this.state.tablaFiltrada}
                />
                {/* <Download head={ (this.state.tablaFiltrada.length==0)? []: Object.keys(this.state.tablaFiltrada[0])} data={this.state.tablaFiltrada}/> */}
                {/* <div>
              <ReactToExcel
                className="btn btn-primary buttonGeneral"
                table="tabla-obtenida"
                filename="Humitemp"
                sheet="sheet 1"
                buttonText="Exportar como xls"
              />
            </div>  */}
              </Col>

              <Col md="2">
                <div>
                  <CSVLink
                    data={this.state.tablaFiltrada}
                    filename={"Humitemp.csv"}
                  >
                    <Button className="buttonGeneral">
                      {" "}
                      Exportar como csv
                    </Button>
                  </CSVLink>
                </div>
              </Col>
              {/* <Col md="2">
                <div>
                  <Button
                    className="buttonGeneral"
                    onClick={() => {
                      this.funcionProbando();
                    }}
                  >
                    Exportar por horas
                  </Button>
                </div>
              </Col> */}
            </Row>

            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">Temperaturas</Card.Title>
                  <p className="card-category">Tablas de temperaturasaaaa</p>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <TableContainer
                    columns={this.columns}
                    data={this.state.tablaFiltrada}
                    id="tabla-obtenida"
                  />

                  {/* <div className="pagination">
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                      {'<<'}
                    </button>{' '}
                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                      {'<'}
                    </button>{' '}
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                      {'>'}
                    </button>{' '}
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                      {'>>'}
                    </button>{' '}
                    <span>
                      Page{' '}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>{' '}
                    </span>
                    <span>
                      | Go to page:{' '}
                      <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                          const page = e.target.value ? Number(e.target.value) - 1 : 0
                          gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                      />
                    </span>{' '}
                    <select
                      value={pageSize}
                      onChange={e => {
                        setPageSize(Number(e.target.value))
                      }}
                    >
                      {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </div> */}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default TablasDatosMonitorizacionTemperatura;
