import React, { Component } from "react";
import MapaGoogle_2 from './MapaGoogle_2.js'
import {
  Card,
  Row,
  Col,
} from "react-bootstrap";

class PoligonalMapa extends Component {

  constructor(props) {
    super(props);

    this.state = {
    };
  }
  poligonalMapaCbk=(estado)=>{
    // console.log("Estado recibido en Poligonal Mapa", estado)
    this.props.parentCallback(estado)
  }

  render() {
    return (
      <>
        <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <div className="body-card">
            <MapaGoogle_2 parentCallback={this.poligonalMapaCbk}/>
            </div>
          </Card>
        </Col>
      </Row>
      </>
    );
  }
}
export default PoligonalMapa;
