import React, {useState} from 'react'
import { Button,Modal } from 'react-bootstrap'
// import 'w3-css/w3.css';
import Tx from '../components/input';

import './formulario/form.css'


export default function BootstrapModa(props) {


    const [show, setShow] = useState(false);
    const [datos, setDatos] = useState({
      nombre:'',
     cantidad:'',
     codigoDeBarras:'',
      descripcion:'',
      error:null
    });


    const expresiones = {
      //usuario: /^[a-zA-Z0-9\_\-]{4,16}$/, // Letras, numeros, guion y guion_bajo
      // nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
      // password: /^.{12}$/, // 4 a 12 digitos.
      //correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
      // cantidad: /^\d{1,5}$/ // 7 a 14 numeros.
    }
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const  links ='https://iot.constecoin.com/api/cadena-frio/inventario/inventario/agregarProducto'


    function enviarDatos (e) {
      var envio  ={producto:{
        nombre:datos.nombre,
       cantidad:parseInt(datos.cantidad),
       codigoDeBarras:datos.codigoDeBarras,
       descripcion:datos.descripcion
    }   }
        e.preventDefault ();
        
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(envio)}
       
        console.log(JSON.stringify(envio)  )
        fetch(links, requestOptions)
        .then(response => console.log(response))
          setDatos({  nombre:'',
          cantidad:'',
          codigoDeBarras:'',
           descripcion:''})
       setShow(false)
    }
    
    const ingreso= (event)=>{
      //console.log(event.target.value)
      setDatos({
        ...datos,[event.target.name]:event.target.value
      })
    }
// console.log(datos.error)
   
    return (
        
        <>
         
  
  
          <Modal
        
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className=" w3-modal-content{background-color: transparent} w3-animate-zoom" style={{maxwidth:600}}>
            <Modal.Header closeButton className="w3-rigth">
            
            </Modal.Header>
            <Modal.Body>
             
             
      <div className="w3-row">  
        
        <div className="w3-rest  "   >
           <h1 className="texto-titulo titulo-card titulo-pagina">Registro de productos  </h1>
    
        <form onSubmit={enviarDatos }>
            <table className="w3-table">
           <tfoot>
        <tr>
          <td> 
        
            <Tx
            
             etiqueta = "Nombre"
             tipo = "text"
             name='nombre'
             onChange={ingreso}
             value ={datos.nombre}
             expresion ={expresiones.nombre}
             
             error={datos.error}
            />
          </td>
          <td>
          <Tx 
          etiqueta ="Cantidad"
          tipo = "number"
          name='cantidad'
          onChange={ingreso}
          value ={datos.cantidad}
             expresion ={expresiones.cantidad}

            /> 
          </td>
        </tr>
        <tr>
          <td  colSpan="2">
          <Tx
           etiqueta ="Codigo de barras"
           tipo = "text" 
           name='codigoDeBarras'
           onChange={ingreso}
           value ={datos.codigoDeBarras}
           expresion ={expresiones.password}  /> 
          </td>
         </tr>
        <tr>
        <td  colSpan="2">
          <Tx
           etiqueta ="Descripción"
           tipo = "text"
           name='descripcion'
           onChange={ingreso}
           value ={datos.descripcion}
           expresion ={expresiones.nombre}  /> 
          </td>
        </tr>
        </tfoot>
           </table>
           <Button  className="buttonGeneral" type="submit">Crear </Button>
           </form>

        </div>
        </div>
  

            </Modal.Body>
            
          </Modal>

          <Button  className="buttonGeneral"  onClick={handleShow}>
            Registro / Actualizacion
          </Button>
        </>
      );
    }