import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import CloseIcon from "@material-ui/icons/Close";
import Api from "../../services/api-service";
import {
  BotonesDerecha,
  BotonesIzquierda,
  ContendorBotones,
  ContenedorEncabezado,
  ContenedorTitulo,
  Main,
  Tabla,
  Td,
  Th,
  ParaSelect,
  LabelSelect,
  Tr,
  Divfiltro,
  CoFiltroIn,
  SelectFiltro,
  Trc,
  BotonModal,
  TituloModal,
  Mapaa,
  CoFiltroInD,
  DivfiltroD,
  SelectFiltroD,
} from "./RegistroComponentes";
import Mapa from "./Mapa";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Notificaciones = () => {
  const [deviceId, setDeviceId] = useState("null");
  const [cantidad, setCantidad] = useState("10");
  const [listaDevices, setListaDevices] = useState([]);

  const [dato, setDatos] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  // Se utiliza el Hook de useEffect para el consumo de la Api y poder obtener la informacion que nos puede brindar, cada ves que cambie ya se el dispositivo o la cantidad este hara un consumo rendirizado.

  useEffect(() => {
    Api.obtenerDatosDispositivos({
      deviceID: deviceId,
      cantidad: cantidad,
    }).then(
      (result) => {
        console.log(result);
        setDatos(result);
        // setLoading(false);
      },
      (error) => {
        console.log("Obtencion erronea");
      }
    );
  }, [cantidad, deviceId]);

    useEffect(() => {
      Api.obtenerIdDispositivos().then(
        (result) => {
          console.log(result);
          setListaDevices(result);
          // setLoading(false);
        },
        (error) => {
          console.log("Obtencion erronea");
        }
      );
    }, []);

  function ActivarBoton() {
    if (deviceId != "null") {
      openModal();
      console.log("Entro a la consola " + deviceId);
    }
  }

  console.log("=> El id de dispositivo es  : " + deviceId);
  return (
    <>
      <Main>
        <ContenedorEncabezado>
          <ContenedorTitulo>Registros</ContenedorTitulo>
          <ContendorBotones>
            <CoFiltroInD>
              <DivfiltroD>
                <BotonesIzquierda id="dispo">Dispositivo</BotonesIzquierda>

                <SelectFiltroD
                  id="dispo"
                  onChange={(event) => setDeviceId(event.target.value)}
                >
                  <option value="null" selected>
                    Selecciona un Dispositivo
                  </option>
                  {listaDevices?.map((item, i) => {
                    return (
                      <option value={item.deviceID}>{item.deviceID}</option>
                    );
                  })}
                </SelectFiltroD>
              </DivfiltroD>
            </CoFiltroInD>
            <BotonesDerecha onClick={ActivarBoton}>
              Ver ubicacion en el Mapa
            </BotonesDerecha>
            <Modal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
              ariaHideApp={false}
            >
              <BotonModal onClick={closeModal}>
                <CloseIcon />
              </BotonModal>
              <TituloModal>
                Ubicacion del dispositivo en el Mapa 🗺️📍
              </TituloModal>
              <Mapaa>
                <Mapa data={dato?.[0]} />
              </Mapaa>
            </Modal>
          </ContendorBotones>
        </ContenedorEncabezado>
        <Tabla>
          <Tr>
            <Th>deviceID</Th>
            <Th>ALARM_status</Th>
            <Th>BatV</Th>
            <Th>FW</Th>
            <Th>LON</Th>
            <Th>MD</Th>
            <Th>latitude</Th>
            <Th>longitude</Th>
            <Th>time</Th>
            <Th>rssi</Th>
          </Tr>
          {dato?.map((item, i) => {
            return (
              <Trc>
                <Td>{item.deviceID}</Td>
                <Td>{item.ALARM_status ? "True" : "False"}</Td>
                <Td>{item.BatV}</Td>
                <Td>{item.FW}</Td>
                <Td>{item.LON ? "True" : "False"}</Td>
                <Td>{item.MD}</Td>
                <Td>{item.latitude}</Td>
                <Td>{item.longitude}</Td>
                <Td>{item.time}</Td>
                <Td>{item.rssi}</Td>
              </Trc>
            );
          })}
        </Tabla>

        <CoFiltroIn>
          <Divfiltro>
            <label>Escoge los ultimos valores : &nbsp;</label>
            <SelectFiltro onChange={(event) => setCantidad(event.target.value)}>
              <option value="10" selected>
                10
              </option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="">Todos</option>
            </SelectFiltro>
          </Divfiltro>
        </CoFiltroIn>
      </Main>
    </>
  );
};

export default Notificaciones;
