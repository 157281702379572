import React, { Component } from "react";
import BotonesConfig from "./BotonesConfig.js";
import CercaCircular from './CercaCircular.js';
import CercaPoligonal from './CercaPoligonal.js';
import CircularMapa from "./CircularMapa.js";
import PoligonalMapa from "./PoligonalMapa.js";

class MapaOrManual extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ingresoBoton: "",
      showComp: false,
      datosCircular: [{radio: "", lat: "", lon:""}],
      datosPoligal: [{}],
    };
  }

  cbkMapOrManual = (stateRx) =>{
    console.log("Estado antes de upd en MapaOrManual", this.state)
    this.state.ingresoBoton = stateRx.ingresoBoton;
    this.state.showComp = stateRx.showComp;
    this.setState({
      ingresoBoton: stateRx.ingresoBoton,
      showComp: stateRx.showComp,
    })
    console.log("Estado después de upd en MapaOrManual", this.state)
  }

  datosCercaCirc = (circular) => {
    console.log("Estado al entrar a datosCercaCirc", this.state)
    this.state.datosCircular = circular;
    this.state.datosPoligal = [{}];
    console.log("Estado al salir de datosCercaCirc", this.state)
    this.props.parentCallback(this.state)
  }

  datosCercaPolig = (poligonal) =>{
    console.log("Estado entrando a datosCercaPolig", this.state)
    console.log("Recibido de ingresado", poligonal.latitudes.length)
    for(var i=0; i<poligonal.longitudes.length;i++){
      this.state.datosPoligal[i] = {lat: poligonal.latitudes[i], lon: poligonal.longitudes[i]}
     }
    this.state.datosCircular = [{radio: "", lat: "", lon:""}];
    console.log("Estado al salir de datosCercaPolig", this.state)
    this.props.parentCallback(this.state)
  }

  datosCercaPoligMap = (poligonal) =>{
    console.log("Estado entrando a datosCercaPoligMap", this.state)
    this.state.datosCircular = [{radio: "", lat: "", lon:""}];
    this.state.datosPoligal = poligonal.posiciones;
    this.props.parentCallback(this.state)
  }

  render() {
    return (
      <>
        <BotonesConfig parentCallback={this.cbkMapOrManual.bind(this)}/>
        {/* {Manual} */}
        {this.state.showComp && this.props.tipoCerca === "Circular" && this.state.ingresoBoton === "manual" &&
        <CercaCircular parentCallback={this.datosCercaCirc.bind(this)}/>}
        {this.state.showComp && this.props.tipoCerca === "Poligonal" && this.state.ingresoBoton === "manual" &&
        <CercaPoligonal parentCallback={this.datosCercaPolig.bind(this)}/>}
        {/* {Mapa} */}
        {this.state.showComp && this.props.tipoCerca === "Circular" && this.state.ingresoBoton === "mapa" &&
        <CircularMapa parentCallback={this.datosCercaCirc.bind(this)}/>}
        {this.state.showComp && this.props.tipoCerca === "Poligonal" && this.state.ingresoBoton === "mapa" &&
        <PoligonalMapa parentCallback={this.datosCercaPoligMap.bind(this)}/>}
      </>
    );
  }
}
export default MapaOrManual;
