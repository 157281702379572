import React, { Component } from 'react';
import { Map, Marker, Polyline, Polygon, GoogleApiWrapper } from 'google-maps-react';

class MapaGoogle_2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contador: 0,
      markers: new Object(),
      limite: 5,
      posiciones: new Object(),
    };
    this.onClick = this.onClick.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
  }

  onClick(t, map, coord) {
    if (this.state.contador < this.state.limite) {
      const { latLng } = coord;
      const lat = latLng.lat();
      const lng = latLng.lng();
      this.state.contador = this.state.contador + 1;


      if (this.state.markers.length >= 0) {
        this.setState(previousState => {
          return {
            markers: [
              ...previousState.markers,
              {
                title: "",
                name: "",
                position: { lat, lng }
              }
            ]
          };
        });
      } else {
        this.setState({
          markers: [
            {
              title: "",
              name: "",
              position: { lat, lng }
            }
          ]
        })
      }

      if (this.state.posiciones.length >= 0) {
        this.setState(previousState => {
          return {
            posiciones: [
              ...previousState.posiciones,
              { lat, lng }
            ]
          };
        });
      } else {
        this.setState({
          posiciones: [
            { lat, lng }
          ]
        })
      }

    }
    console.log("marcadores", this.state.markers, "\n posiciones", this.state.posiciones)
    this.props.parentCallback(this.state)

  }

  elimina(array, elem) {
    var var2 = array.filter(function (e) {
      return e.position.lat !== elem.position.lat &&
        e.position.lng !== elem.position.lng
    });
    return var2
  }

  elimina1(array, elem) {
    var var2 = array.filter(function (e) {
      return e.lat !== elem.lat &&
        e.lng !== elem.lng
    });
    return var2
  }

  onMarkerClick(event) {
    if (this.state.contador !== 0) {
      this.setState({
        contador: this.state.contador - 1
      })
    }
    var var1 = this.elimina(this.state.markers, { title: "", name: "", position: { lat: event.position.lat, lng: event.position.lng } })
    var var3 = this.elimina1(this.state.posiciones, { lat: event.position.lat, lng: event.position.lng })
    this.setState({
      markers: var1,
      posiciones: var3
    })

    console.log("Nuevos marcadores", this.state.markers, "\n Nuevas posiciones", this.state.posiciones,
      "\n contador", this.state.contador)
    // this.props.parentCallback(this.state)  
  }


  render() {
    return (
      <div>
        <Map
          google={this.props.google}
          initialCenter={{
            lat: -0.163202,
            lng: -78.481166
          }}
          style={{ width: "100%", margin: "auto" }}
          className={"map"}
          zoom={17}
          onClick={this.onClick}
        >
          {this.state.markers.length >= 0 && this.state.markers.map((marker, index) => (
            <Marker
              key={index}
              title={marker.title}
              name={marker.name}
              position={marker.position}
              onClick={this.onMarkerClick}
            />
          ))
          }

          {this.state.contador >= 2 &&
            <Polygon
              paths={this.state.posiciones}
              strokeColor="#0000FF"
              strokeOpacity={0.8}
              strokeWeight={2}
              fillColor="#0000FF"
              fillOpacity={0.35} />
          }

        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: ("AIzaSyBYNDUEf0g8OQPJfiEW7ybizYPiMN_7XOU")
})(MapaGoogle_2);