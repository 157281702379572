import React, {useState, useEffect} from 'react'
import { Contenedor, ContenedorFormulario, Formulario, Infor, Input, Lorem, Main, Select,
  Columnas, ColumnasPrincipales, Filas, Par, Tabla, TrC  } from './NotificacionElementos'
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap'
import Api from '../../services/api-service.js'
import TableContainer from "./TableContainer"
import { Button, Card, Container, Row, Col} from "react-bootstrap";

const columns = [
  {
    Header: "Teléfono",
    accessor: "deviceID",
  },
  {
    Header: "Fecha",
    accessor: "fechaEcuador",
  },
  {
    Header: "sms_content",
    accessor: "sms_cont",
  }
]

const Notificacion = () => {


const [datos, setDatos] = useState(false);
const [datos1, setDatos1] = useState([]);
const [datos2, setDatos2] = useState([]);

const setearDatos = async function (){
    var mensajes = await obtenerData('mensaje')
    mensajes = fechaEcuador(mensajes)
    setDatos1(mensajes)
    console.log("mensajes", mensajes)
    var alarmas = await obtenerData('alarma')
    alarmas = fechaEcuador(alarmas)
    setDatos2(alarmas)
    console.log("alarmas", alarmas)
    // setState(prevState => ({
    //   ...prevState,
    //   [name]: value
    // }));
}

function obtenerData (nombre){
  return new Promise((resolve,reject)=>{
    Api.alcantarillasInfoAM(nombre)
    .then(
    result=>{
      resolve (result)
    }, error=>{
      resolve (null)
    }
  );
  })
}

function fechaEcuador(datosGET){
  datosGET.map((data,index)=>{
    var fecha1 = new Date(data.date)
    var mes
    if (( fecha1.getMonth() + 1 ) < 10){
      mes = '0'+ (fecha1.getMonth() + 1)
    } else {
      mes = (fecha1.getMonth() + 1)
    }
    var dia
    if (( fecha1.getDate()) < 10){
      dia = '0'+ (fecha1.getMonth())
    } else {
      dia = ( fecha1.getDate())
    }
    var fecha =  fecha1.getFullYear()+ '-' + mes + '-' + dia;
    var hora1
    if (( fecha1.getHours()) < 10){
      hora1 = '0'+ (fecha1.getHours()-5)
    } else {
      hora1 = ( fecha1.getHours())
    }
    var min
    if (( fecha1.getMinutes()) < 10){
      min = '0'+ (fecha1.getMinutes())
    } else {
      min = (fecha1.getMinutes())
    }
    var sec
    if (( fecha1.getSeconds()) < 10){
      sec = '0'+ (fecha1.getSeconds())
    } else {
      sec = (fecha1.getSeconds())
    }
    var hora = 'T' + hora1+ ':' + min + ':' + sec;
    var fechaYHora = fecha + '' + hora;
    datosGET[index].fechaEcuador = fechaYHora
  })
  return datosGET
}

function sendWhats(){
  var request = require('request');
  var options = {
    'method': 'POST',
    'url': 'https://graph.facebook.com/v13.0/100892332641253/messages',
    'headers': {
      'Authorization': 'Bearer EAAFzMnZBvzDkBADTSzMMutFhhHDRTKD1mwot6rM8pN1viDRTJs2GqLXSSfWA7Hah8HKwP3iL0JfKvxgviJQ6Ay7ewmZBzFy14hM2qKErJg87ZBAbVbQyCZCW0gSupqK4K2b7Cfe9JtVZCO3yU4hAzZCsKL2LaZAd6RwHSofybyRxNx9GyndoD5XRWfoo6Uk0RC54nJ7FfRyKwZDZD',
      'Content-Type': 'application/json'
    },
    'body':'{ "messaging_product": "whatsapp", "to": "593961266100", "type": "template", "template": { "name": "hello_world", "language": { "code": "en_US" } } }'
  };

  request(options, function (error, response) {
    if (error) throw new Error(error);
    console.log(response.body);
  });
}

const CargarDatos=function(e){
  // sendWhats()
    const opcion = e.target.value;
    // console.log(opcion);
    setDatos(opcion);

}

const [ dropdown, setDropdown]=useState(false);

const abrirCerrarDrop=()=>{
  setDropdown(!dropdown);
}

useEffect(() => {
  setearDatos()
}, []);

return (
  <>
    <Container>
        <Row>
          <section className="w3-center w3-panel">
          </section>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Sensor para alcantarillas</Card.Title>
                  <p className="card-category">
                    Tablas de notificaciones enviadas por el sensor de alcantarillas
                  </p>
              </Card.Header>

            </Card>
          </Col>
        </Row>
    </Container>
      <Main>
      <Contenedor>Mensajes Keep Alive</Contenedor>
      <TableContainer columns={columns} data={datos1} id="tabla-obtenida"/>
      <Contenedor>Mensajes ALERTA</Contenedor>
      <TableContainer columns={columns} data={datos2} id="tabla-obtenida"/>
      </Main>
  </>
  )
}

export default Notificacion