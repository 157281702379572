import { Map, Marker, GoogleApiWrapper, InfoWindow } from "google-maps-react";
import React, { useState, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button/index";
import {
  BotonDatos,
} from "./MapElements";
import InfoWindowEx from "./windows"
const YOUR_GOOGLE_API_KEY_GOES_HERE = "AIzaSyBYNDUEf0g8OQPJfiEW7ybizYPiMN_7XOU";

const Mapa = (props) => {


  const [selectedElement, setSelectedElement] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [showInfoWindow, setInfoWindowFlag] = useState(true);

  const [probandooo, setProbando] = useState(false)

  let history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const obteOrganizacion = query.get("organizacion");
  const tipoD = query.get("tipo");

  // props.devices.map((index, i) => {
  //   console.log(index)
  // })


  console.log("-----------------*--------------")
  console.log(selectedElement)


  function probando(e) {
    let url = `/admin/viewDevicewt32?id=${e}&organizacion=${obteOrganizacion}&tipo=${tipoD}&view=all`
    history.push(url);
  }


  return (
    <>
      <Map
        google={window.google}
        initialCenter={{
          lat: -0.2520004,
          lng: -79.1700552,
        }}
        style={props.styleMapa}
        zoom={14}
      >
        {props.devices.map((device) => {
          return (
            <Marker
              id={device.deviceID}
              position={{
                lat: device.coordenadas.lat,
                lng: device.coordenadas.lng,
              }}
              onClick={(props, marker) => {
                setSelectedElement(device);
                setActiveMarker(marker);
                setProbando(true);
              }}

            />
          );
        })}






        {selectedElement ? (
          <InfoWindowEx
            visible={showInfoWindow}
            marker={activeMarker}
            onCloseClick={() => {
              setSelectedElement(null);
            }}
          >
            <div>
              <div></div>
              <p>{selectedElement.nombre}</p>
              <BotonDatos type="button" onClick={(e) => probando(selectedElement.deviceID)}>
                Ver datos
              </BotonDatos>
            </div>
          </InfoWindowEx>
        ) : null}
      </Map>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: YOUR_GOOGLE_API_KEY_GOES_HERE,
})(Mapa);
