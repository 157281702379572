
import React, { Component } from "react";
import { useLocation, NavLink } from "react-router-dom";

import { Nav } from "react-bootstrap";

import "./Top.css";
// import logo from "assets/img/reactlogo.png";

function Top({ color, image, routes }) {
  const location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  return (
    <div className="top" data-image={image} data-color={color}>
      <div
        className="sidebar-background"
        style={{
          backgroundImage: "url(" + image + ")",
        }}
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start">
          <a
            href="https://constecoin.com"
            className="simple-text logo-mini mx-1"
          >
            <div>
              <img className = "logo-img"
                src={require("../../assets/img/logo_constecoin.png").default} 
                alt="..."
              />
            </div>
          </a>
          {/* <a className="simple-text" href="http://www.creative-tim.com">
            CONSTECOIN
          </a> */}
        </div>
        {/* <Nav>
          {routes.map((prop, key) => {
            if (!prop.redirect)
              return (
                <li
                  // className={
                  //   prop.upgrade
                  //     ? "active active-pro"
                  //     : activeRoute(prop.layout + prop.path)
                  // }
                  // key={key}
                >
                  <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
              );
            return null;
          })}
        </Nav> */}
      </div>
    </div>
  );
}

// max-width: 200px;



export default Top;
