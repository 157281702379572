import React, { Component } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

const YOUR_GOOGLE_API_KEY_GOES_HERE = "AIzaSyBYNDUEf0g8OQPJfiEW7ybizYPiMN_7XOU";
const containerStyle = {
  // position: 'relative',  
  width: '100%',
  height: '100%'
};

const style = {
  width: '100%',
  height: '100%'
}


export class MapaComp extends Component {


  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11
  };

  render() {
    return (
      <>

        <Map google={this.props.google}
          initialCenter={{
            lat: this.props.latitud,
            lng: this.props.longitud
          }}
          style={style}
          //   containerStyle={containerStyle} 
          zoom={16}

        >
          <Marker
            title={'Posicion del usuario'}
            name={'Posicion del usuario'}
            position={{ lat: this.props.latitud, lng: this.props.longitud }} />
        </Map>
      </>
    );
  }
}


export default GoogleApiWrapper(
  (props) => ({
    apiKey: YOUR_GOOGLE_API_KEY_GOES_HERE,
    language: props.language,
  }
  )
)(MapaComp)
