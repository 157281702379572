import React from "react";
import ReactDOM from "react-dom";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import PaginationComponent from "react-reactstrap-pagination";
import "bootstrap/dist/css/bootstrap.min.css";

class PageIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPage: 1,
    };

    this.handleSelected = this.handleSelected.bind(this);
  }

  handleSelected(selectedPage) {
    console.log("Pagina actual", selectedPage);
    this.setState({ selectedPage: selectedPage });
  }

  render() {
    return (
      <PaginationComponent
        totalItems={50}
        pageSize={10}
        onSelect={this.handleSelected}
        maxPaginationNumbers={5}
        defaultActivePage={1}
        firstPageText={"<<"}
        previousPageText={"Anterior"}
        nextPageText={"Siguiente"}
        lastPageText={">>"}
      />
    );
  }
}
export default PageIndex;
